/* eslint-disable react/no-array-index-key */
import { Flex, Paragraph } from 'theme-ui';
import { Auth } from 'aws-amplify';
import * as moment from 'moment';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { TableHeaderItem, Pagination, TableRowItem } from '../components/table';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';

export const getMonitoringAlerts = async (
    state,
    updateState,
    direction = 'next',
    paginationData,
    setPaginationData
) => {
    updateState({
        ...state,
        loading: true,
    });

    const pageArray = [...paginationData.paginationArray];

    let pageNumber;
    let token;

    if (direction === 'start') {
        pageNumber = paginationData.pageNumber;
        token = pageArray[pageNumber];
    } else if (direction === 'next') {
        pageNumber = paginationData.pageNumber + 1;
        token = pageArray[pageNumber];
    } else {
        pageNumber = paginationData.pageNumber - 1;
        token = pageArray[pageNumber];
    }

    const { data } = await axios({
        url: process.env.REACT_APP_TX_MONITORING_API,
        method: 'POST',
        data: {
            query: `query { 
                adminSearchMonitoringChecks(user_id: "ALL", limit:13, ${
                    token !== '' ? `pagination_token: "${token}"` : ''
                }) { 
                    success
                    checks {
                        alerts {
                            type
                            description
                        }
                        user_id
                        email
                        timestamp
                    }
                    pagination_token
                }
            }`,
            variables: null,
        },
    });

    const result = data.data?.adminSearchMonitoringChecks;

    if (['next', 'start'].includes(direction)) {
        pageArray[pageNumber + 1] = result?.pagination_token;
    }

    const newPaginationData = {
        paginationArray: pageArray,
        pageNumber,
    };

    updateState({
        checks: result?.checks,
        loading: false,
    });

    setPaginationData(newPaginationData);

    return result;
};

const defaultState = {
    checks: [],
    paginationTokenPrev: '',
    paginationTokenNext: '',
    paginationArray: [''],
    pageNumber: 0,
    loading: true,
    email: '',
};

const KycComplete = ({ logOut, paginationData, setPaginationData }) => {
    const [state, updateState] = useState(defaultState);
    const [showMenu, toggleMenu] = useState(true);

    const history = useHistory();

    useEffect(() => {
        (async () => {
            try {
                await Auth.currentAuthenticatedUser();
                getMonitoringAlerts(state, updateState, 'start', paginationData, setPaginationData);
            } catch (e) {
                history.push('/');
            }
        })();
    }, []);

    return (
        <Flex
            sx={{
                overflow: 'hidden',
                top: '0',
                left: '0',
                bottom: 0,
                minHeight: '100vh',
                width: '100vw',
                flexDirection: 'row',
                backgroundColor: 'greyBackground',
                margin: 0,
                padding: 0,
            }}
        >
            <Sidebar
                logout={logOut}
                currentPage="Monitoring"
                showMenu={showMenu}
                toggleMenu={toggleMenu}
                history={history}
            />

            <Flex
                sx={{
                    border: '0px red solid',
                    width: '90%',
                    maxWidth: '1100px',
                    minHeight: '400px',
                    flexDirection: 'column',
                    mt: '50px',
                    mb: '100px',
                    ml: showMenu ? 240 : 45,
                }}
            >
                {state.loading && <Spinner />}
                <>
                    <Paragraph sx={{ mb: '20px', fontWeight: '600', fontSize: '21px' }}>
                        {' '}
                        Transaction Monitoring Alerts
                    </Paragraph>

                    <Flex
                        sx={{
                            justifyContent: 'space-between',
                            width: '100%',
                            height: '40px',
                            color: '#fff',
                        }}
                    >
                        <TableHeaderItem text="Email" topLeft />
                        <TableHeaderItem text="Processed At" />
                        <TableHeaderItem text=" " topRight />
                    </Flex>

                    {state?.checks?.map((check, i) => (
                        <Flex key={i} sx={{ justifyContent: 'space-between', width: '100%', height: '65px' }}>
                            <TableRowItem text={check.email} />
                            <TableRowItem
                                text={moment.utc(check.timestamp, 'YYYY-MM-DDTHH:mm:ssZ').format('DD-MM-YYYY HH:mm')}
                            />
                            <TableRowItem
                                type="view"
                                text="View"
                                openInNewTab
                                href={`/user/${check.user_id}?activeTab=monitoringAlerts&activeGroup=admin`}
                            />
                        </Flex>
                    ))}
                    <Flex sx={{ mt: 20 }} />
                    <Pagination
                        paginationData={paginationData}
                        setPaginationData={setPaginationData}
                        state={state}
                        updateState={updateState}
                        dataFetchFunction={getMonitoringAlerts}
                    />
                </>
            </Flex>
        </Flex>
    );
};

const mapDispatchToProps = (dispatch) => ({
    logOut: () => dispatch({ type: 'LOG_USER_OUT' }),
    setPaginationData: (paginationData) => dispatch({ type: 'SET_TX_MONITORING_PAGINATION_DATA', paginationData }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        loggedIn: account.loggedIn,
        paginationData: account.txMonitoringPaginationData,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(KycComplete);
