/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import { Flex, Box } from 'theme-ui';
import { Title } from './table';
import Item from './user-data-item';
import labelMapping from '../utils/label-mapping';

const BasicData = ({ state }) => (
    <Flex>
        <Flex sx={{ mt: '30px', flexDirection: 'column' }}>
            <Item title="Company name" value={`${state.company_name || ''}`} />
            <Item required title="Company type" value={`${labelMapping(state.company_type) || ''}`} />
            <Item title="Contact person" value={`${state.contact_person || ''}`} />
            <Item title="Email" value={`${state.contact_email}`} />
        </Flex>

        <Flex sx={{ mt: '30px', flexDirection: 'column', borderLeft: '1px grey solid', pl: '50px' }}>
            <Item required title="Currency" value={`${labelMapping(state.currency) || ''}`} />
            <Item required title="Website" value={`${state.website || ''}`} />
            <Item required title="Registration number" value={`${state.registration_number || ''}`} />
            <Item
                required
                title="Tel. number"
                value={`${state.contact_telephone_number ? state.contact_telephone_number : ''}`}
            />{' '}
        </Flex>
    </Flex>
);

export default ({ state, updateState }) => (
    <Box sx={{ width: '100%', marginLeft: '15px', mb: '30px' }}>
        <Flex sx={{ mt: '10px', mb: '10px', width: '100%', justifyContent: 'flex-start' }}>
            <Item title="Corporate Id" value={`${state.corporate_id || ''}`} smallWidth />
        </Flex>
        <Title title="Company Details" state={state} updateState={updateState} type="company" />
        <BasicData state={state} updateState={updateState} />
    </Box>
);
