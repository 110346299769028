export const defaultUsersFilters = {
    email: '',
    userId: '',
    firstName: '',
    lastName: '',
    telephoneNumber: '',
    nationality: '',
    status: '',
    matchStatus: 'select',
    riskScore: 'select',
};

export const defaultKYCUsersFilters = {
    email: '',
    userId: '',
    firstName: '',
    lastName: '',
    telephoneNumber: '',
    nationality: '',
    kycStatuses: [{ value: 'pending', label: 'Pending' }],
    matchStatus: 'select',
    riskScore: 'select',
    startDate: '',
    endDate: '',
    poaUploaded: 'select',
};

const INITIAL_STATE = {
    loggedIn: false,
    data: {},
    memberships: [],
    paginationData: { pageNumber: 0, paginationArray: [''] },
    CommentpaginationData: { pageNumber: 0, paginationArray: [''] },
    kycPaginationData: { pageNumber: 0, paginationArray: [''] },
    txMonitoringPaginationData: { pageNumber: 0, paginationArray: [''] },
    usersListState: {
        users: [],
        loading: true,
        showAdvancedSearch: false,
        offset: 0,
        limit: 10,
        total: 0,
        pageNumber: 1,
        filters: defaultUsersFilters,
    },

    kycListState: {
        items: [],
        paginationTokenPrev: '',
        paginationTokenNext: '',
        paginationArray: [''],
        pageNumber: 1,
        loading: true,
        showAdvancedSearch: false,
        email: '',
        countries: [],
        total: 0,
        limit: 10,
        offset: 0,
        nationality: 'SELECT',
        riskScore: 'SELECT',
        filters: defaultKYCUsersFilters,
        assignedUsers: new Set(),
    },
};

export const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'LOG_USER_IN':
            return {
                ...state,
                loggedIn: true,
                data: action.data,
                memberships: action.memberships || [],
            };
        case 'LOG_USER_OUT':
            return {
                ...state,
                ...INITIAL_STATE,
            };
        case 'SET_PAGINATION_DATA':
            return {
                ...state,
                paginationData: action.paginationData,
            };
        case 'SET_MEMBERSHIPS':
            return {
                ...state,
                memberships: action.memberships,
            };
        case 'SET_COMMENT_PAGINATION_DATA':
            return {
                ...state,
                CommentpaginationData: action.paginationData,
            };
        case 'SET_KYC_PAGINATION_DATA':
            return {
                ...state,
                kycPaginationData: action.paginationData,
            };
        case 'SET_TX_MONITORING_PAGINATION_DATA':
            return {
                ...state,
                txMonitoringPaginationData: action.paginationData,
            };
        case 'SET_USERS_STATE':
            return {
                ...state,
                usersListState: action.usersListState,
            };
        case 'SET_KYC_LIST_STATE':
            return {
                ...state,
                kycListState: action.kycListState,
            };
        default:
            return state;
    }
};
