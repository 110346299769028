import { useState } from 'react';
import { Flex } from 'theme-ui';
import { TableRowItem } from '../table';

const ChangesRow = ({ items, tableDefinition, currentState, labelMapping }) => {
    const [expandRow, toggleExpandedRow] = useState(false);

    return items?.map((item) => (
        <Flex
            key={JSON.stringify(item)}
            sx={{
                justifyContent: 'space-between',
                width: '100%',
                minHeight: '30px',
            }}
        >
            {tableDefinition[currentState.type]?.cells.map((cell, i) => (
                <TableRowItem
                    key={`${currentState.type}_${cell}`}
                    text={labelMapping(item[cell])}
                    action={() => toggleExpandedRow(!expandRow)}
                    expandRow
                    expandTypeText={i === 0}
                    copyText={i === 0}
                />
            ))}
        </Flex>
    ));
};

export default ChangesRow;
