/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import { Flex, Box, Paragraph } from 'theme-ui';
import axios from 'axios';
import moment from 'moment';
import { Title } from './table';
import Item from './user-data-item';
import labelMapping from '../utils/label-mapping';
import AssigneeDropDown from './assign-cases/assignee-dropdown';

const BasicData = ({ state }) => (
    <Flex>
        <Flex sx={{ mt: '30px', flexDirection: 'column' }}>
            <Item title="First name" value={`${state.first_name || ''}`} />
            <Item title="Middle names" value={`${state.middle_names || ''}`} />
            <Item title="Last name" value={`${state.last_name || ''}`} />
            <Item title="Preferred currency" value={`${labelMapping(state.currency) || ''}`} />
            <Item title="MFA setup" value={state?.mfa?.setup ? 'Yes' : 'No'} />
        </Flex>

        <Flex sx={{ mt: '30px', flexDirection: 'column', borderLeft: '1px grey solid', pl: '50px' }}>
            <Item title="Referral code" value={state?.referralSummary?.referral_code || ''} />
            <Item title="Referrals" value={state?.referralSummary?.number_of_referrals || 0} />
            <Item
                title="Joined via referral"
                value={state?.referralSummary?.joined_via_referral ? 'Yes' : 'No'}
                pageUrl={`/user/${state.referred_by_user_id}`}
                linkToPage={state?.referralSummary?.joined_via_referral && state?.referred_by_user_id}
            />
            <Item
                title="Referral Scheme Version"
                value={
                    state?.referralSummary?.joined_via_referral
                        ? moment
                              .utc(state.created_at, 'YYYY-MM-DDTHH:mm:ssZ')
                              .isAfter(moment.utc('2022-05-24T14:00:00Z', 'YYYY-MM-DDTHH:mm:ssZ'))
                            ? 'V2'
                            : 'V1'
                        : ''
                }
            />
            {state?.referralSummary?.days_left_to_fund_account > 0 && (
                <Item
                    title="Days left to fund account"
                    value={state?.referralSummary?.days_left_to_fund_account || 0}
                />
            )}
            {state?.referralSummary?.days_until_referral_credit_applied > 0 && (
                <Item
                    title="Days till credit due"
                    value={state?.referralSummary?.days_until_referral_credit_applied || 0}
                />
            )}
        </Flex>
    </Flex>
);

const reassign = async (newAssignee, userId, currentState, updateState) => {
    try {
        updateState({ ...currentState, loading: true });

        const { data } = await axios({
            url: process.env.REACT_APP_USERS_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            data: {
                query: `mutation {
                adminAssignCases(input: {
                    assign_to: "${newAssignee}"
                    case_ids: ["${userId}"]
                    force: true
                }) { 
                    success
                    message
                    code
                }}`,
                variables: null,
            },
        });

        if (!data.data.adminAssignCases.success) {
            throw new Error('Failed');
        }

        updateState({
            ...currentState,
            loading: false,
            kyc: {
                ...currentState.kyc,
                assigned_to: newAssignee,
            },
        });
    } catch (e) {
        updateState({
            ...currentState,
            loading: false,
            error: 'We are currently unable to save this change, please try again later',
        });
    }
};

export default ({ state, updateState }) => (
    <Box sx={{ width: '100%', marginLeft: '15px', mb: 40 }}>
        <Title title="Basic Details" state={state} updateState={updateState} type="basic" />

        <BasicData state={state} updateState={updateState} />

        <Flex sx={{ mt: '40px', mb: '0', width: '550px', justifyContent: 'flex-start', flexDirection: 'column' }}>
            <Item title="UserId" value={`${state.user_id || ''}`} smallWidth />
            <Flex>
                <Paragraph sx={{ fontWeight: '800', fontSize: 14, color: '#444', mr: 80, mt: 10 }}>Assignee:</Paragraph>
                <AssigneeDropDown
                    fullHeight={false}
                    adminUserEmails={state.adminUserEmails}
                    currentState={{
                        assignee: state?.kyc?.assigned_to,
                    }}
                    updateState={(newState) => reassign(newState.assignee, state.user_id, state, updateState)}
                />
            </Flex>
        </Flex>

        <Box sx={{ height: state?.corporate_id ? '30px' : '0' }} />

        {state?.corporate_id && (
            <Flex sx={{ width: '450px', flexDirection: 'column' }}>
                <Item title="Company Name" value={state?.company_name || ''} />
                <Item title="Corporate Id" value={state?.corporate_id || ''} />
            </Flex>
        )}

        <Box sx={{ height: '10px' }} />
    </Box>
);
