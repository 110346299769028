/* eslint-disable no-unused-vars */
import { Flex } from 'theme-ui';
import * as Decimal from 'decimal.js';
import { formatting } from '@accru-finance/shared-ui-libs';
import { Title } from '../table';
import Item from '../user-data-item';

const Items = ({ total }) => {
    const { amounts = [] } = total;
    const middle = Math.floor(amounts.length / 2);
    const firstColumn = amounts.slice(0, middle);
    const secondColumn = amounts.slice(middle);
    return (
        <Flex sx={{ mt: '10px', flexDirection: 'row', mb: '25px' }}>
            <Flex sx={{ mt: '30px', flexDirection: 'column' }}>
                {secondColumn.map((item) => {
                    const value = item.format_as_int
                        ? new Intl.NumberFormat('en-US').format(
                              parseFloat(new Decimal(item?.amount).toFixed(0).toString())
                          )
                        : formatting.formatForDisplay(item?.amount);
                    return <Item title={item?.group_by_column} value={value} key={item?.group_by_column} />;
                })}
            </Flex>

            <Flex sx={{ mt: '30px', flexDirection: 'column', borderLeft: '1px grey solid', pl: '50px' }}>
                {firstColumn.map((item) => {
                    const value = item.format_as_int
                        ? new Intl.NumberFormat('en-US').format(
                              parseFloat(new Decimal(item?.amount).toFixed(0).toString())
                          )
                        : formatting.formatForDisplay(item?.amount);
                    return <Item title={item?.group_by_column} value={value} key={item?.group_by_column} />;
                })}
                {firstColumn.length < 1 && <Item title="" value="" empty />}
            </Flex>
        </Flex>
    );
};

const CurrentStandings = ({ state }) => {
    const originalMultiDataArray = state?.multiValueTotals || [];
    const totalsArray = originalMultiDataArray.filter(
        (item) => item?.type !== 'withdrawals_tomorrow' && item?.type !== 'withdrawals_today'
    );
    return (
        <Flex sx={{ flexDirection: 'column', maxWidth: 900 }}>
            {totalsArray.map((total, idx) => (
                <Flex
                    sx={{
                        flexDirection: 'column',
                        mt: idx > 0 ? '30px' : '50px',
                    }}
                >
                    <Title title={total.title} state={state} updateState={{}} showEditIcon={false} />

                    <Items total={total} />
                </Flex>
            ))}
        </Flex>
    );
};

export default CurrentStandings;
