import { Auth } from 'aws-amplify';
import jwtDecode from 'jwt-decode';

export const getMemberships = async () => {
    const session = await Auth.currentSession();

    const decoded: any = jwtDecode(session.getAccessToken().getJwtToken());

    return decoded['cognito:groups'] || [];
};
