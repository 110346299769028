/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/** @jsxImportSource theme-ui */
import { useState, useEffect } from 'react';
import { Flex, Paragraph, Box } from 'theme-ui';
import { getPortfolioFigures } from '../../utils/get-user-details';
import GraphComponent from './graphs/simpleLine';
import GraphControls from './graph-controls';
import Table from './Table';
import Spinner from '../spinner';

const PortfolioFigures = ({ id, preferredCurrency }) => {
    const [currentState, updateState] = useState({
        user_id: id,
        loading: true,
        error: false,
        portfolioFigures: [],
        productType: 'All',
        currencyType: preferredCurrency.toLowerCase(),
        preferredCurrency: preferredCurrency.toLowerCase(),
    });

    useEffect(() => {
        (async () => {
            const currency = preferredCurrency.toLowerCase();
            const portfolioFigures = await getPortfolioFigures(id, currency);
            updateState({
                ...currentState,
                portfolioFigures,
                loading: false,
                error: portfolioFigures?.figures.length < 1,
            });
        })();
    }, []);

    const errorMsg = 'Sorry, we have been unable to retrieve your data';
    const errorMsgNotEnoughData =
        "Sorry, we don't currently have enough data for you to be able to view your your fund performance";
    return (
        <Flex sx={{ width: '100%', minHeight: '500px', mb: '10px', flexDirection: 'column', backgroundColor: 'white' }}>
            {currentState.loading && <Spinner />}

            {!currentState.loading && currentState.error && (
                <Paragraph sx={{ margin: '40px 20px' }}>
                    {currentState?.portfolioFigures?.errorType === 'NOT_ENOUGH_DATA' ? errorMsgNotEnoughData : errorMsg}
                </Paragraph>
            )}

            {!currentState.loading && !currentState.error && (
                <>
                    <Flex
                        sx={{
                            width: '100%',
                            height: '660px',
                            mt: '30px',
                            mb: '30px',
                            p: '5px 5px 5px 0',
                            ml: -10,
                            flexDirection: 'column',
                        }}
                    >
                        <GraphControls currentState={currentState} updateState={updateState} id={id} />
                        <GraphComponent currentState={currentState} figures={currentState.portfolioFigures?.figures} />
                    </Flex>

                    <Table userId={id} />
                </>
            )}
        </Flex>
    );
};

export default PortfolioFigures;
