/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Flex, Paragraph, Link } from 'theme-ui';
import { TableHeaderItem, TableRowItem } from './table';
import Spinner from './spinner';

const fetchStaticDocument = async (docId: string, state: any, updateState: any) => {
    try {
        const { data } = await axios({
            url: process.env.REACT_APP_ONFIDO_ADMIN_API,
            method: 'POST',
            data: {
                query: `query {
                    getReportDocument(
                        user_id: "${state.userId}",
                        document_id: "${docId}",
                        index: 0
                    ) {
                        success
                        document {
                            side
                            type
                            issuing_country
                            base_64_url
                            created_at
                        }
                    }
                }`,
                variables: null,
            },
        });
        const result = data.data?.getReportDocument;
        if (!result.success) {
            throw new Error('Failed');
        }
        return result;
    } catch (e) {
        updateState({
            ...state,
            loading: false,
            error: 'There has been an error loading your document, please try again later',
        });
    }
};

const fetchChecks = async (state: any, updateState: any) => {
    updateState({ ...state, loading: true });

    try {
        const { data } = await axios({
            url: process.env.REACT_APP_ONFIDO_ADMIN_API,
            method: 'POST',
            data: {
                query: `query {
                    getChecks(
                        user_id: "${state.userId}"
                    ) {
                        success
                        checks {
                            id
                            status
                            result
                            created_at
                        }
                    }
                }`,
                variables: null,
            },
        });
        const result = data.data?.getChecks;

        if (!result.success) {
            throw new Error('Failed');
        }

        updateState({ ...state, loading: false, checks: result.checks });

        return result;
    } catch (e) {
        updateState({
            ...state,
            loading: false,
            error: 'There has been an error loading the identity checks, please try again later',
        });
    }
};

const fetchReportsForCheck = async (state: any, updateState: any) => {
    updateState({ ...state, loading: true });

    try {
        const { data } = await axios({
            url: process.env.REACT_APP_ONFIDO_ADMIN_API,
            method: 'POST',
            data: {
                query: `query {
                    getReports(
                        user_id: "${state.userId}",
                        check_id: "${state.checkId.replace('CHECK#', '')}"
                    ) {
                        success
                        reports {
                            id
                            status
                            name
                            result
                            sub_result
                            created_at
                            documents
                        }
                    }
                }`,
                variables: null,
            },
        });
        const result = data.data?.getReports;

        if (!result.success) {
            throw new Error('Failed');
        }

        updateState({ ...state, loading: false, reports: result.reports });

        return result;
    } catch (e) {
        updateState({
            ...state,
            loading: false,
            error: 'There has been an error loading the identity reports, please try again later',
        });
    }
};

const KycChecks = (props: any) => {
    const [state, updateState] = useState({
        loading: false,
        error: null,
        view: 'checks',
        checkId: null,
        reportId: null,
        checks: [],
        reports: [],
        documents: [],
        userId: props.userId,
        report: null,
        documentIndex: 0,
        imageIndex: 0,
        document: null,
    });

    useEffect(() => {
        fetchChecks(state, updateState);
    }, []);

    useEffect(() => {
        if (state.view === 'reports' && !state.loading) {
            fetchReportsForCheck(state, updateState);
        }
    }, [state.view]);

    const fetchDocUrlsAndOpen = async (report: any, state: any, updateState: any) => {
        const promises: Array<Promise<any>> = [];
        for (let i = 0; i < report.documents.length; i += 1) {
            promises.push(fetchStaticDocument(report.documents[i], state, updateState));
        }
        Promise.all(promises).then((values: any) => {
            values.forEach((value: any) => {
                if (!value) {
                    updateState({ ...state, error: 'Error fetching documents', loading: false });
                    return;
                }
                window.open(value.document.base_64_url);
            });
        });
        updateState({ ...state, loading: false, error: null });
    };
    return (
        <Flex sx={{ flexDirection: 'column', mt: 30 }}>
            {state.loading && <Spinner />}

            {state.view === 'checks' && (
                <>
                    <Paragraph sx={{ ml: 20, mb: 20 }}>Onfido Checks</Paragraph>
                    <Flex
                        sx={{
                            justifyContent: 'space-between',
                            width: '100%',
                            height: '40px',
                            color: '#fff',
                        }}
                    >
                        <TableHeaderItem text="Status" />
                        <TableHeaderItem text="Result" />
                        <TableHeaderItem text="Created At" />
                        <TableHeaderItem text="" />
                    </Flex>
                    {state.checks.map((check: any) => (
                        <Flex sx={{ justifyContent: 'space-between', width: '100%', height: '65px' }}>
                            <TableRowItem key={`${check.id}_${check.status}`} text={check.status} />
                            <TableRowItem key={`${check.id}_${check.result}`} text={check.result} />
                            <TableRowItem key={`${check.id}_${check.created_at}`} text={check.created_at} />
                            <TableRowItem
                                action={() => updateState({ ...state, view: 'reports', checkId: check.id })}
                                type="view"
                                key={`${check.id}_view`}
                                text="View Reports"
                            />
                        </Flex>
                    ))}
                </>
            )}

            {state.view === 'reports' && (
                <>
                    <Link
                        sx={{ ml: 20, mb: 20, color: 'primary' }}
                        onClick={() => updateState({ ...state, view: 'checks' })}
                    >
                        {'< '}Back
                    </Link>
                    <Flex>
                        <Paragraph sx={{ ml: 20, mb: 20, mr: 40 }}>Onfido Reports</Paragraph>
                        {state.error && <Paragraph sx={{ color: 'red' }}>{state.error}</Paragraph>}
                    </Flex>

                    {state.loading && <Spinner />}

                    <Flex
                        sx={{
                            justifyContent: 'space-between',
                            width: '100%',
                            height: '40px',
                            color: '#fff',
                        }}
                    >
                        <TableHeaderItem text="Name" />
                        <TableHeaderItem text="Status" />
                        <TableHeaderItem text="Result" />
                        <TableHeaderItem text="Sub Status" />
                        <TableHeaderItem text="Created At" />
                        <TableHeaderItem text="" />
                    </Flex>
                    {state.reports.map((report: any) => (
                        <Flex sx={{ justifyContent: 'space-between', width: '100%', height: '65px' }}>
                            <TableRowItem key={`${report.id}_${report.name}`} text={report.name} />
                            <TableRowItem key={`${report.id}_${report.status}`} text={report.status} />
                            <TableRowItem key={`${report.id}_${report.result}`} text={report.result} />
                            <TableRowItem key={`${report.id}_${report.sub_status}`} text={report.sub_status} />
                            <TableRowItem key={`${report.id}_${report.created_at}`} text={report.created_at} />
                            <TableRowItem
                                action={() => {
                                    updateState({ ...state, loading: true, error: null });
                                    fetchDocUrlsAndOpen(report, state, updateState);
                                }}
                                type="view"
                                key={`${report.id}_view`}
                                text="Documents"
                            />
                        </Flex>
                    ))}
                </>
            )}
        </Flex>
    );
};

export default KycChecks;
