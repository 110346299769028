/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { Flex, Paragraph, Button, Select, Box } from 'theme-ui';
import { useState } from 'react';
import axios from 'axios';
import { formatting } from '@accru-finance/shared-ui-libs';
import { Row } from './deposit';
import Confirm from '../confirm-dialog';
import Overlay from '../overlay';
import labelMapping from '../../utils/label-mapping';
import AccruSpinner from '../spinner';

export const changeStatus = async (currentState, updateState, depositToApprove) => {
    try {
        updateState({ ...currentState, loading: true });

        const { data } = await axios({
            url: process.env.REACT_APP_TRANSACTIONS_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
            data: {
                query: `mutation { 
                    adminTools(input: {
                        user_id: "${depositToApprove.user_id}"
                        uuid: "${depositToApprove.uuid}"
                        status: "${currentState.status}"
                        tool: "update_deposit_to_inspect_status"
                    }) { 
                        success
                        message
                    }
            }`,
                variables: null,
            },
        });

        if (!data.data.adminTools.success) {
            return updateState({ ...currentState, error: 'Uh Oh, there has been a problem', loading: false });
        }

        window.location.reload();
    } catch (e) {
        return updateState({ ...currentState, error: 'Uh Oh, there has been a problem', loading: false });
    }
};

export const refund = async (currentState, updateState, depositToApprove) => {
    try {
        updateState({ ...currentState, loading: true });

        const { data } = await axios({
            url: process.env.REACT_APP_TRANSACTIONS_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
            data: {
                query: `mutation { 
                    adminTools(input: {
                        user_id: "${depositToApprove.user_id}"
                        uuid: "${depositToApprove.uuid}"
                        status: "${currentState.status}"
                        tool: "refund_fiat_deposit"
                    }) { 
                        success
                        message
                    }
            }`,
                variables: null,
            },
        });

        if (!data.data.adminTools.success) {
            return updateState({ ...currentState, error: 'Uh Oh, there has been a problem', loading: false });
        }

        window.location.reload();
    } catch (e) {
        return updateState({ ...currentState, error: 'Uh Oh, there has been a problem', loading: false });
    }
};

const DepositToApprove = ({ depositToApprove }) => {
    const [currentState, updateState] = useState({
        loading: false,
        success: '',
        error: '',
        status: depositToApprove.status,
    });

    return (
        <Flex sx={{ ml: 50, mt: 40, flexDirection: 'column' }}>
            <Paragraph sx={{ fontWeight: '800', mb: 20 }}>Deposit Details</Paragraph>

            {currentState.success && <Paragraph sx={{ color: 'green', mb: 20 }}>{currentState.success}</Paragraph>}

            {currentState.error && <Paragraph sx={{ color: 'red', mb: 20 }}>{currentState.error}</Paragraph>}

            {currentState.loading && <AccruSpinner />}

            {currentState.showConfirmDialog && (
                <Overlay
                    size="small"
                    closeOverlay={() =>
                        updateState({
                            ...currentState,
                            showConfirmDialog: false,
                            confirmDialogText: null,
                            confirmHandler: null,
                        })
                    }
                    overlayContent={
                        <Confirm text={currentState.confirmDialogText} confirmHandler={currentState.confirmHandler} />
                    }
                />
            )}

            <Flex>
                <Row label="Amount" value={formatting.formatForDisplay(depositToApprove.amount)} />
                <Row label="Currency" value={labelMapping(depositToApprove.currency)} />
            </Flex>
            <Flex>
                <Row label="First Name On Record" value={depositToApprove.first_name_on_record} />
                <Row label="Last Name On Record" value={depositToApprove.last_name_on_record} />
            </Flex>
            <Flex>
                <Row label="Name On Deposit Transaction" value={depositToApprove.name_on_transaction} />
                <Row label="Status" value={labelMapping(depositToApprove.status)} />
            </Flex>

            <Flex>
                {depositToApprove.currency === 'eur' && <Row label="IBAN" value={depositToApprove.iban} />}
                {depositToApprove.currency === 'gbp' && (
                    <>
                        <Row label="Account No" value={labelMapping(depositToApprove.account_number)} />
                        <Row label="Sort code" value={labelMapping(depositToApprove.sort_code)} />
                    </>
                )}
            </Flex>

            {depositToApprove.status !== 'APPROVED' && depositToApprove.status !== 'REFUND_SENT' && (
                <>
                    <Paragraph sx={{ fontSize: 14, fontWeight: '800', mb: 10, mt: 20 }}>Update Status</Paragraph>
                    <Flex>
                        <Select
                            defaultValue={currentState.status}
                            value={currentState.status}
                            onChange={(e) =>
                                updateState({
                                    ...currentState,
                                    status: e.target.value,
                                })
                            }
                            sx={{ mb: 20, width: 300, height: 40 }}
                            arrow={
                                <Box
                                    as="svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="currentcolor"
                                    sx={{
                                        ml: -28,
                                        mt: -15,
                                        alignSelf: 'center',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                                </Box>
                            }
                        >
                            <option value="PENDING">Pending</option>
                            <option value="APPROVED">Approved</option>
                            <option value="PROOF_REQUESTED">Proof Requested</option>
                            <option value="REFUND_REQUIRED">Refund required</option>
                        </Select>

                        <Button
                            onClick={() =>
                                updateState({
                                    ...currentState,
                                    showConfirmDialog: true,
                                    confirmDialogText: `Are you sure you wish to change the status to ${currentState.status}`,
                                    confirmHandler: async () => {
                                        await changeStatus(
                                            {
                                                ...currentState,
                                                showConfirmDialog: false,
                                                confirmDialogText: null,
                                                confirmHandler: null,
                                            },
                                            updateState,
                                            depositToApprove
                                        );
                                    },
                                })
                            }
                            sx={{ width: 170, mb: 20, height: 40, ml: 10 }}
                        >
                            Change Status
                        </Button>
                    </Flex>

                    {['PENDING', 'PROOF_REQUESTED', 'REFUND_REQUIRED'].includes(depositToApprove.status) && (
                        <Button
                            onClick={() =>
                                updateState({
                                    ...currentState,
                                    showConfirmDialog: true,
                                    confirmDialogText:
                                        'Are you sure you wish to reject the deposit and return the funds',
                                    confirmHandler: async () => {
                                        await refund(
                                            {
                                                ...currentState,
                                                showConfirmDialog: false,
                                                confirmDialogText: null,
                                                confirmHandler: null,
                                            },
                                            updateState,
                                            depositToApprove
                                        );
                                    },
                                })
                            }
                            sx={{ width: 170, mb: 20, mt: 30, height: 40 }}
                        >
                            Refund
                        </Button>
                    )}
                </>
            )}
        </Flex>
    );
};

export default DepositToApprove;
