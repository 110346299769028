/* eslint-disable no-param-reassign */
/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import { useHistory } from 'react-router-dom';
import { Flex, Paragraph } from 'theme-ui';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { PieChart, Pie, Tooltip, Cell, Legend } from 'recharts';
import { useState } from 'react';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';
import TransactionsList from '../components/transactions-list';
import labelMapping from '../utils/label-mapping';

const Invest = ({ logOut }) => {
    const [state, updateState] = useState({ loading: false, view: 'summaryList', event: null });
    const [showMenu, toggleMenu] = useState(true);
    const history = useHistory();

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    return (
        <Flex
            sx={{
                overflow: 'hidden',
                top: '0',
                left: '0',
                bottom: 0,
                minHeight: '100vh',
                width: '100vw',
                flexDirection: 'row',
                backgroundColor: 'greyBackground',
                margin: 0,
                padding: 0,
            }}
        >
            <Sidebar
                logout={logOut}
                currentPage="Trades"
                showMenu={showMenu}
                toggleMenu={toggleMenu}
                history={history}
            />

            <Flex
                sx={{
                    border: '0px red solid',
                    width: '90%',
                    maxWidth: '900px',
                    minHeight: '400px',
                    flexDirection: 'column',
                    mt: '50px',
                    mb: '100px',
                    ml: showMenu ? 240 : 45,
                }}
            >
                {state.view === 'tradeList' && (
                    <Flex
                        onClick={() => updateState({ ...state, view: 'summaryList', event: null })}
                        sx={{
                            alignSelf: 'flex-start',
                            marginTop: '-30px',
                            marginLeft: 0,
                            fontSize: '15px',
                            color: '#5100C9',
                            position: 'absolute',
                            cursor: 'pointer',
                        }}
                    >
                        <i sx={{ marginRight: '10px', paddingTop: '5px' }} className="fal fa-long-arrow-left" />
                        <Paragraph sx={{ pl: '5px', mt: '-2px' }}>Back</Paragraph>
                    </Flex>
                )}

                {state.loading && <Spinner />}

                {state.view === 'summaryList' && (
                    <>
                        <Flex sx={{ backgroundColor: '#FFFFFF', borderTopLeftRadius: 12, borderTopRightRadius: 12 }}>
                            <Paragraph sx={{ ml: 40, mt: 40, fontWeight: '800', fontSize: 18, mb: 0 }}>
                                Rebalances
                            </Paragraph>
                        </Flex>

                        <TransactionsList
                            updateState={updateState}
                            disableAbilityToExpand
                            expanded
                            type="rebalancing_events"
                            userId="ALL"
                        />
                    </>
                )}

                {state.view === 'tradeList' && (
                    <>
                        <Flex
                            sx={{
                                backgroundColor: '#FFFFFF',
                                borderTopLeftRadius: 12,
                                borderTopRightRadius: 12,
                                flexDirection: 'column',
                            }}
                        >
                            <Paragraph sx={{ ml: 40, mt: 40, fontWeight: '800', fontSize: 22, mb: 0 }}>
                                Rebalance{' '}
                                {state.timestamp ? ` - ${moment.utc(state.timestamp).format('Do MMMM YYYY')}` : ''}
                            </Paragraph>
                            <Paragraph sx={{ ml: 40, mt: 40, fontWeight: '800', fontSize: 16, mb: 0 }}>
                                Fund Valuation At Start
                            </Paragraph>
                            <Paragraph sx={{ ml: 40, mt: 40, fontSize: 16, mb: 0 }}>
                                {state.valuationTrades?.reduce((accum, item) => {
                                    accum += parseFloat(item.amount) * parseFloat(item.exchange_rate || 1);
                                    return accum;
                                }, 0)}{' '}
                                USDC
                            </Paragraph>
                            <Paragraph sx={{ ml: 40, mt: 40, fontWeight: '800', fontSize: 16, mb: 0 }}>
                                Investment Split
                            </Paragraph>
                            {state.pieChartData?.length && (
                                <PieChart width={730} height={250}>
                                    <Pie
                                        data={state.pieChartData}
                                        dataKey="value"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={100}
                                        fill="#8884d8"
                                    >
                                        {state.pieChartData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                    <Legend
                                        formatter={(value, entry, index) => {
                                            return `${value} ${entry.payload.value * 100}%`;
                                        }}
                                    />
                                </PieChart>
                            )}
                            <Paragraph sx={{ ml: 40, mt: 40, fontWeight: '800', fontSize: 16, mb: 0 }}>
                                Quotes and Trades
                            </Paragraph>
                        </Flex>

                        <TransactionsList
                            rebalancingGroupId={state.event.rebalancing_group_id}
                            disableAbilityToExpand
                            expanded
                            type="rebalancing_event_trades"
                            userId="ALL"
                            dataCallback={(data) =>
                                updateState({
                                    ...state,
                                    timestamp: data?.[0]?.created_at,
                                    valuationTrades: data.filter((item) => item.type === 'QUOTE'),
                                    pieChartData: data?.[0]?.weightings?.map((item) => ({
                                        name: labelMapping(item.currency),
                                        value: parseFloat(item.fraction),
                                    })),
                                })
                            }
                        />
                    </>
                )}
            </Flex>
        </Flex>
    );
};

const mapDispatchToProps = (dispatch) => ({
    logOut: () => dispatch({ type: 'LOG_USER_OUT' }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return { loggedIn: account.loggedIn };
};

export default connect(mapStateToProps, mapDispatchToProps)(Invest);
