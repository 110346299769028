/* eslint-disable no-lone-blocks */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import axios from 'axios';
import { Flex, Paragraph, Button } from 'theme-ui';
import FormInput from './form-input';
import Spinner from './spinner';
import buttonStyle from '../utils/button-style';

const validateForm = (currentState) => {
    if (currentState.code.trim() === '' || currentState.code.trim().length !== 6) {
        return { valid: false, message: 'Please enter a 6 character code' };
    }
    if (currentState.days.trim() === '') {
        return { valid: false, message: 'Please enter amount of days until credit' };
    }
    return { valid: true };
};

const submit = async (joinedByReferral, currentState, updateState) => {
    const { valid, message } = validateForm(currentState);
    if (!valid) {
        return updateState({ ...currentState, error: message, loading: false });
    }

    updateState({ ...currentState, error: message, loading: true });

    try {
        const { data } = await axios({
            url: process.env.REACT_APP_ADMIN_REFERRAL_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
            data: {
                query: `mutation {
                    tools(input: {
                        type: "${joinedByReferral ? 'start_clock' : 'apply_code'}"
                        user_id: "${currentState.user_id}"
                        referral_code: "${currentState.code}"
                        create_thirty_day_check: ${true}
                        days_to_check: ${parseInt(currentState.days, 10)}
                    }) { 
                        status
                        message
                    }
                }`,
                variables: null,
            },
        });

        if (data.data.tools.message !== 'Done') {
            return updateState({
                ...currentState,
                loading: false,
                error: 'Failed to set referral data',
            });
        }
        return window.location.reload();
    } catch (e) {
        return updateState({ ...currentState, loading: false, error: 'Failed to set referral data' });
    }
};

const ReferralDataFixForm = ({ state, joinedByReferral }) => {
    const [formState, updateFormState] = useState({
        error: '',
        loading: false,
        success: false,
        user_id: state.user_id,
        code: state?.referralSummary?.code_used_to_join || '',
        days: '',
    });

    return (
        <Flex
            sx={{
                justifyContent: 'space-between',
                width: '400px',
                height: 'auto',
                flexDirection: 'column',
                ml: 40,
                mt: 30,
                mb: '50px',
            }}
        >
            {formState.loading && <Spinner />}

            <Paragraph sx={{ mb: '25px', mt: '20px', fontWeight: 600, fontSize: '17px' }}>
                {joinedByReferral ? 'Start The Clock' : 'Apply Code To Account'}
            </Paragraph>

            {!joinedByReferral && (
                <FormInput
                    onChange={(e) =>
                        updateFormState({
                            ...formState,
                            code: e.target.value,
                        })
                    }
                    value={formState.code}
                    sx={{ width: '400px', mb: '20px' }}
                    label="Referral code"
                />
            )}
            <FormInput
                onChange={(e) =>
                    updateFormState({
                        ...formState,
                        days: e.target.value,
                    })
                }
                value={formState.days}
                sx={{ width: '400px' }}
                label="Days until credit"
            />

            <Button onClick={() => submit(joinedByReferral, formState, updateFormState)} sx={buttonStyle}>
                Submit
            </Button>

            {formState.error && <Paragraph sx={{ color: 'red', mt: 20 }}>{formState.error}</Paragraph>}

            {formState.success && <Paragraph sx={{ color: 'green', mt: 20 }}>Referral Data updated</Paragraph>}
        </Flex>
    );
};

export default ReferralDataFixForm;
