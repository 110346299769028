/* eslint-disable no-unused-vars */
import { Box, Paragraph, Flex, Image } from 'theme-ui';
import { useState, useEffect } from 'react';

const PgPagination = ({ currentState, updateState, totalPages, totalRecords }) => {
    const [pg, updatePg] = useState(currentState.pageNumber);

    useEffect(() => {
        updatePg(currentState?.pageNumber);
    }, [currentState?.pageNumber]);

    return (
        <Flex sx={{ width: '215px', margin: '50px auto 20px', justifyContent: 'space-around' }}>
            <Box
                sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                onClick={() =>
                    !currentState.loading &&
                    updateState({
                        ...currentState,
                        pageNumber: 1,
                        offset: 0,
                    })
                }
            >
                <i style={{ marginTop: '4px' }} className="fa fa-backward" />
            </Box>

            {currentState.pageNumber > 1 && (
                <Box
                    sx={{ width: '26px', height: '26px', cursor: 'pointer', ml: '5px' }}
                    onClick={() =>
                        !currentState.loading &&
                        updateState({
                            ...currentState,
                            pageNumber: currentState.pageNumber - 1,
                            offset: currentState.offset - currentState.limit,
                        })
                    }
                >
                    <Image
                        src="https://cdn.accru.finance/arrows/enabled-left.png"
                        width="26"
                        height="26"
                        alt="prev button"
                    />
                </Box>
            )}
            {currentState.pageNumber <= 1 && (
                <Box sx={{ width: '26px', height: '26px', cursor: 'not-allowed', ml: '5px' }}>
                    <Image
                        src="https://cdn.accru.finance/arrows/disabled-left.png"
                        width="26"
                        height="26"
                        alt="prev button"
                    />
                </Box>
            )}

            <Flex sx={{ flexDirection: 'column', width: '180px' }}>
                <Flex
                    sx={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <Paragraph
                        sx={{
                            fontWeight: '600',
                            fontSize: '14px',
                            mt: '3px',
                            ml: '20px',
                            width: '80px',
                            height: '30px',
                        }}
                    >
                        Page
                    </Paragraph>
                    <Paragraph
                        sx={{
                            fontWeight: '600',
                            fontSize: '14px',
                            mt: '3px',
                            mr: '5px',
                            ml: '10px',
                            width: '170px',
                            height: '30px',
                        }}
                    >
                        {currentState.pageNumber} of {totalPages}
                    </Paragraph>
                </Flex>
                <Flex sx={{ width: '150px', height: '40px', mt: '10px' }}>
                    <Paragraph
                        sx={{
                            fontWeight: '600',
                            fontSize: '10px',
                            mt: '0px',
                            textAlign: 'center',
                            width: '100%',
                        }}
                    >
                        ({new Intl.NumberFormat('en-US').format(totalRecords)} records)
                    </Paragraph>
                </Flex>
            </Flex>

            {currentState.items?.length === currentState.limit && (
                <Box
                    sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                    onClick={() =>
                        !currentState.loading &&
                        updateState({
                            ...currentState,
                            pageNumber: currentState.pageNumber + 1,
                            offset: currentState.offset + currentState.limit,
                        })
                    }
                >
                    <Image
                        src="https://cdn.accru.finance/arrows/enabled-right.png"
                        width="26"
                        height="26"
                        alt="next button"
                    />
                </Box>
            )}

            {currentState.items?.length < currentState.limit && (
                <Box sx={{ width: '26px', height: '26px', cursor: 'not-allowed' }}>
                    <Image
                        src="https://cdn.accru.finance/arrows/disabled-right.png"
                        width="26"
                        height="26"
                        alt="next button"
                    />
                </Box>
            )}

            <Box
                sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                onClick={() =>
                    !currentState.loading &&
                    updateState({
                        ...currentState,
                        pageNumber: Math.ceil(currentState.total / currentState.limit),
                        offset: Math.floor(currentState.total / currentState.limit) * currentState.limit,
                    })
                }
            >
                <i style={{ marginTop: '4px', marginLeft: '10px' }} className="fa fa-forward" />
            </Box>
        </Flex>
    );
};

export default PgPagination;
