/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import { Flex, Paragraph, Button, Checkbox } from 'theme-ui';
import { Auth } from 'aws-amplify';
import * as moment from 'moment';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { TableHeaderItem, TableRowItem } from '../components/table';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';
import TransactionPagination from '../components/pagination/transactions';
import Overlay from '../components/overlay';
import AdvancedSearch, { search } from '../components/advanced-search-kyc';
import AssigneeDropdown from '../components/assign-cases/assignee-dropdown';
import SimpleAlert from '../components/simple-alert';
import KycQuickFilters from '../components/kyc/quick-filters';
import { defaultKYCUsersFilters } from '../store/reducers/users';
import { getAdminUsers } from '../utils/get-user-details';

const assign = async (currentState, updateState, force = false) => {
    if (!currentState.assignee || currentState.assignee === 'select') {
        return updateState({ ...currentState, selectAssignee: true });
    }

    updateState({ ...currentState, error: null, loading: true, selectAssignee: false });

    try {
        const { data } = await axios({
            url: process.env.REACT_APP_USERS_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            data: {
                query: `mutation {
                adminAssignCases(input: {
                    assign_to: "${currentState.assignee}"
                    case_ids: [${[...currentState.assignedUsers].map((id) => `"${id}"`).join(',')}]
                    force: ${force}
                }) { 
                    success
                    message
                    code
                }}`,
                variables: null,
            },
        });

        if (data.data.adminAssignCases?.code === 'USER_ASSIGNED_ALREADY') {
            return updateState({
                ...currentState,
                loading: false,
                alertConfig: {
                    success: false,
                    text: 'Please confirm you wish to override who these cases are already assigned to',
                    showConfirmButton: true,
                    buttonLabel: 'OK',
                    buttonHandler: () => assign(currentState, updateState, true),
                },
            });
        }

        if (!data.data.adminAssignCases.success) {
            return updateState({
                ...currentState,
                selectAssignee: false,
                error:
                    data.data.adminAssignCases.message ||
                    'We are currently unable to save this change, please try again later',
                loading: false,
            });
        }

        updateState({
            ...currentState,
            assignee: 'select',
            assignedUsers: new Set(),
            selectAssignee: false,
            items: currentState.items.map((item) => ({
                ...item,
                assigned_to: currentState.assignedUsers.has(item.user_id) ? currentState.assignee : item.assigned_to,
            })),
        });
    } catch (e) {
        updateState({
            ...currentState,
            selectAssignee: false,
            error: 'We are currently unable to save this change, please try again later',
            loading: false,
        });
    }
};

const KycComplete = ({ logOut, state, updateState }) => {
    const [showMenu, toggleMenu] = useState(true);

    const history = useHistory();

    useEffect(() => {
        (async () => {
            try {
                const adminUserEmails = await getAdminUsers();
                const newState = { ...state, adminUserEmails };

                updateState(newState);

                search(newState, updateState, (users, total) => {
                    updateState({
                        ...newState,
                        loading: false,
                        items: users,
                        total,
                        showAdvancedSearch: false,
                        pageNumber: newState.pageNumber,
                        initialLoadDone: true,
                    });
                    document.body.style.overflowY = 'auto';
                });
            } catch (e) {
                history.push('/');
            }
        })();
    }, []);

    useEffect(() => {
        // only trigger this once we've done search once
        if (state.initialLoadDone) {
            search(state, updateState, (users, total) => {
                updateState({
                    ...state,
                    loading: false,
                    items: users,
                    total,
                    showAdvancedSearch: false,
                    pageNumber: state.pageNumber,
                });
                document.body.style.overflowY = 'auto';
            });
        }
    }, [state.pageNumber]);

    const numberOfFilters = Object.values(state.filters).filter((item) => item !== '' && item !== 'select')?.length;

    return (
        <Flex
            sx={{
                overflow: 'hidden',
                top: '0',
                left: '0',
                bottom: 0,
                minHeight: '100vh',
                width: '100vw',
                flexDirection: 'row',
                backgroundColor: 'greyBackground',
                margin: 0,
                padding: 0,
            }}
        >
            <Sidebar logout={logOut} currentPage="KYC" showMenu={showMenu} toggleMenu={toggleMenu} history={history} />

            <Flex
                sx={{
                    border: '0px red solid',
                    width: '90%',
                    maxWidth: '1100px',
                    minHeight: '400px',
                    flexDirection: 'column',
                    mt: '50px',
                    mb: '100px',
                    ml: showMenu ? 240 : 45,
                }}
            >
                {state.alertConfig && (
                    <Overlay
                        size="small"
                        closeOverlay={() => updateState({ ...state, alertConfig: null })}
                        overlayContent={<SimpleAlert {...state.alertConfig} />}
                    />
                )}

                {state.showAdvancedSearch && (
                    <Overlay
                        size="large"
                        closeOverlay={() => {
                            updateState({ ...state, showAdvancedSearch: false });
                        }}
                        overlayContent={
                            <AdvancedSearch
                                parentState={state}
                                logout={logOut}
                                updateParentState={updateState}
                                updateMainTable={(items, total) => {
                                    updateState({
                                        ...state,
                                        loading: false,
                                        items,
                                        total,
                                        showAdvancedSearch: false,
                                        pageNumber: 1,
                                    });
                                    document.body.style.overflowY = 'auto';
                                }}
                            />
                        }
                    />
                )}

                {state.showAssignCasesModal && (
                    <Overlay
                        closeOverlay={() => {
                            updateState({ ...state, showAssignCasesModal: false });
                        }}
                        overlayContent={
                            <AdvancedSearch
                                parentState={state}
                                logout={logOut}
                                updateParentState={updateState}
                                updateMainTable={(items, total) => {
                                    updateState({
                                        ...state,
                                        loading: false,
                                        items,
                                        total,
                                        showAdvancedSearch: false,
                                        pageNumber: 1,
                                    });
                                    document.body.style.overflowY = 'auto';
                                }}
                            />
                        }
                    />
                )}

                {state.loading && <Spinner />}
                <>
                    <Paragraph sx={{ mb: '20px', fontWeight: '600', fontSize: '21px' }}>
                        {' '}
                        Recently Completed KYC Journeys
                    </Paragraph>

                    <Paragraph sx={{ mb: 20 }}>Total to process {state.total}</Paragraph>

                    <Flex sx={{ width: '100%', justifyContent: 'space-between' }}>
                        <Flex>
                            <Paragraph sx={{ mt: 10, mr: 10 }}>
                                {numberOfFilters} {numberOfFilters === 1 ? 'filter' : 'filters'}
                            </Paragraph>
                            <Button
                                onClick={() => {
                                    updateState({ ...state, showAdvancedSearch: true });
                                }}
                                sx={{ height: 40, mr: 10 }}
                            >
                                Filter
                            </Button>
                            <Button
                                onClick={async () => {
                                    const newState = { ...state, filters: defaultKYCUsersFilters };
                                    updateState(newState);

                                    await Auth.currentAuthenticatedUser();

                                    search(newState, updateState, (users, total) => {
                                        updateState({
                                            ...newState,
                                            loading: false,
                                            items: users,
                                            total,
                                            showAdvancedSearch: false,
                                            pageNumber: state.pageNumber,
                                        });
                                        document.body.style.overflowY = 'auto';
                                    });
                                }}
                                sx={{ height: 40, mr: 10, backgroundColor: 'white', color: '#000' }}
                            >
                                Clear
                            </Button>
                            <Paragraph sx={{ ml: 40, mt: 10, mr: 10 }}>Assign To:</Paragraph>
                            <AssigneeDropdown
                                adminUserEmails={state.adminUserEmails}
                                currentState={state}
                                updateState={updateState}
                            />
                            <Button
                                disabled={
                                    state.assignedUsers.size === 0 || !state.assignee || state.assignee === 'select'
                                }
                                onClick={async () => {
                                    await assign(state, updateState);
                                }}
                                sx={{
                                    height: 40,
                                    ml: 10,
                                    backgroundColor:
                                        state.assignedUsers.size === 0 || !state.assignee || state.assignee === 'select'
                                            ? '#CCC'
                                            : 'primary',
                                    color: '#FFF',
                                }}
                            >
                                Assign
                            </Button>
                        </Flex>
                    </Flex>

                    <KycQuickFilters state={state} updateState={updateState} search={search} />

                    <Flex
                        sx={{
                            mt: 20,
                            justifyContent: 'space-between',
                            width: '100%',
                            height: '40px',
                            color: '#fff',
                        }}
                    >
                        <TableHeaderItem text="Email" topLeft />
                        <TableHeaderItem text="First Name" />
                        <TableHeaderItem text="Last Name" />
                        <TableHeaderItem text="Country" />
                        <TableHeaderItem text="Assignee" />
                        <TableHeaderItem text="Assign" />
                        <TableHeaderItem text="Pending Date" />
                        <TableHeaderItem text=" " topRight />
                    </Flex>
                    {state?.items?.map((journey, i) => (
                        <Flex key={i} sx={{ justifyContent: 'space-between', width: '100%', height: '65px' }}>
                            <TableRowItem text={journey.email_address} />
                            <TableRowItem text={journey.first_name} />
                            <TableRowItem text={journey.last_name} />
                            <TableRowItem text={journey.country_of_residence} />
                            <TableRowItem text={journey.assigned_to} />
                            <Flex
                                sx={{
                                    backgroundColor: '#FFFFFF',
                                    padding: 20,
                                    justifyContent: 'center',
                                    position: 'relative',
                                    minHeight: 90,
                                    lineHeight: '20px',
                                }}
                                onClick={() => {
                                    const newSet = new Set(state.assignedUsers);
                                    if (newSet.has(journey.user_id)) {
                                        newSet.delete(journey.user_id);
                                    } else {
                                        newSet.add(journey.user_id);
                                    }

                                    updateState({ ...state, assignedUsers: newSet });
                                }}
                            >
                                <Checkbox
                                    sx={{ height: 20, width: 50 }}
                                    checked={state.assignedUsers.has(journey.user_id)}
                                />
                            </Flex>

                            <TableRowItem
                                text={
                                    (journey?.kyc_pending_date &&
                                        moment
                                            .utc(journey.kyc_pending_date, 'YYYY-MM-DDTHH:mm:ssZ')
                                            .format('DD-MM-YYYY HH:mm')) ||
                                    ''
                                }
                            />

                            <TableRowItem openInNewTab type="view" text="View" href={`/user/${journey.user_id}`} />
                        </Flex>
                    ))}

                    <TransactionPagination
                        showTotalPages
                        showGoToStartAndEndButtons
                        totalPages={Math.ceil(state.total ? state.total / state.limit : 1)}
                        currentState={state}
                        updateState={updateState}
                    />

                    <Flex sx={{ mt: 20 }} />
                </>
            </Flex>
        </Flex>
    );
};

const mapDispatchToProps = (dispatch) => ({
    logOut: () => dispatch({ type: 'LOG_USER_OUT' }),
    updateState: (kycListState) => dispatch({ type: 'SET_KYC_LIST_STATE', kycListState }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return { loggedIn: account.loggedIn, state: account.kycListState };
};

export default connect(mapStateToProps, mapDispatchToProps)(KycComplete);
