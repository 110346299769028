/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { Flex, Paragraph, Button } from 'theme-ui';
import { useState } from 'react';
import axios from 'axios';
import { formatting } from '@accru-finance/shared-ui-libs';
import { Row } from './deposit';
import Confirm from '../confirm-dialog';
import Overlay from '../overlay';
import labelMapping from '../../utils/label-mapping';
import AccruSpinner from '../spinner';

export const markAsSent = async (currentState, updateState, withdrawal) => {
    try {
        updateState({ ...currentState, loading: true });

        const { data } = await axios({
            url: process.env.REACT_APP_TRANSACTIONS_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
            data: {
                query: `mutation { 
                    adminTools(input: {
                        user_id: "${withdrawal.user_id}"
                        uuid: "${withdrawal.uuid}"
                        tool: "mark_withdrawal_as_sent"
                    }) { 
                        success
                        message
                    }
            }`,
                variables: null,
            },
        });

        if (!data.data.adminTools.success) {
            return updateState({ ...currentState, error: 'Uh Oh, there has been a problem', loading: false });
        }

        window.location.reload();
    } catch (e) {
        return updateState({ ...currentState, error: 'Uh Oh, there has been a problem', loading: false });
    }
};

export const sendMoney = async (currentState, updateState, withdrawal) => {
    try {
        updateState({ ...currentState, loading: true });

        const { data } = await axios({
            url: process.env.REACT_APP_TRANSACTIONS_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
            data: {
                query: `mutation { 
                    adminTools(input: {
                        user_id: "${withdrawal.user_id}"
                        uuid: "${withdrawal.uuid}"
                        tool: "send_fiat_withdrawal"
                    }) { 
                        success
                        message
                    }
            }`,
                variables: null,
            },
        });

        if (!data.data.adminTools.success) {
            return updateState({ ...currentState, error: data.data.adminTools.message, loading: false });
        }

        window.location.reload();
    } catch (e) {
        return updateState({ ...currentState, error: 'Uh Oh, there has been a problem', loading: false });
    }
};

export const cancelCryptoWithdrawal = async (currentState, updateState, withdrawal) => {
    try {
        updateState({ ...currentState, loading: true });

        const { data } = await axios({
            url: process.env.REACT_APP_TRANSACTIONS_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
            data: {
                query: `mutation { 
                    adminTools(input: {
                        user_id: "${withdrawal.user_id}"
                        uuid: "${withdrawal.uuid}"
                        tool: "cancel_crypto_withdrawal"
                    }) { 
                        success
                        message
                    }
            }`,
                variables: null,
            },
        });

        if (!data.data.adminTools.success) {
            return updateState({ ...currentState, error: data.data.adminTools.message, loading: false });
        }

        return updateState({
            ...currentState,
            success: `${data.data.adminTools.message} ${withdrawal.currency_from}`,
            loading: false,
        });
    } catch (e) {
        return updateState({ ...currentState, error: 'Uh Oh, there has been a problem', loading: false });
    }
};

const Withdrawal = ({ withdrawal }) => {
    const [currentState, updateState] = useState({ loading: false, success: '', error: '' });

    return (
        <Flex sx={{ ml: 50, mt: 40, flexDirection: 'column' }}>
            <Paragraph sx={{ fontWeight: '800', mb: 20 }}>Withdrawal Details</Paragraph>

            {currentState.success && <Paragraph sx={{ color: 'green', mb: 20 }}>{currentState.success}</Paragraph>}

            {currentState.error && <Paragraph sx={{ color: 'red', mb: 20 }}>{currentState.error}</Paragraph>}

            {currentState.loading && <AccruSpinner />}

            {currentState.showConfirmDialog && (
                <Overlay
                    size="small"
                    closeOverlay={() =>
                        updateState({
                            ...currentState,
                            showConfirmDialog: false,
                            confirmDialogText: null,
                            confirmHandler: null,
                        })
                    }
                    overlayContent={
                        <Confirm text={currentState.confirmDialogText} confirmHandler={currentState.confirmHandler} />
                    }
                />
            )}

            {withdrawal.status !== 'SENT' && (
                <Flex>
                    <Button
                        onClick={() =>
                            updateState({
                                ...currentState,
                                showConfirmDialog: true,
                                confirmDialogText: 'Are you sure you wish to mark this withdrawal as sent?',
                                confirmHandler: async () => {
                                    await markAsSent(
                                        {
                                            ...currentState,
                                            showConfirmDialog: false,
                                            confirmDialogText: null,
                                            confirmHandler: null,
                                        },
                                        updateState,
                                        withdrawal
                                    );
                                },
                            })
                        }
                        sx={{ width: 170, mb: 20, height: 40 }}
                    >
                        Mark As Sent
                    </Button>

                    {withdrawal.crypto_wallet_address === 'fiat' && withdrawal.status === 'ACTIONED' && (
                        <Button
                            onClick={() =>
                                updateState({
                                    ...currentState,
                                    showConfirmDialog: true,
                                    confirmDialogText: 'Are you sure you wish to send this fiat withdrawal?',
                                    confirmHandler: async () => {
                                        await sendMoney(
                                            {
                                                ...currentState,
                                                showConfirmDialog: false,
                                                confirmDialogText: null,
                                                confirmHandler: null,
                                            },
                                            updateState,
                                            withdrawal
                                        );
                                    },
                                })
                            }
                            sx={{ width: 170, mb: 20, height: 40, ml: 20 }}
                        >
                            Send Money
                        </Button>
                    )}

                    {withdrawal.crypto_wallet_address !== 'fiat' && withdrawal.status === 'ACTIONED' && (
                        <Button
                            onClick={() =>
                                updateState({
                                    ...currentState,
                                    showConfirmDialog: true,
                                    confirmDialogText: 'Are you sure you wish to cancel this crypto withdrawal?',
                                    confirmHandler: async () => {
                                        await cancelCryptoWithdrawal(
                                            {
                                                ...currentState,
                                                showConfirmDialog: false,
                                                confirmDialogText: null,
                                                confirmHandler: null,
                                            },
                                            updateState,
                                            withdrawal
                                        );
                                    },
                                })
                            }
                            sx={{ width: 190, mb: 20, height: 40, ml: 20 }}
                        >
                            Cancel Withdrawal
                        </Button>
                    )}
                </Flex>
            )}

            <Row label="Amount" value={formatting.formatForDisplay(withdrawal.amount)} />
            <Row label="Currency" value={labelMapping(withdrawal.currency_from)} />
            <Row label="Status" value={labelMapping(withdrawal.status)} />
            <Row label="Fee" value={withdrawal.fee} />
            <Row label="Withdrawal Date" value={withdrawal.withdrawal_date} />

            {withdrawal.crypto_wallet_address && withdrawal.crypto_wallet_address !== 'fiat' && (
                <>
                    <Row label="Gas Fee" value={withdrawal.gas_fee} />
                    <Row label="Crypto Wallet Address" value={withdrawal.crypto_wallet_address} />
                </>
            )}

            {withdrawal.crypto_transaction_id && (
                <Row label="Crypto Transaction id" value={withdrawal.crypto_transaction_id} />
            )}

            {withdrawal?.fiat_beneficiary && (
                <>
                    <Paragraph sx={{ mb: 20, mt: 20, fontWeight: '800' }}>Beneficiary Details</Paragraph>

                    <Row label="First Name" value={withdrawal.fiat_beneficiary?.first_name} />
                    <Row label="Middle Name" value={withdrawal.fiat_beneficiary?.middle_name} />
                    <Row label="Last Name" value={withdrawal.fiat_beneficiary?.last_name} />
                    <Row label="Address Line" value={withdrawal.fiat_beneficiary?.address_line} />
                    <Row label="City" value={withdrawal.fiat_beneficiary?.city} />
                    <Row label="Country" value={withdrawal.fiat_beneficiary?.country} />
                    <Row label="Post Code" value={withdrawal.fiat_beneficiary?.post_code} />
                    <Row label="Bank Account Country" value={withdrawal.fiat_beneficiary?.bank_account_country} />
                    <Row label="Bank Name" value={withdrawal.fiat_beneficiary?.bank_name} />
                    <Row label="IBAN" value={withdrawal.fiat_beneficiary?.iban} />
                    <Row label="Account Number" value={withdrawal.fiat_beneficiary?.account_number} />
                    <Row label="Sort Code" value={withdrawal.fiat_beneficiary?.sort_code} />
                    <Row label="Payment Type" value={withdrawal.fiat_beneficiary?.payment_type} />
                </>
            )}
        </Flex>
    );
};

export default Withdrawal;
