/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-unreachable */
/* eslint-disable no-plusplus */
import { Flex, Button, Paragraph } from 'theme-ui';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Spinner from '../spinner';
import Basic from './basic';
import Contact from './contact';
import KYC from './kyc';
import { UpdateUserDetails } from '../../utils/update-user-details';

const loadCountries = async (currentState: any, updateState: any, formState: any, updateFormState: any) => {
    try {
        const { data: countryList } = await axios({
            url: `https://cdn.accru.finance/countries/codes.json?cache=${new Date().getTime()}`,
        });

        updateState([{ code: 'SELECT', name: 'Please select a country' }, ...countryList]);
    } catch (e) {
        updateState([]);
    }
    updateFormState({ ...formState, loading: false });
};

const validateData = (formState: any, type: any, updateErrorState: any) => {
    const errorState = {
        first_name: false,
        last_name: false,
        occupation: false,
        source_of_funds: false,
        date_of_birth: false,
        nationality: false,
        address: false,
        place_of_birth: false,
        country_of_birth: false,
        other_nationality: false,
        expected_amount_to_invest: false,
        expected_amount_to_invest_crypto: false,
    };
    let errors = 0;
    if (type === 'basic') {
        if (formState.first_name === '') {
            errorState.first_name = true;
            errors++;
        }
        if (formState.last_name === '') {
            errorState.last_name = true;
            errors++;
        }
    }

    if (type === 'contact') {
        if (
            formState.address?.street === '' ||
            formState.address?.post_code === '' ||
            formState.address?.country === 'SELECT'
        ) {
            errorState.address = true;
            errors++;
        }
    }

    if (type === 'kyc') {
        if (formState.occupation === '') {
            errorState.occupation = true;
            errors++;
        }
        if (formState.source_of_funds === 'select') {
            errorState.source_of_funds = true;
            errors++;
        }
        if (formState.date_of_birth === '') {
            errorState.date_of_birth = true;
            errors++;
        }
        if (formState.nationality === 'SELECT') {
            errorState.nationality = true;
            errors++;
        }
        if (formState.place_of_birth === '') {
            errorState.place_of_birth = true;
            errors++;
        }
        if (formState.country_of_birth === 'SELECT') {
            errorState.country_of_birth = true;
            errors++;
        }
        if (formState.expected_amount_to_invest === 'select') {
            errorState.expected_amount_to_invest = true;
            errors++;
        }
        if (formState.expected_amount_to_invest_crypto === 'select') {
            errorState.expected_amount_to_invest_crypto = true;
            errors++;
        }
    }

    updateErrorState({ ...errorState });
    return errors < 1;
};

const createReasonString = (originalValues: any, formState: any, type: any) => {
    let reasonString = '';

    if (type === 'basic') {
        if (originalValues.first_name !== formState.first_name) {
            reasonString += ` First name changed from ${originalValues.first_name} to ${formState.first_name}, `;
        }
        if (originalValues.middle_names !== formState.middle_names) {
            reasonString += ` Middle names changed from ${originalValues.middle_names} to ${formState.middle_names}, `;
        }
        if (originalValues.last_name !== formState.last_name) {
            reasonString += ` Last name changed from ${originalValues.last_name} to ${formState.last_name}, `;
        }
    }

    if (type === 'contact') {
        const origAddress = originalValues?.address || {};
        const currentAddress = formState?.address || {};
        if (origAddress.country !== currentAddress.country) {
            reasonString += ` Country has changed from ${origAddress.country} to ${currentAddress.country}, `;
        }
        if (origAddress.post_code !== currentAddress.post_code) {
            reasonString += ` Post code has changed from ${origAddress.post_code} to ${currentAddress.post_code}, `;
        }
        if (origAddress.state !== currentAddress.state) {
            reasonString += ` State has changed from ${origAddress.state} to ${currentAddress.state}, `;
        }
        if (origAddress.street !== currentAddress.street) {
            reasonString += ` Street has changed from ${origAddress.street} to ${currentAddress.street}, `;
        }
        if (origAddress.sub_street !== currentAddress.sub_street) {
            reasonString += ` Sub Street has changed from ${origAddress.sub_street} to ${currentAddress.sub_street}, `;
        }
        if (origAddress.town !== currentAddress.town) {
            reasonString += ` Town has changed from ${origAddress.town} to ${currentAddress.town}, `;
        }
    }

    if (type === 'kyc') {
        if (originalValues.occupation !== formState.occupation) {
            reasonString += ` Occupation changed from ${originalValues.occupation} to ${formState.occupation}, `;
        }
        if (originalValues.source_of_funds !== formState.source_of_funds) {
            reasonString += ` Source of funds changed from ${originalValues.source_of_funds} to ${formState.source_of_funds}, `;
        }
        if (originalValues.date_of_birth !== formState.date_of_birth) {
            reasonString += ` DOB changed from ${originalValues.date_of_birth} to ${formState.date_of_birth}, `;
        }
        if (originalValues.nationality !== formState.nationality) {
            reasonString += ` Nationality changed from ${originalValues.nationality} to ${formState.nationality}, `;
        }
        if (originalValues.place_of_birth !== formState.place_of_birth) {
            reasonString += ` Place of birth changed from ${originalValues.place_of_birth} to ${formState.place_of_birth}, `;
        }
        if (originalValues.country_of_birth !== formState.country_of_birth) {
            reasonString += ` Country of birth changed from ${originalValues.country_of_birth} to ${formState.country_of_birth}, `;
        }
        if (originalValues.personal_id_number !== formState.personal_id_number) {
            reasonString += ` Personal ID number changed from ${originalValues.personal_id_number} to ${formState.personal_id_number}, `;
        }
        if (originalValues.other_nationality !== formState.other_nationality) {
            reasonString += ` Other nationality changed from ${originalValues.other_nationality} to ${formState.other_nationality}, `;
        }
        if (originalValues.expected_amount_to_invest !== formState.expected_amount_to_invest) {
            reasonString += ` Expected amount to invest changed from ${originalValues.expected_amount_to_invest} to ${formState.expected_amount_to_invest}, `;
        }
        if (originalValues.expected_amount_to_invest_crypto !== formState.expected_amount_to_invest_crypto) {
            reasonString += ` Expected amount to invest changed from ${originalValues.expected_amount_to_invest_crypto} to ${formState.expected_amount_to_invest_crypto}, `;
        }
    }

    return reasonString;
};

const submitData = async (formState: any, reason: any) => {
    const {
        user_id,
        first_name,
        middle_names,
        last_name,
        occupation,
        source_of_funds,
        date_of_birth,
        nationality,
        address,
        place_of_birth,
        country_of_birth,
        personal_id_number,
        other_nationality,
        expected_amount_to_invest,
        expected_amount_to_invest_crypto,
    } = formState;

    const res = await UpdateUserDetails({
        user_id,
        first_name,
        middle_names,
        last_name,
        occupation,
        source_of_funds,
        date_of_birth,
        nationality,
        address,
        place_of_birth,
        country_of_birth,
        personal_id_number,
        other_nationality,
        expected_amount_to_invest,
        expected_amount_to_invest_crypto,
        reason,
    });
    return res;
};

const updateUser = async (
    formState: any,
    updateFormState: any,
    originalValues: any,
    type: any,
    updateErrorState: any
) => {
    updateFormState({ ...formState, loading: true, errorMsg: '' });

    const validForm = validateData(formState, type, updateErrorState);

    if (validForm) {
        const reason = createReasonString(originalValues, formState, type);

        const result = await submitData(formState, reason);
        // @ts-ignore
        if (result?.success) {
            window.location.reload();
        } else {
            updateFormState({ ...formState, loading: false, errorMsg: 'Failed to submit changes' });
        }
    } else {
        updateFormState({ ...formState, loading: false, errorMsg: '' });
    }
};

const UserUpdate = ({ type, state }: any) => {
    const {
        user_id,
        first_name,
        middle_names,
        last_name,
        occupation,
        source_of_funds,
        date_of_birth,
        nationality,
        address,
        place_of_birth,
        country_of_birth,
        personal_id_number,
        other_nationality,
        expected_amount_to_invest,
        expected_amount_to_invest_crypto,
    } = state;

    const [formState, updateFormState] = useState({
        user_id,
        first_name,
        middle_names,
        last_name,
        occupation,
        source_of_funds,
        date_of_birth,
        nationality,
        address,
        place_of_birth,
        country_of_birth,
        personal_id_number,
        other_nationality,
        expected_amount_to_invest,
        expected_amount_to_invest_crypto,
        loading: true,
        errorMsg: '',
    });

    const [errorState, updateErrorState] = useState({
        first_name: false,
        last_name: false,
        occupation: false,
        source_of_funds: false,
        date_of_birth: false,
        nationality: false,
        address: false,
        place_of_birth: false,
        country_of_birth: false,
        other_nationality: false,
        expected_amount_to_invest: false,
        expected_amount_to_invest_crypto: false,
    });

    const [countries, updateCountries] = useState([]);

    useEffect(() => {
        loadCountries(countries, updateCountries, formState, updateFormState);
    }, []);

    return (
        <Flex
            sx={{
                width: '95%',
                height: '86%',
                margin: '7% auto 0',
                boxSizing: 'border-box',
                overflow: 'scroll',
                position: 'relative',
            }}
        >
            {formState.loading && <Spinner />}
            {type === 'basic' && (
                <Basic formState={formState} updateFormState={updateFormState} errorState={errorState} />
            )}
            {type === 'contact' && (
                <Contact
                    formState={formState}
                    updateFormState={updateFormState}
                    countries={countries}
                    errorState={errorState}
                />
            )}
            {type === 'kyc' && (
                <KYC
                    countries={countries}
                    formState={formState}
                    updateFormState={updateFormState}
                    errorState={errorState}
                    updateErrorState={updateErrorState}
                />
            )}

            <Flex
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    width: '90%',
                    margin: '0 auto',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                }}
            >
                <Button
                    onClick={() => updateUser(formState, updateFormState, state, type, updateErrorState)}
                    sx={{ color: 'white', position: 'absolute', margin: '0 auto', width: '180px' }}
                >
                    Update
                </Button>
                {formState.errorMsg && (
                    <Paragraph sx={{ width: '100%', textAlign: 'center', height: '30px', color: 'red', pt: '15px' }}>
                        {formState.errorMsg}
                    </Paragraph>
                )}
            </Flex>
        </Flex>
    );
};

export default UserUpdate;
