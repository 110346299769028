/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';

export const duplicatesCouldExist = async (firstName: string, lastName: string, dateOfBirth: string) => {
    const { data } = await axios({
        url: process.env.REACT_APP_ADMIN_SEARCH_API,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
        },
        method: 'POST',
        data: {
            query: {
                bool: {
                    must: [
                        { wildcard: { FirstName: `*${firstName}*` } },
                        { wildcard: { LastName: `*${lastName}*` } },
                        { match_phrase: { DateOfBirth: { query: dateOfBirth } } },
                        {
                            bool: {
                                should: [
                                    { match_phrase: { KycStatus: { query: 'verified' } } },
                                    { match_phrase: { KycStatus: { query: 'blocked' } } },
                                    { match_phrase: { KycStatus: { query: 'rejected' } } },
                                ],
                            },
                        },
                    ],
                },
            },
        },
    });

    return data?.total?.value;
};

export const getTransactionDataSummary = async (userId: string) => {
    const { data } = await axios({
        url: process.env.REACT_APP_TRANSACTIONS_API,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'POST',
        data: {
            query: `query { 
                adminListTransactions(user_id: "${userId}", offset: 0, limit: 0, type: "summary") { 
                    addresses {
                        type
                        address
                    }
                    account_summary {
                        total_uninvested_eur
                        total_uninvested_gbp
                        total_uninvested_custom
                        gbp_balance
                        eur_balance
                        custom_balance
                        total_earnings_eur
                        total_earnings_gbp
                        total_earnings_custom
                        earnings_per_hour_eur
                        earnings_per_hour_gbp
                        earnings_per_hour_custom
                        deposit_balances {
                            currency
                            amount
                        }
                        coin_balances {
                            type
                            value
                            profit_and_loss {
                                usd
                                custom
                            }
                        }
                        locked_investment_windows {
                            status
                            start_date_of_lock_in
                            end_date_of_lock_in
                            days_to_go
                            amount_invested
                            product_name
                            product
                        }
                        mixed_fund_balances {
                            amount
                            amount_custom
                            currency
                            currency_label
                            product
                        }
                        mixed_funds_overview {
                            pnl_custom
                            enabled
                            product
                            pnl_usd
                            valuation
                        }
                    }
                } 
            }`,
            variables: null,
        },
    });

    return data.data.adminListTransactions;
};

export const getUserDetails = async (id: any) => {
    const { data } = await axios({
        url: process.env.REACT_APP_USERS_API,
        method: 'POST',
        data: {
            query: `query { 
                        getUserAsAdmin(user_id:"${id}", email:"${id}") { 
                            created_at
                            success
                            user_id
                            user_type
                            email_address
                            first_name
                            middle_names
                            last_name
                            phone_number
                            phone_number_parts
                            currency
                            currency_label
                            enabled
                            match_status
                            edd {
                                status
                                sub_reason
                                tier {
                                    crypto
                                    fiat
                                }
                            }
                            kyc {
                                applicant_id
                                status
                                check_attempts_count
                                poa_uploaded
                                assigned_to
                                address_verification
                            }
                            metadata {
                                biometrics_prompt_viewed
                                welcome_prompt_viewed
                                disable_weekly_interest_email
                                disable_marketing_nudges
                                use_alt_email_provider
                            }
                            occupation
                            source_of_funds
                            date_of_birth
                            nationality 
                            address {
                                street
                                sub_street
                                town
                                post_code
                                state
                                country
                            }
                            place_of_birth
                            country_of_birth
                            personal_id_number
                            has_other_nationality
                            other_nationality
                            expected_amount_to_invest
                            expected_amount_to_invest_crypto
                            permissions {
                                crypto_withdrawals
                                fiat_withdrawals
                                buying
                                selling
                            }
                            risk_score {
                                rating
                                score
                                expected_amounts_to_invest {
                                    currency
                                    fiat {
                                        in_euros
                                        chosen
                                    }
                                    crypto {
                                        in_euros
                                        chosen
                                    }
                                }
                            }
                            mfa {
                                setup
                            }
                            corporate_id
                            company_name
                            referred_by_user_id
                            kyc_pending_date
                            kyc_verified_date
                            kyc_updated_date
                            open_payd {
                                euro_id
                                gbp_id
                                linked_client_id
                            }
                            regular_payment_config {
                                day_of_month
                                amount
                                product
                                source
                                currency
                            }
                        }
                    }`,
            variables: null,
        },
    });

    const result = data.data?.getUserAsAdmin;

    if (data.data?.getUserAsAdmin?.user_type === 'Corporate') {
        window.location.assign(window.location.href.replace('user/', 'corporate/'));
    }

    return result;
};

export const getAdminUsers = async () => {
    const { data } = await axios({
        url: process.env.REACT_APP_USERS_API,
        method: 'POST',
        data: {
            query: `mutation {
                adminTools(input: {
                    tool: "list_admin_users"
                }) { 
                    success
                    message
                    emails
                }
            }`,
            variables: null,
        },
    });

    const result = data.data?.adminTools;

    if (!result.success) {
        throw new Error('Failed');
    }

    return result.emails;
};

export const deleteUser = async (state: any, updateState: any) => {
    updateState({ ...state, loading: true });

    try {
        const { data } = await axios({
            url: process.env.REACT_APP_USERS_API,
            method: 'POST',
            data: {
                query: `mutation {
                adminTools(input: {
                    user_id: "${state.user_id}"
                    tool: "delete_user"
                    current_email_address: "${state.email_address}"
                }) { 
                    success
                    message
                }
            }`,
                variables: null,
            },
        });

        const result = data.data?.adminTools;

        if (!result.success) {
            throw new Error('Failed');
        }

        window.location.reload();
    } catch (e) {
        updateState({
            ...state,
            loading: false,
            error: 'There has been an error deleting the user, please try again later',
        });
    }
};

export const getReferralSummary = async (id: any) => {
    const { data } = await axios({
        url: process.env.REACT_APP_ADMIN_REFERRAL_API,
        method: 'POST',
        data: {
            query: `query { 
                getSummary(user_id:"${id}") { 
                    success
                    number_of_referrals
                    referral_code
                    joined_via_referral
                    date_joined
                    days_until_referral_credit_applied
                    days_left_to_fund_account
                    refer_on_verification
                    referrer_usdt_amount
                    referee_usdt_amount
                    code_used_to_join
                }
            }`,
            variables: null,
        },
    });

    return data.data?.getSummary;
};

export const getCorporateDetails = async (id: any) => {
    const { data } = await axios({
        url: process.env.REACT_APP_CORPORATES_API,
        method: 'POST',
        data: {
            query: `query {
                    getCorporate(corporate_id: "${id}") { 
                        success
                        corporate_id
                        company_name
                        company_type
                        registration_number
                        website
                        contact_email
                        contact_telephone_number
                        contact_person
                        next_kyc_refresh_date
                        kyc_status
                        industry
                        expected_amount_to_invest
                        licence_details
                        source_of_funds
                        risk_score
                        currency
                        licence_details_validity_date
                        correspondence_address {
                            street
                            sub_street
                            town
                            post_code
                            state
                            country
                        }
                        address {
                            street
                            sub_street
                            town
                            post_code
                            state
                            country
                        }
                    }
                }`,
            variables: null,
        },
    });
    const result = data.data?.getCorporate;
    return result;
};

export const getUsersReferredByTheCurrentUser = async (id: any) => {
    const filterOptions = [{ match_all: {} }, { match_phrase: { ReferredByUserId: { query: id } } }];
    try {
        const { data } = await axios({
            url: process.env.REACT_APP_ADMIN_SEARCH_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
            data: {
                from: 0,
                size: 20,
                query: {
                    bool: {
                        filter: filterOptions,
                    },
                },
                sort: [
                    {
                        CreatedAt: {
                            order: 'desc',
                        },
                    },
                ],
            },
        });

        const formattedForMainTable = data.users.map((item: any) => ({
            email_address: item._source.Email,
            user_id: item._source.UserId,
            created_at: item._source.CreatedAt,
        }));

        return formattedForMainTable;
    } catch (e) {
        console.log('swallow, failed getting Referred users');
    }
};

export const getPortfolioFigures = async (userId: string, currency: string) => {
    try {
        const { data } = await axios({
            url: process.env.REACT_APP_TRANSACTIONS_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            data: {
                query: `query { 
                getPortfolioFiguresAsAdmin(input:{user_id: "${userId}", limit: 50, pagination_token: null, currency: "${currency}"}) {
                    status
                    pagination_token
                    currency_label
                    currency_symbol
                    products {
                        id
                        label
                    }
                    figures {
                        timestamp
                        auto_btc
                        auto_btc_custom
                        auto_btc_total_invested
                        auto_btc_total_invested_custom
                        auto_eth
                        auto_eth_custom
                        auto_eth_total_invested
                        auto_eth_total_invested_custom
                        stablecoin
                        stablecoin_custom
                        stablecoin_total_invested
                        stablecoin_total_invested_custom
                        maple
                        maple_custom
                        maple_total_invested
                        maple_total_invested_custom
                        maple_eth
                        maple_eth_custom
                        maple_eth_total_invested
                        maple_eth_total_invested_custom
                        custom_total
                        unix
                    }
                }}`,
                variables: null,
            },
        });

        if (data.data.getPortfolioFiguresAsAdmin?.status === 'OK') {
            return { ...data.data.getPortfolioFiguresAsAdmin, errorType: '' };
        }

        if (data.data.getPortfolioFiguresAsAdmin?.status === 'NOT_ENOUGH_DATA') {
            return { figures: [], errorType: 'NOT_ENOUGH_DATA' };
        }

        return { figures: [], errorType: 'FAILED_TO_GET_DATA' };
    } catch (e) {
        return { figures: [], errorType: 'FAILED_TO_GET_DATA' };
    }
};
