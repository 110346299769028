/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import { Flex, Paragraph, Image, Label, Radio } from 'theme-ui';
import { Auth } from 'aws-amplify';
import * as axios from 'axios';
import * as moment from 'moment';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { formatting } from '@accru-finance/shared-ui-libs';
import * as Decimal from 'decimal.js';
import { useEffect, useState } from 'react';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';
import { TableRowItem, TableHeaderItem } from '../components/table';

const defaultState = {
    uninvestedCurrency: 'eur',
    investedProduct: 'stablecoin',
    depositCurrency: 'eur',
    loading: false,
    error: null,
    singleValueTotals: [],
    multiValueTotals: [],
};

const getAum = async () => {
    const { data } = await axios({
        url: process.env.REACT_APP_ADMIN_DASHBOARD_API,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'POST',
        data: {
            query: `query { 
                aum {
                    usd
                }
            }`,
            variables: null,
        },
    });

    return data.data.aum.usd;
};

const getTotalUsers = async () => {
    const { data } = await axios({
        url: process.env.REACT_APP_ADMIN_SEARCH_API,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
        },
        params: {
            api: '_count',
        },
        method: 'POST',
        data: {},
    });

    return data?.count;
};

const getCountFromEs = async (fieldName, fieldValue) => {
    const { data } = await axios({
        url: process.env.REACT_APP_ADMIN_SEARCH_API,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
        },
        method: 'POST',
        data: {
            query: { term: { [fieldName]: { value: fieldValue } } },
            size: 0,
            track_total_hits: true,
        },
    });

    return data?.total?.value;
};

const getDataForComplexBarChart = async (type) => {
    const from = moment.utc().subtract(30, 'days').format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]');
    const to = moment.utc().format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]');

    const { data } = await axios({
        url: process.env.REACT_APP_ADMIN_DASHBOARD_API,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'POST',
        data: {
            query: `query { 
                getAmounts(type: "${type}", from: "${from}", to: "${to}", pagination_token: "", limit: 20) { 
                    success
                    amounts {
                        type
                        timestamp
                        amounts {
                            type
                            amount
                        }
                    }
                } 
            }`,
            variables: null,
        },
    });

    return data.data.getAmounts.amounts.reverse();
};

const getDataForSimpleBarChart = async (type) => {
    const from = moment.utc().subtract(30, 'days').format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]');
    const to = moment.utc().format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]');

    const { data } = await axios({
        url: process.env.REACT_APP_ADMIN_DASHBOARD_API,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'POST',
        data: {
            query: `query { 
                getAmounts(type: "${type}", from: "${from}", to: "${to}", pagination_token: "", limit: 20) { 
                    success
                    amounts {
                        type
                        timestamp
                        amount
                    }
                } 
            }`,
            variables: null,
        },
    });

    return data.data.getAmounts.amounts;
};

const formatXAxis = (tickItem) => moment(tickItem).format('MMM Do YY');

const loadFigures = async (currentState, updateState) => {
    updateState({ ...currentState, loading: true });

    try {
        const { data } = await axios({
            url: process.env.REACT_APP_ADMIN_DASHBOARD_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            data: {
                query: `query { 
                getDashboardFigures { 
                    success
                    totals {
                        type
                        title
                        format_as_int
                        amounts {
                            amount
                            group_by_column
                        }
                    }
                } 
            }`,
                variables: null,
            },
        });

        if (!data.data.getDashboardFigures.success) {
            return updateState({
                ...currentState,
                loading: false,
                error: 'Uh oh, there has been an error loading the figures',
            });
        }

        const [
            registrations,
            dailyRegistrations,
            uninvestedBalances,
            investedBalances,
            depositedAmounts,
            verifiedUsers,
            aum,
        ] = await Promise.all([
            getDataForSimpleBarChart('TOTAL_REGISTRATIONS'),
            getDataForSimpleBarChart('DAILY_REGISTRATIONS'),
            getDataForComplexBarChart('UNINVESTED_BALANCES'),
            getDataForComplexBarChart('INVESTED_BALANCES'),
            getDataForComplexBarChart('DEPOSITED_AMOUNTS'),
            getCountFromEs('KycStatus', 'verified'),
            getAum(),
        ]);

        const singleValueTotals = data.data.getDashboardFigures.totals.filter((total) => total.amounts.length <= 1);

        singleValueTotals.push({
            title: 'Verified Users',
            format_as_int: true,
            amounts: [
                {
                    amount: verifiedUsers,
                },
            ],
        });

        singleValueTotals.push({
            title: 'Total Registrations',
            format_as_int: true,
            amounts: [
                {
                    amount: await getTotalUsers(),
                },
            ],
        });

        singleValueTotals.push({
            title: 'AUM ($)',
            format_as_int: true,
            amounts: [
                {
                    amount: aum,
                },
            ],
        });

        updateState({
            ...currentState,
            loading: false,
            verifiedUsers,
            registrations: registrations.reverse(),
            dailyRegistrations: dailyRegistrations.reverse(),
            uninvestedBalances: uninvestedBalances.map((item) => ({
                timestamp: item.timestamp,
                ...item.amounts.reduce((accum, it) => {
                    accum[it.type] = parseFloat(it.amount);
                    return accum;
                }, {}),
            })),
            investedBalances: investedBalances.map((item) => ({
                timestamp: item.timestamp,
                ...item.amounts.reduce((accum, it) => {
                    accum[it.type] = parseFloat(it.amount);
                    return accum;
                }, {}),
            })),
            depositedAmounts: depositedAmounts.map((item) => ({
                timestamp: item.timestamp,
                ...item.amounts.reduce((accum, it) => {
                    accum[it.type] = parseFloat(it.amount);
                    return accum;
                }, {}),
            })),
            singleValueTotals,
            multiValueTotals: data.data.getDashboardFigures.totals.filter((total) => total.amounts.length > 1),
        });
    } catch (e) {
        updateState({ ...currentState, loading: false, error: 'Uh oh, there has been an error loading the figures' });
    }
};

const Users = ({ logOut }) => {
    const [state, updateState] = useState(defaultState);
    const [showMenu, toggleMenu] = useState(true);
    const history = useHistory();

    useEffect(() => {
        (async () => {
            try {
                await Auth.currentAuthenticatedUser();
            } catch (e) {
                history.push('/');
            }
        })();

        loadFigures(state, updateState);
    }, []);

    return (
        <Flex
            sx={{
                overflow: 'hidden',
                top: '0',
                left: '0',
                bottom: 0,
                minHeight: '100vh',
                width: '100vw',
                flexDirection: 'row',
                backgroundColor: 'greyBackground',
                margin: 0,
                padding: 0,
            }}
        >
            <Sidebar
                logout={logOut}
                currentPage="Dashboard"
                showMenu={showMenu}
                toggleMenu={toggleMenu}
                history={history}
            />

            <Flex
                sx={{
                    border: '0px red solid',
                    width: '90%',
                    maxWidth: '1300px',
                    minHeight: '400px',
                    flexDirection: 'column',
                    mt: '50px',
                    mb: '100px',
                    ml: showMenu ? 240 : 45,
                }}
            >
                {state.loading && <Spinner />}

                {state.error && <Paragraph sx={{ color: 'red' }}>{state.error}</Paragraph>}

                {!state.loading && (
                    <>
                        <Flex sx={{ width: 600 }}>
                            <Image
                                sx={{ width: 150, height: 40 }}
                                src="https://cdn.accru.finance/logos/secondary.png"
                                alt="logo"
                            />
                            <Paragraph sx={{ mb: 40, mt: 0, ml: '5px', fontWeight: '600', fontSize: 24 }}>MI</Paragraph>
                        </Flex>
                        <Flex
                            sx={{
                                flexDirection: 'row',
                                maxWidth: 800,
                                justifyContent: 'space-around',
                                flexWrap: 'wrap',
                            }}
                        >
                            {state.singleValueTotals.map((total) => (
                                <Flex sx={{ flexDirection: 'column', mb: 40, ml: 10, mr: 10 }}>
                                    <Paragraph sx={{ mb: 10, height: 50, textAlign: 'center' }}>
                                        {total.title}
                                    </Paragraph>

                                    {total.amounts.length === 0 && (
                                        <Paragraph sx={{ mt: 30, textAlign: 'center' }}>None</Paragraph>
                                    )}
                                    {total.amounts.map((amount) => (
                                        <Flex
                                            sx={{
                                                height: '65px',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            {amount.group_by_column && <Paragraph>{amount.group_by_column}</Paragraph>}

                                            <Paragraph sx={{ ml: 30, fontSize: total.format_as_int ? 50 : 20 }}>
                                                {total.format_as_int
                                                    ? new Intl.NumberFormat('en-US').format(
                                                          parseFloat(new Decimal(amount.amount).toFixed(0).toString())
                                                      )
                                                    : new Decimal(amount.amount).toFixed(6)}
                                            </Paragraph>
                                        </Flex>
                                    ))}
                                </Flex>
                            ))}
                        </Flex>
                        <Flex sx={{ mt: 40 }}>
                            <BarChart
                                width={500}
                                height={300}
                                data={state.registrations}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis tickFormatter={formatXAxis} dataKey="timestamp" />
                                <YAxis />
                                <Tooltip labelFormatter={(label) => moment.utc(label).format('Do MMM YYYY HH:mm')} />
                                <Legend />
                                <Bar
                                    dataKey="amount"
                                    name="Total Registrations"
                                    label={{ fill: '#FFFFFF' }}
                                    fill="#5100C9"
                                />
                            </BarChart>
                            <BarChart
                                width={500}
                                height={300}
                                data={state.dailyRegistrations}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis tickFormatter={formatXAxis} dataKey="timestamp" />
                                <YAxis />
                                <Tooltip labelFormatter={(label) => moment.utc(label).format('Do MMM YYYY HH:mm')} />
                                <Legend />
                                <Bar
                                    dataKey="amount"
                                    name="Daily Registrations"
                                    label={{ fill: '#FFFFFF' }}
                                    fill="#F0B929"
                                />
                            </BarChart>
                        </Flex>

                        <Flex sx={{ mt: 60, flexDirection: 'column' }}>
                            <Paragraph sx={{ alignSelf: 'flex-start', fontWeight: '800', mb: 50 }}>
                                Uninvested Balances over time
                            </Paragraph>

                            <Flex
                                sx={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    mb: 40,
                                    width: '80%',
                                }}
                            >
                                <Label>
                                    <Radio
                                        onClick={() => updateState({ ...state, uninvestedCurrency: 'bitcoin' })}
                                        name="dark-mode"
                                        value="bitcoin"
                                    />
                                    Bitcoin
                                </Label>
                                <Label>
                                    <Radio
                                        onClick={() => updateState({ ...state, uninvestedCurrency: 'ethereum' })}
                                        name="dark-mode"
                                        value="ethereum"
                                    />
                                    Ethereum
                                </Label>
                                <Label>
                                    <Radio
                                        onClick={() => updateState({ ...state, uninvestedCurrency: 'usdc-coin' })}
                                        name="dark-mode"
                                        value="usdc-coin"
                                    />
                                    USDC
                                </Label>
                                <Label>
                                    <Radio
                                        onClick={() => updateState({ ...state, uninvestedCurrency: 'tether' })}
                                        name="dark-mode"
                                        value="tether"
                                    />
                                    USDT
                                </Label>
                                <Label>
                                    <Radio
                                        onClick={() => updateState({ ...state, uninvestedCurrency: 'dai' })}
                                        name="dark-mode"
                                        value="dai"
                                    />
                                    DAI
                                </Label>
                                <Label>
                                    <Radio
                                        onClick={() => updateState({ ...state, uninvestedCurrency: 'eur' })}
                                        name="dark-mode"
                                        value="eur"
                                        defaultChecked
                                    />
                                    Euros
                                </Label>
                                <Label>
                                    <Radio
                                        onClick={() => updateState({ ...state, uninvestedCurrency: 'gbp' })}
                                        name="dark-mode"
                                        value="gbp"
                                        defaultChecked
                                    />
                                    Pounds
                                </Label>
                            </Flex>

                            <BarChart width={800} height={300} data={state.uninvestedBalances} margin={{}}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis tickFormatter={formatXAxis} dataKey="timestamp" />
                                <YAxis tickFormatter={(value) => formatting.formatForDisplay(value)} />
                                <Tooltip
                                    formatter={(value) => formatting.formatForDisplay(value)}
                                    labelFormatter={(label) => moment.utc(label).format('Do MMM YYYY HH:mm')}
                                />
                                <Legend />
                                <Bar dataKey={state.uninvestedCurrency} fill="#5100C9" />
                            </BarChart>
                        </Flex>

                        <Flex sx={{ mt: 60, flexDirection: 'column' }}>
                            <Paragraph sx={{ fontWeight: '800', mb: 50, alignSelf: 'flex-start' }}>
                                Invested Balances over time
                            </Paragraph>

                            <Flex
                                sx={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    mb: 40,
                                    width: '60%',
                                }}
                            >
                                <Label>
                                    <Radio
                                        onClick={() => updateState({ ...state, investedProduct: 'auto_btc' })}
                                        name="dark-mode"
                                        value="auto_btc"
                                    />
                                    Bitcoin
                                </Label>
                                <Label>
                                    <Radio
                                        onClick={() => updateState({ ...state, investedProduct: 'auto_eth' })}
                                        name="dark-mode"
                                        value="auto_eth"
                                    />
                                    Ethereum
                                </Label>
                                <Label>
                                    <Radio
                                        onClick={() => updateState({ ...state, investedProduct: 'stablecoin' })}
                                        name="dark-mode"
                                        value="stablecoin"
                                        defaultChecked
                                    />
                                    Stablecoin
                                </Label>
                                <Label>
                                    <Radio
                                        onClick={() => updateState({ ...state, investedProduct: 'maple' })}
                                        name="dark-mode"
                                        value="maple"
                                        defaultChecked
                                    />
                                    Maple USDC
                                </Label>
                            </Flex>

                            <BarChart width={800} height={300} data={state.investedBalances}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis tickFormatter={formatXAxis} dataKey="timestamp" />
                                <YAxis tickFormatter={(value) => formatting.formatForDisplay(value)} />
                                <Tooltip
                                    formatter={(value) => formatting.formatForDisplay(value)}
                                    labelFormatter={(label) => moment.utc(label).format('Do MMM YYYY HH:mm')}
                                />
                                <Legend />
                                <Bar dataKey={state.investedProduct} fill="#27AE60" />
                            </BarChart>
                        </Flex>

                        <Flex sx={{ mt: 60, flexDirection: 'column' }}>
                            <Paragraph sx={{ alignSelf: 'flex-start', fontWeight: '800', mb: 50 }}>
                                Deposits over time
                            </Paragraph>

                            <Flex
                                sx={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    mb: 40,
                                    width: '80%',
                                }}
                            >
                                <Label>
                                    <Radio
                                        onClick={() => updateState({ ...state, depositCurrency: 'bitcoin' })}
                                        name="dark-mode"
                                        value="bitcoin"
                                    />
                                    Bitcoin
                                </Label>
                                <Label>
                                    <Radio
                                        onClick={() => updateState({ ...state, depositCurrency: 'ethereum' })}
                                        name="dark-mode"
                                        value="ethereum"
                                    />
                                    Ethereum
                                </Label>
                                <Label>
                                    <Radio
                                        onClick={() => updateState({ ...state, depositCurrency: 'usdc-coin' })}
                                        name="dark-mode"
                                        value="usdc-coin"
                                    />
                                    USDC
                                </Label>
                                <Label>
                                    <Radio
                                        onClick={() => updateState({ ...state, depositCurrency: 'tether' })}
                                        name="dark-mode"
                                        value="tether"
                                    />
                                    USDT
                                </Label>
                                <Label>
                                    <Radio
                                        onClick={() => updateState({ ...state, depositCurrency: 'dai' })}
                                        name="dark-mode"
                                        value="dai"
                                    />
                                    DAI
                                </Label>
                                <Label>
                                    <Radio
                                        onClick={() => updateState({ ...state, depositCurrency: 'eur' })}
                                        name="dark-mode"
                                        value="eur"
                                        defaultChecked
                                    />
                                    Euros
                                </Label>
                                <Label>
                                    <Radio
                                        onClick={() => updateState({ ...state, depositCurrency: 'gbp' })}
                                        name="dark-mode"
                                        value="gbp"
                                        defaultChecked
                                    />
                                    Pounds
                                </Label>
                            </Flex>

                            <BarChart width={800} height={300} data={state.depositedAmounts}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis tickFormatter={formatXAxis} dataKey="timestamp" />
                                <YAxis padding={70} tickFormatter={(value) => formatting.formatForDisplay(value)} />
                                <Tooltip
                                    formatter={(value) => formatting.formatForDisplay(value)}
                                    labelFormatter={(label) => moment.utc(label).format('Do MMM YYYY HH:mm')}
                                />
                                <Legend />
                                <Bar dataKey={state.depositCurrency} fill="#5100C9" />
                            </BarChart>
                        </Flex>

                        <Flex sx={{ flexDirection: 'column', maxWidth: 900 }}>
                            {state.multiValueTotals.map((total, idx) => (
                                <Flex
                                    sx={{
                                        flexDirection: 'column',
                                        mt: idx > 0 ? 70 : 40,
                                        maxWidth: total.amounts.length > 4 ? 900 : 600,
                                    }}
                                >
                                    <Paragraph sx={{ mb: 20, fontWeight: '800' }}>{total.title}</Paragraph>

                                    <Flex
                                        sx={{
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            height: '40px',
                                            color: '#fff',
                                        }}
                                    >
                                        {total.amounts
                                            .map((amount) => amount.group_by_column)
                                            .map((col) => (
                                                <TableHeaderItem key={col} text={col} />
                                            ))}
                                    </Flex>

                                    <Flex
                                        key={total}
                                        sx={{
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            height: '65px',
                                        }}
                                    >
                                        {total.amounts
                                            .map((amount) => amount.amount)
                                            .map((value) => (
                                                <TableRowItem key={value} text={formatting.formatForDisplay(value)} />
                                            ))}
                                    </Flex>
                                </Flex>
                            ))}
                        </Flex>
                    </>
                )}
            </Flex>
        </Flex>
    );
};

const mapDispatchToProps = (dispatch) => ({
    logOut: () => dispatch({ type: 'LOG_USER_OUT' }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return { loggedIn: account.loggedIn };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
