import { Flex, Paragraph, Input, Button } from 'theme-ui';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { debounce } from 'debounce';
import { connect } from 'react-redux';
import { useEffect, useState, useMemo } from 'react';
import { getMemberships } from '../utils/cognito';
import { TableHeader, TableRow } from '../components/table';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';
import Overlay from '../components/overlay';
import AdvancedSearch, { search } from '../components/advanced-search';
import TransactionPagination from '../components/pagination/transactions';
import { defaultUsersFilters } from '../store/reducers/users';

const Users = ({ logOut, state, updateState }) => {
    const [showMenu, toggleMenu] = useState(true);

    const history = useHistory();

    const getUsersDebounced = useMemo(
        () =>
            debounce((e) => {
                const newState = {
                    ...state,
                    filters: { ...state.filters, email: e.target.value },
                    showAdvancedSearch: false,
                };

                updateState(newState);

                search(newState, updateState, (users, total) => {
                    updateState({ ...newState, users, loading: false, showAdvancedSearch: false, total });
                    document.body.style.overflowY = 'auto';
                });
            }, 500),
        []
    );

    useEffect(() => {
        (async () => {
            try {
                await Auth.currentAuthenticatedUser();

                const memberships = await getMemberships();
                if (!memberships?.includes('tier_1')) {
                    history.push('/kyc-complete');
                }

                search(state, updateState, (users, total) => {
                    updateState({ ...state, loading: false, users, showAdvancedSearch: false, total });
                    document.body.style.overflowY = 'auto';
                });
            } catch (e) {
                history.push('/');
            }
        })();
    }, []);

    useEffect(() => {
        search(state, updateState, (users, total) => {
            updateState({ ...state, loading: false, users, showAdvancedSearch: false, total });
            document.body.style.overflowY = 'auto';
        });
    }, [state.pageNumber]);

    const numberOfFilters = Object.values(state.filters).filter((item) => item !== '' && item !== 'select')?.length;

    return (
        <Flex
            sx={{
                overflow: 'hidden',
                top: '0',
                left: '0',
                bottom: 0,
                minHeight: '100vh',
                width: '100vw',
                flexDirection: 'row',
                backgroundColor: 'greyBackground',
                margin: 0,
                padding: 0,
            }}
        >
            <Sidebar
                logout={logOut}
                currentPage="Users"
                showMenu={showMenu}
                toggleMenu={toggleMenu}
                history={history}
            />

            <Flex
                sx={{
                    border: '0px red solid',
                    width: '90%',
                    maxWidth: '900px',
                    minHeight: '400px',
                    flexDirection: 'column',
                    mt: '50px',
                    mb: '100px',
                    ml: showMenu ? 240 : 45,
                }}
            >
                {state.loading && <Spinner />}

                {state.showAdvancedSearch && (
                    <Overlay
                        size="large"
                        closeOverlay={() => updateState({ ...state, showAdvancedSearch: false })}
                        overlayContent={
                            <AdvancedSearch
                                parentState={state}
                                logout={logOut}
                                updateParentState={updateState}
                                updateMainTable={(users, total) => {
                                    updateState({ ...state, loading: false, users, total, showAdvancedSearch: false });
                                    document.body.style.overflowY = 'auto';
                                }}
                            />
                        }
                    />
                )}

                <>
                    <Paragraph sx={{ mb: '20px', fontWeight: '600', fontSize: '21px' }}> User Records</Paragraph>

                    <Flex sx={{ width: '100%', justifyContent: 'space-between' }}>
                        <Input
                            onChange={getUsersDebounced}
                            sx={{ width: 400, mb: 20, background: '#FFFFFF' }}
                            placeholder="Search by email"
                        />
                        <Flex>
                            <Paragraph sx={{ mt: 10, mr: 10 }}>
                                {numberOfFilters} {numberOfFilters === 1 ? 'filter' : 'filters'}
                            </Paragraph>
                            <Button
                                onClick={() => updateState({ ...state, showAdvancedSearch: true })}
                                sx={{ height: 40, mr: 10 }}
                            >
                                Filter
                            </Button>
                            <Button
                                onClick={() => {
                                    const newState = { ...state, filters: defaultUsersFilters };
                                    updateState(newState);

                                    search(newState, updateState, (users, total) => {
                                        updateState({
                                            ...newState,
                                            loading: false,
                                            users,
                                            showAdvancedSearch: false,
                                            total,
                                        });
                                        document.body.style.overflowY = 'auto';
                                    });
                                }}
                                sx={{ height: 40, mr: 10, backgroundColor: 'white', color: '#000' }}
                            >
                                Clear
                            </Button>
                        </Flex>
                    </Flex>

                    <TableHeader />

                    {state?.users?.map((user, i) => (
                        <TableRow user={user} i={i} key={`${user.user_id}-row`} history={history} />
                    ))}

                    <TransactionPagination
                        showTotalPages
                        totalPages={Math.ceil(state.total ? state.total / state.limit : 1)}
                        currentState={{ ...state, items: state.users }}
                        showTotalRecords
                        totalRecords={state.total}
                        updateState={updateState}
                    />
                </>
            </Flex>
        </Flex>
    );
};

const mapDispatchToProps = (dispatch) => ({
    logOut: () => dispatch({ type: 'LOG_USER_OUT' }),
    updateState: (usersListState) => dispatch({ type: 'SET_USERS_STATE', usersListState }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return { loggedIn: account.loggedIn, state: account.usersListState };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
