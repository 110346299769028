/* eslint-disable no-unused-vars */
import * as moment from 'moment';
import { LineChart, Line, Legend, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { formatXAxis, getCurrencyValue, fundNames } from '../helper';

const GraphComponent = ({ figures = [], currentState }) => (
    <ResponsiveContainer width="100%" height="100%">
        <LineChart
            width={500}
            height={300}
            data={figures}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="timestamp" tickFormatter={formatXAxis} minTickGap={45} tickMargin={10} tickSize={6} />
            <YAxis
                width={100}
                tickMargin={5}
                padding={{ top: 50 }}
                tickFormatter={(value) => `${getCurrencyValue(value, currentState.currencyType, currentState)}`}
            />
            <Tooltip
                formatter={(value, name) => [
                    `${getCurrencyValue(value, currentState.currencyType, currentState)}`,
                    fundNames[name],
                ]}
                labelFormatter={(label) => moment.utc(label).format('Do MMM YY')}
            />
            <Legend align="center" verticalAlign="top" height={46} formatter={(value) => [fundNames[value]]} />
            {(currentState?.productType === 'All' || currentState?.productType === 'Bitcoin') && (
                <Line
                    type="monotone"
                    dataKey={`${
                        currentState?.currencyType === 'Native' && currentState?.productType !== 'All'
                            ? 'auto_btc'
                            : 'auto_btc_custom'
                    }`}
                    stroke="#5100C9"
                    activeDot={false}
                    dot={false}
                />
            )}
            {currentState?.productType === 'Bitcoin' && (
                <Line
                    type="monotone"
                    dataKey={`${
                        currentState?.currencyType === 'Native'
                            ? 'auto_btc_total_invested'
                            : 'auto_btc_total_invested_custom'
                    }`}
                    stroke="#777"
                    activeDot={false}
                    dot={false}
                    strokeDasharray="3 3"
                />
            )}

            {(currentState?.productType === 'All' || currentState?.productType === 'Ethereum') && (
                <Line
                    type="monotone"
                    dataKey={`${
                        currentState?.currencyType === 'Native' && currentState?.productType !== 'All'
                            ? 'auto_eth'
                            : 'auto_eth_custom'
                    }`}
                    stroke="#5d9471"
                    activeDot={false}
                    dot={false}
                />
            )}
            {currentState?.productType === 'Ethereum' && (
                <Line
                    type="monotone"
                    dataKey={`${
                        currentState?.currencyType === 'Native'
                            ? 'auto_eth_total_invested'
                            : 'auto_eth_total_invested_custom'
                    }`}
                    stroke="#777"
                    activeDot={false}
                    dot={false}
                    strokeDasharray="3 3"
                />
            )}
            {(currentState?.productType === 'All' || currentState?.productType === 'Stablecoin') && (
                <Line
                    type="monotone"
                    dataKey={`${
                        currentState?.currencyType === 'Native' && currentState?.productType !== 'All'
                            ? 'stablecoin'
                            : 'stablecoin_custom'
                    }`}
                    stroke="#bf9441"
                    activeDot={false}
                    dot={false}
                />
            )}
            {currentState?.productType === 'Stablecoin' && (
                <Line
                    type="monotone"
                    dataKey={`${
                        currentState?.currencyType === 'Native'
                            ? 'stablecoin_total_invested'
                            : 'stablecoin_total_invested_custom'
                    }`}
                    stroke="#777"
                    activeDot={false}
                    dot={false}
                    strokeDasharray="3 3"
                />
            )}
            {(currentState?.productType === 'All' || currentState?.productType === 'Maple USDC') && (
                <Line
                    type="monotone"
                    dataKey={`${
                        currentState?.currencyType === 'Native' && currentState?.productType !== 'All'
                            ? 'maple'
                            : 'maple_custom'
                    }`}
                    stroke="blue"
                    activeDot={false}
                    dot={false}
                />
            )}
            {currentState?.productType === 'Maple USDC' && (
                <Line
                    type="monotone"
                    dataKey={`${
                        currentState?.currencyType === 'Native' ? 'maple_total_invested' : 'maple_total_invested_custom'
                    }`}
                    stroke="#777"
                    activeDot={false}
                    dot={false}
                    strokeDasharray="3 3"
                />
            )}
            {(currentState?.productType === 'All' || currentState?.productType === 'Maple ETH') && (
                <Line
                    type="monotone"
                    dataKey={`${
                        currentState?.currencyType === 'Native' && currentState?.productType !== 'All'
                            ? 'maple_eth'
                            : 'maple_eth_custom'
                    }`}
                    stroke="orange"
                    activeDot={false}
                    dot={false}
                />
            )}
            {currentState?.productType === 'Maple USDC' && (
                <Line
                    type="monotone"
                    dataKey={`${
                        currentState?.currencyType === 'Native'
                            ? 'maple_eth_total_invested'
                            : 'maple_eth_total_invested_custom'
                    }`}
                    stroke="#777"
                    activeDot={false}
                    dot={false}
                    strokeDasharray="3 3"
                />
            )}
        </LineChart>
    </ResponsiveContainer>
);

export default GraphComponent;
