import { Button, Textarea, Flex, Paragraph } from 'theme-ui';
import axios from 'axios';

const submitNewComment = async (currentState, updateState, reload, close) => {
    updateState({ ...currentState, loading: true });
    try {
        const { userId, manualComment } = currentState;

        const { data } = await axios({
            url: process.env.REACT_APP_USERS_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            data: {
                query: `mutation {
                adminAddComment(input: {
                    user_id: "${userId}", 
                    reason: "${manualComment.replace(/\n/g, ' ')}"
                }) {
                    success
                    message
                }}`,
                variables: null,
            },
        });
        const result = data?.data?.adminAddComment || {};
        if (result.success) {
            if (reload) {
                reload();
                close();
            } else {
                window.location.reload();
            }
        } else {
            updateState({
                ...currentState,
                loading: false,
                manualComment: '',
                manualCommentError: true,
                manualCommentErrorMsg: 'Failed to submit comment',
            });
        }
    } catch (e) {
        updateState({
            ...currentState,
            loading: false,
            manualComment: '',
            manualCommentError: false,
            manualCommentErrorMsg: '',
            showAddComment: false,
            error: 'Failed to submit the comment',
        });
    }
};

const AddComment = ({ currentState, updateState, reload, close }) => (
    <Flex
        sx={{
            width: '100%',
            height: '130px',
            marginBottom: '50px',
            marginTop: '20px',
            alignItems: 'center',
            flexDirection: 'column',
        }}
    >
        <Paragraph sx={{ mb: '20px', width: '100%', fontWeight: 600, position: 'relative' }}>
            Add a comment for this user
            <span
                style={{
                    marginBottom: '20px',
                    marginRight: '190px',
                    width: '100%',
                    fontWeight: 600,
                    color: 'red',
                    textAlign: 'right',
                    right: 0,
                    position: 'absolute',
                }}
            >
                {currentState.manualCommentErrorMsg}
            </span>
        </Paragraph>

        <Flex
            sx={{
                width: '100%',
                height: '80px',
                alignItems: 'center',
                mb: '50px',
            }}
        >
            {currentState.loading && <Paragraph>Submitting comment ...</Paragraph>}{' '}
            {!currentState.loading && (
                <>
                    <Textarea
                        sx={{
                            height: '100px',
                            width: '585px',
                            mr: '30px',
                            resize: 'none',
                            border: currentState.manualCommentError ? '1px solid red' : '1px solid ',
                        }}
                        defaultValue={currentState.manualComment}
                        rows={8}
                        onChange={(e) => updateState({ ...currentState, manualComment: e.target.value })}
                    />

                    <Button
                        onClick={async () => {
                            if (currentState.manualComment === '') {
                                updateState({ ...currentState, manualCommentError: true });
                            } else {
                                await submitNewComment(currentState, updateState, reload, close);
                            }
                        }}
                        sx={{ color: 'white', mt: '0px', width: 200 }}
                    >
                        Add comment
                    </Button>
                </>
            )}
        </Flex>
    </Flex>
);

export default AddComment;
