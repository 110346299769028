/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/** @jsxImportSource theme-ui */
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { Flex, Paragraph, Button, Box, Select } from 'theme-ui';
import Spinner from './spinner';
import FormInput from './form-input';
import KycDropDown from './kyc-drop-down';
import { CountryListSelect } from './user-update/country-select';
import AssigneeDropdown from './assign-cases/assignee-dropdown';

export const search = async (currentState, updateState, updateMainTable, limit = 10) => {
    updateState({ ...currentState, loading: true });

    const filterOptions = [{ match_all: {} }];

    if (currentState?.filters?.email) {
        filterOptions.push({ match_phrase_prefix: { Email: currentState?.filters?.email } });
    }

    if (currentState?.filters?.kycStatuses) {
        filterOptions.push({
            bool: {
                should: currentState?.filters?.kycStatuses.map((item) => ({
                    match_phrase: {
                        KycStatus: {
                            query: item.value,
                        },
                    },
                })),
            },
        });
    }

    if (currentState?.filters?.matchStatus && currentState?.filters?.matchStatus !== 'select') {
        filterOptions.push({
            match_phrase: { ComplyAdvantageMatchStatus: { query: currentState?.filters?.matchStatus } },
        });
    }

    if (currentState?.filters?.poaUploaded !== 'select') {
        filterOptions.push({
            match_phrase: { PoaUploaded: { query: currentState?.filters?.poaUploaded === 'yes' } },
        });
    }

    if (currentState?.filters?.assignee && currentState?.filters?.assignee !== 'select') {
        filterOptions.push({
            match_phrase_prefix: { AssignedTo: { query: currentState?.filters?.assignee } },
        });
    }

    if (currentState?.filters?.countryOfResidence) {
        filterOptions.push({
            match_phrase: { 'Address.country': { query: currentState?.filters?.countryOfResidence } },
        });
    }

    if (currentState?.filters?.riskScore && currentState?.filters?.riskScore !== 'select') {
        filterOptions.push({ match_phrase: { 'RiskScore.rating': { query: currentState?.filters?.riskScore } } });
    }

    const rangeFieldName =
        currentState?.filters?.status === 'verified'
            ? 'KycVerifiedDate'
            : currentState?.filters?.status === 'pending'
            ? 'KycPendingDate'
            : 'KycUpdatedDate';

    const useStartRange = !!currentState?.filters?.startDate;
    const useEndRange = !!currentState?.filters?.endDate;
    const rangeQuery = {
        range: {
            [rangeFieldName]: {
                gte: useStartRange ? `${currentState?.filters?.startDate}T00:00:00Z` : '1969-01-01T00:00:00',
                lte: useEndRange ? `${currentState?.filters?.endDate}T23:59:59Z` : 'now',
            },
        },
    };

    const standardQuery = {
        bool: {
            filter: filterOptions,
        },
    };

    const queryToUse =
        !useStartRange && !useEndRange
            ? standardQuery
            : {
                  bool: {
                      must: [standardQuery, rangeQuery],
                  },
              };

    try {
        const { data } = await axios({
            url: process.env.REACT_APP_ADMIN_SEARCH_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
            data: {
                size: limit,
                from: currentState.offset,
                query: {
                    ...queryToUse,
                },
                sort: [
                    {
                        KycPendingDate: {
                            order: 'asc',
                        },
                    },
                ],
            },
        });

        updateState({ ...currentState, error: '', loading: false });

        const formattedForMainTable = data.users.map((item) => ({
            email_address: item._source.Email,
            first_name: item._source.FirstName || '',
            kyc_status: item._source.KycStatus,
            kyc_pending_date: item._source.KycPendingDate || '',
            kyc_verified_date: item._source.KycVerifiedDate || '',
            kyc_updated_date: item._source.KycUpdatedDate || '',
            last_name: item._source.LastName,
            user_id: item._source.UserId,
            created_at: item._source.CreatedAt,
            assigned_to: item._source.AssignedTo,
            country_of_residence: item.country_of_residence
                ? currentState?.countries.find((it) => it.code === item._source.Address?.country)?.name ||
                  item._source.Address?.country
                : item._source.Address?.country,
        }));

        updateMainTable(formattedForMainTable, data?.total?.value || 0);
    } catch (e) {
        if (e?.response?.data?.message === 'Disallowed') {
            try {
                await Auth.signOut();
            } catch (ex) {
                console.log('swallow');
            }
            return window.location.reload();
        }
        updateState({ ...currentState, error: 'Uh Oh, there has been a problem', loading: false });
    }
};

const loadData = async (currentState, updateState) => {
    updateState({
        ...currentState,
        loading: true,
    });

    const { data } = await axios({
        url: `https://cdn.accru.finance/countries/codes.json?cache=${new Date().getTime()}`,
    });

    updateState({
        ...currentState,
        countries: [{ code: 'SELECT', name: 'Please Select' }, ...data],
        loading: false,
    });
};

const AdvancedSearch = ({ updateMainTable, parentState, updateParentState }) => {
    const [currentState, updateState] = useState({
        countries: [],
    });

    useEffect(() => {
        loadData(currentState, updateState);
    }, []);

    return (
        <Flex sx={{ flexDirection: 'column', ml: 20 }}>
            {currentState.loading && <Spinner />}
            <Paragraph sx={{ mt: 40, mb: 30, fontWeight: '800' }}>KYC Search</Paragraph>
            <Flex>
                <Flex sx={{ flexDirection: 'column', ml: 30 }}>
                    <FormInput
                        onChange={(e) =>
                            updateParentState({
                                ...parentState,
                                filters: { ...parentState.filters, email: e.target.value },
                            })
                        }
                        value={parentState?.filters?.email}
                        sx={{ width: 350 }}
                        label="Email"
                    />

                    <Paragraph sx={{ mb: 10, mt: 20 }}>KYC Status</Paragraph>
                    <Flex sx={{ width: 350 }}>
                        <KycDropDown
                            width={350}
                            includePleaseSelectOption
                            currentState={parentState.filters}
                            updateState={(filters) => updateParentState({ ...parentState, filters })}
                        />
                    </Flex>
                    <Paragraph sx={{ mb: 10, mt: 10 }}>Start Date</Paragraph>
                    <FormInput
                        onChange={(e) =>
                            updateParentState({
                                ...parentState,
                                filters: { ...parentState.filters, startDate: e.target.value },
                            })
                        }
                        value={parentState?.filters?.startDate}
                        type="date"
                        sx={{ width: 350, height: 55 }}
                    />
                    <Paragraph sx={{ mb: 10, mt: 20 }}>End Date</Paragraph>
                    <FormInput
                        onChange={(e) =>
                            updateParentState({
                                ...parentState,
                                filters: { ...parentState.filters, endDate: e.target.value },
                            })
                        }
                        value={parentState?.filters?.endDate}
                        type="date"
                        sx={{ width: 350, height: 55 }}
                    />
                    <Paragraph sx={{ mt: 20, mb: 15 }}>Assigned To:</Paragraph>
                    <AssigneeDropdown
                        currentState={parentState.filters}
                        adminUserEmails={parentState.adminUserEmails}
                        updateState={(filters) => updateParentState({ ...parentState, filters })}
                        fullHeight
                    />
                </Flex>

                <Flex sx={{ ml: 250, flexDirection: 'column', minHeight: 800, width: 350 }}>
                    {currentState.countries?.length ? (
                        <CountryListSelect
                            error={null}
                            label="Country Of Residence"
                            value={parentState?.filters?.countryOfResidence}
                            countries={currentState.countries}
                            updateField={(e) =>
                                updateParentState({
                                    ...parentState,
                                    filters: { ...parentState.filters, countryOfResidence: e === 'SELECT' ? '' : e },
                                })
                            }
                        />
                    ) : null}
                    <Box sx={{ mt: 20 }} />

                    <Paragraph sx={{ mb: 10, mt: 10 }}>Risk Score</Paragraph>
                    <Select
                        defaultValue={currentState.riskScore}
                        value={parentState?.filters?.riskScore}
                        onChange={(e) =>
                            updateParentState({
                                ...parentState,
                                filters: { ...parentState.filters, riskScore: e.target.value },
                            })
                        }
                        sx={{ mb: 20, width: 350 }}
                        arrow={
                            <Box
                                as="svg"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="currentcolor"
                                sx={{
                                    ml: -28,
                                    mt: -15,
                                    alignSelf: 'center',
                                    pointerEvents: 'none',
                                }}
                            >
                                <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                            </Box>
                        }
                    >
                        <option value="select">Please Select</option>
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                    </Select>

                    <Paragraph sx={{ mb: 10, mt: 10 }}>Match Status</Paragraph>

                    <Select
                        defaultValue={currentState.matchStatus}
                        value={parentState?.filters?.matchStatus}
                        onChange={(e) =>
                            updateParentState({
                                ...parentState,
                                filters: { ...parentState.filters, matchStatus: e.target.value },
                            })
                        }
                        sx={{ mb: 20, width: 350 }}
                        arrow={
                            <Box
                                as="svg"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="currentcolor"
                                sx={{
                                    ml: -28,
                                    mt: -15,
                                    alignSelf: 'center',
                                    pointerEvents: 'none',
                                }}
                            >
                                <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                            </Box>
                        }
                    >
                        <option value="select">Please Select</option>
                        <option value="no_match">No Match</option>
                        <option value="false_positive">False Positive</option>
                        <option value="potential_match">Potential Match</option>
                        <option value="true_positive">True Positive</option>
                        <option value="unknown">Unknown</option>
                        <option value="true_positive_approve">True Positive Approve</option>
                        <option value="true_positive_reject">True Positive Reject</option>
                    </Select>

                    <Paragraph sx={{ mb: 10, mt: 15 }}>POA Uploaded</Paragraph>
                    <Select
                        defaultValue={currentState?.filters?.poaUploaded}
                        value={parentState?.filters?.poaUploaded}
                        onChange={(e) =>
                            updateParentState({
                                ...parentState,
                                filters: { ...parentState.filters, poaUploaded: e.target.value },
                            })
                        }
                        sx={{ mb: 20, width: 350 }}
                        arrow={
                            <Box
                                as="svg"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="currentcolor"
                                sx={{
                                    ml: -28,
                                    mt: -15,
                                    alignSelf: 'center',
                                    pointerEvents: 'none',
                                }}
                            >
                                <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                            </Box>
                        }
                    >
                        <option value="select">Please Select</option>
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                    </Select>

                    <Button
                        onClick={() => search(parentState, updateParentState, updateMainTable)}
                        sx={{ alignSelf: 'flex-end', width: 180, mt: '150px' }}
                    >
                        Search
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default AdvancedSearch;
