/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import axios from 'axios';
import { Flex, Paragraph, Button, Box, Select } from 'theme-ui';
import FormInput from './form-input';
import Spinner from './spinner';

const validateForm = (currentState) => {
    if (currentState.currency === 'select') {
        return { valid: false, message: 'Please select a currency' };
    }
    if (
        currentState.amountFrom.trim() === '' ||
        isNaN(currentState.amountFrom.trim() || parseFloat(currentState.amountFrom.trim()) < 0)
    ) {
        return { valid: false, message: 'Please enter an amount to use from the wallet' };
    }

    if (
        currentState.amountTo.trim() === '' ||
        isNaN(currentState.amountTo.trim() || parseFloat(currentState.amountTo.trim()) < 0)
    ) {
        return { valid: false, message: 'Please enter an amount to invest' };
    }

    if (currentState.product === 'select') {
        return { valid: false, message: 'Please select a product' };
    }

    if (currentState.reason.trim() === '') {
        return { valid: false, message: 'Please enter a reason' };
    }

    return { valid: true };
};

const submit = async (currentState, updateState) => {
    const { valid, message } = validateForm(currentState);
    if (!valid) {
        return updateState({ ...currentState, error: message, loading: false });
    }

    updateState({ ...currentState, error: message, loading: true });

    try {
        const { data } = await axios({
            url: process.env.REACT_APP_TRANSACTIONS_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
            data: {
                query: `mutation {
                    creditAccount(input: {
                        tool: "invest_from_wallet"
                        user_id: "${currentState.user_id}"
                        currency: "${currentState.currency}"
                        product: "${currentState.product}"
                        amount_from: ${currentState.amountFrom}
                        amount_to: ${currentState.amountTo}
                        reason: "${currentState.reason.replace(/\n/g, ' ')}"
                    }) { 
                        success
                        message
                    }
                }`,
                variables: null,
            },
        });

        if (!data.data.creditAccount.success) {
            return updateState({
                ...currentState,
                loading: false,
                error: data.data.creditAccount.message,
            });
        }

        return window.location.reload();
    } catch (e) {
        return updateState({ ...currentState, loading: false, error: 'Failed to invest into account' });
    }
};

const ManuallyInvest = (props) => {
    const [currentState, updateState] = useState({
        error: '',
        loading: false,
        description: '',
        success: false,
        user_id: props.userId,
        currency: 'select',
        reason: '',
        product: 'select',
        amountFrom: '',
        amountTo: '',
    });

    return (
        <Flex
            sx={{
                justifyContent: 'space-between',
                width: 600,
                height: '65px',
                flexDirection: 'column',
                ml: 40,
                mt: 30,
            }}
        >
            {currentState.loading && <Spinner />}

            <Paragraph sx={{ mt: 10, mb: 20, fontWeight: '800' }}>Manually Invest</Paragraph>

            <Paragraph sx={{ mb: 10 }}>Currency</Paragraph>

            <Select
                defaultValue={currentState.currency}
                value={currentState.currency}
                onChange={(e) => updateState({ ...currentState, currency: e.target.value })}
                arrow={
                    <Box
                        as="svg"
                        // @ts-ignore
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentcolor"
                        sx={{
                            ml: -28,
                            mt: -18,
                            alignSelf: 'center',
                            pointerEvents: 'none',
                        }}
                    >
                        <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                    </Box>
                }
                sx={{ mb: 20, width: 300 }}
            >
                <option value="select">Please Select</option>
                <option value="bitcoin">Bitcoin</option>
                <option value="ethereum">Ethereum</option>
                <option value="usd-coin">USDC</option>
                <option value="tether">USDT</option>
                <option value="dai">DAI</option>
                <option value="gbp">Pounds</option>
                <option value="eur">Euros</option>
            </Select>

            <FormInput
                onChange={(e) =>
                    updateState({
                        ...currentState,
                        amountFrom: e.target.value,
                    })
                }
                value={currentState.amountFrom}
                sx={{ width: 200 }}
                label="Amount From Wallet"
            />

            <Paragraph sx={{ mb: 10, mt: 10 }}>Product</Paragraph>

            <Select
                defaultValue={currentState.product}
                value={currentState.product}
                onChange={(e) => updateState({ ...currentState, product: e.target.value })}
                arrow={
                    <Box
                        as="svg"
                        // @ts-ignore
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentcolor"
                        sx={{
                            ml: -28,
                            mt: -18,
                            alignSelf: 'center',
                            pointerEvents: 'none',
                        }}
                    >
                        <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                    </Box>
                }
                sx={{ mb: 20, width: 300 }}
            >
                <option value="select">Please Select</option>
                <option value="stablecoin">Stablecoin</option>
                <option value="auto_btc">Bitcoin</option>
                <option value="auto_eth">Ethereum</option>
            </Select>

            <FormInput
                onChange={(e) =>
                    updateState({
                        ...currentState,
                        amountTo: e.target.value,
                    })
                }
                value={currentState.amountTo}
                sx={{ width: 200 }}
                label="Amount To Be Invested Into Product"
            />

            <FormInput
                onChange={(e) =>
                    updateState({
                        ...currentState,
                        reason: e.target.value,
                    })
                }
                value={currentState.reason}
                sx={{ width: 600 }}
                label="Please provide a reason"
            />

            <Button onClick={() => submit(currentState, updateState)} sx={{ width: 170, mt: 20 }}>
                Submit
            </Button>

            {currentState.error && <Paragraph sx={{ color: 'red', mt: 20 }}>{currentState.error}</Paragraph>}

            {currentState.success && <Paragraph sx={{ color: 'green', mt: 20 }}>Document uploaded</Paragraph>}
        </Flex>
    );
};

export default ManuallyInvest;
