/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import { Paragraph, Flex, Box, Image } from 'theme-ui';
import moment from 'moment';
import Decimal from 'decimal.js';

const formatValue = (val) => {
    const value = val
        .toSignificantDigits(7)
        .toString()
        .replace(/\d(?=(\d{3})+\.)/g, '$&,');

    return value;
};

export const TableHeaderItem = ({ text, topLeft = false, topRight = false }) => (
    <Box
        sx={{
            border: '1px solid #09042B',
            flex: 1,
            textAlign: 'center',
            fontSize: '13px',
            fontWeight: '600',
            lineHeight: '35px',
            padding: '0 10px',
            borderTopLeftRadius: topLeft ? '12px' : '',
            borderTopRightRadius: topRight ? '12px' : '',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor: '#09042B',
        }}
    >
        {text}
    </Box>
);

export const TableHeader = () => (
    <Flex
        sx={{
            justifyContent: 'space-between',
            width: '100%',
            height: '40px',
            color: '#fff',
        }}
    >
        <TableHeaderItem text="Date" topLeft />
        <TableHeaderItem text="Product" />
        <TableHeaderItem text="Type" />
        <TableHeaderItem text="Amount" topRight />
    </Flex>
);

export const TableRowItem = ({ text, amountColor = '', bold = false, image = false, imagePath = '' }) => (
    <Flex
        sx={{
            borderBottom: '1px solid #F1F1F1',
            flex: 1,
            textAlign: 'center',
            lineHeight: '20px',
            padding: '20px',
            background: '#FFF',
            width: '90%',
            justifyContent: 'center',
            position: 'relative',
            '@media screen and (max-width: 700px)': { minHeight: '95px', alignItems: 'center' },
        }}
    >
        <Flex sx={{ '@media screen and (max-width: 700px)': { flexDirection: 'column' } }}>
            {image && (
                <Box
                    sx={{
                        width: '36px',
                        height: '36px',
                        mt: 0,
                        mr: '10px',
                        '@media screen and (max-width: 700px)': { width: '25px', height: '25px', margin: '0 auto 5px' },
                    }}
                >
                    <Image src={imagePath} alt="product image" />
                </Box>
            )}
            <Paragraph
                sx={{
                    textAlign: 'center',
                    width: '100%',
                    position: 'relative',
                    fontSize: '13px',
                    fontWeight: bold ? 600 : 300,
                    color: amountColor !== '' ? amountColor : 'black',
                    '@media screen and (max-width: 700px)': { textAlign: 'left', fontSize: '12px' },
                }}
            >
                {text}
            </Paragraph>
        </Flex>
    </Flex>
);

export const TableRow = ({ data, darkBottomBorder, itemKey, idx, privacyEnabled }) => {
    let amountColor = '';

    if (data.type === 'withdrawal') {
        amountColor = '#1C1C1C';
    }

    if (data.type === 'deposit' || data.trade_type === 'BUY' || data.type === 'Interest Deposit') {
        amountColor = 'green';
    }

    if (data.trade_type === 'SELL') {
        amountColor = '#F56132';
    }

    return (
        <Flex
            key={`${itemKey}-${idx}`}
            sx={{
                justifyContent: 'space-between',
                width: '100%',
                height: '60px',
                borderBottom: darkBottomBorder ? '2px solid darkgrey' : '1px solid lightgrey',
                '@media screen and (max-width: 700px)': { minHeight: '95px' },
            }}
        >
            <TableRowItem text={data.type === 'loading' ? '' : moment(data.created_at).format('DD MMM YYYY')} />
            <TableRowItem text={data?.label} image={data.type !== 'loading'} imagePath={data?.img} bold />
            <TableRowItem text={data?.type_label} />
            <TableRowItem
                bold
                text={data.type !== 'loading' ? `${formatValue(new Decimal(data?.amount))}` : ''}
                amountColor={amountColor}
            />
        </Flex>
    );
};

export const Pagination = ({ transactionsState, setTransactions }) => (
    <Flex sx={{ width: '250px', margin: '20px auto', justifyContent: 'space-around' }}>
        <Box
            sx={{
                width: '26px',
                height: '26px',
                cursor: transactionsState.paging.current_page > 1 ? 'pointer' : 'not-allowed',
            }}
            onClick={() =>
                transactionsState.paging.current_page > 1 &&
                setTransactions({
                    ...transactionsState,
                    offset: transactionsState.offset - transactionsState.limit,
                })
            }
        >
            <Image
                src={`https://cdn.accru.finance/arrows/${
                    transactionsState.paging.current_page > 1 ? 'enabled' : 'disabled'
                }-left.png`}
                width="26"
                height="26"
                alt="prev button"
            />
        </Box>
        <Box>
            <Paragraph>{`${transactionsState.paging.current_page} / ${transactionsState.paging.total_pages}`}</Paragraph>
        </Box>
        <Box
            sx={{
                width: '26px',
                height: '26px',
                cursor:
                    transactionsState.paging.current_page < transactionsState.paging.total_pages
                        ? 'pointer'
                        : 'not-allowed',
            }}
            onClick={() => {
                if (transactionsState.transactions.transactions.length >= transactionsState.limit) {
                    setTransactions({
                        ...transactionsState,
                        offset: transactionsState.offset + transactionsState.limit,
                    });
                }
            }}
        >
            <Image
                src={`https://cdn.accru.finance/arrows/${
                    transactionsState.paging.current_page < transactionsState.paging.total_pages
                        ? 'enabled'
                        : 'disabled'
                }-right.png`}
                width="26"
                height="26"
                alt="next button"
            />
        </Box>
    </Flex>
);
