import axios from 'axios';
import { useState } from 'react';
import { Flex, Textarea, Paragraph, Button, Heading, Select, Box } from 'theme-ui';
import Spinner from './spinner';

const updateScore = async (currentState: any, updateState: any) => {
    if (!currentState.reason) {
        return updateState({ ...currentState, error: 'Please state a reason for this change' });
    }

    updateState({ ...currentState, error: null, loading: true });
    try {
        const { data } = await axios({
            url: process.env.REACT_APP_USERS_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            data: {
                query: `mutation {
                adminTools(input: {
                    user_id: "${currentState.userId}"
                    tool: "update_risk_score"
                    risk_score: {
                        rating: "${currentState?.riskScore?.rating}"
                        score: ${currentState?.riskScore?.score || 0}
                        expected_amounts_to_invest: {
                            currency: "${currentState.riskScore?.expected_amounts_to_invest?.currency || ''}"
                            fiat: {
                                chosen: "${currentState.riskScore?.expected_amounts_to_invest?.fiat?.chosen || ''}"
                                in_euros: "${currentState.riskScore?.expected_amounts_to_invest?.fiat?.in_euros || ''}"
                            }
                            crypto: {
                                chosen: "${currentState.riskScore?.expected_amounts_to_invest?.fiat?.chosen || ''}"
                                in_euros: "${currentState.riskScore?.expected_amounts_to_invest?.fiat?.in_euros || ''}"
                            }
                        }
                    }
                    reason: "${currentState?.reason?.replace(/\n/g, ' ')}"
                }) {
                    success
                    message
                }}`,
                variables: null,
            },
        });

        if (!data.data.adminTools.success) {
            return updateState({
                ...currentState,
                error:
                    data.data.adminTools.message ||
                    'We are currently unable to save this change, please try again later',
                loading: false,
            });
        }

        window.location.reload();
    } catch (e) {
        console.log(e);

        updateState({
            ...currentState,
            error: 'We are currently unable to save this change, please try again later',
            loading: false,
        });
    }
};

const ChangeRiskScore = (props: any) => {
    const [currentState, updateState] = useState({
        loading: false,
        error: null,
        reason: '',
        riskScore: props.riskScore,
        userId: props.userId,
    });

    return (
        <Flex sx={{ mt: 70, ml: 20, mr: 20, flexDirection: 'column' }}>
            {currentState.loading && <Spinner />}

            <Heading sx={{ mb: 30 }}>Change Risk Score</Heading>

            <Select
                defaultValue={currentState?.riskScore?.rating || 'select'}
                value={currentState?.riskScore?.rating || 'select'}
                onChange={(e) =>
                    updateState({ ...currentState, riskScore: { ...currentState.riskScore, rating: e.target.value } })
                }
                arrow={
                    <Box
                        as="svg"
                        // @ts-ignore
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentcolor"
                        sx={{
                            ml: -28,
                            mt: -20,
                            alignSelf: 'center',
                            pointerEvents: 'none',
                        }}
                    >
                        <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                    </Box>
                }
                sx={{ mb: 20, width: 300 }}
            >
                <option value="select">Please Select</option>
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
            </Select>

            <Paragraph sx={{ mb: 20, ml: '5px' }}>Please specify a reason for the change</Paragraph>

            <Textarea
                value={currentState.reason}
                onChange={(e) => updateState({ ...currentState, reason: e.target.value })}
                sx={{ mb: 30, fontSize: 16, fontFamily: '"Open Sans", sans-serif' }}
                rows={8}
            />

            {currentState.error && <Paragraph sx={{ color: 'red', mb: 20 }}>{currentState.error}</Paragraph>}

            <Button onClick={() => updateScore(currentState, updateState)} sx={{ mb: 30 }}>
                Save
            </Button>
        </Flex>
    );
};

export default ChangeRiskScore;
