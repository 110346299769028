/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import { Flex } from 'theme-ui';
import { Title } from '../table';
import Item from '../user-data-item';

const KycStats = ({ state }) => (
    <Flex
        sx={{
            flexDirection: 'column',
            mt: '10px',
        }}
    >
        <Title title="Kyc statistics 🚀 " state={state} updateState={{}} showEditIcon={false} />

        <Flex sx={{ mt: '10px', flexDirection: 'row', mb: '25px' }}>
            <Flex sx={{ mt: '30px', flexDirection: 'column' }}>
                <Item title="Not Started" value={state.kycStats?.notStartedCount} />
                <Item title="Pending" value={state.kycStats?.pendingCount} />
                <Item title="Pending Today" value={state.kycStats?.pendingTodayCount} />
                <Item title="Verified Today" value={state.kycStats?.verifiedTodayCount} />
                <Item title="Asked To Retry" value={state.kycStats?.retryStateCount} />
            </Flex>
            <Flex sx={{ mt: '30px', flexDirection: 'column', borderLeft: '1px grey solid', pl: '50px' }}>
                <Item title="Unsuccessful Today" value={state.kycStats?.processedTodayIntoUnsuccessfulStateCount} />
                <Item title="Pending Over 24 Hours" value={state.kycStats?.kycPendingForOver24HoursCount} />
                <Item title="EDD In Progress" value={state.kycStats?.eddInProgressCount} />
            </Flex>
        </Flex>
    </Flex>
);

export default KycStats;
