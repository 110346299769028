/* eslint-disable no-unused-vars */
import { Flex, Paragraph, Button } from 'theme-ui';
import { formatting } from '@accru-finance/shared-ui-libs';
import moment from 'moment';
import labelMapping from '../../utils/label-mapping';

export const Row = ({ label = '', value = '' }) => (
    <Flex>
        <Paragraph sx={{ fontSize: 14, mr: 20, fontWeight: 800, mb: 10, width: 180 }}>{label}:</Paragraph>
        <Paragraph sx={{ fontSize: 14, width: 400, overflowWrap: 'break-word' }}>{value}</Paragraph>
    </Flex>
);

const RebalancingEvent = ({ event, close }) => (
    <Flex sx={{ ml: 50, mt: 60, flexDirection: 'column' }}>
        <Paragraph sx={{ fontWeight: '800', mb: 20 }}>Trade/Quote Detail</Paragraph>

        <Row label="Product" value={labelMapping(event.product)} />
        <Row label="Type" value={labelMapping(event.type)} />
        <Row label="Status" value={labelMapping(event.status)} />
        <Row label="Currency From" value={labelMapping(event.currency_from)} />
        <Row label="Currency To" value={labelMapping(event.currency_to)} />

        {event.type === 'EXECUTION' && <Row label="Amount" value={formatting.formatForDisplay(event.amount)} />}

        {event.type === 'QUOTE' && (
            <Row label="Existing Amount In Portfolio" value={formatting.formatForDisplay(event.amount)} />
        )}

        <Row label="Exchange Rate" value={event.exchange_rate} />

        {event.type === 'EXECUTION' && <Row label="Tx Id" value={event.exec_id} />}

        <Row label="Timestamp" value={moment.utc(event.created_at).format('Do MMMM YYYY HH:ss')} />

        <Button onClick={close} sx={{ alignSelf: 'center', position: 'absolute', bottom: 30, width: 150 }}>
            OK
        </Button>
    </Flex>
);

export default RebalancingEvent;
