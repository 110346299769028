/* eslint-disable no-unused-vars */
import { Flex, Paragraph, Box } from 'theme-ui';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';
import { loadFigures } from '../utils/dashboard-helper';
import { TopFigures, Registrations, BalancesAndDeposits, CurrentStandings } from '../components/dashboard';

const defaultState = {
    uninvestedCurrency: 'eur',
    investedProduct: 'stablecoin',
    depositCurrency: 'eur',
    loading: false,
    error: null,
    singleValueTotals: [],
    multiValueTotals: [],
};

const Users = ({ logOut }) => {
    const [state, updateState] = useState(defaultState);
    const [emailInState, updateEmail] = useState('');
    const [showMenu, toggleMenu] = useState(true);
    const history = useHistory();

    useEffect(() => {
        (async () => {
            try {
                const res = await Auth.currentAuthenticatedUser();
                if (res?.attributes?.email) {
                    updateEmail(res?.attributes?.email);
                }
            } catch (e) {
                history.push('/');
            }
        })();

        loadFigures(state, updateState);
    }, []);

    return (
        <Flex
            sx={{
                overflow: 'hidden',
                top: '0',
                left: '0',
                bottom: 0,
                minHeight: '100vh',
                width: '100vw',
                flexDirection: 'row',
                backgroundColor: 'greyBackground',
                margin: 0,
                padding: 0,
                position: 'relative',
            }}
        >
            <Sidebar
                logout={logOut}
                currentPage="Dashboard"
                showMenu={showMenu}
                toggleMenu={toggleMenu}
                history={history}
            />

            <Flex
                sx={{
                    border: '0px red solid',
                    width: '90%',
                    maxWidth: '900px',
                    minHeight: '400px',
                    flexDirection: 'column',
                    mt: '50px',
                    mb: '100px',
                    ml: showMenu ? 240 : 45,
                }}
            >
                <Flex
                    sx={{
                        width: '100%',
                        maxWidth: '900px',
                        minHeight: '100vh',
                        padding: '30px 15px',
                        backgroundColor: 'white',
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'relative',
                    }}
                >
                    <Paragraph
                        sx={{
                            position: 'absolute',
                            top: '-35px',
                            right: '10px',
                            fontSize: '15px',
                            cursor: 'pointer',
                            color: 'lightgrey',
                        }}
                        onClick={() => history.push('/dashboard-legacy')}
                    >
                        Old version
                    </Paragraph>
                    {state.loading && (
                        <Spinner
                            customSx={{
                                position: 'relative',
                                mt: '150px',
                                width: '100px',
                                height: '100px',
                            }}
                        />
                    )}

                    {state.error && <Paragraph sx={{ color: 'red' }}>{state.error}</Paragraph>}

                    {!state.loading && (
                        <Box sx={{ width: '100%', marginLeft: '15px', mb: '55px' }}>
                            <TopFigures state={state} emailInState={emailInState} />

                            <Registrations state={state} />

                            <BalancesAndDeposits state={state} updateState={updateState} />

                            <CurrentStandings state={state} />
                        </Box>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};

const mapDispatchToProps = (dispatch) => ({
    logOut: () => dispatch({ type: 'LOG_USER_OUT' }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return { loggedIn: account.loggedIn };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
