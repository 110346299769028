/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unreachable */
/* eslint-disable no-throw-literal */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
import * as moment from 'moment';
import * as axios from 'axios';
import DatePicker from 'react-datepicker';
import { useEffect, useState } from 'react';
import { Box, Spinner, Paragraph, Flex, Button, Select } from 'theme-ui';
import { connect } from 'react-redux';
import { Pagination, TableHeaderItem } from './table';
import labelMapping from '../utils/label-mapping';
import ChangesRow from './changes/row';
import TransactionRow from './transactions-row';
import Overlay from './overlay';
import CommentsPagination from './pagination/comments';
import PgPagination from './pagination/pg';
import TransactionsPagination from './pagination/transactions';
import AddComment from './changes/add-comment';
import DepositDetailView from './detail-views/deposit';
import WithdrawalDetailView from './detail-views/withdrawal';
import ExchangeDetailView from './detail-views/exchange';
import MixedFundTradeDetailView from './detail-views/mixed-fund-trade';
import RebalancingTradeDetailView from './detail-views/rebalancing-trade';
import RebalancingEventDetailView from './detail-views/rebalancing-event';
import DepositToApproveDetailView from './detail-views/deposit-to-approve';
import CommsItemDetailView from './detail-views/comms-item';
import { getComments, getCorporateUsers, getActionedWithdrawals, getDepositsToApprove } from '../utils/pagination';

import 'react-datepicker/dist/react-datepicker.css';
import './custom.css';

const tableDefinition = {
    trades: {
        headers: ['Product', 'Type', 'Amount', 'Currency', 'Created at'],
        cells: ['product', 'type', 'amount', 'currency', 'created_at'],
        timestampColumns: ['created_at'],
        formatAsCurrency: ['amount'],
    },
    swaps: {
        headers: ['Currency From', 'Amount From', 'Currency To', 'Amount To', 'Created at'],
        cells: ['currency_from', 'amount_from', 'currency_to', 'amount_to', 'created_at'],
        timestampColumns: ['created_at'],
        formatAsCurrency: ['amount_from', 'amount_to'],
        showViewMoreColumn: true,
    },
    mixed_fund_trades: {
        headers: ['Product', 'Currency From', 'Amount From', 'Currency To', 'Amount To', 'Type', 'Created at'],
        cells: ['product', 'currency_from', 'amount_from', 'currency_to', 'amount_to', 'type', 'created_at'],
        timestampColumns: ['created_at'],
        formatAsCurrency: ['amount_from', 'amount_to'],
        showViewMoreColumn: true,
    },
    rebalancing_trades: {
        headers: ['Product', 'Currency From', 'Previous Amount', 'Currency To', 'New Amount', 'Created at'],
        cells: ['product', 'currency_from', 'previous_amount', 'currency_to', 'new_amount', 'created_at'],
        timestampColumns: ['created_at'],
        formatAsCurrency: ['previous_amount', 'new_amount'],
        showViewMoreColumn: true,
    },
    rebalancing_events: {
        headers: ['Product', 'Group Id', 'Processed At'],
        cells: ['product', 'rebalancing_group_id', 'processed_at'],
        showViewMoreColumn: true,
    },
    rebalancing_event_trades: {
        headers: ['Product', 'Type', 'Currency From', 'Currency To', 'Status'],
        cells: ['product', 'type', 'currency_from', 'currency_to', 'status'],
        showViewMoreColumn: true,
    },
    actioned_withdrawals: {
        headers: ['User Id', 'Currency', 'Amount', 'Created at', 'Scheduled For'],
        cells: ['user_id', 'currency', 'amount', 'created_at', 'withdrawal_date'],
        timestampColumns: ['created_at', 'withdrawal_date'],
        formatAsCurrency: ['amount'],
    },
    deposits_to_approve: {
        headers: ['Status', 'First Name', 'Last Name', 'Transaction Name', 'Created at'],
        cells: ['status', 'first_name_on_record', 'last_name_on_record', 'name_on_transaction', 'created_at'],
        timestampColumns: ['created_at'],
        formatAsCurrency: ['amount'],
    },
    deposits: {
        headers: ['Amount', 'Currency', 'Type', 'Created at'],
        cells: ['amount', 'currency', 'currency_type', 'created_at'],
        timestampColumns: ['created_at'],
        formatAsCurrency: ['amount'],
        showViewMoreColumn: true,
    },
    withdrawals: {
        headers: ['Currency', 'Status', 'Amount', 'Fee', 'Gas fee', 'Withdrawal date'],
        cells: ['currency_to', 'status', 'amount', 'fee', 'gas_fee', 'withdrawal_date'],
        timestampColumns: ['withdrawal_date'],
        formatAsCurrency: ['amount'],
        showViewMoreColumn: true,
    },
    changes: {
        headers: ['Comment', 'Created at'],
        cells: ['reason', 'created_at'],
        timestampColumns: ['created_at'],
    },
    corporateUsers: {
        headers: ['Email', 'Created at'],
        cells: ['email_address', 'created_at'],
        timestampColumns: ['created_at'],
    },
    referredUsers: {
        headers: ['Email', 'Created at'],
        cells: ['email_address', 'created_at'],
        timestampColumns: ['created_at'],
    },
    monitoring: {
        headers: ['Number Of Alerts', 'Status', 'Processed At'],
        cells: ['count', 'status', 'timestamp'],
        timestampColumns: ['timestamp'],
        showViewMoreColumn: true,
    },
    comms: {
        headers: ['Type', 'Status', 'Template', 'Created At'],
        cells: ['type', 'status', 'template', 'created_at'],
        timestampColumns: ['created_at'],
        showViewMoreColumn: true,
    },
    devices: {
        headers: ['Name', 'Last IP Used', 'Created At', 'Last Authenticated At'],
        cells: ['name', 'last_ip_used', 'created_at', 'last_authenticated_at'],
        timestampColumns: ['created_at', 'last_authenticated_at'],
    },
    userActivity: {
        headers: ['Platform', 'IP Address', 'Country', 'Timestamp'],
        cells: ['platform', 'ip_address', 'country', 'activity_timestamp'],
        timestampColumns: ['activity_timestamp'],
    },
};

export const getMonitoringAlerts = async (
    state,
    updateState,
    direction = 'next',
    paginationData,
    setPaginationData
) => {
    updateState({
        ...state,
        loading: true,
    });

    const pageArray = [...paginationData.paginationArray];

    let pageNumber;
    let token;

    if (direction === 'start') {
        pageNumber = paginationData.pageNumber;
        token = pageArray[pageNumber];
    } else if (direction === 'next') {
        pageNumber = paginationData.pageNumber + 1;
        token = pageArray[pageNumber];
    } else {
        pageNumber = paginationData.pageNumber - 1;
        token = pageArray[pageNumber];
    }

    const { data } = await axios({
        url: process.env.REACT_APP_TX_MONITORING_API,
        method: 'POST',
        data: {
            query: `query { 
                adminSearchMonitoringChecks(user_id: "${state.userId}", limit: 10, ${
                token !== '' ? `pagination_token: "${token}"` : ''
            }) { 
                    success
                    checks {
                        alerts {
                            type
                            description
                        }
                        user_id
                        timestamp
                        status
                    }
                    pagination_token
                }
            }`,
            variables: null,
        },
    });

    const result = data.data?.adminSearchMonitoringChecks;

    if (['next', 'start'].includes(direction)) {
        pageArray[pageNumber + 1] = result?.pagination_token;
    }

    const newPaginationData = {
        paginationArray: pageArray,
        pageNumber,
    };

    updateState({
        ...state,
        items: result?.checks.map((check) => ({ ...check, count: check.alerts.length })),
        loading: false,
        type: 'monitoring',
    });

    setPaginationData(newPaginationData);

    return result;
};

export const getUserComms = async (state, updateState, direction = 'next', paginationData, setPaginationData) => {
    updateState({
        ...state,
        loading: true,
    });

    const pageArray = [...paginationData.paginationArray];

    let pageNumber;
    let token;

    if (direction === 'start') {
        pageNumber = paginationData.pageNumber;
        token = pageArray[pageNumber];
    } else if (direction === 'next') {
        pageNumber = paginationData.pageNumber + 1;
        token = pageArray[pageNumber];
    } else {
        pageNumber = paginationData.pageNumber - 1;
        token = pageArray[pageNumber];
    }

    const { data } = await axios({
        url: process.env.REACT_APP_COMMS_API,
        method: 'POST',
        data: {
            query: `query { 
                listComms(input: {
                    user_id: "${state.userId}"
                    limit: 10
                    ${token !== '' ? `pagination_token: "${token}"` : ''}
                }) { 
                    success
                    items {
                        type
                        status
                        id
                        s3_location
                        created_at
                        template
                    }
                    pagination_token
                }
            }`,
            variables: null,
        },
    });

    const result = data.data?.listComms;

    if (['next', 'start'].includes(direction)) {
        pageArray[pageNumber + 1] = result?.pagination_token;
    }

    const newPaginationData = {
        paginationArray: pageArray,
        pageNumber,
    };

    updateState({
        ...state,
        items: result.items,
        loading: false,
        type: 'comms',
    });

    setPaginationData(newPaginationData);

    return result;
};

export const getUserDevices = async (state, updateState, direction = 'next', paginationData, setPaginationData) => {
    updateState({
        ...state,
        loading: true,
    });

    const pageArray = [...paginationData.paginationArray];

    let pageNumber;
    let token;

    if (direction === 'start') {
        pageNumber = paginationData.pageNumber;
        token = pageArray[pageNumber];
    } else if (direction === 'next') {
        pageNumber = paginationData.pageNumber + 1;
        token = pageArray[pageNumber];
    } else {
        pageNumber = paginationData.pageNumber - 1;
        token = pageArray[pageNumber];
    }

    const { data } = await axios({
        url: process.env.REACT_APP_USERS_API,
        method: 'POST',
        data: {
            query: `query { 
                adminDevices(
                    user_id: "${state.userId}"
                    limit: 10
                    ${token !== '' ? `pagination_token: "${token}"` : ''}
                ) { 
                    success
                    devices {
                        name
                        last_ip_used
                        created_at
                        last_authenticated_at
                    }
                }
            }`,
            variables: null,
        },
    });

    const result = data.data.adminDevices;

    if (['next', 'start'].includes(direction)) {
        pageArray[pageNumber + 1] = result?.pagination_token;
    }

    const newPaginationData = {
        paginationArray: pageArray,
        pageNumber,
    };

    updateState({
        ...state,
        items: result.devices,
        loading: false,
        type: 'devices',
    });

    setPaginationData(newPaginationData);

    return result;
};

export const getUserActivity = async (state, updateState, direction = 'next', paginationData, setPaginationData) => {
    updateState({
        ...state,
        loading: true,
    });

    const pageArray = [...paginationData.paginationArray];

    let pageNumber;
    let token;

    if (direction === 'start') {
        pageNumber = paginationData.pageNumber;
        token = pageArray[pageNumber];
    } else if (direction === 'next') {
        pageNumber = paginationData.pageNumber + 1;
        token = pageArray[pageNumber];
    } else {
        pageNumber = paginationData.pageNumber - 1;
        token = pageArray[pageNumber];
    }

    let fromDate = state.fromDate;
    let toDate = state.toDate;

    if (!state.fromDate || !state.toDate) {
        fromDate = moment.utc().subtract(10, 'years').toDate();
        toDate = moment.utc().toDate();
    }

    const { data } = await axios({
        url: process.env.REACT_APP_USERS_API,
        method: 'POST',
        data: {
            query: `query { 
                adminGetUserActivityV2(
                    input: {
                        user_id: "${state.userId}"
                        limit: 10
                        from: "${moment.utc(fromDate).format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]')}"
                        to: "${moment.utc(toDate).format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]')}"
                        ${token !== '' ? `pagination_token: "${token}"` : ''}
                    }
                ) { 
                    success
                    pagination_token
                    items {
                        user_id
                        activity_timestamp
                        type
                        platform
                        mobile_os
                        ip_address
                        country
                    }
                }
            }`,
            variables: null,
        },
    });

    const result = data.data.adminGetUserActivityV2;

    if (['next', 'start'].includes(direction)) {
        pageArray[pageNumber + 1] = result?.pagination_token;
    }

    const newPaginationData = {
        paginationArray: pageArray,
        pageNumber,
    };

    updateState({
        ...state,
        items: result.items,
        loading: false,
        type: 'userActivity',
    });

    setPaginationData(newPaginationData);

    return result;
};

const loadFigures = async (currentState, updateState, type, paginationData, setPaginationData, dataCallback) => {
    updateState({ ...currentState, loading: true });

    if (type === 'referredUsers') {
        updateState({
            ...currentState,
            loading: false,
        });
    } else if (type === 'corporateUsers') {
        try {
            await getCorporateUsers(currentState, updateState);
        } catch (e) {
            updateState({
                ...currentState,
                loading: false,
                error: 'Failed to get the corporate users',
            });
        }
    } else if (type === 'actioned_withdrawals') {
        try {
            await getActionedWithdrawals(currentState, updateState);
        } catch (e) {
            updateState({
                ...currentState,
                loading: false,
                error: 'Failed to get the actioned withdrawals',
            });
        }
    } else if (type === 'deposits_to_approve') {
        try {
            await getDepositsToApprove(
                {
                    ...currentState,
                    depositsToApproveStatuses:
                        currentState.embeddedIn === 'userDetail' ? 'ALL' : 'PENDING,REFUND_REQUIRED,PROOF_REQUESTED',
                },
                updateState
            );
        } catch (e) {
            updateState({
                ...currentState,
                loading: false,
                error: 'Failed to get the deposits to approve',
            });
        }
    } else if (type === 'changes') {
        try {
            await getComments(currentState, updateState, 'start', paginationData, setPaginationData);
        } catch (e) {
            updateState({
                ...currentState,
                loading: false,
                error: 'Failed to get the comments',
            });
        }
    } else if (type === 'monitoring') {
        try {
            await getMonitoringAlerts(currentState, updateState, 'start', paginationData, setPaginationData);
        } catch (e) {
            updateState({
                ...currentState,
                loading: false,
                error: 'Failed to get the monitoring alerts',
            });
        }
    } else if (type === 'comms') {
        try {
            await getUserComms(currentState, updateState, 'start', paginationData, setPaginationData);
        } catch (e) {
            updateState({
                ...currentState,
                loading: false,
                error: "Failed to get the user's communications history",
            });
        }
    } else if (type === 'devices') {
        try {
            await getUserDevices(currentState, updateState, 'start', paginationData, setPaginationData);
        } catch (e) {
            updateState({
                ...currentState,
                loading: false,
                error: "Failed to get the user's device history",
            });
        }
    } else if (type === 'userActivity') {
        try {
            await getUserActivity(currentState, updateState, 'start', paginationData, setPaginationData);
        } catch (e) {
            updateState({
                ...currentState,
                loading: false,
                error: "Failed to get the user's activity",
            });
        }
    } else {
        try {
            let extraQueryParams = '';

            if (currentState.fromDate && currentState.toDate) {
                extraQueryParams = `, from: "${moment(currentState.fromDate).format(
                    'YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]'
                )}", to: "${moment(currentState.toDate).format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]')}"`;
            }

            if (currentState.currency) {
                extraQueryParams = `${extraQueryParams}, currency: "${currentState.currency}"`;
            }

            if (currentState.product) {
                extraQueryParams = `${extraQueryParams}, product: "${currentState.product}"`;
            }

            const { data } = await axios({
                url: process.env.REACT_APP_TRANSACTIONS_API,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                data: {
                    query: `query { 
                    adminListTransactionsByDateRange(input: {
                        user_id: "${currentState.userId}"
                        offset: ${currentState.offset}
                        rebalancing_group_id: "${currentState.rebalancingGroupId}"
                        limit: ${currentState.type === 'rebalancing_event_trades' ? 200 : currentState.limit}
                        type: "${currentState.type === 'swaps' ? 'coin_swaps' : currentState.type}" ${extraQueryParams}
                    }) { 
                    swaps {
                        user_id
                        uuid
                        amount_from
                        amount_to
                        currency_from
                        currency_to
                        exchange_rate
                        exec_id
                        trading_fee
                        created_at
                    }
                    mixed_fund_trades {
                        user_id
                        uuid
                        amount_from
                        amount_to
                        currency_from
                        type
                        currency_to
                        exchange_rate
                        exec_id
                        product
                        trading_fee
                        created_at
                        weightings {
                            fraction
                            currency
                        }
                    }
                    rebalancing_trades {
                        user_id
                        previous_amount
                        new_amount
                        type
                        currency_from
                        currency_to
                        exchange_rate
                        product
                        created_at
                    }
                    trades {
                        user_id
                        product
                        type
                        amount
                        currency
                        created_at
                        updated_at
                    }
                    deposits {
                        amount
                        eur
                        currency
                        currency_type
                        type
                        created_at
                        updated_at
                        source_address
                        crypto_transaction_id
                        fiat_details {
                            id
                            payment_type
                            sender_address
                            sender_bic
                            sender_iban
                            sender_name
                            sender_account_number
                            sender_routing_codes {
                                routingCodeKey
                                routingCodeValue
                            }
                            transaction_id
                        }
                    }
                    rebalancing_events {
                        rebalancing_group_id
                        processed_at
                        product
                    }
                    rebalancing_event_trades {
                        type
                        amount
                        status
                        currency_from
                        currency_to
                        product
                        exchange_rate
                        exec_id
                        weightings {
                            fraction
                            currency
                        }
                        created_at
                    }
                    withdrawals {
                        user_id
                        uuid
                        currency_from
                        currency_to
                        status
                        amount
                        fee
                        crypto_wallet_address
                        gas_fee
                        withdrawal_date
                        created_at
                        updated_at
                        crypto_transaction_id
                        fiat_beneficiary {
                            first_name
                            middle_name
                            last_name
                            address_line
                            bank_account_country
                            bank_name
                            city
                            country
                            iban
                            payment_type
                            post_code
                            account_number
                            sort_code
                        }
                    }
                } 
            }`,
                    variables: null,
                },
            });

            updateState({
                ...currentState,
                loading: false,
                error: false,
                items: data.data.adminListTransactionsByDateRange[currentState.type],
            });

            if (dataCallback) {
                dataCallback(data.data.adminListTransactionsByDateRange[currentState.type]);
            }
        } catch (e) {
            updateState({
                ...currentState,
                loading: false,
                error: 'Uh oh, there has been an error loading the figures',
            });
        }
    }
};

const TransactionsList = (props) => {
    const [currentState, updateState] = useState({
        loading: false,
        error: null,
        type: props.type,
        embeddedIn: props.embeddedIn,
        rebalancingGroupId: props.rebalancingGroupId,
        userId: props.userId,
        limit: 10,
        commentsLimit: props.commentsLimit || 10,
        offset: 0,
        items: props.type === 'referredUsers' ? props.list : [],
        pageNumber: 1,
        showAddComment: false,
        manualComment: '',
        manualCommentError: false,
        showDepositOverlay: false,
        manualCommentErrorMsg: '',
        expanded: props.expanded || false,
        fromDate: null,
        toDate: null,
        deposit: null,
        mixedFundTrade: null,
        withdrawal: null,
        currency: null,
        product: null,
    });

    useEffect(() => {
        if (currentState.expanded) {
            loadFigures(
                currentState,
                updateState,
                props.type,
                props.paginationData,
                props.setPaginationData,
                props.dataCallback
            );
        }
    }, [currentState.expanded]);

    useEffect(() => {
        if (currentState.expanded) {
            loadFigures(
                currentState,
                updateState,
                props.type,
                props.paginationData,
                props.setPaginationData,
                props.dataCallback
            );
        }
    }, [currentState.pageNumber, currentState.expanded]);

    if (!currentState.expanded) {
        return (
            <Box sx={{ mb: 20, borderRadius: 12, background: '#FFFFFF' }}>
                <Flex
                    sx={{
                        padding: 30,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        cursor: 'pointer',
                    }}
                    onClick={() => updateState({ ...currentState, expanded: true })}
                >
                    <Paragraph sx={{ fontWeight: '800', fontSize: 18 }}>{props.title}</Paragraph>
                    <i
                        style={{
                            fontSize: '13px',
                            fontWeight: 600,
                            cursor: 'pointer',
                            marginTop: 5,
                        }}
                        className="fal fa-chevron-down"
                    />
                </Flex>
            </Box>
        );
    }

    return (
        <Box sx={{ mb: 20, borderRadius: 12, background: '#FFFFFF', borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
            {currentState.showDepositOverlay && (
                <Overlay
                    closeOverlay={() => updateState({ ...currentState, showDepositOverlay: false })}
                    overlayContent={
                        <DepositDetailView
                            deposit={currentState.deposit}
                            close={() => updateState({ ...currentState, showDepositOverlay: false })}
                        />
                    }
                />
            )}

            {currentState.showExchangeOverlay && (
                <Overlay
                    closeOverlay={() => updateState({ ...currentState, showExchangeOverlay: false })}
                    overlayContent={
                        <ExchangeDetailView
                            exchange={currentState.exchange}
                            close={() => updateState({ ...currentState, showExchangeOverlay: false })}
                        />
                    }
                />
            )}

            {currentState.showMixedFundOverlay && (
                <Overlay
                    closeOverlay={() => updateState({ ...currentState, showMixedFundOverlay: false })}
                    size="tall"
                    overlayContent={
                        <MixedFundTradeDetailView
                            trade={currentState.mixedFundTrade}
                            close={() => updateState({ ...currentState, showMixedFundOverlay: false })}
                        />
                    }
                />
            )}

            {currentState.showRebalancingEventOverlay && (
                <Overlay
                    closeOverlay={() => updateState({ ...currentState, showRebalancingEventOverlay: false })}
                    overlayContent={
                        <RebalancingEventDetailView
                            event={currentState.rebalancingEvent}
                            close={() => updateState({ ...currentState, showRebalancingEventOverlay: false })}
                        />
                    }
                />
            )}

            {currentState.showRebalancingOverlay && (
                <Overlay
                    closeOverlay={() => updateState({ ...currentState, showRebalancingOverlay: false })}
                    overlayContent={
                        <RebalancingTradeDetailView
                            trade={currentState.rebalancingTrade}
                            close={() => updateState({ ...currentState, showRebalancingOverlay: false })}
                        />
                    }
                />
            )}

            {currentState.showWithdrawalOverlay && (
                <Overlay
                    closeOverlay={() => updateState({ ...currentState, showWithdrawalOverlay: false })}
                    size="large"
                    overlayContent={
                        <WithdrawalDetailView
                            withdrawal={currentState.withdrawal}
                            close={() => updateState({ ...currentState, showWithdrawalOverlay: false })}
                        />
                    }
                />
            )}

            {currentState.showDepositToApproveOverlay && (
                <Overlay
                    closeOverlay={() => updateState({ ...currentState, showDepositToApproveOverlay: false })}
                    size="large"
                    overlayContent={
                        <DepositToApproveDetailView
                            depositToApprove={currentState.depositToApprove}
                            close={() => updateState({ ...currentState, showDepositToApproveOverlay: false })}
                        />
                    }
                />
            )}

            {currentState.showCommsOverlay && (
                <Overlay
                    closeOverlay={() => updateState({ ...currentState, showCommsOverlay: false })}
                    size={currentState.commsItem.s3_location ? 'large' : 'medium'}
                    overlayContent={
                        <CommsItemDetailView
                            commsItem={currentState.commsItem}
                            close={() => updateState({ ...currentState, showCommsOverlay: false })}
                        />
                    }
                />
            )}

            {!currentState.expanded && (
                <Flex
                    sx={{
                        padding: 30,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        cursor: 'pointer',
                    }}
                    onClick={() => updateState({ ...currentState, expanded: true })}
                >
                    <Paragraph sx={{ fontWeight: '800', fontSize: 18 }}>{props.title}</Paragraph>
                    <i
                        style={{
                            fontSize: '13px',
                            fontWeight: 600,
                            cursor: 'pointer',
                            marginTop: 5,
                        }}
                        className="fal fa-chevron-down"
                    />
                </Flex>
            )}

            {currentState.expanded && (
                <Box sx={{ padding: 40 }}>
                    {currentState.loading && <Spinner sx={{ position: 'absolute', ml: 100, mt: -14 }} />}

                    <>
                        <Flex
                            sx={{
                                justifyContent: 'space-between',
                                flexDirection: props?.type === 'changes' ? 'row' : 'column',
                            }}
                        >
                            {!props.disableAbilityToExpand && (
                                <Flex
                                    onClick={() =>
                                        !props.hideExpandChevron && updateState({ ...currentState, expanded: false })
                                    }
                                >
                                    <Paragraph sx={{ mb: 20, mr: 20, fontWeight: '800', fontSize: 18 }}>
                                        {props.title}
                                    </Paragraph>
                                    {!props.hideExpandChevron && (
                                        <i
                                            style={{
                                                fontSize: '13px',
                                                fontWeight: 600,
                                                cursor: 'pointer',
                                                marginTop: 5,
                                            }}
                                            className="fal fa-chevron-up"
                                        />
                                    )}
                                </Flex>
                            )}

                            {currentState.error && (
                                <Paragraph sx={{ mb: 20, color: 'red' }}>{currentState.error}</Paragraph>
                            )}

                            {props.showDatePickers && (
                                <Flex
                                    sx={{
                                        mt: 20,
                                        mb: 40,
                                        maxWidth: 900,
                                        justifyContent: 'flex-start',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    <Flex>
                                        <Paragraph sx={{ mr: 20, mt: 10, width: 160 }}>From: </Paragraph>
                                        <DatePicker
                                            showTimeSelect
                                            dateFormat="dd-MM-yyyy HH:mm"
                                            selected={currentState.fromDate}
                                            onChange={(date) =>
                                                updateState({
                                                    ...currentState,
                                                    fromDate: date,
                                                    toDate: currentState.toDate || date,
                                                })
                                            }
                                        />
                                    </Flex>
                                    <Flex>
                                        <Paragraph sx={{ mr: 20, mt: 10 }}>To: </Paragraph>
                                        <DatePicker
                                            showTimeSelect
                                            dateFormat="dd-MM-yyyy HH:mm"
                                            selected={currentState.toDate}
                                            onChange={(date) => updateState({ ...currentState, toDate: date })}
                                        />
                                    </Flex>

                                    {props.showCurrencyDropDown && (
                                        <Flex sx={{ mt: 40 }}>
                                            <Paragraph sx={{ mr: 40, mt: 12, width: 80 }}>Currency: </Paragraph>
                                            <Select
                                                sx={{ width: 300 }}
                                                value={currentState.currency}
                                                onChange={(e) => {
                                                    updateState({ ...currentState, currency: e.target.value });
                                                }}
                                            >
                                                <option value="">Select a currency</option>
                                                <option value="eur">Euros</option>
                                                <option value="dai">DAI</option>
                                                <option value="bitcoin">Bitcoin</option>
                                                <option value="ethereum">Ethereum</option>
                                                <option value="tether">USDT</option>
                                                <option value="usd-coin">USDC</option>
                                            </Select>
                                        </Flex>
                                    )}

                                    {props.showProductDropDown && (
                                        <Flex sx={{ mt: 30 }}>
                                            <Paragraph sx={{ mr: 40, mt: 12, width: 80 }}>Product: </Paragraph>
                                            <Select
                                                sx={{ width: 300 }}
                                                value={currentState.product}
                                                onChange={(e) => {
                                                    updateState({ ...currentState, product: e.target.value });
                                                }}
                                            >
                                                <option value="">Select a product</option>
                                                <option value="auto_eth">Ethereum</option>
                                                <option value="auto_btc">Bitcoin</option>
                                                <option value="stablecoin">Stablecoin</option>
                                            </Select>
                                        </Flex>
                                    )}

                                    <Flex sx={{ mt: 70 }}>
                                        <Button
                                            onClick={() =>
                                                loadFigures(
                                                    currentState,
                                                    updateState,
                                                    props.type,
                                                    props.paginationData,
                                                    props.setPaginationData,
                                                    props.dataCallback
                                                )
                                            }
                                            sx={{ ml: 20, width: 200, height: 40, mt: -10 }}
                                        >
                                            Search
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                updateState({ ...currentState, fromDate: null, toDate: null });
                                                loadFigures(
                                                    { ...currentState, fromDate: null, toDate: null, offset: 0 },
                                                    updateState,
                                                    props.type,
                                                    props.paginationData,
                                                    props.setPaginationData,
                                                    props.dataCallback
                                                );
                                            }}
                                            sx={{ ml: 20, width: 200, height: 40, mt: -10 }}
                                        >
                                            Clear
                                        </Button>
                                    </Flex>
                                </Flex>
                            )}

                            {props?.type === 'changes' && (
                                <Paragraph
                                    sx={{ color: '#09042B', cursor: 'pointer' }}
                                    onClick={() =>
                                        updateState({
                                            ...currentState,
                                            showAddComment: !currentState.showAddComment,
                                            manualComment: '',
                                            manualCommentError: false,
                                            manualCommentErrorMsg: '',
                                        })
                                    }
                                >
                                    {currentState.showAddComment ? 'Close' : 'Add a comment'}
                                </Paragraph>
                            )}
                        </Flex>

                        {currentState.showAddComment && (
                            <AddComment
                                close={() => updateState({ ...currentState, showAddComment: false })}
                                reload={() =>
                                    loadFigures(
                                        { ...currentState, showAddComment: false },
                                        updateState,
                                        props.type,
                                        props.paginationData,
                                        props.setPaginationData,
                                        props.dataCallback
                                    )
                                }
                                currentState={currentState}
                                updateState={updateState}
                            />
                        )}

                        <Flex
                            sx={{
                                justifyContent: 'space-between',
                                width: '100%',
                                height: '40px',
                                color: '#fff',
                            }}
                        >
                            {tableDefinition[currentState.type]?.headers?.map((header) => (
                                <TableHeaderItem key={`${currentState.type}_${header}`} text={header} />
                            ))}

                            {(tableDefinition[currentState.type]?.showViewMoreColumn || props.openUserOnViewMore) && (
                                <TableHeaderItem key={`${currentState.type}_viewmore`} text="" />
                            )}

                            {props.additionalLinkColumns?.map((col, idx) => (
                                <TableHeaderItem key={`${currentState.type}_viewmore_${idx}`} text="" />
                            ))}
                        </Flex>

                        {props?.type !== 'changes' && (
                            <TransactionRow
                                currentState={currentState}
                                labelMapping={labelMapping}
                                tableDefinition={tableDefinition}
                                expandable={currentState.type === 'monitoring'}
                                updateState={updateState}
                                openUserOnViewMore={props.openUserOnViewMore}
                                additionalLinkColumns={props.additionalLinkColumns}
                                action={(item) => {
                                    if (currentState.type === 'rebalancing_events') {
                                        props.updateState({
                                            ...props.currentState,
                                            view: 'tradeList',
                                            event: item,
                                        });
                                    }

                                    if (currentState.type === 'monitoring') {
                                        props.updateState({
                                            ...props.currentState,
                                            showMonitoringAlertsOverlay: true,
                                            monitoringAlert: item,
                                        });
                                    }

                                    if (currentState.type === 'deposits') {
                                        updateState({ ...currentState, showDepositOverlay: true, deposit: item });
                                    }

                                    if (currentState.type === 'swaps') {
                                        updateState({ ...currentState, showExchangeOverlay: true, exchange: item });
                                    }

                                    if (currentState.type === 'mixed_fund_trades') {
                                        updateState({
                                            ...currentState,
                                            showMixedFundOverlay: true,
                                            mixedFundTrade: item,
                                        });
                                    }

                                    if (currentState.type === 'rebalancing_event_trades') {
                                        updateState({
                                            ...currentState,
                                            showRebalancingEventOverlay: true,
                                            rebalancingEvent: item,
                                        });
                                    }

                                    if (currentState.type === 'rebalancing_trades') {
                                        updateState({
                                            ...currentState,
                                            showRebalancingOverlay: true,
                                            rebalancingTrade: item,
                                        });
                                    }

                                    if (currentState.type === 'trades' && props.openUserOnViewMore) {
                                        window.location.assign(`/user/${item.user_id}`);
                                    }

                                    if (currentState.type === 'actioned_withdrawals' && props.openUserOnViewMore) {
                                        window.location.assign(
                                            `/user/${item.user_id}?activeTabGroup=currency&activeTab=withdrawals`
                                        );
                                    }

                                    if (currentState.type === 'deposits_to_approve' && props.openUserOnViewMore) {
                                        if (props.embeddedIn === 'userDetail') {
                                            updateState({
                                                ...currentState,
                                                showDepositToApproveOverlay: true,
                                                depositToApprove: item,
                                            });
                                        } else {
                                            window.location.assign(
                                                `/user/${item.user_id}?activeTabGroup=currency&activeTab=depositChecks`
                                            );
                                        }
                                    }

                                    if (currentState.type === 'withdrawals') {
                                        updateState({
                                            ...currentState,
                                            showWithdrawalOverlay: true,
                                            withdrawal: item,
                                        });
                                    }

                                    if (currentState.type === 'comms') {
                                        updateState({
                                            ...currentState,
                                            showCommsOverlay: true,
                                            commsItem: item,
                                        });
                                    }

                                    if (currentState.type === 'corporateUsers' && props.openUserOnViewMore) {
                                        window.location.assign(`/user/${item.corporate_id}`);
                                    }

                                    if (currentState.type === 'referredUsers' && props.openUserOnViewMore) {
                                        window.location.assign(`/user/${item.user_id}`);
                                    }
                                }}
                            />
                        )}

                        {props?.type === 'changes' && (
                            <ChangesRow
                                items={currentState.items}
                                tableDefinition={tableDefinition}
                                currentState={currentState}
                                labelMapping={labelMapping}
                            />
                        )}

                        {props.type === 'changes' && currentState.items?.length > 1 && (
                            <CommentsPagination
                                currentState={currentState}
                                updateState={updateState}
                                paginationData={props.paginationData}
                                setPaginationData={props.setPaginationData}
                            />
                        )}

                        {(props.type === 'actioned_withdrawals' || props.type === 'deposits_to_approve') &&
                            currentState.items?.length > 0 && (
                                <Flex sx={{ width: '200px', margin: '20px auto', justifyContent: 'space-around' }}>
                                    <PgPagination
                                        currentState={currentState}
                                        updateState={updateState}
                                        totalPages={Math.ceil(
                                            currentState.total ? currentState.total / currentState.limit : 1
                                        )}
                                        totalRecords={currentState.total}
                                    />
                                </Flex>
                            )}

                        {[
                            'withdrawals',
                            'deposits',
                            'trades',
                            'swaps',
                            'mixed_fund_trades',
                            'rebalancing_event_trades',
                            'rebalancing_trades',
                        ].includes(props.type) &&
                            currentState.items?.length > 0 && (
                                <Flex sx={{ width: '200px', margin: '20px auto', justifyContent: 'space-around' }}>
                                    <TransactionsPagination
                                        currentState={currentState}
                                        updateState={updateState}
                                        totalPages={Math.ceil(
                                            currentState.total ? currentState.total / currentState.limit : 1
                                        )}
                                        totalRecords={currentState.total}
                                    />
                                </Flex>
                            )}

                        {props.type === 'monitoring' && (
                            <Flex sx={{ width: '200px', margin: '20px auto', justifyContent: 'space-around' }}>
                                <Pagination
                                    paginationData={props.paginationData}
                                    setPaginationData={props.setPaginationData}
                                    state={currentState}
                                    updateState={updateState}
                                    dataFetchFunction={getMonitoringAlerts}
                                />
                            </Flex>
                        )}

                        {props.type === 'devices' && (
                            <Flex sx={{ width: '200px', margin: '20px auto', justifyContent: 'space-around' }}>
                                <Pagination
                                    paginationData={props.paginationData}
                                    setPaginationData={props.setPaginationData}
                                    state={currentState}
                                    updateState={updateState}
                                    dataFetchFunction={getUserDevices}
                                />
                            </Flex>
                        )}

                        {props.type === 'userActivity' && (
                            <Flex sx={{ width: '200px', margin: '20px auto', justifyContent: 'space-around' }}>
                                <Pagination
                                    paginationData={props.paginationData}
                                    setPaginationData={props.setPaginationData}
                                    state={currentState}
                                    updateState={updateState}
                                    dataFetchFunction={getUserActivity}
                                />
                            </Flex>
                        )}

                        {props.type === 'comms' && (
                            <Flex sx={{ width: '200px', margin: '20px auto', justifyContent: 'space-around' }}>
                                <Pagination
                                    paginationData={props.paginationData}
                                    setPaginationData={props.setPaginationData}
                                    state={currentState}
                                    updateState={updateState}
                                    dataFetchFunction={getUserComms}
                                />
                            </Flex>
                        )}
                    </>
                </Box>
            )}
        </Box>
    );
};

const mapDispatchToProps = (dispatch) => ({
    setPaginationData: (paginationData) => dispatch({ type: 'SET_COMMENT_PAGINATION_DATA', paginationData }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return { paginationData: account.CommentpaginationData };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsList);
