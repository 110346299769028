import { Flex } from 'theme-ui';

const AppWrapper = ({ children }) => (
    <Flex
        sx={{
            background: '#E5E5E5',
            height: '100%',
            minHeight: '100vh',
            padding: '0',
            flexDirection: 'column',
        }}
    >
        {children}
    </Flex>
);

export default AppWrapper;
