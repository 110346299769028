import { Select, Box } from 'theme-ui';

export default ({ currentState, updateState, adminUserEmails, fullHeight }) => (
    <Select
        defaultValue={currentState.assignee}
        value={currentState.assignee}
        onChange={(e) => updateState({ ...currentState, assignee: e.target.value })}
        arrow={
            <Box
                as="svg"
                // @ts-ignore
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentcolor"
                sx={{
                    ml: -28,
                    mt: -20,
                    alignSelf: 'center',
                    pointerEvents: 'none',
                }}
            >
                <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
            </Box>
        }
        sx={{
            mb: 20,
            width: 300,
            height: fullHeight ? 60 : 35,
            border: currentState.selectAssignee ? '1px solid red' : 'auto',
        }}
    >
        <option value="select">Please Select</option>

        {adminUserEmails?.map((item) => (
            <option value={item}>{item}</option>
        ))}
    </Select>
);
