import { Flex, Select, Box, Paragraph } from 'theme-ui';
import { currencySymbol } from './helper';
import { getPortfolioFigures } from '../../utils/get-user-details';

const GraphControls = ({ currentState, updateState, id }) => (
    <Flex sx={{ flexDirection: 'column', width: '300px', mb: '50px', height: '170px', ml: '20px' }}>
        <Paragraph sx={{ mb: '35px', fontWeight: '600', fontSize: '21px' }}>Fund Performance </Paragraph>
        <Flex sx={{ width: '800px' }}>
            <Flex sx={{ width: '250px', flexDirection: 'column', height: '40px' }}>
                <Paragraph
                    sx={{
                        mt: '0px',
                        textAlign: 'left',
                        maxWidth: '450px',
                        color: '#09042B',
                        fontSize: '16px',
                    }}
                >
                    Fund
                </Paragraph>
                <Select
                    sx={{
                        mt: '7px',
                        width: '250px',
                        height: '45px',
                        border: '1px solid grey',
                    }}
                    value={currentState?.productType}
                    onChange={async (e) => {
                        const product = e.target.value;
                        if (product === 'All') {
                            const portfolioFigures = await getPortfolioFigures(id, currentState?.preferredCurrency);
                            updateState({
                                ...currentState,
                                portfolioFigures,
                                loading: false,
                                currencyType: currentState?.preferredCurrency,
                                productType: product,
                            });
                        } else {
                            updateState({
                                ...currentState,
                                productType: product,
                                currencyType: currentState?.currencyType,
                            });
                        }
                    }}
                    arrow={
                        <Box
                            as="svg"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="currentcolor"
                            sx={{
                                ml: -28,
                                mt: 15,
                                alignSelf: 'center',
                                pointerEvents: 'none',
                            }}
                        >
                            <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                        </Box>
                    }
                >
                    <option key="All" value="All">
                        All
                    </option>
                    {currentState?.portfolioFigures?.products.map((item) => (
                        <option key={item.id} value={item.label}>
                            {item.label}
                        </option>
                    ))}
                </Select>
            </Flex>

            <Flex sx={{ width: '250px', flexDirection: 'column', height: '40px', ml: '30px' }}>
                <Paragraph
                    sx={{
                        mt: '0px',
                        textAlign: 'left',
                        maxWidth: '450px',
                        color: '#09042B',
                        fontSize: '16px',
                    }}
                >
                    Currency
                </Paragraph>
                <Select
                    sx={{
                        mt: '7px',
                        width: '250px',
                        height: '45px',
                        border: '1px solid grey',
                    }}
                    value={currentState?.currencyType}
                    onChange={async (e) => {
                        const selectedValue = e.target.value;
                        if (selectedValue === 'Native') {
                            updateState({ ...currentState, currencyType: selectedValue });
                        } else {
                            const portfolioFigures = await getPortfolioFigures(id, selectedValue);
                            updateState({
                                ...currentState,
                                portfolioFigures,
                                loading: false,
                                currencyType: selectedValue,
                            });
                        }
                    }}
                    arrow={
                        <Box
                            as="svg"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="currentcolor"
                            sx={{
                                ml: -28,
                                mt: 15,
                                alignSelf: 'center',
                                pointerEvents: 'none',
                            }}
                        >
                            <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                        </Box>
                    }
                >
                    <option key="gbp" value="gbp">
                        GBP
                    </option>
                    <option key="eur" value="eur">
                        EUR
                    </option>
                    <option key="usd" value="usd">
                        USD
                    </option>
                    <option key="cad" value="cad">
                        CAD
                    </option>
                    {currentState?.productType !== 'All' && (
                        <option key="native" value="Native">
                            Native ({`${currencySymbol[currentState?.productType]}`})
                        </option>
                    )}
                </Select>
            </Flex>
        </Flex>
    </Flex>
);

export default GraphControls;
