import { Flex, Paragraph } from 'theme-ui';
import axios from 'axios';
import TransactionsList from '../transactions-list';

const setFlag = async (state, updateState, metadataKey, key, value) => {
    updateState({ ...state, loading: true });

    try {
        const { data } = await axios({
            url: process.env.REACT_APP_USERS_API,
            method: 'POST',
            data: {
                query: `mutation {
                adminTools(input: {
                    user_id: "${state.user_id}"
                    tool: "update_boolean_flag"
                    key: "${key}"
                    boolean_value: ${value}
                }) { 
                    success
                    message
                }
            }`,
                variables: null,
            },
        });

        const result = data.data?.adminTools;

        if (!result.success) {
            throw new Error('Failed');
        }

        updateState({
            ...state,
            loading: false,
            metadata: {
                ...state.metadata,
                [metadataKey]: value,
            },
        });
    } catch (e) {
        updateState({
            ...state,
            loading: false,
            error: 'Uh oh, there has been an error, please try again later',
        });
    }
};

const CommsTab = ({ state, updateState }) => (
    <Flex
        sx={{
            flexDirection: 'column',
            backgroundColor: 'white',
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
        }}
    >
        <Flex sx={{ flexDirection: 'column', ml: 40 }}>
            <Paragraph sx={{ fontWeight: '800', fontSize: 20, mb: 20 }}>Comms</Paragraph>
            <Flex
                sx={{ mb: 20, cursor: 'pointer' }}
                onClick={() =>
                    setFlag(
                        state,
                        updateState,
                        'disable_marketing_nudges',
                        'DisableMarketingNudges',
                        !state?.metadata?.disable_marketing_nudges
                    )
                }
            >
                <Paragraph sx={{ width: 240 }}>Disable Marketing Nudges</Paragraph>
                <i
                    style={{ color: 'black', fontSize: 17 }}
                    className={`far ${state?.metadata?.disable_marketing_nudges ? 'fa-check-square' : 'fa-square'}`}
                />
            </Flex>
            <Flex
                sx={{ mb: 20, cursor: 'pointer' }}
                onClick={() =>
                    setFlag(
                        state,
                        updateState,
                        'disable_weekly_interest_email',
                        'DisableWeeklyInterestEmail',
                        !state?.metadata?.disable_weekly_interest_email
                    )
                }
            >
                <Paragraph sx={{ width: 240 }}>Disable Weekly Interest Email</Paragraph>
                <i
                    style={{ color: 'black', fontSize: 17 }}
                    className={`far ${
                        state?.metadata?.disable_weekly_interest_email ? 'fa-check-square' : 'fa-square'
                    }`}
                />
            </Flex>
            <Flex
                sx={{ cursor: 'pointer' }}
                onClick={() =>
                    setFlag(
                        state,
                        updateState,
                        'use_alt_email_provider',
                        'UseAlternativeEmailProvider',
                        !state?.metadata?.use_alt_email_provider
                    )
                }
            >
                <Paragraph sx={{ width: 240 }}>Use Sendgrid</Paragraph>
                <i
                    style={{ color: 'black', fontSize: 17 }}
                    className={`far ${state?.metadata?.use_alt_email_provider ? 'fa-check-square' : 'fa-square'}`}
                />
            </Flex>
        </Flex>
        <TransactionsList
            expanded
            disableAbilityToExpand
            currentState={state}
            updateState={updateState}
            title=""
            type="comms"
            userId={state.user_id}
        />
    </Flex>
);

export default CommsTab;
