/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-unreachable */
/* eslint-disable no-plusplus */

import { Flex, Button, Paragraph } from 'theme-ui';
import { useState, useEffect } from 'react';
import Spinner from '../spinner';
import CompanyInfo from './companyInfo';
import AdditionalInfo from './additionalInfo';
import AddressCompany from './addressCompany';
import AddressCorrespondence from './addressCorrespondence';
import { createReasonString, loadCountries, validateData, submitData } from './helper';

const updateCorporate = async (
    formState: any,
    updateFormState: any,
    originalValues: any,
    type: any,
    updateErrorState: any
) => {
    updateFormState({ ...formState, loading: true, errorMsg: '' });

    const validForm = validateData(formState, type, updateErrorState);

    if (validForm) {
        const reason = createReasonString(originalValues, formState, type);

        const result = await submitData(formState, reason);

        // @ts-ignore
        if (result?.success) {
            window.location.reload();
        } else {
            updateFormState({ ...formState, loading: false, errorMsg: 'Failed to submit changes' });
        }
    } else {
        updateFormState({ ...formState, loading: false, errorMsg: '' });
    }
};

const CorporateUpdate = ({ type, state }: any) => {
    const {
        corporate_id,
        company_name,
        company_type,
        registration_number,
        website,
        contact_email,
        contact_telephone_number,
        contact_person,
        currency,
        next_kyc_refresh_date,
        industry,
        expected_amount_to_invest,
        licence_details,
        licence_details_validity_date,
        source_of_funds,
        correspondence_address,
        address,
        risk_score,
    } = state;

    const [formState, updateFormState] = useState({
        corporate_id,
        company_name,
        company_type,
        registration_number,
        website,
        contact_email,
        contact_telephone_number,
        contact_person,
        currency,
        next_kyc_refresh_date,
        industry,
        expected_amount_to_invest,
        licence_details,
        licence_details_validity_date,
        source_of_funds,
        risk_score,
        correspondence_address: {
            street: correspondence_address?.street,
            sub_street: correspondence_address?.sub_street,
            town: correspondence_address?.town,
            post_code: correspondence_address?.post_code,
            state: correspondence_address?.state,
            country: correspondence_address?.country,
        },
        address: {
            street: address?.street,
            sub_street: address?.sub_street,
            town: address?.town,
            post_code: address?.post_code,
            state: address?.state,
            country: address?.country,
        },
        loading: true,
        errorMsg: '',
    });

    const [errorState, updateErrorState] = useState({
        company_name: false,
        company_type: false,
        registration_number: false,
        website: false,
        contact_email: false,
        contact_telephone_number: false,
        contact_person: false,
        currency: false,
        next_kyc_refresh_date: false,
        industry: false,
        expected_amount_to_invest: false,
        licence_details: false,
        licence_details_validity_date: false,
        source_of_funds: false,
        correspondence_address: false,
        risk_score: false,
        address: false,
    });

    const [countries, updateCountries] = useState([]);

    useEffect(() => {
        loadCountries(countries, updateCountries, formState, updateFormState);
    }, []);

    return (
        <Flex
            sx={{
                width: '95%',
                height: '86%',
                margin: '7% auto 0',
                boxSizing: 'border-box',
                overflow: 'scroll',
                position: 'relative',
            }}
        >
            {formState.loading && <Spinner />}

            {type === 'company' && (
                <CompanyInfo formState={formState} updateFormState={updateFormState} errorState={errorState} />
            )}
            {type === 'additional' && (
                <AdditionalInfo formState={formState} updateFormState={updateFormState} errorState={errorState} />
            )}
            {type === 'addressCompany' && (
                <AddressCompany
                    formState={formState}
                    updateFormState={updateFormState}
                    countries={countries}
                    errorState={errorState}
                />
            )}
            {type === 'addressCorrespondence' && (
                <AddressCorrespondence
                    formState={formState}
                    updateFormState={updateFormState}
                    countries={countries}
                    errorState={errorState}
                />
            )}

            <Flex sx={{ position: 'absolute', bottom: 0, width: '100%', margin: '0 auto', flexDirection: 'column' }}>
                {formState.errorMsg && (
                    <Paragraph sx={{ width: '100%', textAlign: 'center', height: '30px', color: 'red' }}>
                        {formState.errorMsg}
                    </Paragraph>
                )}

                <Button
                    onClick={() => updateCorporate(formState, updateFormState, state, type, updateErrorState)}
                    sx={{ color: 'white', position: 'relative', margin: '0 auto', width: '180px' }}
                >
                    Update
                </Button>
            </Flex>
        </Flex>
    );
};

export default CorporateUpdate;
