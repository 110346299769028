/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import { Flex, Box } from 'theme-ui';
import * as moment from 'moment';
import { Title } from './table';
import Item from './user-data-item';
import labelMapping from '../utils/label-mapping';

const AdditionalData = ({ state }) => (
    <Flex>
        <Flex sx={{ mt: '30px', flexDirection: 'column' }}>
            <Item
                required
                title="Next KYC refresh date"
                value={`${
                    state.next_kyc_refresh_date === 'null' || !state.next_kyc_refresh_date
                        ? ''
                        : moment(state.next_kyc_refresh_date).format('DD MMM YYYY')
                }`}
            />
            <Item required title="Industry" value={`${state.industry || ''}`} />
            <Item
                required
                title="Expected amount to invest"
                value={`${labelMapping(state.expected_amount_to_invest || '')}`}
            />
            <Item title="KYC Status" value={`${labelMapping(state.kyc_status) || ''}`} />
        </Flex>

        <Flex sx={{ mt: '30px', flexDirection: 'column', borderLeft: '1px grey solid', pl: '50px' }}>
            <Item required title="License details" value={`${state.licence_details || ''}`} />

            {state.licence_details !== 'false' && (
                <Item
                    title="License details validity date"
                    value={`${moment(state.licence_details_validity_date).format('DD MMM YYYY') || ''}`}
                />
            )}
            <Item required title="Source of funds" value={`${labelMapping(state.source_of_funds) || ''}`} />
            <Item required title="Risk score" value={`${labelMapping(state.risk_score) || ''}`} />
        </Flex>
    </Flex>
);

export default ({ state, updateState }) => (
    <Box sx={{ width: '100%', marginLeft: '15px', mb: 40 }}>
        <Title title="Additional Details" type="additional" state={state} updateState={updateState} />
        <AdditionalData state={state} updateState={updateState} />
        <Box sx={{ height: state?.corporate_id ? '20px' : '0' }} />
    </Box>
);
