/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { Flex, Box, Paragraph, Select } from 'theme-ui';
import Input from '../form-input';

const companyTypes = [
    { id: 'Limited-Liability', label: 'Limited Liability', value: 'LIMITED_LIABILITY' },
    { id: 'Sole-Trader', label: 'Sole Trader', value: 'SOLE_TRADER' },
    { id: 'Partnership', label: 'Partnership', value: 'PARTNERSHIP' },
    { id: 'Public-limited-Company', label: 'Public Limited Company', value: 'PUBLIC_LIMITED_COMPANY' },
    { id: 'Joint-Stock-Company', label: 'Joint Stock Company', value: 'JOINT_STOCK_COMPANY' },
    { id: 'Charity', label: 'Charity', value: 'CHARITY' },
];

const currencies = [
    { id: 'eur', label: 'Euros', value: 'eur' },
    { id: 'gbp', label: 'British Pounds', value: 'gbp' },
    { id: 'cad', label: 'Canadian Dollars', value: 'cad' },
    { id: 'usd', label: 'US Dollars', value: 'usd' },
];

const CompanyInfo = ({ formState, updateFormState, countries = [], errorState }: any) => {
    const {
        company_name,
        company_type,
        registration_number,
        website,
        contact_email,
        contact_telephone_number,
        contact_person,
        currency,
    } = formState;
    return (
        <Flex sx={{ justifyContent: 'center', width: '100%' }}>
            <Box sx={{ width: '100%' }}>
                <Paragraph sx={{ fontWeight: 600, textAlign: 'center', mb: 20, mt: 0 }}>Company Details</Paragraph>
                <Flex>
                    <Box sx={{ width: '100%', mr: '10px' }}>
                        <Input
                            sx={{
                                mb: '12px',
                                alignSelf: 'center',
                                width: '100%',
                                height: 40,
                                background: '#FFFFFF',
                                border: errorState.company_name ? '1px red solid' : '1px solid grey',
                            }}
                            type="text"
                            id="company_name"
                            data-testid="company_name"
                            name="company_name"
                            label="Company name"
                            placeholder="enter company name"
                            onChange={(e: any) => updateFormState({ ...formState, company_name: e.target.value })}
                            error={null}
                            value={company_name}
                        />
                        <Input
                            sx={{
                                mb: '12px',
                                alignSelf: 'center',
                                width: '100%',
                                height: 40,
                                background: '#FFFFFF',
                                border: errorState.contact_email ? '1px red solid' : '1px solid grey',
                            }}
                            type="text"
                            id="contact_email"
                            data-testid="contact_email"
                            name="contact_email"
                            label="Company Email"
                            placeholder="enter company email"
                            onChange={(e: any) => updateFormState({ ...formState, contact_email: e.target.value })}
                            error={null}
                            value={contact_email}
                        />
                        <Input
                            sx={{
                                mb: '12px',
                                alignSelf: 'center',
                                width: '100%',
                                height: 40,
                                background: '#FFFFFF',
                                border: errorState.contact_email ? '1px red solid' : '1px solid grey',
                            }}
                            type="text"
                            id="contact_person"
                            data-testid="contact_person"
                            name="contact_person"
                            label="Contact Person"
                            placeholder="enter contact person"
                            onChange={(e: any) => updateFormState({ ...formState, contact_person: e.target.value })}
                            error={null}
                            value={contact_person}
                        />
                        <Paragraph
                            sx={{
                                mt: '0px',
                                textAlign: 'left',
                                maxWidth: '450px',
                                color: '#09042B',
                                fontSize: '16px',
                            }}
                        >
                            Currency
                        </Paragraph>
                        <Select
                            sx={{
                                mt: '7px',
                                width: '100%',
                                height: '40px',
                                border: errorState.currency ? '1px red solid' : '1px solid grey',
                            }}
                            value={currency}
                            onChange={(e: any) => updateFormState({ ...formState, currency: e.target.value })}
                            arrow={
                                <Box
                                    as="svg" // @ts-ignore
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="currentcolor"
                                    sx={{
                                        ml: -28,
                                        mt: 15,
                                        alignSelf: 'center',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                                </Box>
                            }
                        >
                            <option key="select" value="">
                                Select the currency
                            </option>

                            {currencies.map((item: any) => (
                                <option key={item.id} value={item.value}>
                                    {item.label}
                                </option>
                            ))}
                        </Select>
                    </Box>
                    <Box sx={{ width: '100%', ml: '10px' }}>
                        <Input
                            sx={{
                                mb: '12px',
                                alignSelf: 'center',
                                width: '100%',
                                height: 40,
                                background: '#FFFFFF',
                                border: errorState.contact_telephone_number ? '1px red solid' : '1px solid grey',
                            }}
                            type="text"
                            id="contact_telephone_number"
                            data-testid="contact_telephone_number"
                            name="contact_telephone_number"
                            label="Telephone number"
                            placeholder="enter company phone number"
                            onChange={(e: any) =>
                                updateFormState({ ...formState, contact_telephone_number: e.target.value })
                            }
                            error={null}
                            value={contact_telephone_number}
                        />
                        <Input
                            sx={{
                                mb: '12px',
                                alignSelf: 'center',
                                width: '100%',
                                height: 40,
                                background: '#FFFFFF',
                                border: errorState.registration_number ? '1px red solid' : '1px solid grey',
                            }}
                            type="text"
                            id="registration_number"
                            data-testid="registration_number"
                            name="registration_number"
                            label="Registration number"
                            placeholder="enter registration number"
                            onChange={(e: any) =>
                                updateFormState({
                                    ...formState,
                                    registration_number: e.target.value,
                                })
                            }
                            error={null}
                            value={registration_number}
                        />
                        <Input
                            sx={{
                                mb: '12px',
                                alignSelf: 'center',
                                width: '100%',
                                height: 40,
                                background: '#FFFFFF',
                                border: errorState.website ? '1px red solid' : '1px solid grey',
                            }}
                            type="text"
                            id="website"
                            data-testid="website"
                            name="website"
                            label="Website"
                            placeholder="enter website"
                            onChange={(e: any) =>
                                updateFormState({
                                    ...formState,
                                    website: e.target.value,
                                })
                            }
                            error={null}
                            value={website}
                        />
                        <Paragraph
                            sx={{
                                mt: '0px',
                                textAlign: 'left',
                                maxWidth: '450px',
                                color: '#09042B',
                                fontSize: '16px',
                            }}
                        >
                            Company type
                        </Paragraph>
                        <Select
                            sx={{
                                mt: '7px',
                                width: '100%',
                                height: '40px',
                                border: errorState.company_type ? '1px red solid' : '1px solid grey',
                            }}
                            value={company_type}
                            onChange={(e: any) => updateFormState({ ...formState, company_type: e.target.value })}
                            arrow={
                                <Box
                                    as="svg" // @ts-ignore
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="currentcolor"
                                    sx={{
                                        ml: -28,
                                        mt: 15,
                                        alignSelf: 'center',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                                </Box>
                            }
                        >
                            <option key="select" value="">
                                Select the company type
                            </option>

                            {companyTypes.map((item: any) => (
                                <option key={item.id} value={item.value}>
                                    {item.label}
                                </option>
                            ))}
                        </Select>
                        <Box sx={{ height: '10px' }} />
                    </Box>
                </Flex>
            </Box>
        </Flex>
    );
};

export default CompanyInfo;
