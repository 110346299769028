/* eslint-disable spaced-comment */
/* eslint-disable camelcase */
/* eslint-disable no-alert */
import { Flex, Paragraph, Box, Button, Link } from 'theme-ui';
import { Auth } from 'aws-amplify';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import * as moment from 'moment';
import { useEffect, useState } from 'react';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';
import { Title } from '../components/table';
import {
    getReferralSummary,
    getUserDetails,
    getTransactionDataSummary,
    getUsersReferredByTheCurrentUser,
    duplicatesCouldExist,
    getAdminUsers,
    deleteUser,
} from '../utils/get-user-details';
import TransactionsList from '../components/transactions-list';
import CommsTab from '../components/comms/tab';
import Overlay from '../components/overlay';
import KycChecks from '../components/kyc-checks';
import ProofOfAddressUploads from '../components/proof-of-address-uploads';
import VerifyKyc from '../components/verify-kyc';
import WithdrawalLimits from '../components/withdrawal-limits';
import ChangeRiskScore from '../components/change-risk-score';
import labelMapping from '../utils/label-mapping';
import UpdateUserContent from '../components/user-update/update';
import UserPermissions from '../components/user-permissions';
import ReferralConfig from '../components/referral-config';
import UploadUserDocument from '../components/upload-user-document';
import MonitoringAlertContent from '../components/monitoring-alerts/popup-content';
import AdminDocumentUploads from '../components/admin-doc-uploads';
import Balances from '../components/balances';
import PortfolioFigures from '../components/portfolio-figures';
import CreditAccount from '../components/credit-account';
import ManuallyInvest from '../components/manually-invest';
import TabGroup from '../components/detail-views/tab-group-combined';
import Confirm from '../components/confirm-dialog';
import ChangeEmail from '../components/change-email';
import { formatDate } from '../utils/validation-helpers';
import { disableMfa, forceKycCheck } from '../utils/tools';
import BasicDetails from '../components/basic-details';
import EmailSelector from '../components/send-email-template';
import ContactDetails from '../components/contact-details';
import KYCDetails from '../components/kyc-details';
import buttonStyle from '../utils/button-style';
import DataItem from '../components/user-data-item';
import ReferralForm from '../components/referral-fix-form';
import downloadTransactions, { downloadKoinlyTransactions } from '../utils/download-transactions';
import LockWindows from '../components/lock-windows';
import InteroTransfer from '../components/intero/transfer';

const defaultState = {
    currency: '',
    created_at: '',
    email_address: '',
    first_name: '',
    last_name: '',
    kyc_pending_date: '',
    kyc_verified_date: '',
    kyc_updated_date: '',
    address: { street: '', sub_street: '', town: '', post_code: '', state: '', country: '' },
    kyc: { verified: false, applicant_id: null },
    metadata: { biometrics_prompt_viewed: false, welcome_prompt_viewed: false },
    phone_number: '',
    phone_number_parts: [],
    success: false,
    showKycEnableOverlay: false,
    showWithdrawal: false,
    showProofOfAddressOverlay: false,
    user_id: '',
    loading: true,
    showOverlay: false,
    showReferralConfigOverlay: false,
    showChangeRiskScore: false,
    overlayContentType: null,
    showUpdateOverlay: false,
    updateOverlayType: '',
    showMonitoringAlertsOverlay: false,
    showDocumentUpload: false,
    showUploadedDocuments: false,
    showEmailTemplates: false,
    showLockins: false,
    showChangeEmail: false,
    monitoringAlert: null,
    activeTab: 'personal',
    activeTabGroup: 'personal',
    error: null,
    risk_score: {
        rating: '',
        score: 0,
        expected_amounts_to_invest: {
            currency: '',
            fiat: { in_euros: '', chosen: '' },
            crypto: { in_euros: '', chosen: '' },
        },
    },
};

const fetchUserData = async (id, state, updateState, history) => {
    try {
        await Auth.currentAuthenticatedUser();
        updateState({ ...state, loading: true });

        const [
            data,
            referralSummary,
            transactionsDataSummary,
            referredUsers,
            adminUserEmails,
            { data: countriesList },
        ] = await Promise.all([
            getUserDetails(id),
            getReferralSummary(id),
            getTransactionDataSummary(id),
            getUsersReferredByTheCurrentUser(id),
            getAdminUsers(),
            axios({
                url: `https://cdn.accru.finance/countries/codes.json?cache=${new Date().getTime()}`,
            }),
        ]);

        let showDuplicatesWarning = false;

        if (
            data.first_name &&
            data.last_name &&
            data.date_of_birth &&
            !['verified', 'blocked', 'rejected'].includes(data?.kyc?.status)
        ) {
            try {
                showDuplicatesWarning = await duplicatesCouldExist(data.first_name, data.last_name, data.date_of_birth);
            } catch (e) {
                console.log(e);
            }
        }

        updateState({
            ...state,
            ...data,
            adminUserEmails,
            referralSummary,
            countriesList,
            transactionsDataSummary,
            referredUsers,
            loading: false,
            showDuplicatesWarning,
            date_of_birth: formatDate(data.date_of_birth, false),
        });
        return data;
    } catch (e) {
        history.push('/');
    }
};

const Users = ({ logOut, memberships }) => {
    const queryParams = new URLSearchParams(window.location.search);
    const [state, updateState] = useState({
        ...defaultState,
        activeTab: queryParams.get('activeTab') || 'personal',
        activeTabGroup: queryParams.get('activeTabGroup') || 'personal',
    });
    const [showMenu, toggleMenu] = useState(true);
    const [subMenu, toggleSubMenu] = useState({ show: false, type: null });
    const { id } = useParams();
    const history = useHistory();

    useEffect(() => {
        (async () => {
            await fetchUserData(id, state, updateState, history);
        })();
    }, []);
    return (
        <Flex
            sx={{
                overflow: 'hidden',
                top: '0',
                left: '0',
                bottom: 0,
                minHeight: '100vh',
                width: '100vw',
                flexDirection: 'row',
                backgroundColor: 'greyBackground',
                margin: 0,
                padding: 0,
                position: 'relative',
            }}
        >
            {subMenu?.show && (
                <Box
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0,0,0,0.4)',
                        top: 0,
                        left: 0,
                        zIndex: 9999,
                    }}
                />
            )}
            <Sidebar
                logout={logOut}
                currentPage="Users"
                showMenu={showMenu}
                toggleMenu={toggleMenu}
                history={history}
                memberships={memberships}
            />

            <Flex
                sx={{
                    border: '0px red solid',
                    width: '90%',
                    maxWidth: '900px',
                    minHeight: '400px',
                    flexDirection: 'column',
                    mt: '20px',
                    mb: '100px',
                    ml: showMenu ? 240 : 45,
                }}
            >
                {state.showOverlay && (
                    <Overlay
                        size="large"
                        closeOverlay={() => updateState({ ...state, showOverlay: false })}
                        overlayContent={<KycChecks userId={state.user_id} />}
                    />
                )}

                {state.loading && <Spinner />}

                {state.showConfirmDialog && (
                    <Overlay
                        size="small"
                        closeOverlay={() =>
                            updateState({
                                ...state,
                                showConfirmDialog: false,
                                confirmDialogText: null,
                                confirmHandler: null,
                            })
                        }
                        overlayContent={
                            <Confirm text={state.confirmDialogText} confirmHandler={state.confirmHandler} />
                        }
                    />
                )}

                {state.showProofOfAddressOverlay && (
                    <Overlay
                        closeOverlay={() => updateState({ ...state, showProofOfAddressOverlay: false })}
                        overlayContent={<ProofOfAddressUploads userId={state.user_id} />}
                    />
                )}

                {state.showMonitoringAlertsOverlay && (
                    <Overlay
                        size="large"
                        closeOverlay={() => {
                            window.location.assign(
                                `/user/${state.user_id}?activeTab=monitoringAlerts&activeGroup=admin`
                            );
                        }}
                        overlayContent={
                            <MonitoringAlertContent userId={state.user_id} monitoringAlert={state.monitoringAlert} />
                        }
                    />
                )}

                {state.showKycEnableOverlay && (
                    <Overlay
                        closeOverlay={() => updateState({ ...state, showKycEnableOverlay: false })}
                        overlayContent={
                            <VerifyKyc
                                closeOverlay={() => {
                                    updateState({ ...state, showKycEnableOverlay: false });
                                    fetchUserData(id, { ...state, showKycEnableOverlay: false }, updateState, history);
                                }}
                                status={state?.kyc?.status}
                                userId={state.user_id}
                            />
                        }
                    />
                )}
                {state.showWithdrawal && (
                    <Overlay
                        closeOverlay={() => updateState({ ...state, showWithdrawal: false })}
                        overlayContent={
                            <WithdrawalLimits
                                closeOverlay={() => {
                                    updateState({ ...state, showWithdrawal: false });
                                    fetchUserData(id, { ...state, showWithdrawal: false }, updateState, history);
                                }}
                                state={state}
                                userId={state.user_id}
                            />
                        }
                    />
                )}

                {state.showChangeRiskScore && (
                    <Overlay
                        closeOverlay={() => updateState({ ...state, showChangeRiskScore: false })}
                        overlayContent={<ChangeRiskScore riskScore={state.risk_score} userId={state.user_id} />}
                    />
                )}

                {state.showUpdateOverlay && (
                    <Overlay
                        closeOverlay={() => updateState({ ...state, showUpdateOverlay: false, updateOverlayType: '' })}
                        overlayContent={<UpdateUserContent type={state.updateOverlayType} state={state} />}
                    />
                )}

                {state.showChangeEmail && (
                    <Overlay
                        closeOverlay={() => updateState({ ...state, showChangeEmail: false })}
                        overlayContent={<ChangeEmail userId={state.user_id} />}
                        size="small"
                    />
                )}

                {state.showDocumentUpload && (
                    <Overlay
                        closeOverlay={() => updateState({ ...state, showDocumentUpload: false })}
                        overlayContent={
                            <UploadUserDocument
                                state={state}
                                userId={state.user_id}
                                viewUploads={() =>
                                    updateState({ ...state, showUploadedDocuments: true, showDocumentUpload: false })
                                }
                                close={() => updateState({ ...state, showDocumentUpload: false })}
                            />
                        }
                    />
                )}

                {state.showUploadedDocuments && (
                    <Overlay
                        size="large"
                        closeOverlay={() => updateState({ ...state, showUploadedDocuments: false })}
                        overlayContent={
                            <AdminDocumentUploads
                                openUploadDocumentOverlay={() =>
                                    updateState({ ...state, showUploadedDocuments: false, showDocumentUpload: true })
                                }
                                state={state}
                                userId={state.user_id}
                            />
                        }
                    />
                )}

                {state.error && (
                    <Overlay
                        size="small"
                        closeOverlay={() => updateState({ ...state, error: null })}
                        overlayContent={
                            <Paragraph
                                sx={{ ml: 20, mr: 20, color: 'red', mt: 130, alignSelf: 'center', textAlign: 'center' }}
                            >
                                {state.error}
                            </Paragraph>
                        }
                    />
                )}

                {state.showReferralConfigOverlay && (
                    <Overlay
                        closeOverlay={() => updateState({ ...state, showReferralConfigOverlay: false })}
                        overlayContent={<ReferralConfig state={state} userId={state.user_id} />}
                    />
                )}

                {state.showCreditAccountDialog && (
                    <Overlay
                        closeOverlay={() => updateState({ ...state, showCreditAccountDialog: false })}
                        overlayContent={<CreditAccount state={state} userId={state.user_id} />}
                    />
                )}

                {state.showManualInvestDialog && (
                    <Overlay
                        closeOverlay={() => updateState({ ...state, showManualInvestDialog: false })}
                        overlayContent={<ManuallyInvest state={state} userId={state.user_id} />}
                    />
                )}
                {state.showEmailTemplates && (
                    <Overlay
                        closeOverlay={() => updateState({ ...state, showEmailTemplates: false })}
                        overlayContent={
                            <EmailSelector
                                userId={state.user_id}
                                closeOverlay={() => updateState({ ...state, showEmailTemplates: false })}
                            />
                        }
                    />
                )}
                {state.showLockins && (
                    <Overlay
                        closeOverlay={() => updateState({ ...state, showLockins: false })}
                        overlayContent={
                            <LockWindows
                                investmentWindows={
                                    state.transactionsDataSummary.account_summary.locked_investment_windows
                                }
                            />
                        }
                    />
                )}
                {state.showInteroTransferDialog && (
                    <Overlay
                        closeOverlay={() => updateState({ ...state, showInteroTransferDialog: false })}
                        overlayContent={<InteroTransfer state={state} userId={state.user_id} />}
                    />
                )}

                <Flex sx={{ flexDirection: 'column' }}>
                    <Flex>
                        <Flex sx={{ mt: '4px', cursor: 'pointer' }} onClick={() => history.push('/users')}>
                            <i sx={{ marginRight: '10px' }} className="fas fa-arrow-left" />
                        </Flex>
                        <Paragraph sx={{ mb: '35px', fontWeight: '600', fontSize: '20px', ml: '15px' }}>
                            {state.first_name} {state.last_name}{' '}
                            {state.email_address?.includes('_deleted_') ? ' - DELETED' : ''}
                            {state.showDuplicatesWarning ? (
                                <span
                                    style={{
                                        color: 'red',
                                        fontSize: '14px',
                                        marginLeft: '15px',
                                        fontWeight: '600',
                                        pb: '50px',
                                    }}
                                >
                                    <i className="far fa-bell" /> Potential Duplicate <i className="far fa-bell" />
                                </span>
                            ) : null}
                        </Paragraph>
                    </Flex>
                    <Flex
                        sx={{
                            position: 'relative',
                        }}
                    >
                        <TabGroup
                            state={state}
                            updateState={updateState}
                            subMenu={subMenu}
                            toggleSubMenu={toggleSubMenu}
                            memberships={memberships}
                            tabs={[
                                {
                                    id: 'personal',
                                    label: 'Personal',
                                    icon: 'far fa-users',
                                },
                                {
                                    id: 'currency',
                                    label: 'Currency',
                                    icon: 'far fa-credit-card',
                                },
                                {
                                    id: 'admin',
                                    label: 'Admin',
                                    icon: 'far fa-cog',
                                },
                            ]}
                        />
                    </Flex>
                    <Box sx={{ width: '100%', height: '30px', backgroundColor: 'white' }} />
                    {state.activeTab === 'personal' && (
                        <Flex
                            sx={{
                                width: '100%',
                                maxWidth: '900px',
                                minHeight: '450px',
                                backgroundColor: 'white',
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                                borderBottomLeftRadius: 10,
                                borderBottomRightRadius: 10,
                                flexDirection: 'row',
                            }}
                        >
                            <Flex
                                sx={{
                                    flexDirection: 'column',
                                    flex: 1,
                                    justifyContent: 'space-between',
                                    ml: '20px',
                                    zIndex: 0,
                                }}
                            >
                                <Box sx={{ width: '100%', marginLeft: '15px', mb: '55px' }}>
                                    <Title
                                        title="KYC Tools"
                                        state={state}
                                        updateState={updateState}
                                        showEditIcon={false}
                                    />

                                    <Link
                                        sx={{}}
                                        target="_blank"
                                        href={`https://dashboard.onfido.com/applicant/${state?.kyc?.applicant_id}`}
                                    >
                                        <Button sx={buttonStyle}>Onfido Profile</Button>
                                    </Link>
                                    <Link
                                        sx={{}}
                                        target="_blank"
                                        href={`https://app.complyadvantage.com/#/case-management?client_ref=${state.user_id}&page=1&perPage=10&sort_by=created_at&sort_order=DESC`}
                                    >
                                        <Button sx={buttonStyle}>Comply Advantage</Button>
                                    </Link>
                                    <Button
                                        onClick={() => updateState({ ...state, showChangeRiskScore: true })}
                                        sx={buttonStyle}
                                    >
                                        Change Risk Score
                                    </Button>
                                    <Button
                                        onClick={() => updateState({ ...state, showProofOfAddressOverlay: true })}
                                        sx={buttonStyle}
                                    >
                                        Proof of Address
                                    </Button>
                                    <Button
                                        onClick={() => updateState({ ...state, showUploadedDocuments: true })}
                                        sx={buttonStyle}
                                    >
                                        Uploaded Documents
                                    </Button>
                                    <Button
                                        onClick={() => updateState({ ...state, showDocumentUpload: true })}
                                        sx={buttonStyle}
                                    >
                                        Upload User Document
                                    </Button>
                                    <Button
                                        onClick={() => updateState({ ...state, showKycEnableOverlay: true })}
                                        sx={buttonStyle}
                                    >
                                        Update KYC Status
                                    </Button>
                                    <Button
                                        onClick={() => updateState({ ...state, showWithdrawal: true })}
                                        sx={buttonStyle}
                                    >
                                        Withdrawal Limits
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            updateState({
                                                ...state,
                                                showOverlay: true,
                                                overlayContentType: 'kyc_checks',
                                            })
                                        }
                                        sx={buttonStyle}
                                    >
                                        View Onfido checks
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            updateState({
                                                ...state,
                                                showConfirmDialog: true,
                                                confirmDialogText: 'Are you sure you wish to create a check in Onfido?',
                                                confirmHandler: async () => {
                                                    await forceKycCheck(state.user_id, updateState, state);
                                                    updateState({
                                                        ...state,
                                                        showConfirmDialog: false,
                                                        confirmDialogText: null,
                                                        confirmHandler: null,
                                                    });
                                                },
                                            })
                                        }
                                        sx={buttonStyle}
                                    >
                                        Force Onfido Check
                                    </Button>
                                </Box>

                                <BasicDetails state={state} updateState={updateState} />

                                <ContactDetails state={state} updateState={updateState} />

                                <KYCDetails state={state} updateState={updateState} />

                                <Box sx={{ width: '100%', marginLeft: '15px', mb: 40 }}>
                                    <Title
                                        title="Crypto Addresses"
                                        state={state}
                                        updateState={updateState}
                                        showEditIcon={false}
                                    />
                                    <Flex
                                        sx={{
                                            mt: '50px',
                                            mb: '10px',
                                            width: '500px',
                                            justifyContent: 'flex-start',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <DataItem
                                            title="ERC20"
                                            value={`${
                                                state.transactionsDataSummary?.addresses?.find(
                                                    (item) => item.type === 'ERC20'
                                                )?.address || 'Not Setup'
                                            }`}
                                            smallWidth
                                        />
                                        <DataItem
                                            title="BTC"
                                            value={`${
                                                state.transactionsDataSummary?.addresses?.find(
                                                    (item) => item.type === 'BTC'
                                                )?.address || 'Not Setup'
                                            }`}
                                            smallWidth
                                        />
                                    </Flex>
                                </Box>

                                <Balances state={state} updateState={updateState} />

                                <Box sx={{ width: '100%', marginLeft: '15px', mb: '60px' }}>
                                    <Title title="Additional Tools" showEditIcon={false} />
                                    <Flex sx={{ flexWrap: 'wrap', mt: '10px', ml: '20px' }}>
                                        <Button
                                            onClick={() =>
                                                updateState({
                                                    ...state,
                                                    showReferralConfigOverlay: true,
                                                })
                                            }
                                            sx={buttonStyle}
                                        >
                                            Referral Setup
                                        </Button>

                                        <Button
                                            onClick={() => updateState({ ...state, showChangeEmail: true })}
                                            sx={buttonStyle}
                                        >
                                            Change Email
                                        </Button>
                                        <Button
                                            onClick={() => downloadTransactions(state, updateState)}
                                            sx={buttonStyle}
                                        >
                                            Download transactions
                                        </Button>
                                        <Button
                                            onClick={() => downloadKoinlyTransactions(state, updateState)}
                                            sx={buttonStyle}
                                        >
                                            Koinly transactions
                                        </Button>
                                        <Button
                                            onClick={() => updateState({ ...state, showCreditAccountDialog: true })}
                                            sx={buttonStyle}
                                        >
                                            Credit Account
                                        </Button>
                                        {memberships?.includes('super_user') && (
                                            <Button
                                                onClick={() => updateState({ ...state, showManualInvestDialog: true })}
                                                sx={buttonStyle}
                                            >
                                                Manually Invest
                                            </Button>
                                        )}

                                        <Button
                                            onClick={() =>
                                                updateState({
                                                    ...state,
                                                    showConfirmDialog: true,
                                                    confirmDialogText:
                                                        'Are you sure you wish to disable MFA for this user?',
                                                    confirmHandler: async () => {
                                                        await disableMfa(state.user_id, updateState, state);
                                                        updateState({
                                                            ...state,
                                                            showConfirmDialog: false,
                                                            confirmDialogText: null,
                                                            confirmHandler: null,
                                                        });
                                                    },
                                                })
                                            }
                                            sx={buttonStyle}
                                        >
                                            Disable MFA
                                        </Button>

                                        <Button
                                            sx={buttonStyle}
                                            onClick={() => updateState({ ...state, showEmailTemplates: true })}
                                        >
                                            Send Email
                                        </Button>
                                        <Button
                                            sx={buttonStyle}
                                            onClick={() => updateState({ ...state, showLockins: true })}
                                        >
                                            View Lock-ins
                                        </Button>

                                        {!state.email_address?.includes('_deleted_') &&
                                            memberships?.includes('super_user') && (
                                                <Button
                                                    onClick={() =>
                                                        updateState({
                                                            ...state,
                                                            showConfirmDialog: true,
                                                            confirmDialogText:
                                                                'Are you sure you wish to delete this user? There is no going back.',
                                                            confirmHandler: () => deleteUser(state, updateState),
                                                        })
                                                    }
                                                    sx={buttonStyle}
                                                >
                                                    Delete User
                                                </Button>
                                            )}
                                        <Button
                                            sx={buttonStyle}
                                            onClick={() => updateState({ ...state, showInteroTransferDialog: true })}
                                        >
                                            Intero Transfer
                                        </Button>
                                    </Flex>
                                </Box>
                            </Flex>
                        </Flex>
                    )}

                    {state.activeTab === 'graph' && (
                        <PortfolioFigures id={id} preferredCurrency={labelMapping(state.currency) || 'EUR'} />
                    )}

                    {state.activeTab === 'userPermissions' && state?.user_id && typeof state?.enabled === 'boolean' && (
                        <UserPermissions expanded user={state} />
                    )}

                    {state.user_id && (
                        <>
                            {state.activeTab === 'changes' && (
                                <TransactionsList expanded title="Comments" type="changes" userId={state.user_id} />
                            )}

                            {state.activeTab === 'referredUsers' && (
                                <Flex sx={{ flexDirection: 'column', backgroundColor: 'white', pb: '50px' }}>
                                    <Flex sx={{ padding: '0 20px' }}>
                                        <ReferralForm
                                            joinedByReferral={state?.referralSummary?.joined_via_referral}
                                            state={state}
                                            updateState={updateState}
                                        />
                                    </Flex>

                                    {state?.referredUsers.length > 0 && (
                                        <TransactionsList
                                            expanded={state.activeTab === 'referredUsers'}
                                            title="Referred Users"
                                            type="referredUsers"
                                            list={state.referredUsers || []}
                                            openUserOnViewMore
                                        />
                                    )}
                                </Flex>
                            )}

                            {state.activeTab === 'monitoringAlerts' && memberships?.includes('compliance') && (
                                <TransactionsList
                                    expanded
                                    disableAbilityToExpand
                                    currentState={state}
                                    updateState={updateState}
                                    title="Monitoring Alerts"
                                    type="monitoring"
                                    userId={state.user_id}
                                />
                            )}

                            {state.activeTab === 'comms' && <CommsTab state={state} updateState={updateState} />}

                            {state.activeTab === 'trades' && (
                                <TransactionsList
                                    expanded
                                    showDatePickers
                                    title="Trades"
                                    type="trades"
                                    userId={state.user_id}
                                />
                            )}

                            {state.activeTab === 'deposits' && (
                                <Flex sx={{ flexDirection: 'column', backgroundColor: '#FFF' }}>
                                    {state.regular_payment_config && (
                                        <>
                                            <Paragraph sx={{ ml: 40, fontSize: 20, mt: 0, mb: 20, fontWeight: '800' }}>
                                                Regular Payments
                                            </Paragraph>

                                            <Flex sx={{ flexDirection: 'column', maxWidth: 400, ml: 40, mb: 20 }}>
                                                <DataItem
                                                    title="Day Of Month"
                                                    value={moment
                                                        .utc('2022-01-01')
                                                        .set('dayOfYear', state.regular_payment_config?.day_of_month)
                                                        .format('Do')}
                                                />
                                                <DataItem title="Amount" value={state.regular_payment_config?.amount} />
                                                <DataItem
                                                    title="Currency"
                                                    value={labelMapping(state.regular_payment_config?.currency)}
                                                />
                                                <DataItem
                                                    title="Product"
                                                    value={labelMapping(state.regular_payment_config?.product)}
                                                />
                                            </Flex>
                                        </>
                                    )}

                                    <Paragraph sx={{ ml: 40, fontSize: 20, mt: 0, mb: 20, fontWeight: '800' }}>
                                        OpenPayd
                                    </Paragraph>

                                    <Flex sx={{ flexDirection: 'column', maxWidth: 400, ml: 40 }}>
                                        <DataItem title="Linked client id" value={state.open_payd?.linked_client_id} />
                                        <DataItem title="GBP account id" value={state.open_payd?.gbp_id} />
                                        <DataItem title="EUR account id" value={state.open_payd?.eur_id} />
                                    </Flex>

                                    <Paragraph
                                        sx={{ ml: 40, mt: 20, mb: -30, zIndex: 10, fontSize: 20, fontWeight: '800' }}
                                    >
                                        Deposits
                                    </Paragraph>

                                    <TransactionsList
                                        expanded
                                        disableAbilityToExpand
                                        showDatePickers
                                        title="Deposits"
                                        type="deposits"
                                        userId={state.user_id}
                                    />
                                </Flex>
                            )}

                            {state.activeTab === 'depositChecks' && (
                                <TransactionsList
                                    openUserOnViewMore
                                    enableDrilldownIntoUser
                                    expanded
                                    hideExpandChevron
                                    title="Deposits To Approve"
                                    type="deposits_to_approve"
                                    userId={state.user_id}
                                    embeddedIn="userDetail"
                                />
                            )}

                            {state.activeTab === 'withdrawals' && (
                                <TransactionsList
                                    expanded
                                    showDatePickers
                                    title="Withdrawals"
                                    type="withdrawals"
                                    userId={state.user_id}
                                />
                            )}

                            {state.activeTab === 'exchange' && (
                                <TransactionsList
                                    hideExpandChevron
                                    expanded
                                    title="Exchange"
                                    type="swaps"
                                    userId={state.user_id}
                                />
                            )}

                            {state.activeTab === 'mixed_fund_trades' && (
                                <TransactionsList
                                    hideExpandChevron
                                    expanded
                                    title="Investment Trades"
                                    type="mixed_fund_trades"
                                    userId={state.user_id}
                                />
                            )}

                            {state.activeTab === 'rebalancing_trades' && (
                                <TransactionsList
                                    hideExpandChevron
                                    expanded
                                    title="Rebalancing Trades"
                                    type="rebalancing_trades"
                                    userId={state.user_id}
                                />
                            )}

                            {state.activeTab === 'userActivity' && (
                                <TransactionsList
                                    expanded
                                    showDatePickers
                                    title="Activity"
                                    type="userActivity"
                                    userId={state.user_id}
                                />
                            )}
                        </>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};

const mapDispatchToProps = (dispatch) => ({
    logOut: () => dispatch({ type: 'LOG_USER_OUT' }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return { loggedIn: account.loggedIn, memberships: account.memberships };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
