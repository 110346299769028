/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import axios from 'axios';

export default async (currentState) => {
    const { userId, offset, limit } = currentState;

    try {
        const { data } = await axios({
            url: process.env.REACT_APP_TRANSACTIONS_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
            data: {
                query: `query {
            getTransactionsHistoryAsAdmin(limit: ${limit}, offset: ${offset}, user_id: "${userId}") { 
                success
                paging {
                    total_pages
                    current_page
                }
                transactions {
                    status
                    amount
                    amount_from
                    amount_to
                    currency_from
                    currency_to
                    currency_pair
                    product
                    product_label
                    fee
                    exchange_rate
                    type
                    created_at
                    trade_type
                    type_label
                    currency
                    currency_label
                    img
                    label
                }
            }
        }`,
                variables: null,
            },
        });
        if (!data.data.getTransactionsHistoryAsAdmin.success) {
            throw new Error('failed');
        }

        const { transactions, paging } = data.data.getTransactionsHistoryAsAdmin;
        const pendingWithdrawals = [];
        const otherTransactions = transactions.filter((tx) => {
            if (tx.type === 'withdrawal' && tx.status === 'ACTIONED') {
                pendingWithdrawals.push(tx);
                return false;
            }
            return true;
        });
        return { transactions: { transactions, otherTransactions, pendingWithdrawals }, paging };
    } catch (e) {
        console.log('transaction err; ', e);
    }
};
