/* eslint-disable no-param-reassign */
import * as axios from 'axios';
import * as moment from 'moment';
import { loadCountsForKyc } from './kyc-stats';

const getAum = async () => {
    const { data } = await axios({
        url: process.env.REACT_APP_ADMIN_DASHBOARD_API,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'POST',
        data: {
            query: `query { 
                aum {
                    usd
                }
            }`,
            variables: null,
        },
    });

    return data.data.aum.usd;
};

const getTotalUsers = async () => {
    const { data } = await axios({
        url: process.env.REACT_APP_ADMIN_SEARCH_API,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
        },
        params: {
            api: '_count',
        },
        method: 'POST',
        data: {},
    });

    return data?.count;
};

const getCountFromEs = async (fieldName, fieldValue) => {
    const { data } = await axios({
        url: process.env.REACT_APP_ADMIN_SEARCH_API,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
        },
        method: 'POST',
        data: {
            query: { term: { [fieldName]: { value: fieldValue } } },
            size: 0,
            track_total_hits: true,
        },
    });

    return data?.total?.value;
};

const getTotalRegistrationsToday = async () => {
    const { data } = await axios({
        url: process.env.REACT_APP_ADMIN_SEARCH_API,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
        },
        method: 'POST',
        data: {
            query: {
                bool: {
                    must: [
                        {
                            range: {
                                CreatedAt: {
                                    gte: moment.utc().startOf('day').format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]'),
                                    lte: moment.utc().endOf('day').format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]'),
                                },
                            },
                        },
                    ],
                },
            },
            size: 0,
            track_total_hits: true,
        },
    });

    return data?.total?.value;
};

const getDataForComplexBarChart = async (type) => {
    const from = moment.utc().subtract(30, 'days').format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]');
    const to = moment.utc().format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]');

    const { data } = await axios({
        url: process.env.REACT_APP_ADMIN_DASHBOARD_API,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'POST',
        data: {
            query: `query { 
                getAmounts(type: "${type}", from: "${from}", to: "${to}", pagination_token: "", limit: 20) { 
                    success
                    amounts {
                        type
                        timestamp
                        amounts {
                            type
                            amount
                        }
                    }
                } 
            }`,
            variables: null,
        },
    });

    return data.data.getAmounts.amounts.reverse();
};

const getDataForSimpleBarChart = async (type) => {
    const from = moment.utc().subtract(30, 'days').format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]');
    const to = moment.utc().format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]');

    const { data } = await axios({
        url: process.env.REACT_APP_ADMIN_DASHBOARD_API,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'POST',
        data: {
            query: `query { 
                getAmounts(type: "${type}", from: "${from}", to: "${to}", pagination_token: "", limit: 20) { 
                    success
                    amounts {
                        type
                        timestamp
                        amount
                    }
                } 
            }`,
            variables: null,
        },
    });

    return data.data.getAmounts.amounts;
};

export const formatXAxis = (tickItem) => moment(tickItem).format('MMM Do');

export const loadFigures = async (currentState, updateState) => {
    updateState({ ...currentState, loading: true });

    try {
        const { data } = await axios({
            url: process.env.REACT_APP_ADMIN_DASHBOARD_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            data: {
                query: `query { 
                getDashboardFigures { 
                    success
                    totals {
                        type
                        title
                        format_as_int
                        amounts {
                            amount
                            group_by_column
                        }
                    }
                } 
            }`,
                variables: null,
            },
        });

        if (!data.data.getDashboardFigures.success) {
            return updateState({
                ...currentState,
                loading: false,
                error: 'Uh oh, there has been an error loading the figures',
            });
        }

        const [
            registrations,
            dailyRegistrations,
            uninvestedBalances,
            investedBalances,
            depositedAmounts,
            verifiedUsers,
            aum,
            registrationsToday,
        ] = await Promise.all([
            getDataForSimpleBarChart('TOTAL_REGISTRATIONS'),
            getDataForSimpleBarChart('DAILY_REGISTRATIONS'),
            getDataForComplexBarChart('UNINVESTED_BALANCES'),
            getDataForComplexBarChart('INVESTED_BALANCES'),
            getDataForComplexBarChart('DEPOSITED_AMOUNTS'),
            getCountFromEs('KycStatus', 'verified'),
            getAum(),
            getTotalRegistrationsToday(),
        ]);

        const singleValueTotals = data.data.getDashboardFigures.totals.filter((total) => total.amounts.length <= 1);

        singleValueTotals.push({
            title: 'Verified Users',
            format_as_int: true,
            amounts: [
                {
                    amount: verifiedUsers,
                },
            ],
        });

        singleValueTotals.push({
            title: 'Total Registrations',
            format_as_int: true,
            amounts: [
                {
                    amount: await getTotalUsers(),
                },
            ],
        });
        singleValueTotals.push({
            title: 'Registrations Today',
            format_as_int: true,
            amounts: [
                {
                    amount: registrationsToday,
                },
            ],
        });

        singleValueTotals.push({
            title: 'AUM ($)',
            format_as_int: true,
            amounts: [
                {
                    amount: aum,
                },
            ],
        });

        updateState({
            ...currentState,
            loading: false,
            verifiedUsers,
            registrations: registrations.reverse(),
            dailyRegistrations: dailyRegistrations.reverse(),
            uninvestedBalances: uninvestedBalances.map((item) => ({
                timestamp: item.timestamp,
                ...item.amounts.reduce((accum, it) => {
                    accum[it.type] = parseFloat(it.amount);
                    return accum;
                }, {}),
            })),
            investedBalances: investedBalances.map((item) => ({
                timestamp: item.timestamp,
                ...item.amounts.reduce((accum, it) => {
                    accum[it.type] = parseFloat(it.amount);
                    return accum;
                }, {}),
            })),
            depositedAmounts: depositedAmounts.map((item) => ({
                timestamp: item.timestamp,
                ...item.amounts.reduce((accum, it) => {
                    accum[it.type] = parseFloat(it.amount);
                    return accum;
                }, {}),
            })),
            singleValueTotals,
            multiValueTotals: data.data.getDashboardFigures.totals.filter((total) => total.amounts.length > 1),
            kycStats: await loadCountsForKyc(),
        });
    } catch (e) {
        updateState({ ...currentState, loading: false, error: 'Uh oh, there has been an error loading the figures' });
    }
};
