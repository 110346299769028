/* eslint-disable no-lonely-if */
/* eslint-disable no-alert */
import { Flex, Paragraph, Box, Button } from 'theme-ui';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';
import { DisplayItem as Item, Title } from '../components/table';
import { getTransactionDataSummary, getCorporateDetails } from '../utils/get-user-details';
import TransactionsList from '../components/transactions-list';
import Overlay from '../components/overlay';
import KycChecks from '../components/kyc-checks';
import VerifyKyc from '../components/verify-kyc';
import labelMapping from '../utils/label-mapping';
import UpdateCorporateContent from '../components/corporate-update/update';
import PortfolioFigures from '../components/portfolio-figures';
import UploadUserDocument from '../components/upload-user-document';
import MonitoringAlertContent from '../components/monitoring-alerts/popup-content';
import AdminDocumentUploads from '../components/admin-doc-uploads';
import Balances from '../components/balances';
import CreditAccount from '../components/credit-account';
import ManuallyInvest from '../components/manually-invest';
import AddUser from '../components/corporate-update/add-user';
import { validateData } from '../components/corporate-update/helper';
import TabGroup from '../components/detail-views/tab-group-combined';
import SimpleAlert from '../components/simple-alert';
import ApiConfig from '../components/api-config';
import DataItem from '../components/user-data-item';
import CorporateDetails from '../components/corporate-details';
import CorporateAdditionalDetails from '../components/corporate-additional-details';
import buttonStyle from '../utils/button-style';
import downloadTransactions, { downloadKoinlyTransactions } from '../utils/download-transactions';

const formatAddress = (address, countriesList) => {
    let addressText = '';

    addressText += address.street ? `${address.street}` : '';
    addressText += address.sub_street ? `, ${address.sub_street}` : '';
    addressText += address.town ? `, ${address.town}` : '';
    addressText += address.post_code ? `, ${address.post_code}` : '';
    addressText += address.state ? `, ${address.state}` : '';

    if (address.country) {
        const countryName = countriesList.find((item) => item.code === address.country)?.name;
        addressText += `, ${countryName}`;
    }

    return addressText;
};

const defaultState = {
    corporate_id: '',
    company_name: '',
    company_type: '',
    registration_number: '',
    website: '',
    contact_email: '',
    contact_telephone_number: '',
    contact_person: '',
    currency: '',
    next_kyc_refresh_date: '',
    industry: '',
    expected_amount_to_invest: '',
    licence_details: '',
    licence_details_validity_date: '',
    source_of_funds: '',
    risk_score: '',
    correspondence_address: {},
    address: {},
    loading: true,
    showOverlay: false,
    overlayContentType: null,
    showUpdateOverlay: false,
    updateOverlayType: '',
    showMonitoringAlertsOverlay: false,
    showDocumentUpload: false,
    showUploadedDocuments: false,
    showAddUserOverlay: false,
    monitoringAlert: null,
    showAddUserError: false,
    activeTab: 'corporateDetail',
    activeTabGroup: 'corporate',
};

const fetchUserData = async (id, state, updateState, history) => {
    try {
        await Auth.currentAuthenticatedUser();
        updateState({ ...state, loading: true });

        const [corporateDetails, transactionsDataSummary, { data: countriesList }] = await Promise.all([
            getCorporateDetails(id),
            getTransactionDataSummary(id),
            axios({
                url: `https://cdn.accru.finance/countries/codes.json?cache=${new Date().getTime()}`,
            }),
        ]);
        updateState({
            ...state,
            countriesList,
            transactionsDataSummary,
            ...corporateDetails,
            loading: false,
        });
    } catch (e) {
        history.push('/');
    }
};

const resend = async (item, currentState, updateState) => {
    try {
        updateState({ ...currentState, loading: true });

        const { data } = await axios({
            url: process.env.REACT_APP_CORPORATES_API,
            method: 'POST',
            data: {
                query: `mutation {
                    assignUserToCorporate(input: {
                        corporate_id: "${item.corporate_id}",
                        first_name: "foo",
                        last_name: "foo",
                        email: "${item.email_address}",
                        resend: true                    
                    }) { 
                        success 
                        message
                    }
                }`,
            },
        });
        const result = data.data?.assignUserToCorporate;

        if (!result.success) {
            return updateState({
                ...currentState,
                alertConfig: {
                    success: false,
                    text: result.message,
                },
                loading: false,
            });
        }

        updateState({
            ...currentState,
            loading: false,
            alertConfig: {
                success: true,
                text: 'Successfully resent',
            },
        });
    } catch (e) {
        updateState({
            ...currentState,
            alertConfig: {
                success: false,
                text: 'Uh oh, time to contact support or try again',
            },
            loading: false,
        });
    }
};

const areAllCorporateDetailsAdded = (state) => validateData(state, 'all', () => {});

const CorporateUser = ({ logOut, memberships }) => {
    const queryParams = new URLSearchParams(window.location.search);
    const [state, updateState] = useState({
        ...defaultState,
        activeTab: queryParams.get('activeTab') || 'corporateDetail',
        activeTabGroup: queryParams.get('activeTabGroup') || 'personal',
    });

    const [showMenu, toggleMenu] = useState(true);
    const [subMenu, toggleSubMenu] = useState({ show: false, type: null });

    const { id } = useParams();
    const history = useHistory();

    useEffect(() => {
        (async () => {
            await fetchUserData(id, state, updateState, history);
        })();
    }, []);

    return (
        <Flex
            sx={{
                overflow: 'hidden',
                top: '0',
                left: '0',
                bottom: 0,
                minHeight: '100vh',
                width: '100vw',
                flexDirection: 'row',
                backgroundColor: 'greyBackground',
                margin: 0,
                padding: 0,
                position: 'relative',
            }}
        >
            {subMenu?.show && (
                <Box
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0,0,0,0.4)',
                        top: 0,
                        left: 0,
                        zIndex: 9999,
                    }}
                />
            )}
            <Sidebar
                logout={logOut}
                currentPage="Corporates"
                showMenu={showMenu}
                toggleMenu={toggleMenu}
                history={history}
                memberships={memberships}
            />

            <Flex
                sx={{
                    border: '0px red solid',
                    width: '90%',
                    maxWidth: '900px',
                    minHeight: '400px',
                    flexDirection: 'column',
                    mt: '20px',
                    mb: '100px',
                    ml: showMenu ? 240 : 45,
                }}
            >
                {state.loading && <Spinner />}

                {state.showOverlay && (
                    <Overlay
                        closeOverlay={() => updateState({ ...state, showOverlay: false })}
                        overlayContent={<KycChecks userId={state.corporate_id} />}
                    />
                )}

                {state.alertConfig && (
                    <Overlay
                        size="small"
                        closeOverlay={() => updateState({ ...state, alertConfig: null })}
                        overlayContent={<SimpleAlert {...state.alertConfig} />}
                    />
                )}

                {state.showManualInvestDialog && (
                    <Overlay
                        closeOverlay={() => updateState({ ...state, showManualInvestDialog: false })}
                        overlayContent={<ManuallyInvest state={state} userId={state.corporate_id} />}
                    />
                )}

                {state.showMonitoringAlertsOverlay && (
                    <Overlay
                        size="large"
                        closeOverlay={() => {
                            window.location.reload();
                        }}
                        overlayContent={
                            <MonitoringAlertContent
                                userId={state.corporate_id}
                                monitoringAlert={state.monitoringAlert}
                            />
                        }
                    />
                )}

                {state.showCreditAccountDialog && (
                    <Overlay
                        closeOverlay={() => updateState({ ...state, showCreditAccountDialog: false })}
                        overlayContent={<CreditAccount state={state} userId={state.corporate_id} />}
                    />
                )}

                {state.showKycEnableOverlay && (
                    <Overlay
                        closeOverlay={() => updateState({ ...state, showKycEnableOverlay: false })}
                        overlayContent={
                            <VerifyKyc
                                closeOverlay={() => {
                                    updateState({ ...state, showKycEnableOverlay: false });
                                    fetchUserData(id, { ...state, showKycEnableOverlay: false }, updateState, history);
                                }}
                                status={state?.kyc?.status}
                                userId={state.corporate_id}
                            />
                        }
                    />
                )}

                {state.showAddUserOverlay && (
                    <Overlay
                        closeOverlay={() => updateState({ ...state, showAddUserOverlay: false })}
                        overlayContent={
                            <AddUser
                                closeOverlay={() => {
                                    updateState({ ...state, showAddUserOverlay: false });
                                }}
                                corporateId={state.corporate_id}
                            />
                        }
                    />
                )}

                {state.showUpdateOverlay && (
                    <Overlay
                        closeOverlay={() => updateState({ ...state, showUpdateOverlay: false, updateOverlayType: '' })}
                        overlayContent={<UpdateCorporateContent type={state.updateOverlayType} state={state} />}
                    />
                )}

                {state.showDocumentUpload && (
                    <Overlay
                        closeOverlay={() => updateState({ ...state, showDocumentUpload: false })}
                        overlayContent={
                            <UploadUserDocument
                                state={state}
                                userId={state.corporate_id}
                                viewUploads={() =>
                                    updateState({ ...state, showUploadedDocuments: true, showDocumentUpload: false })
                                }
                                close={() => updateState({ ...state, showDocumentUpload: false })}
                            />
                        }
                    />
                )}

                {state.showUploadedDocuments && (
                    <Overlay
                        size="large"
                        closeOverlay={() => updateState({ ...state, showUploadedDocuments: false })}
                        overlayContent={
                            <AdminDocumentUploads
                                state={state}
                                userId={state.corporate_id}
                                openUploadDocumentOverlay={() =>
                                    updateState({ ...state, showUploadedDocuments: false, showDocumentUpload: true })
                                }
                            />
                        }
                    />
                )}

                <Flex sx={{ flexDirection: 'column' }}>
                    <Flex>
                        <Flex sx={{ mt: '4px', cursor: 'pointer' }} onClick={() => history.push('/corporates')}>
                            <i sx={{ marginRight: '10px' }} className="fas fa-arrow-left" />
                        </Flex>
                        <Paragraph sx={{ mb: '35px', fontWeight: '600', fontSize: '20px', ml: '15px' }}>
                            Corporate Record - {state.company_name}
                        </Paragraph>
                    </Flex>
                    <Flex
                        sx={{
                            position: 'relative',
                        }}
                    >
                        <TabGroup
                            state={state}
                            updateState={updateState}
                            subMenu={subMenu}
                            toggleSubMenu={toggleSubMenu}
                            memberships={memberships}
                            type="corporate"
                            tabs={[
                                {
                                    id: 'corporate',
                                    label: 'Company',
                                    icon: 'far fa-city',
                                },
                                {
                                    id: 'currency',
                                    label: 'Currency',
                                    icon: 'far fa-credit-card',
                                },
                                {
                                    id: 'admin',
                                    label: 'Admin',
                                    icon: 'far fa-cog',
                                },
                            ]}
                        />
                    </Flex>
                    {state.activeTab === 'graph' && (
                        <PortfolioFigures id={id} preferredCurrency={labelMapping(state.currency) || 'EUR'} />
                    )}
                    <Box sx={{ width: '100%', height: '30px', backgroundColor: 'white' }} />

                    {state.activeTab === 'corporateDetail' && (
                        <Flex
                            sx={{
                                width: '100%',
                                maxWidth: '900px',
                                minHeight: '450px',
                                backgroundColor: 'white',
                                borderRadius: '12px',
                                flexDirection: 'row',
                                borderTopRightRadius: 0,
                                borderTopLeftRadius: 0,
                            }}
                        >
                            <Flex
                                sx={{ flexDirection: 'column', flex: 1, justifyContent: 'space-between', ml: '20px' }}
                            >
                                <CorporateDetails state={state} updateState={updateState} />

                                <CorporateAdditionalDetails state={state} updateState={updateState} />

                                <Box sx={{ width: '100%', marginLeft: '15px', mb: '30px' }}>
                                    <Title
                                        title="Company Address"
                                        type="addressCompany"
                                        state={state}
                                        updateState={updateState}
                                    />
                                    <Flex sx={{ justifyContent: 'flex-start' }}>
                                        <Item
                                            required
                                            title=""
                                            sx={{ width: 100, mt: 0 }}
                                            valueSx={{ lineHeight: '25px', marginTop: '5px' }}
                                            value={`${
                                                state.address ? formatAddress(state.address, state.countriesList) : ''
                                            }`}
                                        />
                                    </Flex>
                                </Box>

                                <Box sx={{ width: '100%', marginLeft: '15px', mb: '30px' }}>
                                    <Title
                                        title="Correspondence Address"
                                        type="addressCorrespondence"
                                        state={state}
                                        updateState={updateState}
                                    />
                                    <Flex sx={{ justifyContent: 'flex-start' }}>
                                        <Item
                                            required
                                            title=""
                                            sx={{ width: 100, mt: 0 }}
                                            valueSx={{ lineHeight: '25px', marginTop: '5px' }}
                                            value={`${
                                                state.correspondence_address
                                                    ? formatAddress(state.correspondence_address, state.countriesList)
                                                    : ''
                                            }`}
                                        />
                                    </Flex>
                                </Box>

                                <Box sx={{ width: '100%', marginLeft: '15px', mb: 40 }}>
                                    <Title
                                        title="Crypto Addresses"
                                        state={state}
                                        updateState={updateState}
                                        showEditIcon={false}
                                    />
                                    <Flex
                                        sx={{
                                            mt: '50px',
                                            mb: '10px',
                                            width: '500px',
                                            justifyContent: 'flex-start',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <DataItem
                                            title="ERC20"
                                            value={`${
                                                state.transactionsDataSummary?.addresses?.find(
                                                    (item) => item.type === 'ERC20'
                                                )?.address || 'Not Setup'
                                            }`}
                                            smallWidth
                                        />
                                        <DataItem
                                            title="BTC"
                                            value={`${
                                                state.transactionsDataSummary?.addresses?.find(
                                                    (item) => item.type === 'BTC'
                                                )?.address || 'Not Setup'
                                            }`}
                                            smallWidth
                                        />
                                    </Flex>
                                </Box>

                                <Balances state={state} updateState={updateState} />

                                <Box sx={{ width: '100%', marginLeft: '15px', mb: '60px' }}>
                                    <Title title="Tools" showEditIcon={false} />
                                    <Flex sx={{ flexWrap: 'wrap', mt: '10px', ml: '20px' }}>
                                        <Button
                                            onClick={() => updateState({ ...state, showDocumentUpload: true })}
                                            sx={buttonStyle}
                                        >
                                            Upload Document
                                        </Button>
                                        <Button
                                            onClick={() => updateState({ ...state, showUploadedDocuments: true })}
                                            sx={buttonStyle}
                                        >
                                            Uploaded Documents
                                        </Button>
                                        <Button
                                            onClick={() => downloadTransactions({ ...state, user_id: id }, updateState)}
                                            sx={buttonStyle}
                                        >
                                            Download transactions
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                downloadKoinlyTransactions({ ...state, user_id: id }, updateState)
                                            }
                                            sx={buttonStyle}
                                        >
                                            Koinly transactions
                                        </Button>
                                        <Button
                                            onClick={() => updateState({ ...state, showKycEnableOverlay: true })}
                                            sx={buttonStyle}
                                        >
                                            Update KYC Status
                                        </Button>

                                        {memberships?.includes('super_user') && (
                                            <Button
                                                onClick={() => updateState({ ...state, showCreditAccountDialog: true })}
                                                sx={buttonStyle}
                                            >
                                                Credit Account
                                            </Button>
                                        )}

                                        {memberships?.includes('super_user') && (
                                            <Button
                                                onClick={() => updateState({ ...state, showManualInvestDialog: true })}
                                                sx={buttonStyle}
                                            >
                                                Manually Invest
                                            </Button>
                                        )}

                                        <Button
                                            onClick={() => {
                                                if (areAllCorporateDetailsAdded(state)) {
                                                    updateState({ ...state, showAddUserOverlay: true });
                                                } else {
                                                    if (!state.showAddUserError) {
                                                        updateState({ ...state, showAddUserError: true });
                                                        setTimeout(() => {
                                                            updateState({
                                                                ...state,
                                                                showAddUserError: false,
                                                            });
                                                        }, 5000);
                                                    }
                                                }
                                            }}
                                            sx={buttonStyle}
                                        >
                                            Add User
                                        </Button>
                                        {state.showAddUserError && (
                                            <Paragraph
                                                sx={{
                                                    lineHeight: '45px',
                                                    marginLeft: '15px',
                                                    mb: '30px',
                                                    color: 'red',
                                                }}
                                            >
                                                You must complete all of the required corporate data before you can add
                                                a user.
                                            </Paragraph>
                                        )}
                                    </Flex>
                                </Box>
                            </Flex>
                        </Flex>
                    )}

                    {state.activeTab === 'apiConfig' && <ApiConfig corporateId={state.corporate_id} />}

                    {state.corporate_id && (
                        <>
                            {state.activeTab === 'users' && (
                                <TransactionsList
                                    title="Users"
                                    expanded
                                    type="corporateUsers"
                                    userId={state.corporate_id}
                                    additionalLinkColumns={[
                                        {
                                            label: 'Resend invite',
                                            action: (item) => resend(item, state, updateState),
                                        },
                                        {
                                            label: 'View',
                                            action: (item) => window.location.assign(`/user/${item.corporate_id}`),
                                        },
                                    ]}
                                />
                            )}

                            {state.activeTab === 'changes' && (
                                <TransactionsList
                                    expanded
                                    title="Comments"
                                    type="changes"
                                    userId={state.corporate_id}
                                />
                            )}

                            {state.activeTab === 'monitoringAlerts' && memberships?.includes('compliance') && (
                                <TransactionsList
                                    expanded
                                    currentState={state}
                                    updateState={updateState}
                                    title="Monitoring Alerts"
                                    type="monitoring"
                                    userId={state.corporate_id}
                                />
                            )}

                            {state.activeTab === 'depositChecks' && (
                                <TransactionsList
                                    openUserOnViewMore
                                    enableDrilldownIntoUser
                                    expanded
                                    hideExpandChevron
                                    title="Deposits To Approve"
                                    type="deposits_to_approve"
                                    userId={state.corporate_id}
                                    embeddedIn="userDetail"
                                />
                            )}

                            {state.activeTab === 'exchange' && (
                                <TransactionsList
                                    hideExpandChevron
                                    expanded
                                    title="Exchange"
                                    type="swaps"
                                    userId={state.user_id}
                                />
                            )}

                            {state.activeTab === 'trades' && (
                                <TransactionsList
                                    showDatePickers
                                    expanded
                                    title="Trades"
                                    type="trades"
                                    userId={state.corporate_id}
                                />
                            )}

                            {state.activeTab === 'deposits' && (
                                <TransactionsList
                                    expanded
                                    showDatePickers
                                    title="Deposits"
                                    type="deposits"
                                    userId={state.corporate_id}
                                />
                            )}

                            {state.activeTab === 'withdrawals' && (
                                <TransactionsList
                                    expanded
                                    showDatePickers
                                    title="Withdrawals"
                                    type="withdrawals"
                                    userId={state.corporate_id}
                                />
                            )}
                        </>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};

const mapDispatchToProps = (dispatch) => ({
    logOut: () => dispatch({ type: 'LOG_USER_OUT' }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return { loggedIn: account.loggedIn, memberships: account.memberships };
};

export default connect(mapStateToProps, mapDispatchToProps)(CorporateUser);
