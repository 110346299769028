export default {
    color: '#09042B',
    border: '1px solid #09042B',
    backgroundColor: '#FFF',
    mb: '0px',
    mt: '30px',
    mr: '20px',
    height: '40px',
    width: '180px',
    fontSize: '14px',
    borderRadius: '12px',
    '&:hover': {
        transform: 'scale(1.02)',
        boxShadow: ' 0px 0px 12px rgba(0,0,0,0.1)',
    },
};
