/* eslint-disable no-unused-vars */
import ReactSelect from 'react-select';
import { Select, Box } from 'theme-ui';

export const SingleSelectDropDown = ({ currentState, updateState, includePleaseSelectOption = false, width = 300 }) => (
    <Select
        defaultValue={currentState.status}
        value={currentState.status}
        onChange={(e) => updateState({ ...currentState, status: e.target.value })}
        arrow={
            <Box
                as="svg"
                // @ts-ignore
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentcolor"
                sx={{
                    ml: -28,
                    mt: -20,
                    alignSelf: 'center',
                    pointerEvents: 'none',
                }}
            >
                <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
            </Box>
        }
        sx={{ mb: 20, width }}
    >
        {includePleaseSelectOption && <option value="select">Please Select</option>}
        <option value="not_started">Not started</option>
        <option value="pending">Pending</option>
        <option value="retry_all">Retry all</option>
        <option value="retry_onfido">Retry Onfido</option>
        <option value="retry_poa">Retry Proof of Address</option>
        <option value="verified">Verified</option>
        <option value="blocked">Blocked</option>
        <option value="rejected">Rejected</option>
        <option value="edd">EDD Legacy (Soon to be removed)</option>
        <option value="edd_onboarding">EDD Onboarding</option>
        <option value="edd_ongoing">EDD Ongoing</option>
        <option value="edd_on_hold">EDD On Hold</option>
    </Select>
);

export default ({ currentState, updateState, width = 300 }) => {
    const options = [
        { value: 'not_started', label: 'Not started' },
        { value: 'pending', label: 'Pending' },
        { value: 'retry_all', label: 'Retry all' },
        { value: 'retry_onfido', label: 'Retry Onfido' },
        { value: 'retry_poa', label: 'Retry Proof of Address' },
        { value: 'verified', label: 'Verified' },
        { value: 'blocked', label: 'Blocked' },
        { value: 'rejected', label: 'Rejected' },
        { value: 'edd', label: 'EDD Legacy (Soon to be removed)' },
        { value: 'edd_onboarding', label: 'EDD Onboarding' },
        { value: 'edd_ongoing', label: 'EDD Ongoing' },
        { value: 'edd_on_hold', label: 'EDD On Hold' },
    ];

    return (
        <ReactSelect
            value={currentState.kycStatuses}
            onChange={(kycStatuses) => updateState({ ...currentState, kycStatuses })}
            styles={{
                control: (provided) => ({
                    ...provided,
                    width,
                }),
            }}
            isMulti
            options={options}
        />
    );
};
