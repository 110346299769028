/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import { Flex, Box } from 'theme-ui';
import * as moment from 'moment';
import { Title } from './table';
import Item from './user-data-item';
import labelMapping from '../utils/label-mapping';

const formatCountry = (countriesList, value) => {
    if (!value) {
        return '';
    }
    return countriesList.find((item) => item.code === value)?.name || '';
};

const KYCData = ({ state }) => (
    <Flex>
        <Flex sx={{ mt: '30px', flexDirection: 'column' }}>
            <Item title="Nationality" value={`${formatCountry(state.countriesList, state.nationality)}`} />
            <Item
                title="Other Nationality"
                value={`${
                    !!state.other_nationality && state?.other_nationality?.toLowerCase() !== 'select'
                        ? formatCountry(state.countriesList, state.other_nationality)
                        : ''
                }`}
            />
            <Item title="Country of birth" value={`${formatCountry(state.countriesList, state.country_of_birth)}`} />
            <Item title="Place of birth" value={state.place_of_birth} />
            <Item title="Date of birth" value={`${state.date_of_birth || ''}`} />
            <Item title="Personal Id" value={`${state.personal_id_number || ''}`} />
            <Item title="Occupation" value={`${state.occupation || ''}`} />
            <Item title="Source of funds" value={labelMapping(state.source_of_funds)} />
            <Item title="Expected fiat to invest" value={labelMapping(state.expected_amount_to_invest) || ''} />

            <Item
                title="Expected crypto to invest"
                value={labelMapping(state.expected_amount_to_invest_crypto) || ''}
            />
            <Item title="Address verification result" value={state.kyc?.address_verification} />
        </Flex>

        <Flex sx={{ mt: '30px', flexDirection: 'column', borderLeft: '1px grey solid', pl: '50px' }}>
            <Item title="Risk score" value={labelMapping(state.risk_score?.rating)} />
            <Item title="KYC Status" value={labelMapping(state.kyc?.status)} />
            <Item title="Match status" value={labelMapping(state.match_status)} />
            <Item title="Number of checks" value={state.kyc?.check_attempts_count} />
            <Item
                title="Created date"
                value={state.created_at ? moment(state.created_at).format('DD MMM YY HH:ss') : 'n/a'}
            />
            <Item
                title="Pending date"
                value={
                    state.kyc_pending_date
                        ? moment(state.kyc_pending_date).format('DD MMM YY HH:ss')
                        : moment(state.created_at).format('DD MMM YY HH:ss')
                }
            />
            <Item
                title="Verified date"
                value={state.kyc_verified_date ? moment(state.kyc_verified_date).format('DD MMM YY HH:ss') : 'n/a'}
            />
            <Item
                title="Updated date"
                value={state.kyc_updated_date ? moment(state.kyc_updated_date).format('DD MMM YY HH:ss') : 'n/a'}
            />
            <Item title="EDD Status" value={labelMapping(state?.edd?.status)} />
            <Item title="EDD Sub Reason" value={labelMapping(state?.edd?.sub_reason)} />
        </Flex>
    </Flex>
);

export default ({ state, updateState }) => (
    <Box sx={{ width: '100%', marginLeft: '15px', mb: 40 }}>
        <Title title="KYC Information" type="kyc" state={state} updateState={updateState} />
        <KYCData state={state} updateState={updateState} />
    </Box>
);
