/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import { Flex, Paragraph, Link } from 'theme-ui';

import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';

import { useEffect, useState } from 'react';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';

const defaultState = {
    loading: true,
};

const fetchLeaders = async (state, updateState, history) => {
    try {
        updateState({ ...state, loading: true });

        const referralCodesBreakdown = await axios({
            url: `${process.env.REACT_APP_ADMIN_SEARCH_API}?indexName=referrals`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
            data: {
                size: 50,
                sort: [
                    {
                        ReferralCount: {
                            order: 'desc',
                        },
                    },
                ],
            },
        });

        updateState({
            ...state,
            loading: false,
            leaders: referralCodesBreakdown.data.users.map((item) => item._source),
        });
    } catch (e) {
        history.push('/');
    }
};

const ReferralLeaderboard = ({ logOut, memberships }) => {
    const [state, updateState] = useState(defaultState);
    const [showMenu, toggleMenu] = useState(true);
    const history = useHistory();

    useEffect(() => {
        fetchLeaders(state, updateState, history);
    }, []);

    return (
        <Flex
            sx={{
                overflow: 'hidden',
                top: '0',
                left: '0',
                bottom: 0,
                minHeight: '100vh',
                width: '100vw',
                flexDirection: 'row',
                backgroundColor: 'greyBackground',
                margin: 0,
                padding: 0,
            }}
        >
            <Sidebar
                logout={logOut}
                currentPage="Referrals"
                showMenu={showMenu}
                toggleMenu={toggleMenu}
                history={history}
                memberships={memberships}
            />

            <Flex
                sx={{
                    border: '0px red solid',
                    width: '90%',
                    maxWidth: '900px',
                    minHeight: '400px',
                    flexDirection: 'column',
                    mt: '50px',
                    mb: '100px',
                    ml: showMenu ? 240 : 45,
                }}
            >
                <Flex
                    onClick={() => history.push('/users')}
                    sx={{
                        alignSelf: 'flex-start',
                        marginTop: '-30px',
                        marginLeft: 0,
                        fontSize: '15px',
                        color: '#5100C9',
                        position: 'absolute',
                        cursor: 'pointer',
                    }}
                >
                    <i sx={{ marginRight: '10px', paddingTop: '5px' }} className="fal fa-long-arrow-left" />
                    <Paragraph sx={{ pl: '5px', mt: '-2px' }}>Back</Paragraph>
                </Flex>

                {state.loading && <Spinner />}

                <Flex
                    sx={{
                        width: '100%',
                        maxWidth: '900px',
                        minHeight: '450px',
                        backgroundColor: 'white',
                        borderRadius: '12px',
                        flexDirection: 'column',
                    }}
                >
                    <Paragraph sx={{ fontSize: 20, ml: 60, mt: 60, fontWeight: '600' }}>
                        Referral Code Leaderboard
                    </Paragraph>

                    <Flex sx={{ flexDirection: 'column', padding: 60, width: 600 }}>
                        {state?.leaders?.map((leader, idx) => (
                            <Flex key={leader.UserId} sx={{ justifyContent: 'space-between', mb: 20 }}>
                                <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', mb: 10 }}>
                                    <Paragraph sx={{ mr: 30, width: 25 }}>{idx + 1}.</Paragraph>
                                    <Paragraph sx={{ width: 200 }}>{leader.ReferralCode}</Paragraph>
                                </Flex>
                                <Paragraph>{leader.ReferralCount}</Paragraph>
                                <Link sx={{ color: '#5100C9' }} href={`/user/${leader.UserId}`}>
                                    View
                                </Link>
                            </Flex>
                        ))}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

const mapDispatchToProps = (dispatch) => ({
    logOut: () => dispatch({ type: 'LOG_USER_OUT' }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return { loggedIn: account.loggedIn, memberships: account.memberships };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferralLeaderboard);
