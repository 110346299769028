/** @jsxImportSource theme-ui */
import { Flex, Paragraph, Button } from 'theme-ui';

const SimpleAlert = ({ text, success, showConfirmButton = false, buttonLabel = '', buttonHandler }) => (
    <Flex sx={{ mt: 80, alignItems: 'center', flexDirection: 'column' }}>
        {success && (
            <>
                <i style={{ marginBottom: 20, fontSize: 50, color: 'green' }} className="fas fa-check" />
                <Paragraph sx={{ ml: 20, mr: 20, textAlign: 'center' }}>{text}</Paragraph>
            </>
        )}
        {!success && (
            <>
                <i style={{ marginBottom: 20, fontSize: 50, color: 'red' }} className="fas fa-exclamation-triangle" />
                <Paragraph sx={{ ml: 20, mr: 20, textAlign: 'center' }}>{text}</Paragraph>
            </>
        )}
        {showConfirmButton && buttonLabel && (
            <Button onClick={() => buttonHandler()} sx={{ mt: 20, width: 100 }}>
                {buttonLabel}
            </Button>
        )}
    </Flex>
);

export default SimpleAlert;
