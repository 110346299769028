import moment from 'moment';
import { formatting } from '@accru-finance/shared-ui-libs';

export const currencySymbol = {
    usd: '$',
    cad: '$',
    eur: '€',
    gbp: '£',
    Stablecoin: '$',
    Bitcoin: 'BTC',
    Ethereum: 'ETH',
    'Maple USDC': 'USDC',
    'Maple ETH': 'ETH',
    All: '$',
};

export const fundNames = {
    stablecoin: 'Stablecoin investment with AQRU',
    stablecoin_custom: 'Stablecoin investment with AQRU',
    stablecoin_total_invested: 'Base investment without AQRU',
    stablecoin_total_invested_custom: 'Base investment without AQRU',
    auto_eth: 'Ethereum investment with AQRU',
    auto_eth_custom: 'Ethereum investment with AQRU',
    auto_eth_total_invested: 'Base investment without AQRU',
    auto_eth_total_invested_custom: 'Base investment without AQRU',
    auto_btc: 'Bitcoin investment with AQRU',
    auto_btc_custom: 'Bitcoin investment with AQRU',
    auto_btc_total_invested: 'Base investment without AQRU',
    auto_btc_total_invested_custom: 'Base investment without AQRU',
    maple: 'Maple USDC investment with AQRU',
    maple_custom: 'Maple USDC investment with AQRU',
    maple_total_invested: 'Base investment without AQRU',
    maple_total_invested_custom: 'Base investment without AQRU',
    maple_eth: 'Maple ETH investment with AQRU',
    maple_eth_custom: 'Maple ETH investment with AQRU',
    maple_eth_total_invested: 'Base investment without AQRU',
    maple_eth_total_invested_custom: 'Base investment without AQRU',
};

export const formatXAxis = (tickItem) => moment(tickItem).format('MMM Do YY') || '';

export const getCurrencySymbol = (value, currentState) =>
    currencySymbol[currentState?.currencyType === 'Native' ? currentState?.productType : value];

export const getCurrencyValue = (value, currency, currentState) => {
    let currencyValue = '';
    if (currentState?.currencyType === 'Native' && currentState?.productType !== 'Stablecoin') {
        currencyValue = `${formatting.formatForDisplay(value)} ${getCurrencySymbol(currency, currentState)}`;
    } else {
        currencyValue = `${getCurrencySymbol(currency, currentState)}${formatting.formatForDisplay(value)}`;
    }
    return currencyValue;
};
