/* eslint-disable react/no-danger */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Flex, Paragraph, Box } from 'theme-ui';
import * as axios from 'axios';
import { Row } from './deposit';
import Spinner from '../spinner';

const getSignedUrl = async (id, currentState, updateState) => {
    try {
        updateState({ ...currentState, loading: true, error: null });

        const { data } = await axios({
            url: process.env.REACT_APP_COMMS_API,
            method: 'POST',
            data: {
                query: `query { 
                getSignedUrl(id: "${id}") { 
                    success
                    url
                }
            }`,
                variables: null,
            },
        });

        const result = data.data?.getSignedUrl;

        if (!result.success) throw new Error('Failed to get url');

        const { data: commsData } = await axios.get(result.url);

        updateState({ ...currentState, loading: false, error: null, emailBody: commsData.body });
    } catch (e) {
        updateState({ ...currentState, loading: false, error: 'Failed to load data sent to customer' });
    }
};

const CommsItem = ({ commsItem }) => {
    const [currentState, updateState] = useState({ loading: false, emailBody: null, error: null });

    useEffect(() => {
        if (commsItem.s3_location) {
            getSignedUrl(commsItem.id, currentState, updateState);
        }
    }, []);

    return (
        <Flex sx={{ ml: 50, mr: 50, mt: 40, flexDirection: 'column' }}>
            {currentState.loading && <Spinner />}

            <Paragraph sx={{ fontWeight: '800', mb: 20 }}>Details</Paragraph>

            <Row label="Id" value={commsItem.id} />
            <Row label="Type" value={commsItem.type} />
            <Row label="Template" value={commsItem.template} />
            <Row label="Status" value={commsItem.status} />
            <Row label="Created at" value={commsItem.created_at} />

            {currentState.emailBody && (
                <Box sx={{ mt: 20, mb: 50 }} dangerouslySetInnerHTML={{ __html: currentState.emailBody }} />
            )}
        </Flex>
    );
};

export default CommsItem;
