/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import axios from 'axios';

export const UpdateCorporateDetails = async ({
    corporate_id,
    company_name,
    company_type,
    registration_number,
    website,
    contact_email,
    contact_telephone_number,
    contact_person,
    currency,
    next_kyc_refresh_date,
    industry,
    expected_amount_to_invest,
    licence_details,
    licence_details_validity_date,
    source_of_funds,
    correspondence_address,
    address,
    reason,
    risk_score,
}: any) => {
    try {
        if (!corporate_id) {
            throw new Error('corporate id requried for an update');
        }

        const { data } = await axios({
            url: process.env.REACT_APP_CORPORATES_API,
            method: 'POST',
            data: {
                query: `mutation {
                    saveCorporate(input: {
                        corporate_id: "${corporate_id}",
                        company_name: "${company_name || ''}",
                        company_type: "${company_type || ''}",
                        registration_number: "${registration_number || ''}",
                        website: "${website || ''}",
                        contact_email: "${contact_email || ''}",
                        contact_telephone_number: "${contact_telephone_number || ''}",
                        contact_person: "${contact_person || ''}",
                        next_kyc_refresh_date: "${next_kyc_refresh_date}",
                        industry: "${industry || ''}",
                        expected_amount_to_invest: "${expected_amount_to_invest || ''}",
                        licence_details: ${licence_details || false},
                        source_of_funds: "${source_of_funds || ''}",
                        currency: "${currency || ''}",
                        licence_details_validity_date: "${licence_details_validity_date}",
                        risk_score: "${risk_score || ''}", 
                        correspondence_address: {
                            street: "${correspondence_address?.street || ''}",
                            sub_street: "${correspondence_address?.sub_street || ''}",
                            town: "${correspondence_address?.town || ''}",
                            post_code: "${correspondence_address?.post_code || ''}",
                            state: "${correspondence_address?.state || ''}",
                            country: "${correspondence_address?.country || ''}"
                        },
                        address: {
                            street: "${address?.street || ''}",
                            sub_street: "${address?.sub_street || ''}",
                            town: "${address?.town || ''}",
                            post_code: "${address?.post_code || ''}",
                            state: "${address?.state || ''}",
                            country: "${address?.country || ''}"
                        },
                        reason: "${reason}"                    
                    }) { 
                        success 
                    }
                }`,
            },
        });
        const result = data.data?.saveCorporate;
        return result;
    } catch (e) {
        return { success: false };
    }
};
