/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/** @jsxImportSource theme-ui */
import { useState, useEffect } from 'react';
import { Flex, Paragraph, Box } from 'theme-ui';
import { TableHeader, TableRow, Pagination } from './transaction-table';
import Spinner from '../spinner';
import getTransactionsAsAdmin from '../../utils/get-transactions-as-admin';

const defaultItems = [
    { type: 'loading' },
    { type: 'loading' },
    { type: 'loading' },
    { type: 'loading' },
    { type: 'loading' },
    { type: 'loading' },
    { type: 'loading' },
    { type: 'loading' },
    { type: 'loading' },
    { type: 'loading' },
];

const Table = ({ userId }) => {
    const [transactionsState, setTransactions] = useState({
        userId,
        transactions: {},
        paging: {},
        offset: 0,
        limit: 10,
        loading: true,
        error: false,
    });

    useEffect(() => {
        (async () => {
            try {
                setTransactions({ ...transactionsState, loading: true, error: false });
                const data = await getTransactionsAsAdmin(transactionsState);
                setTransactions({
                    ...transactionsState,
                    transactions: data.transactions,
                    paging: data.paging,
                    loading: false,
                });
            } catch (e) {
                setTransactions({ ...transactionsState, loading: false, error: true });
            }
        })();
    }, [transactionsState.offset]);
    return (
        <Flex
            sx={{
                width: '100%',
                justifyContent: 'space-between',
                pl: '100px',
                pr: '100px',
                mt: '0px',
                flexDirection: 'column',
                '@media screen and (max-width: 1000px)': { flexDirection: 'column' },
                '@media screen and (max-width: 700px)': { pl: '2%', pr: 0, width: '98%' },
            }}
        >
            {transactionsState.loading && (
                <Box sx={{ pt: '0', minHeight: '150px', width: '100%', position: 'relative' }}>
                    <Spinner />
                    <TableHeader />
                    {defaultItems.map((item) => (
                        <TableRow itemKey={JSON.stringify(item)} data={item} />
                    ))}
                </Box>
            )}

            {transactionsState.error && (
                <Paragraph
                    sx={{
                        color: 'red',
                        marginTop: 15,
                        fontSize: 15,
                        width: '100%',
                        textAlign: 'center',
                    }}
                >
                    We are currently unable to load transactions
                </Paragraph>
            )}

            {!transactionsState.loading && (
                <>
                    <TableHeader />

                    {transactionsState.transactions?.pendingWithdrawals?.length > 0 && (
                        <Flex sx={{ width: '100%', background: '#EFE6FD', height: '46px' }}>
                            <Paragraph sx={{ padding: '12px 15px 10px', fontSize: '14px', fontWeight: 700 }}>
                                User has pending withdrawals
                            </Paragraph>
                        </Flex>
                    )}

                    {transactionsState.transactions?.pendingWithdrawals?.map((item, idx) => (
                        <TableRow
                            itemKey={JSON.stringify(item)}
                            data={item}
                            darkBottomBorder={idx + 1 === transactionsState.transactions.pendingWithdrawals.length}
                        />
                    ))}

                    {transactionsState.transactions.otherTransactions.map((item, idx) => (
                        <TableRow itemKey={JSON.stringify(item)} data={item} />
                    ))}
                    <Pagination transactionsState={transactionsState} setTransactions={setTransactions} />
                </>
            )}
        </Flex>
    );
};

export default Table;
