/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import { Flex, Paragraph, Box } from 'theme-ui';
import * as Decimal from 'decimal.js';
import { Title } from '../table';
import Item from '../user-data-item';
import KycStats from './kyc-stats';

const Totals = ({ firstColumn, secondColumn }) => (
    <Flex sx={{ mt: '10px', flexDirection: 'row', mb: '25px' }}>
        <Flex sx={{ mt: '30px', flexDirection: 'column' }}>
            {firstColumn.map((item) => {
                const value = item.format_as_int
                    ? new Intl.NumberFormat('en-US').format(
                          parseFloat(new Decimal(item?.amounts[0]?.amount).toFixed(0).toString())
                      )
                    : new Decimal(item?.amounts[0]?.amount).toFixed(6);
                return <Item title={item?.title} value={value} key={item?.title} />;
            })}
        </Flex>

        <Flex sx={{ mt: '30px', flexDirection: 'column', borderLeft: '1px grey solid', pl: '50px' }}>
            {secondColumn.map((item) => {
                const value = item.format_as_int
                    ? new Intl.NumberFormat('en-US').format(
                          parseFloat(new Decimal(item?.amounts[0]?.amount).toFixed(0).toString())
                      )
                    : new Decimal(item?.amounts[0]?.amount).toFixed(6);
                return <Item title={item?.title} value={value} key={item?.title} />;
            })}
        </Flex>
    </Flex>
);

const Withdrawals = ({ data }) => {
    const { amounts = [] } = data;
    const middle = Math.floor(amounts.length / 2);
    const firstColumn = amounts.slice(0, middle);
    const secondColumn = amounts.slice(middle);
    return (
        <Flex sx={{ mt: '10px', flexDirection: 'row', mb: '25px' }}>
            <Flex sx={{ mt: '30px', flexDirection: 'column' }}>
                {secondColumn.map((item) => {
                    const value = item.format_as_int
                        ? new Intl.NumberFormat('en-US').format(
                              parseFloat(new Decimal(item?.amount).toFixed(0).toString())
                          )
                        : new Decimal(item?.amount).toFixed(6);
                    return <Item title={item?.group_by_column} value={value} key={item?.group_by_column} />;
                })}
            </Flex>

            <Flex sx={{ mt: '30px', flexDirection: 'column', borderLeft: '1px grey solid', pl: '50px' }}>
                {firstColumn.map((item) => {
                    const value = item.format_as_int
                        ? new Intl.NumberFormat('en-US').format(
                              parseFloat(new Decimal(item?.amount).toFixed(0).toString())
                          )
                        : new Decimal(item?.amount).toFixed(6);
                    return <Item title={item?.group_by_column} value={value} key={item?.group_by_column} />;
                })}
                {firstColumn.length < 1 && <Item title="" value="" empty />}
            </Flex>
        </Flex>
    );
};

const TopFigures = ({ state }) => {
    const originalDataArray = state?.singleValueTotals || [];
    const originalMultiDataArray = state?.multiValueTotals || [];
    const totalsArray = originalDataArray.filter(
        (item) => item?.type !== 'withdrawals_tomorrow' && item?.type !== 'withdrawals_today'
    );
    const withdrawalsArray = originalDataArray.filter(
        (item) => item?.type === 'withdrawals_tomorrow' || item?.type === 'withdrawals_today'
    );
    const withdrawalsMultiArray = originalMultiDataArray.filter(
        (item) => item?.type === 'withdrawals_tomorrow' || item?.type === 'withdrawals_today'
    );

    const middle = Math.floor(totalsArray.length / 2);
    const firstColumn = totalsArray.slice(0, middle);
    const secondColumn = totalsArray.slice(middle);
    const withdrawalsToday = withdrawalsArray.find((item) => item.type === 'withdrawals_today');
    const withdrawalsMultiToday = withdrawalsMultiArray.find((item) => item.type === 'withdrawals_today');
    const withdrawalsTomorrow = withdrawalsArray.find((item) => item.type === 'withdrawals_tomorrow');
    const withdrawalsMultiTomorrow = withdrawalsMultiArray.find((item) => item.type === 'withdrawals_tomorrow');

    return (
        <Flex
            sx={{
                flexDirection: 'column',
                mt: '10px',
            }}
        >
            <Title title="User numbers 🔥" state={state} updateState={{}} showEditIcon={false} />
            <Totals firstColumn={firstColumn} secondColumn={secondColumn} />

            <KycStats state={state} />
            <Box sx={{ height: '20px' }} />

            <Title title="Withdrawals today  🤑" state={state} updateState={{}} showEditIcon={false} />
            {(withdrawalsToday || withdrawalsMultiToday) && (
                <Withdrawals data={withdrawalsMultiToday ? withdrawalsMultiToday : withdrawalsToday} />
            )}
            {!withdrawalsToday && !withdrawalsMultiToday && (
                <Paragraph sx={{ mt: '25px', fontSize: '16px', fontWeight: 400, textAlign: 'left' }}>
                    No withdrawals scheduled for today 🎉
                </Paragraph>
            )}
            <Box sx={{ height: '30px' }} />

            <Title title="Withdrawals tomorrow  💰" state={state} updateState={{}} showEditIcon={false} />
            {(withdrawalsTomorrow || withdrawalsMultiTomorrow) && (
                <Withdrawals data={withdrawalsMultiTomorrow ? withdrawalsMultiTomorrow : withdrawalsTomorrow} />
            )}
            {!withdrawalsTomorrow && !withdrawalsMultiTomorrow && (
                <Paragraph sx={{ mt: '25px', fontSize: '16px', fontWeight: 400, textAlign: 'left' }}>
                    No withdrawals scheduled for tomorrow 🎉
                </Paragraph>
            )}
        </Flex>
    );
};
export default TopFigures;
