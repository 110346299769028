/* eslint-disable no-unused-vars */
import { Box, Paragraph, Flex, Image } from 'theme-ui';
import { useState, useEffect } from 'react';
import FormInput from '../form-input';

const TransactionPagination = ({
    currentState,
    updateState,
    showTotalPages = false,
    totalPages,
    showTotalRecords,
    totalRecords,
    showGoToStartAndEndButtons = false,
}) => {
    const [pg, updatePg] = useState(currentState.pageNumber);

    useEffect(() => {
        updatePg(currentState?.pageNumber);
    }, [currentState?.pageNumber]);

    return (
        <Flex
            sx={{ width: showTotalPages ? '215px' : '215px', margin: '50px auto 20px', justifyContent: 'space-around' }}
        >
            {showGoToStartAndEndButtons && (
                <Box
                    sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                    onClick={() =>
                        !currentState.loading &&
                        updateState({
                            ...currentState,
                            pageNumber: 1,
                            offset: 0,
                        })
                    }
                >
                    <i style={{ marginTop: '4px' }} className="fa fa-backward" />
                </Box>
            )}

            {currentState.pageNumber > 1 && (
                <Box
                    sx={{ width: '26px', height: '26px', cursor: 'pointer', ml: '5px' }}
                    onClick={() =>
                        !currentState.loading &&
                        updateState({
                            ...currentState,
                            pageNumber: currentState.pageNumber - 1,
                            offset: currentState.offset - currentState.limit,
                        })
                    }
                >
                    <Image
                        src="https://cdn.accru.finance/arrows/enabled-left.png"
                        width="26"
                        height="26"
                        alt="prev button"
                    />
                </Box>
            )}
            {currentState.pageNumber <= 1 && (
                <Box sx={{ width: '26px', height: '26px', cursor: 'not-allowed', ml: '5px' }}>
                    <Image
                        src="https://cdn.accru.finance/arrows/disabled-left.png"
                        width="26"
                        height="26"
                        alt="prev button"
                    />
                </Box>
            )}

            {!showTotalPages && (
                <Paragraph sx={{ fontWeight: '600', fontSize: '14px', mt: '3px' }}>{currentState.pageNumber}</Paragraph>
            )}

            {showTotalPages && (
                <Flex sx={{ flexDirection: 'column', width: '180px' }}>
                    <Flex
                        sx={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Paragraph
                            sx={{
                                fontWeight: '600',
                                fontSize: '14px',
                                mt: '3px',
                                ml: '10px',
                                width: '80px',
                                height: '30px',
                            }}
                        >
                            Page
                        </Paragraph>
                        <Flex sx={{ width: '30px' }}>
                            <FormInput
                                onChange={(e) => {
                                    if (!currentState.loading) {
                                        updatePg(e.target.value);
                                    }
                                }}
                                value={pg}
                                sx={{
                                    width: '30px',
                                    height: '30px',
                                    color: '#444',
                                    pl: '5px',
                                    pr: '5px',
                                    ml: '5px',
                                    textAlign: 'center',
                                    fontSize: '13px',
                                    fontWeight: '600',
                                }}
                                label=""
                                onKeyDown={(e) => {
                                    const pageNum = parseInt(pg, 10);
                                    const pageNumToUse = pageNum <= 1 ? 0 : pageNum - 1;
                                    if (e?.keyCode === 13) {
                                        updateState({
                                            ...currentState,
                                            pageNumber: pageNum,
                                            offset: pageNumToUse * currentState?.limit,
                                        });
                                    }
                                }}
                            />
                        </Flex>
                        <Paragraph
                            sx={{
                                fontWeight: '600',
                                fontSize: '14px',
                                mt: '3px',
                                mr: '5px',
                                ml: '30px',
                                width: '90px',
                                height: '30px',
                            }}
                        >
                            of {totalPages}
                        </Paragraph>
                    </Flex>
                    <Flex sx={{ width: '150px', height: '40px', mt: '10px' }}>
                        {showTotalRecords && (
                            <Paragraph
                                sx={{
                                    fontWeight: '600',
                                    fontSize: '10px',
                                    mt: '0px',
                                    textAlign: 'center',
                                    width: '100%',
                                }}
                            >
                                ({new Intl.NumberFormat('en-US').format(totalRecords)} records)
                            </Paragraph>
                        )}
                    </Flex>
                </Flex>
            )}

            {currentState.items?.length === currentState.limit && (
                <Box
                    sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                    onClick={() =>
                        !currentState.loading &&
                        updateState({
                            ...currentState,
                            pageNumber: currentState.pageNumber + 1,
                            offset: currentState.offset + currentState.limit,
                        })
                    }
                >
                    <Image
                        src="https://cdn.accru.finance/arrows/enabled-right.png"
                        width="26"
                        height="26"
                        alt="next button"
                    />
                </Box>
            )}

            {currentState.items?.length < currentState.limit && (
                <Box sx={{ width: '26px', height: '26px', cursor: 'not-allowed' }}>
                    <Image
                        src="https://cdn.accru.finance/arrows/disabled-right.png"
                        width="26"
                        height="26"
                        alt="next button"
                    />
                </Box>
            )}

            {showGoToStartAndEndButtons && (
                <Box
                    sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                    onClick={() =>
                        !currentState.loading &&
                        updateState({
                            ...currentState,
                            pageNumber: Math.ceil(currentState.total / currentState.limit),
                            offset: Math.floor(currentState.total / currentState.limit) * currentState.limit,
                        })
                    }
                >
                    <i style={{ marginTop: '4px', marginLeft: '10px' }} className="fa fa-forward" />
                </Box>
            )}
        </Flex>
    );
};

export default TransactionPagination;
