/* eslint-disable no-unused-vars */
import { Flex, Heading, Box } from 'theme-ui';
import moment from 'moment';
import { Title } from './table';
import Item from './user-data-item';

const LockWindows = ({ investmentWindows }: any) => (
    <Flex sx={{ justifyContent: 'center', height: '90%', alignItems: 'center' }}>
        <Flex
            sx={{
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '500px',
                height: '500px',
                flexDirection: 'column',
                overflow: 'auto',
            }}
        >
            <Heading sx={{ mb: '40px' }}>Maple Lock-ins</Heading>

            <Flex sx={{ ml: '50px', fontSize: '18px', flexDirection: 'column', width: '100%' }}>
                {investmentWindows.map((item: any) => (
                    <Box sx={{ mb: '40px' }}>
                        <Title title={`🍁 ${item?.product_name}`} state={{}} updateState={{}} showEditIcon={false} />
                        <Box sx={{ width: '300px', mt: '10px' }}>
                            <Item title="Status" value={item?.status} customSx={{ minHeight: '30px' }} />
                            <Item
                                title="Amount invested"
                                value={item?.amount_invested}
                                customSx={{ minHeight: '30px' }}
                            />
                            <Item
                                title="Start"
                                value={
                                    item.start_date_of_lock_in
                                        ? moment(item.start_date_of_lock_in).format('DD MMM YYYY HH:mm')
                                        : ''
                                }
                                customSx={{ minHeight: '30px' }}
                            />
                            <Item
                                title="End"
                                value={
                                    item.end_date_of_lock_in
                                        ? moment(item.end_date_of_lock_in).format('DD MMM YYYY HH:mm')
                                        : ''
                                }
                                customSx={{ minHeight: '30px' }}
                            />
                            <Item title="Days to go" value={item?.days_to_go} customSx={{ minHeight: '30px' }} />
                        </Box>
                    </Box>
                ))}
            </Flex>
        </Flex>
    </Flex>
);

export default LockWindows;
