/* eslint-disable camelcase */
import axios from 'axios';

export const disableMfa = async (userId: string, updateState: any, currentState: any) => {
    updateState({ ...currentState, loading: true, error: null });
    try {
        const { data } = await axios({
            url: process.env.REACT_APP_USERS_API,
            method: 'POST',
            data: {
                query: `mutation { 
                        adminTools(input: { user_id:"${userId}", tool:"reset_mfa" }) { 
                            success
                            message
                        }
                    }`,
                variables: null,
            },
        });
        const result = data.data?.adminTools;

        if (result.success) {
            window.location.reload();
        } else {
            throw new Error('Error setting mfa');
        }
    } catch (e) {
        updateState({
            ...currentState,
            loading: false,
            error: "Uh oh, something has gone wrong resetting the user's MFA",
        });
    }
};

export const forceKycCheck = async (userId: string, updateState: any, currentState: any) => {
    updateState({ ...currentState, loading: true, error: null });
    try {
        const { data } = await axios({
            url: process.env.REACT_APP_USERS_API,
            method: 'POST',
            data: {
                query: `mutation { 
                        adminTools(input: { user_id:"${userId}", tool:"force_onfido_check" }) { 
                            success
                            message
                        }
                    }`,
                variables: null,
            },
        });
        const result = data.data?.adminTools;

        if (result.success) {
            window.location.reload();
        } else {
            updateState({
                ...currentState,
                loading: false,
                error: result.message,
            });
        }
    } catch (e) {
        updateState({
            ...currentState,
            loading: false,
            error: 'Uh oh, something has gone wrong creating the check in onfido, contact support',
        });
    }
};

export const updateEdd = async (updateState: any, currentState: any) => {
    updateState({ ...currentState, loading: true, error: '' });
    const { edd_status, edd_sub_reason, newTiers, userId } = currentState;

    try {
        const { data } = await axios({
            url: process.env.REACT_APP_USERS_API,
            method: 'POST',
            data: {
                query: `mutation {
                        adminTools(input: {
                            user_id:"${userId}",
                            tool:"edd_update",
                            edd_tier: {
                                crypto: "${newTiers.crypto}",
                                fiat: "${newTiers.fiat}"
                            },
                            edd_status:"${edd_status}",
                            edd_status_sub_reason:"${edd_sub_reason}"})
                            {
                            success
                            message
                        }
                    }`,
                variables: null,
            },
        });
        const result = data.data?.adminTools;

        if (result.success) {
            window.location.reload();
        } else {
            throw new Error('Error updating EDD');
        }

        updateState({ ...currentState, loading: false });
    } catch (e) {
        updateState({
            ...currentState,
            loading: false,
            error: 'Uh oh, something has gone wrong updating the users EDD',
        });
    }
};
