import { Auth } from 'aws-amplify';

export const logUserOut = async (history: any, logOut: any) => {
    try {
        await Auth.signOut();
        logOut();
        history.push('/');
    } catch (e) {
        console.log('failed logging OUT ', e);
    }
};
