/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { Flex, Box, Paragraph } from 'theme-ui';
import Input from '../form-input';

const BasicDetails = ({ formState, updateFormState, errorState }: any) => {
    const { first_name, middle_names, last_name } = formState;
    return (
        <Flex sx={{ justifyContent: 'center', width: '100%' }}>
            <Box>
                <Paragraph sx={{ fontWeight: 600, textAlign: 'center', mb: '70px' }}>Basic Details</Paragraph>
                <Input
                    sx={{
                        mb: '12px',
                        alignSelf: 'center',
                        width: 400,
                        background: '#FFFFFF',
                        border: errorState.first_name ? '1px red solid' : '1px solid grey',
                    }}
                    type="text"
                    id="first_name"
                    data-testid="code"
                    name="first_name"
                    label="First Name"
                    placeholder="enter first name"
                    onChange={(e: any) => updateFormState({ ...formState, first_name: e.target.value })}
                    error={null}
                    value={first_name}
                />
                <Input
                    sx={{
                        mb: '12px',
                        alignSelf: 'center',
                        width: 400,
                        background: '#FFFFFF',
                        border: '1px solid grey',
                    }}
                    type="text"
                    id="middle_names"
                    data-testid="code"
                    name="middle_names"
                    label="Middle Names"
                    placeholder="enter middle names"
                    onChange={(e: any) => updateFormState({ ...formState, middle_names: e.target.value })}
                    error={null}
                    value={middle_names}
                />
                <Input
                    sx={{
                        mb: '12px',
                        alignSelf: 'center',
                        width: 400,
                        background: '#FFFFFF',
                        border: errorState.last_name ? '1px red solid' : '1px solid grey',
                    }}
                    type="text"
                    id="last_name"
                    data-testid="code"
                    name="last_name"
                    label="Last Name"
                    placeholder="enter last name"
                    onChange={(e: any) => updateFormState({ ...formState, last_name: e.target.value })}
                    error={null}
                    value={last_name}
                />
            </Box>
        </Flex>
    );
};

export default BasicDetails;
