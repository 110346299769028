const theme = {
    colors: {
        text: '#000',
        background: '#FFFFFF',
        primary: '#09042B',
        secondary: '#F0B929',
        color2: '#54436D',
        color3: '#7F4BD5',
        fontDark: '#1C1C1C',
        validationError: '#F56132',
        greyBackground: '#E5E5E5',
        darkBackground: '#09042B',
        inputBorder: '#D1D1D1',
        errorLight: '#FFE8E2',
        validationSuccessDark: '#219653',
        validationSuccessLight: '#DDF3E6',
        linkColor: '#5100C9',
    },
    select: {
        borderColor: 'inputBorder',
        height: 50,
        borderRadius: 6,
        color: 'fontDark',
        paddingLeft: 15,
        outline: 'none',
    },
    alerts: {
        primary: {
            color: 'background',
            bg: 'primary',
        },
        success: {
            bg: 'validationSuccessLight',
            color: 'fontDark',
            border: '1px solid #219653',
        },
        error: {
            bg: 'errorLight',
            color: 'fontDark',
            border: '1px solid #F56132',
        },
    },
    close: {
        error: {
            color: 'validationError',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        success: {
            color: 'validationSuccessDark',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        '&:hover': {
            cursor: 'pointer',
        },
    },
    fonts: {
        body: '"Open Sans", sans-serif',
        heading: '"Open Sans", sans-serif',
        monospace: '"Open Sans", sans-serif',
        label: '"Open Sans", sans-serif',
        textarea: '"Open Sans", sans-serif',
    },
    fontWeights: {
        body: 400,
        heading: 700,
    },
    styles: {
        spinner: {
            color: 'primary',
        },
        h1: {
            fontSize: 36,
            fontFamily: 'body',
            fontWeight: 'heading',
            color: 'fontDark',
            mt: 4,
            mb: 2,
        },
        h2: {
            fontFamily: 'body',
            fontWeight: 'heading',
            color: 'fontDark',
            lineHeight: '49.18px',
        },
        a: {
            '&:hover': {
                cursor: 'pointer',
            },
            color: 'primary',
            fontFamily: 'body',
        },
        p: {
            fontFamily: 'body',
        },
        body: {
            backgroundColor: 'greyBackground',
        },
    },
    breakpoints: ['40em', '@media (min-width: 56em) and (orientation: landscape)', '64em'],
    label: {
        fontFamily: 'body',
    },
    buttons: {
        primary: {
            backgroundColor: 'primary',
            '&:hover': {
                cursor: 'pointer',
            },
            height: 49,
            borderRadius: 100,
        },
        'primary-wide': {
            backgroundColor: 'primary',
            '&:hover': {
                cursor: 'pointer',
            },
            height: 49,
            width: ['90%', 404],
            marginRight: ['5%', 'unset'],
            marginLeft: ['5%', 'unset'],
            borderRadius: 100,
        },
        secondary: {
            backgroundColor: 'secondary',
            '&:hover': {
                cursor: 'pointer',
            },
            color: 'fontDark',
            height: 49,
            borderRadius: 100,
        },
    },
    box: {
        formSplitScreenLeftHandSide: {
            backgroundColor: 'darkBackground',
            width: [0, 0, '35%'],
            display: ['none', 'none', 'flex'],
            justifyContent: 'center',
        },
        formSplitScreenRightHandSide: {
            alignSelf: 'center',
            backgroundColor: 'greyBackground',
            paddingLeft: ['unset', 'unset', 150],
            paddingTop: 40,
        },
    },
};

export default theme;
