/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/** @jsxImportSource theme-ui */
import axios from 'axios';
import { useState } from 'react';
import { Flex, Paragraph, Button } from 'theme-ui';
import Spinner from './spinner';
import FormInput from './form-input';
import buttonStyle from '../utils/button-style';

const addNewCorporate = async (currentState, updateState) => {
    updateState({ ...currentState, loading: true });
    try {
        if (
            currentState.company_name === '' ||
            currentState.contact_email === '' ||
            currentState.contact_telephone_number === '' ||
            currentState.contact_person === ''
        ) {
            return updateState({ ...currentState, error: 'Please complete all fields', loading: false });
        }

        const { data } = await axios({
            url: process.env.REACT_APP_CORPORATES_API,
            method: 'POST',
            data: {
                query: `mutation {
                    saveCorporate(input: {
                        contact_email: "${currentState.contact_email}"
                        contact_telephone_number: "${currentState.contact_telephone_number}"
                        contact_person: "${currentState.contact_person}"
                        company_name: "${currentState.company_name}"
                        registration_number: ""
                        next_kyc_refresh_date: ""
                        industry: ""
                        website: ""
                        currency: ""
                        correspondence_address: {
                            street: ""
                            sub_street: ""
                            town: ""
                            post_code: ""
                            state: ""
                            country: ""
                        }
                        address: {
                            street: ""
                            sub_street: ""
                            town: ""
                            post_code: ""
                            state: ""
                            country: ""
                        }
                        licence_details: false
                        licence_details_validity_date: ""
                        expected_amount_to_invest: ""
                        source_of_funds: ""
                    }) { 
                        success
                        corporate_id
                    }
                }`,
                variables: null,
            },
        });

        if (!data.data.saveCorporate.success) {
            throw new Error('failed');
        }

        window.location.assign(`/corporate/${data.data.saveCorporate.corporate_id}`);
    } catch (e) {
        updateState({ ...currentState, error: 'Failed to create the new corporate', loading: false });
    }
};

const AddCorporate = ({ updateMainTable }) => {
    const [currentState, updateState] = useState({
        loading: false,
        error: '',
        company_name: '',
        contact_email: '',
        contact_telephone_number: '',
        contact_person: '',
    });

    return (
        <Flex sx={{ flexDirection: 'column', ml: 20 }}>
            {currentState.loading && <Spinner />}
            <Paragraph sx={{ mt: '30px', mb: '55px', fontWeight: '800', textAlign: 'center' }}>Add Corporate</Paragraph>
            <Flex sx={{ justifyContent: 'center' }}>
                <Flex sx={{ flexDirection: 'column' }}>
                    <FormInput
                        onChange={(e) => updateState({ ...currentState, company_name: e.target.value })}
                        value={currentState.company_name}
                        sx={{ width: 290 }}
                        label="Company name"
                    />
                    <FormInput
                        onChange={(e) => updateState({ ...currentState, contact_email: e.target.value })}
                        value={currentState.contact_email}
                        sx={{ width: 290 }}
                        label="Contact email"
                    />
                    <FormInput
                        onChange={(e) => updateState({ ...currentState, contact_telephone_number: e.target.value })}
                        value={currentState.contact_telephone_number}
                        sx={{ width: 290 }}
                        label="Contact phone number"
                    />
                    <FormInput
                        onChange={(e) => updateState({ ...currentState, contact_person: e.target.value })}
                        value={currentState.contact_person}
                        sx={{ width: 290 }}
                        label="Contact person"
                    />

                    <Paragraph
                        sx={{
                            color: 'red',
                            mb: '0px',
                            mt: '20px',
                            height: '30px',
                            maxWidth: '300px',
                            textAlign: 'right',
                        }}
                    >
                        {currentState.error}
                    </Paragraph>

                    <Button
                        onClick={() => addNewCorporate(currentState, updateState, updateMainTable)}
                        sx={buttonStyle}
                    >
                        Add New Corporate
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default AddCorporate;
