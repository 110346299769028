/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
import { Paragraph, Box } from 'theme-ui';

const SubMenuOption = ({ subMenuType, toggleSubMenu, items = [] }) =>
    items.map((item, i) => (
        <Box
            sx={{
                width: '100%',
                height: '50px',
                border: 'none',
                borderBottom: '1px solid #DCDCDC',
                backgroundColor: subMenuType === item?.subMenuGroup ? '#09042B' : '#FFF',
                color: subMenuType === item?.subMenuGroup ? '#FFF' : '#09042B',

                '&:hover': {
                    backgroundColor: '#09042B',
                    color: '#FFF',
                },
            }}
            onClick={() => toggleSubMenu({ show: true, type: item?.subMenuGroup })}
            onMouseOver={() => toggleSubMenu({ show: true, type: item?.subMenuGroup })}
            key={`subMenu-${i}`}
        >
            <Paragraph
                sx={{
                    fontSize: '14px',
                    width: '100%',
                    textAlign: 'center',
                    margin: '15px 0 ',
                }}
            >
                {item?.title}
            </Paragraph>
        </Box>
    ));

export default SubMenuOption;
