/* eslint-disable prefer-const */
/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import axios from 'axios';
import * as moment from 'moment';
import { Flex, Paragraph, Image, Link, Box } from 'theme-ui';
import { TableHeaderItem, TableRowItem } from './table';
import Overlay from './overlay';
import SimpleAlert from './simple-alert';
import Spinner from './spinner';

const fileTypeLookup = (fileType: string) => {
    let extension = fileType.split('/')[1];

    if (extension === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        extension = 'xlsx';
    }

    if (extension?.includes('wordprocessingml')) {
        extension = 'docx';
    }
    if (extension?.includes('plain')) {
        extension = 'txt';
    }

    return extension;
};

const download = (fileType: string, base64: string) => {
    const a = document.createElement('a');
    a.href = base64;
    a.download = `AdminUpload.${fileTypeLookup(fileType)}`;
    a.click();
};

const fetchUploads = async (state: any, updateState: any, action = 'fetch', idx = 0) => {
    updateState({ ...state, loading: true });

    try {
        const { data } = await axios({
            url: process.env.REACT_APP_USERS_API,
            method: 'POST',
            data: {
                query: `query {
                    getUserDocumentsOfType(
                        user_id: "${state.userId}",
                        type: "admin_upload",
                        documentIndex: ${idx}
                    ) {
                        success
                        items {
                            upload_timestamp
                            file_type
                            file_path
                            signed_url
                            description
                        }
                    }
                }`,
                variables: null,
            },
        });

        const result = data.data?.getUserDocumentsOfType;
        if (!result.success) {
            throw new Error('Failed');
        }

        let newState = { ...state, loading: false, items: result.items };

        let {
            data: { base_64, file_type },
        } = await axios({
            url: result.items[idx].signed_url,
            method: 'GET',
        });

        if (base_64.startsWith('data:')) {
            result.items[idx].base_64 = base_64;
        } else {
            // @ts-ignore
            base_64 = `data:${file_type};base64,${base_64}`;
            result.items[idx].base_64 = base_64;
        }

        if (action === 'view') {
            if (result.items[idx].file_type === 'application/pdf') {
                let win = window.open('');
                win?.document.write(
                    `<iframe src="${result.items[idx].base_64}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
                );
                updateState({ ...state, loading: false, documentUpload: null, view: 'uploads' });
                return;
            }
            newState = {
                ...newState,
                view: 'document',
                documentUpload: result.items[idx],
            };
        } else if (action === 'download') {
            download(result.items[idx].file_type, result.items[idx].base_64);
        }

        updateState(newState);

        return result;
    } catch (e) {
        updateState({
            ...state,
            loading: false,
            error: 'There has been an error loading the document, please try again later',
        });
    }
};

const deleteDocument = async (state: any, updateState: any, upload_timestamp: string) => {
    updateState({ ...state, loading: true });

    try {
        const { data } = await axios({
            url: process.env.REACT_APP_USERS_API,
            method: 'POST',
            data: {
                query: `mutation {
                adminTools(input: {
                    user_id: "${state.userId}"
                    tool: "delete_document"
                    document_upload_timestamp: "${upload_timestamp}"
                }) { 
                    success
                    message
                }
            }`,
                variables: null,
            },
        });

        const result = data.data?.adminTools;

        if (!result.success) {
            throw new Error('Failed');
        }

        window.location.reload();
    } catch (e) {
        updateState({
            ...state,
            loading: false,
            error: 'There has been an error deleting the document, please try again later',
        });
    }
};

const AdminUploads = (props: any) => {
    const [state, updateState] = useState({
        loading: false,
        error: null,
        view: 'uploads',
        checkId: null,
        reportId: null,
        documentUpload: null,
        items: [],
        reports: [],
        documents: [],
        userId: props.userId,
        report: null,
        documentIndex: 0,
        document: null,
        alertConfig: null,
    });

    useEffect(() => {
        fetchUploads(state, updateState);
    }, []);

    return (
        <Flex sx={{ flexDirection: 'column', mt: 30, overflow: 'scroll' }}>
            {state.loading && <Spinner />}

            {state.alertConfig && (
                <Overlay
                    size="small"
                    closeOverlay={() => updateState({ ...state, alertConfig: null })}
                    // @ts-ignore
                    overlayContent={<SimpleAlert {...state.alertConfig} />}
                />
            )}

            {state.view === 'uploads' && (
                <>
                    <Flex>
                        <Paragraph sx={{ ml: 20, mb: 20 }}>Admin Uploads</Paragraph>
                        <Link
                            onClick={() => props.openUploadDocumentOverlay()}
                            sx={{ ml: 20, fontSize: 12, textDecoration: 'underline', color: 'blue', mt: '3px' }}
                        >
                            Upload Document
                        </Link>
                    </Flex>
                    <Flex
                        sx={{
                            justifyContent: 'space-between',
                            width: '100%',
                            height: '40px',
                            color: '#fff',
                        }}
                    >
                        <TableHeaderItem text="Description" />
                        <TableHeaderItem text="File Type" />
                        <TableHeaderItem text="Uploaded At" />
                        <TableHeaderItem text="" />
                        <TableHeaderItem text="" />
                        <TableHeaderItem text="" />
                    </Flex>
                    {state.items.map((item: any, idx: number) => (
                        <Flex sx={{ justifyContent: 'space-between', width: '100%', height: '65px' }}>
                            <TableRowItem key={`${item.description}`} text={item.description} />
                            <TableRowItem key={`${item.file_type}`} text={fileTypeLookup(item.file_type)} />
                            <TableRowItem
                                key={`${item.upload_timestamp}`}
                                text={moment.utc(item.upload_timestamp).format('Do MMMM YY HH:mm')}
                            />
                            <TableRowItem
                                action={() => fetchUploads(state, updateState, 'view', idx)}
                                type="view"
                                key={`${item.upload_timestamp}_view`}
                                text="View"
                            />
                            <TableRowItem
                                action={() => fetchUploads(state, updateState, 'download', idx)}
                                type="view"
                                key={`${item.upload_timestamp}_view`}
                                text="Download"
                            />
                            <TableRowItem
                                action={() =>
                                    updateState({
                                        ...state,
                                        // @ts-ignore
                                        alertConfig: {
                                            success: false,
                                            text: 'Please confirm you wish to delete this document',
                                            showConfirmButton: true,
                                            buttonLabel: 'OK',
                                            buttonHandler: () =>
                                                deleteDocument(state, updateState, item.upload_timestamp),
                                        },
                                    })
                                }
                                type="view"
                                key={`${item.upload_timestamp}_delete`}
                                text="Delete"
                            />
                        </Flex>
                    ))}
                </>
            )}

            {state.view === 'document' && (
                <>
                    <Link
                        sx={{ ml: 20, mb: 20, color: 'primary' }}
                        onClick={() => updateState({ ...state, view: 'uploads', documentUpload: null })}
                    >
                        {'< '}Back
                    </Link>
                    <Paragraph sx={{ ml: 20, mb: 20 }}>Uploaded Document</Paragraph>
                    {state.documentUpload && (
                        <Box
                            sx={{
                                height: 470,
                                width: 500,
                                overflow: 'scroll',
                                alignSelf: 'center',
                                mb: 80,
                                ml: 'auto',
                                mr: 'auto',
                            }}
                        >
                            <Image
                                sx={{ mt: 20, width: '100%' }}
                                alt="id"
                                // @ts-ignore
                                src={state?.documentUpload?.base_64}
                            />
                        </Box>
                    )}
                </>
            )}
        </Flex>
    );
};

export default AdminUploads;
