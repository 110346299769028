import moment from 'moment';

export const formatDate = (dob: string, yearFirst: boolean = false): string => {
    if (!dob) {
        return '';
    }
    return moment.utc(dob, ['DD-MM-YYYY', 'YYYY-MM-DD']).format(yearFirst ? 'YYYY-MM-DD' : 'DD-MM-YYYY');
};
export const validateDOB = (dob: string): boolean => {
    const dobRegEx = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;
    const validDOBRegex = dobRegEx.test(dob);
    let validDOB = false;
    if (validDOBRegex) {
        const [day, month, year] = dob.split('-'); // @ts-ignore
        const years = moment().diff(`${year}-${month}-${day}`, 'years', true);
        validDOB = years >= 18;
    }
    return validDOB;
};

export const autoFormatDOB = (input: any, existingDOB: any) =>
    new Promise((resolve) => {
        let dob = input.trim();
        if (!dob) {
            resolve('');
        }

        const lengthOFInput = dob.length;
        if (existingDOB.length < lengthOFInput) {
            if (lengthOFInput === 2 || lengthOFInput === 5) {
                dob = `${dob}-`;
            }
            if (lengthOFInput >= 10) {
                resolve(dob.substring(0, 10));
            }
        } else {
            dob = '';
        }

        resolve(dob);
    });
