/** @jsxImportSource theme-ui */
import { Flex, Paragraph, Box, Button } from 'theme-ui';
import { useState, useEffect } from 'react';
import axios from 'axios';
import FormInput from './form-input';
import Spinner from './spinner';

const updateApiConfigItem = async (currentState, updateState) => {
    if (!currentState.apiConfigItem.btc_withdrawal_address) {
        return updateState({
            ...currentState,
            error: 'Please specify a btc withdrawal address',
            success: null,
        });
    }

    if (
        !currentState.apiConfigItem.erc20_withdrawal_address ||
        !currentState.apiConfigItem.erc20_withdrawal_address.startsWith('0x')
    ) {
        return updateState({
            ...currentState,
            error: 'Please specify a valid ERC20 withdrawal address beginning with 0x',
            success: null,
        });
    }

    if (!currentState.apiConfigItem.ips) {
        return updateState({
            ...currentState,
            error: 'Please specify some ip addresses',
            success: null,
        });
    }

    updateState({ ...currentState, loading: true, error: null, success: null });

    try {
        const { data } = await axios({
            url: process.env.REACT_APP_CORPORATES_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            data: {
                query: `mutation {
                updateApiConfig(input: {
                    corporate_id: "${currentState.corporateId}"
                    ips: "${currentState.apiConfigItem.ips.trim()}"
                    btc_withdrawal_address: "${currentState.apiConfigItem.btc_withdrawal_address.trim()}"
                    erc20_withdrawal_address: "${currentState.apiConfigItem.erc20_withdrawal_address.trim()}"
                }) { 
                    success
                    message
                    api_key
                }}`,
                variables: null,
            },
        });

        if (!data.data.updateApiConfig.success) {
            return updateState({
                ...currentState,
                error: data.data.updateApiConfig.message,
                loading: false,
                success: null,
            });
        }

        updateState({
            ...currentState,
            error: null,
            loading: false,
            success: true,
            apiConfigItem: {
                ...currentState.apiConfigItem,
                api_key: data.data.updateApiConfig.api_key,
            },
        });
    } catch (e) {
        updateState({
            ...currentState,
            error: 'We are currently unable to save the config',
            loading: false,
            success: null,
        });
    }
};

const loadApiConfigItem = async (currentState, updateState) => {
    updateState({ ...currentState, loading: true, error: null });

    try {
        const { data } = await axios({
            url: process.env.REACT_APP_CORPORATES_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            data: {
                query: `query {
                getApiConfig(corporate_id: "${currentState.corporateId}") { 
                    success
                    message
                    ips,
                    btc_withdrawal_address
                    erc20_withdrawal_address
                    api_key
                }}`,
                variables: null,
            },
        });

        if (!data.data.getApiConfig.success) {
            return updateState({
                ...currentState,
                error: data.data.getApiConfig.message,
                loading: false,
            });
        }

        updateState({
            ...currentState,
            error: null,
            loading: false,
            apiConfigItem: data.data.getApiConfig,
        });
    } catch (e) {
        updateState({
            ...currentState,
            error: 'We are currently unable to load the config',
            loading: false,
        });
    }
};

const ApiConfig = (props) => {
    const [currentState, updateState] = useState({
        corporateId: props.corporateId,
        apiConfigItem: {},
    });

    useEffect(() => {
        loadApiConfigItem(currentState, updateState);
    }, []);

    return (
        <Box sx={{ mb: 20, borderRadius: 12, background: '#FFFFFF', borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
            <Flex sx={{ ml: 40, mt: 10, mb: 30, alignItems: 'flex-start', flexDirection: 'column' }}>
                <Paragraph sx={{ mb: 40, mr: 30, fontSize: 20, fontWeight: '800' }}>Api configuration</Paragraph>

                {currentState.loading && <Spinner />}

                {currentState.error && (
                    <Paragraph sx={{ mb: 40, mr: 30, fontSize: 16, color: 'red' }}>{currentState.error}</Paragraph>
                )}

                {currentState.success && (
                    <Paragraph sx={{ mb: 40, mr: 30, fontSize: 16, color: 'green' }}>Saved</Paragraph>
                )}

                {currentState.apiConfigItem.api_key && (
                    <Flex sx={{ mb: 30 }}>
                        <Paragraph sx={{ mr: 20, fontSize: 17 }}>Api Key</Paragraph>

                        <Button
                            onClick={() => navigator.clipboard.writeText(currentState.apiConfigItem.api_key)}
                            sx={{
                                width: 100,
                                mt: '-5px',
                                height: 30,
                                fontSize: 11,
                                '&:active': {
                                    transform: 'translateY(1px)',
                                },
                            }}
                        >
                            Copy
                        </Button>
                    </Flex>
                )}

                <FormInput
                    onChange={(e) =>
                        updateState({
                            ...currentState,
                            apiConfigItem: { ...currentState.apiConfigItem, btc_withdrawal_address: e.target.value },
                        })
                    }
                    value={currentState?.apiConfigItem?.btc_withdrawal_address}
                    sx={{ width: 400, mb: 30, fontSize: 14 }}
                    label="BTC Withdrawal Address"
                />

                <FormInput
                    onChange={(e) =>
                        updateState({
                            ...currentState,
                            apiConfigItem: { ...currentState.apiConfigItem, erc20_withdrawal_address: e.target.value },
                        })
                    }
                    value={currentState?.apiConfigItem?.erc20_withdrawal_address}
                    sx={{ width: 400, mb: 30, fontSize: 14 }}
                    label="ERC20 Withdrawal Address"
                />

                <FormInput
                    onChange={(e) =>
                        updateState({
                            ...currentState,
                            apiConfigItem: { ...currentState.apiConfigItem, ips: e.target.value },
                        })
                    }
                    value={currentState?.apiConfigItem?.ips}
                    sx={{ width: 400, mb: 30, fontSize: 14 }}
                    label="IP Addresses (comma separated)"
                />

                <Button onClick={() => updateApiConfigItem(currentState, updateState)} sx={{ width: 150 }}>
                    Save
                </Button>
            </Flex>
        </Box>
    );
};

export default ApiConfig;
