/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
import { Flex, Paragraph, Button } from 'theme-ui';
import { PieChart, Pie, Tooltip, Cell, Legend } from 'recharts';
import { formatting } from '@accru-finance/shared-ui-libs';
import moment from 'moment';
import labelMapping from '../../utils/label-mapping';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export const Row = ({ label = '', value = '' }) => (
    <Flex>
        <Paragraph sx={{ fontSize: 14, mr: 20, fontWeight: 800, mb: 10, width: 180 }}>{label}:</Paragraph>
        <Paragraph sx={{ fontSize: 14, width: 300, overflowWrap: 'break-word' }}>{value}</Paragraph>
    </Flex>
);

const MixedFundTrade = ({ trade, close }) => {
    const pieChartData = trade.weightings?.map((item) => ({
        name: labelMapping(item.currency),
        value: parseFloat(item.fraction),
    }));

    return (
        <Flex sx={{ ml: 50, mt: 60, flexDirection: 'column' }}>
            <Flex sx={{ flexDirection: 'column' }}>
                <Flex sx={{ flexDirection: 'column' }}>
                    <Paragraph sx={{ fontWeight: '800', mb: 20 }}>Trade Details</Paragraph>
                    <Row label="Product" value={labelMapping(trade.product)} />
                    <Row label="Type" value={labelMapping(trade.type)} />
                    <Row label="Currency From" value={labelMapping(trade.currency_from)} />
                    <Row label="Amount From" value={formatting.formatForDisplay(trade.amount_from)} />

                    <Row label="Currency To" value={labelMapping(trade.currency_to)} />
                    <Row label="Amount To" value={formatting.formatForDisplay(trade.amount_to)} />

                    <Row label="Timestamp" value={moment.utc(trade.created_at).format('Do MMMM YYYY HH:ss')} />

                    <Row label="Exchange Rate" value={trade.exchange_rate} />

                    <Row label="Trading Fee" value={trade.trading_fee} />

                    <Row label="Provider Tx Id" value={trade.exec_id} />
                </Flex>
                <Flex sx={{ flexDirection: 'column', mt: 40, alignSelf: 'center' }}>
                    {trade.weightings?.length ? (
                        <>
                            <PieChart width={350} height={250}>
                                <Pie
                                    data={pieChartData}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={100}
                                    fill="#8884d8"
                                >
                                    {pieChartData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend
                                    formatter={(value, entry, index) => {
                                        return `${value} ${entry.payload.value * 100}%`;
                                    }}
                                />
                            </PieChart>
                        </>
                    ) : null}
                </Flex>
            </Flex>
            <Button onClick={close} sx={{ alignSelf: 'center', position: 'absolute', bottom: 30, width: 150 }}>
                OK
            </Button>
        </Flex>
    );
};
export default MixedFundTrade;
