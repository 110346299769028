/* eslint-disable no-unused-vars */
import { Flex, Paragraph, Button } from 'theme-ui';
import { formatting } from '@accru-finance/shared-ui-libs';
import moment from 'moment';
import labelMapping from '../../utils/label-mapping';

export const Row = ({ label = '', value = '' }) => (
    <Flex>
        <Paragraph sx={{ fontSize: 14, mr: 20, fontWeight: 800, mb: 10, width: 180 }}>{label}:</Paragraph>
        <Paragraph sx={{ fontSize: 14, width: 400, overflowWrap: 'break-word' }}>{value}</Paragraph>
    </Flex>
);

const RebalancingTrade = ({ trade, close }) => (
    <Flex sx={{ ml: 50, mt: 60, flexDirection: 'column' }}>
        <Paragraph sx={{ fontWeight: '800', mb: 20 }}>Trade Details</Paragraph>

        <Row label="Product" value={labelMapping(trade.product)} />
        <Row label="Type" value={labelMapping(trade.type)} />
        <Row label="Currency From" value={labelMapping(trade.currency_from)} />
        <Row label="Previous Amount" value={formatting.formatForDisplay(trade.previous_amount)} />

        <Row label="Currency To" value={labelMapping(trade.currency_to)} />
        <Row label="New Amount" value={formatting.formatForDisplay(trade.new_amount)} />

        <Row label="Timestamp" value={moment.utc(trade.created_at).format('Do MMMM YYYY HH:ss')} />

        <Row label="Exchange Rate" value={trade.exchange_rate} />

        <Button onClick={close} sx={{ alignSelf: 'center', position: 'absolute', bottom: 30, width: 150 }}>
            OK
        </Button>
    </Flex>
);

export default RebalancingTrade;
