/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
import axios from 'axios';
import { UpdateCorporateDetails } from '../../utils/update-corporate-details';

export const submitData = async (formState: any, reason: any) => {
    const {
        corporate_id,
        company_name,
        company_type,
        registration_number,
        website,
        contact_email,
        contact_telephone_number,
        contact_person,
        currency,
        next_kyc_refresh_date,
        industry,
        expected_amount_to_invest,
        licence_details,
        licence_details_validity_date,
        source_of_funds,
        correspondence_address,
        address,
        risk_score,
    } = formState;

    const res = await UpdateCorporateDetails({
        corporate_id,
        company_name,
        company_type,
        registration_number,
        website,
        contact_email,
        contact_telephone_number,
        contact_person,
        currency,
        next_kyc_refresh_date,
        industry,
        expected_amount_to_invest,
        licence_details,
        licence_details_validity_date,
        source_of_funds,
        correspondence_address,
        address,
        reason,
        risk_score,
    });
    return res;
};

export const validateData = (formState: any, type: any, updateErrorState: any) => {
    const errorState = {
        company_name: false,
        company_type: false,
        registration_number: false,
        website: false,
        contact_email: false,
        contact_telephone_number: false,
        contact_person: false,
        currency: false,
        next_kyc_refresh_date: false,
        industry: false,
        expected_amount_to_invest: false,
        licence_details: false,
        licence_details_validity_date: false,
        source_of_funds: false,
        correspondence_address: false,
        address: false,
        risk_score: false,
    };
    let errors = 0;

    if (type === 'company' || type === 'all') {
        if (formState.company_name === '') {
            errorState.company_name = true;
            errors++;
        }
        if (formState.company_type === '') {
            errorState.company_type = true;
            errors++;
        }
        if (formState.contact_person === '') {
            errorState.contact_person = true;
            errors++;
        }
        if (formState.contact_email === '') {
            errorState.contact_email = true;
            errors++;
        }
        if (formState.currency === '') {
            errorState.currency = true;
            errors++;
        }
        if (formState.contact_telephone_number === '') {
            errorState.contact_telephone_number = true;
            errors++;
        }
        if (formState.registration_number === '') {
            errorState.registration_number = true;
            errors++;
        }
        if (formState.website === '') {
            errorState.website = true;
            errors++;
        }
    }

    if (type === 'additional' || type === 'all') {
        if (formState.next_kyc_refresh_date === '' || formState.next_kyc_refresh_date === 'null') {
            errorState.next_kyc_refresh_date = true;
            errors++;
        }
        if (formState.industry === '') {
            errorState.industry = true;
            errors++;
        }
        if (formState.expected_amount_to_invest === '') {
            errorState.expected_amount_to_invest = true;
            errors++;
        }
        if (formState.risk_score === '' || formState.risk_score === 'select') {
            errorState.risk_score = true;
            errors++;
        }
        if (formState.licence_details === '') {
            errorState.licence_details = true;
            errors++;
        }
        if (
            (formState.licence_details === true || formState.licence_details === 'true') &&
            (formState.licence_details_validity_date === '' || formState.licence_details_validity_date === 'null')
        ) {
            errorState.licence_details_validity_date = true;
            errors++;
        }
        if (formState.source_of_funds === '') {
            errorState.source_of_funds = true;
            errors++;
        }
    }

    if (type === 'addressCompany' || type === 'all') {
        if (
            formState.address?.street === '' ||
            formState.address?.town === '' ||
            formState.address?.post_code === '' ||
            formState.address?.country === 'SELECT' ||
            formState.address?.country === '' ||
            formState.address === null
        ) {
            errorState.address = true;
            errors++;
        }
    }

    if (type === 'addressCorrespondence' || type === 'all') {
        if (
            formState.correspondence_address?.street === '' ||
            formState.correspondence_address?.town === '' ||
            formState.correspondence_address?.post_code === '' ||
            formState.correspondence_address?.country === 'SELECT' ||
            formState.correspondence_address?.country === '' ||
            formState.correspondence_address === null
        ) {
            errorState.correspondence_address = true;
            errors++;
        }
    }

    updateErrorState({ ...errorState });
    return errors < 1;
};

export const loadCountries = async (currentState: any, updateState: any, formState: any, updateFormState: any) => {
    try {
        const { data: countryList } = await axios({
            url: `https://cdn.accru.finance/countries/codes.json?cache=${new Date().getTime()}`,
        });

        updateState([{ code: 'SELECT', name: 'Please select a country' }, ...countryList]);
    } catch (e) {
        updateState([]);
    }
    updateFormState({ ...formState, loading: false });
};

export const createReasonString = (originalValues: any, formState: any, type: any) => {
    let reasonString = '';

    if (type === 'company') {
        if (originalValues.company_name !== formState.company_name) {
            reasonString += ` Company name changed from ${originalValues.company_name} to ${formState.company_name}, `;
        }
        if (originalValues.company_type !== formState.company_type) {
            reasonString += ` Company type changed from ${originalValues.company_type} to ${formState.company_type}, `;
        }
        if (originalValues.registration_number !== formState.registration_number) {
            reasonString += ` Company registration number changed from ${originalValues.registration_number} to ${formState.registration_number}, `;
        }
        if (originalValues.website !== formState.website) {
            reasonString += ` Company website changed from ${originalValues.website} to ${formState.website}, `;
        }
        if (originalValues.contact_email !== formState.contact_email) {
            reasonString += ` Company email changed from ${originalValues.contact_email} to ${formState.contact_email}, `;
        }
        if (originalValues.contact_telephone_number !== formState.contact_telephone_number) {
            reasonString += ` Company phone number changed from ${originalValues.contact_telephone_number} to ${formState.contact_telephone_number}, `;
        }
        if (originalValues.contact_person !== formState.contact_person) {
            reasonString += ` Company contact person changed from ${originalValues.contact_person} to ${formState.contact_person}, `;
        }
        if (originalValues.currency !== formState.currency) {
            reasonString += ` Company currency changed from ${originalValues.currency} to ${formState.currency}, `;
        }
    }

    if (type === 'additional') {
        if (originalValues.next_kyc_refresh_date !== formState.next_kyc_refresh_date) {
            reasonString += ` KYC refresh date changed from ${originalValues.next_kyc_refresh_date} to ${formState.next_kyc_refresh_date}, `;
        }
        if (originalValues.industry !== formState.industry) {
            reasonString += ` Company industry changed from ${originalValues.industry} to ${formState.industry}, `;
        }
        if (originalValues.expected_amount_to_invest !== formState.expected_amount_to_invest) {
            reasonString += ` Expected amount to invest changed from ${originalValues.expected_amount_to_invest} to ${formState.expected_amount_to_invest}, `;
        }
        if (originalValues.licence_details !== formState.licence_details) {
            reasonString += ` License details changed from ${originalValues.licence_details} to ${formState.licence_details}, `;
        }
        if (originalValues.licence_details_validity_date !== formState.licence_details_validity_date) {
            reasonString += ` License details validity date changed from ${originalValues.licence_details_validity_date} to ${formState.licence_details_validity_date}, `;
        }
        if (originalValues.source_of_funds !== formState.source_of_funds) {
            reasonString += ` Source of funds changed from ${originalValues.source_of_funds} to ${formState.source_of_funds}, `;
        }
        if (originalValues.risk_score !== formState.risk_score) {
            reasonString += ` Risk score changed from ${originalValues.risk_score} to ${formState.risk_score}, `;
        }
    }

    if (type === 'addressCompany') {
        const origAddress = originalValues?.address || {};
        const currentAddress = formState?.address || {};
        if (origAddress?.street !== currentAddress?.street) {
            reasonString += ` Address street changed from ${origAddress?.street} to ${currentAddress?.street}, `;
        }
        if (origAddress?.sub_street !== currentAddress?.sub_street) {
            reasonString += ` Address sub street changed from ${origAddress?.sub_street} to ${currentAddress?.sub_street}, `;
        }
        if (origAddress?.town !== currentAddress?.town) {
            reasonString += ` Address town changed from ${origAddress?.town} to ${currentAddress?.town}, `;
        }
        if (origAddress?.post_code !== currentAddress?.post_code) {
            reasonString += ` Address post code changed from ${origAddress?.post_code} to ${currentAddress?.post_code}, `;
        }
        if (origAddress?.state !== currentAddress?.state) {
            reasonString += ` Address state changed from ${origAddress?.state} to ${currentAddress?.state}, `;
        }
        if (origAddress?.country !== currentAddress?.country) {
            reasonString += ` Address country changed from ${origAddress?.country} to ${currentAddress?.country}, `;
        }
    }

    if (type === 'addressCorrespondence') {
        const origCorrespondenceAddress = originalValues?.correspondence_address || {};
        const currentCorrespondenceAddress = formState?.correspondence_address || {};
        if (origCorrespondenceAddress?.street !== currentCorrespondenceAddress?.street) {
            reasonString += ` Correspondence address street changed from ${origCorrespondenceAddress?.street} to ${currentCorrespondenceAddress?.street}, `;
        }
        if (origCorrespondenceAddress?.sub_street !== currentCorrespondenceAddress?.sub_street) {
            reasonString += ` Correspondence address sub street changed from ${origCorrespondenceAddress?.sub_street} to ${currentCorrespondenceAddress?.sub_street}, `;
        }
        if (origCorrespondenceAddress?.town !== currentCorrespondenceAddress?.town) {
            reasonString += ` Correspondence address town changed from ${origCorrespondenceAddress?.town} to ${currentCorrespondenceAddress?.town}, `;
        }
        if (origCorrespondenceAddress?.post_code !== currentCorrespondenceAddress?.post_code) {
            reasonString += ` Correspondence address post code changed from ${origCorrespondenceAddress?.post_code} to ${currentCorrespondenceAddress?.post_code}, `;
        }
        if (origCorrespondenceAddress?.state !== currentCorrespondenceAddress?.state) {
            reasonString += ` Correspondence address state changed from ${origCorrespondenceAddress?.state} to ${currentCorrespondenceAddress?.state}, `;
        }
        if (origCorrespondenceAddress?.country !== currentCorrespondenceAddress?.country) {
            reasonString += ` Correspondence address country changed from ${origCorrespondenceAddress?.country} to ${currentCorrespondenceAddress?.country}, `;
        }
    }

    return reasonString;
};
