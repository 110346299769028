/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import axios from 'axios';
import { Flex, Paragraph, Button } from 'theme-ui';
import FormInput from './form-input';
import Spinner from './spinner';

const validateEmail = (email) =>
    email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

const changeEmail = async (currentState, updateState) => {
    try {
        if (!currentState.newEmailAddress || !validateEmail(currentState.newEmailAddress)) {
            return updateState({ ...currentState, error: 'Please enter a valid email address', loading: false });
        }

        updateState({ ...currentState, loading: true });

        const { data } = await axios({
            url: process.env.REACT_APP_USERS_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
            data: {
                query: `mutation {
                    adminTools(input: {
                        user_id: "${currentState.user_id}"
                        tool: "change_email"
                        new_email_address: "${currentState.newEmailAddress}"
                    }) { 
                        success
                        message
                    }
                }`,
                variables: null,
            },
        });

        if (!data.data.adminTools.success) {
            return updateState({ ...currentState, error: data.data.adminTools.message, loading: false });
        }

        return window.location.reload();
    } catch (e) {
        return updateState({ ...currentState, error: 'Failed to update', loading: false });
    }
};

const ChangeEmail = (props) => {
    const [currentState, updateState] = useState({
        error: '',
        loading: false,
        user_id: props.userId,
        newEmailAddress: '',
    });

    return (
        <Flex
            sx={{
                justifyContent: 'space-between',
                width: '100%',
                height: '65px',
                flexDirection: 'column',
                ml: 40,
                mt: 70,
            }}
        >
            {currentState.loading && <Spinner />}

            <FormInput
                onChange={(e) =>
                    updateState({
                        ...currentState,
                        newEmailAddress: e.target.value.toLowerCase(),
                    })
                }
                value={currentState.newEmailAddress}
                sx={{ width: 280 }}
                label="New Email Address"
            />

            <Button onClick={() => changeEmail(currentState, updateState)} sx={{ width: 170, mt: 10 }}>
                Submit
            </Button>

            {currentState.error && <Paragraph sx={{ color: 'red', mt: 20 }}>{currentState.error}</Paragraph>}
        </Flex>
    );
};

export default ChangeEmail;
