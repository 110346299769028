/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

import { Flex, Box, Paragraph, Select } from 'theme-ui';
import Input from '../form-input';
import { CountryListSelect } from './country-select';
import { validateDOB } from '../../utils/validation-helpers';

const sourceOfFunds = [
    { id: 'salary', label: 'Salary' },
    { id: 'dividends', label: 'Dividends' },
    { id: 'loan', label: 'Loan' },
    { id: 'inheritance', label: 'Inheritance' },
    { id: 'property_sale', label: 'Property Sale' },
    { id: 'savings', label: 'Savings' },
    { id: 'gift_or_donation', label: 'Gift/Donation' },
];

const intendedAmountToInvest = [
    { id: 'not_applicable', label: '0' },
    { id: 'between_1_10000', label: '1-10,000' },
    { id: 'between_10001_25000', label: '10,00-125,000' },
    { id: 'between_25001_50000', label: '25,001-50,000' },
    { id: 'between_50001_100000', label: '50,001-100,000' },
    { id: 'over_100000', label: 'Over 100,000' },
];
const intendedAmountToInvestCrypto = [
    { id: 'not_applicable', label: '0' },
    { id: 'between_1_50000', label: '1-50,000' },
    { id: 'between_50001_100000', label: '50,001-100,000' },
    { id: 'between_100001_300000', label: '100,001-300,001' },
    { id: 'between_300001_500000', label: '300,001-500,000' },
    { id: 'over_500000', label: 'Over 500,000' },
];

const KYCDetails = ({ formState, updateFormState, countries = [], errorState, updateErrorState }: any) => {
    const {
        occupation,
        source_of_funds,
        date_of_birth,
        nationality,
        place_of_birth,
        country_of_birth,
        personal_id_number,
        other_nationality,
        expected_amount_to_invest,
        expected_amount_to_invest_crypto,
    } = formState;

    return (
        <Flex sx={{ justifyContent: 'center', width: '100%' }}>
            <Box sx={{ width: '100%' }}>
                <Paragraph sx={{ fontWeight: 600, textAlign: 'center', mb: '20px' }}>KYC Details</Paragraph>
                <Flex>
                    <Box sx={{ width: '100%', mr: '10px' }}>
                        <Input
                            sx={{
                                mb: '12px',
                                alignSelf: 'center',
                                width: '100%',
                                background: '#FFFFFF',
                                border: errorState.occupation ? '1px red solid' : '1px solid grey',
                            }}
                            type="text"
                            id="occupation"
                            data-testid="occupation"
                            name="occupation"
                            label="Occupation"
                            placeholder="enter occupation"
                            onChange={(e: any) => updateFormState({ ...formState, occupation: e.target.value })}
                            error={null}
                            value={occupation}
                        />

                        <Paragraph
                            sx={{
                                mt: '0px',
                                textAlign: 'left',
                                maxWidth: '450px',
                                color: '#09042B',
                                fontSize: '16px',
                            }}
                        >
                            Source Of Funds
                        </Paragraph>
                        <Select
                            sx={{
                                mt: '7px',
                                width: '100%',
                                height: '37px',
                                border: errorState.source_of_funds ? '1px red solid' : '1px solid grey',
                            }}
                            value={source_of_funds}
                            onChange={(e: any) => updateFormState({ ...formState, source_of_funds: e.target.value })}
                            arrow={
                                <Box
                                    as="svg" // @ts-ignore
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="currentcolor"
                                    sx={{
                                        ml: -28,
                                        mt: 15,
                                        alignSelf: 'center',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                                </Box>
                            }
                        >
                            <option key="select" value="select">
                                Select a source of funds
                            </option>

                            {sourceOfFunds.map((item: any) => (
                                <option key={item.id} value={item.id}>
                                    {item.label}
                                </option>
                            ))}
                        </Select>
                        <Box sx={{ height: '10px' }} />

                        <Input
                            sx={{
                                mb: '12px',
                                alignSelf: 'center',
                                width: '100%',
                                background: '#FFFFFF',
                                border: errorState.date_of_birth ? '2px red solid' : '1px solid grey',
                            }}
                            type="text"
                            id="date_of_birth"
                            data-testid="date_of_birth"
                            name="date_of_birth"
                            label="Date Of Birth: (dd-mm-yyyy)"
                            placeholder="DD-MM-YYYY"
                            onChange={(e: any) => {
                                updateErrorState({ ...errorState, date_of_birth: !validateDOB(e.target.value) });
                                updateFormState({ ...formState, date_of_birth: e.target.value });
                            }}
                            error={validateDOB(date_of_birth)}
                            value={date_of_birth}
                        />
                        <CountryListSelect
                            error={errorState.nationality}
                            label="Nationality"
                            value={nationality}
                            countries={countries}
                            updateField={(e: any) => updateFormState({ ...formState, nationality: e })}
                        />
                        <Box sx={{ height: '10px' }} />
                        <Input
                            sx={{
                                mb: '12px',
                                alignSelf: 'center',
                                width: '100%',
                                background: '#FFFFFF',
                                border: errorState.place_of_birth ? '1px red solid' : '1px solid grey',
                            }}
                            type="text"
                            id="place_of_birth"
                            data-testid="place_of_birth"
                            name="place_of_birth"
                            label="Place of birth"
                            placeholder="enter place of birth"
                            onChange={(e: any) => updateFormState({ ...formState, place_of_birth: e.target.value })}
                            error={null}
                            value={place_of_birth}
                        />
                    </Box>
                    <Box sx={{ width: '100%', ml: '10px' }}>
                        <CountryListSelect
                            error={errorState.country_of_birth}
                            label="Country of birth"
                            value={country_of_birth}
                            countries={countries}
                            updateField={(e: any) => updateFormState({ ...formState, country_of_birth: e })}
                        />
                        <Box sx={{ height: '10px' }} />

                        <Input
                            sx={{
                                mb: '12px',
                                alignSelf: 'center',
                                width: '100%',
                                background: '#FFFFFF',
                                border: '1px solid grey',
                            }}
                            type="text"
                            id="personal_id_number"
                            data-testid="personal_id_number"
                            name="personal_id_number"
                            label="Personal ID number"
                            placeholder="Enter personal id number"
                            onChange={(e: any) => updateFormState({ ...formState, personal_id_number: e.target.value })}
                            error={null}
                            value={personal_id_number}
                        />

                        <CountryListSelect
                            error={errorState.other_nationality}
                            label="Other nationality"
                            value={other_nationality}
                            countries={countries}
                            updateField={(e: any) => updateFormState({ ...formState, other_nationality: e })}
                        />
                        <Box sx={{ height: '10px' }} />

                        <Paragraph
                            sx={{
                                mt: '0px',
                                textAlign: 'left',
                                maxWidth: '450px',
                                color: '#09042B',
                                fontSize: '16px',
                            }}
                        >
                            Expected fiat amount to invest
                        </Paragraph>
                        <Select
                            sx={{
                                mt: '7px',
                                width: '100%',
                                height: '37px',
                                border: errorState.expected_amount_to_invest ? '1px red solid' : '1px solid grey',
                            }}
                            value={expected_amount_to_invest}
                            onChange={(e: any) =>
                                updateFormState({ ...formState, expected_amount_to_invest: e.target.value })
                            }
                            arrow={
                                <Box
                                    as="svg" // @ts-ignore
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="currentcolor"
                                    sx={{
                                        ml: -28,
                                        mt: 15,
                                        alignSelf: 'center',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                                </Box>
                            }
                        >
                            <option key="select" value="select">
                                Select your source of funds
                            </option>

                            {intendedAmountToInvest.map((item: any) => (
                                <option key={item.id} value={item.id}>
                                    {item.label}
                                </option>
                            ))}
                        </Select>

                        <Paragraph
                            sx={{
                                mt: '7px',
                                textAlign: 'left',
                                maxWidth: '450px',
                                color: '#09042B',
                                fontSize: '16px',
                            }}
                        >
                            Expected crypto amount to invest
                        </Paragraph>
                        <Select
                            sx={{
                                mt: '7px',
                                width: '100%',
                                height: '37px',
                                border: errorState.expected_amount_to_invest_crypto
                                    ? '1px red solid'
                                    : '1px solid grey',
                            }}
                            value={expected_amount_to_invest_crypto}
                            onChange={(e: any) =>
                                updateFormState({ ...formState, expected_amount_to_invest_crypto: e.target.value })
                            }
                            arrow={
                                <Box
                                    as="svg" // @ts-ignore
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="currentcolor"
                                    sx={{
                                        ml: -28,
                                        mt: 15,
                                        alignSelf: 'center',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                                </Box>
                            }
                        >
                            <option key="select" value="select">
                                Select
                            </option>

                            {intendedAmountToInvestCrypto.map((item: any) => (
                                <option key={item.id} value={item.id}>
                                    {item.label}
                                </option>
                            ))}
                        </Select>

                        <Box sx={{ height: '15px' }} />
                    </Box>
                </Flex>
            </Box>
        </Flex>
    );
};

export default KYCDetails;
