/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import { Paragraph, Flex, Box, Image } from 'theme-ui';
import { useState } from 'react';
import { getUsers } from '../utils/pagination';

const RowWrapper = (props: any) => {
    if (props.href) {
        return (
            <a target={props.openInNewTab ? '_blank' : '_self'} style={{ textDecoration: 'none' }} href={props.href}>
                {props.children}
            </a>
        );
    }

    return props.children;
};

// @ts-ignore
export const Title = ({ title = '', type = '', state = {}, updateState, showEditIcon = true }) => (
    <Paragraph
        sx={{
            fontSize: '18px',
            lineHeight: '20px',
            color: '#000',
            fontWeight: '600',
            borderBottom: '1px solid #AAA',
            paddingBottom: '5px',
            marginRight: 50,
            position: 'relative',
        }}
    >
        {title}
        {showEditIcon && (
            <i
                style={{ marginLeft: '10px', fontSize: '12px', cursor: 'pointer', position: 'absolute', top: '3px' }}
                className="fa fa-pencil"
                aria-hidden="true"
                onClick={() => updateState({ ...state, showUpdateOverlay: true, updateOverlayType: type })}
            />
        )}
    </Paragraph>
);

export const DisplayItem = ({
    title = '',
    value = '',
    type = '',
    sx = {},
    valueSx = {},
    required = false,
    linkToPage = false,
    pageUrl = '',
}) => (
    <Flex
        sx={{
            minWidth: '120px',
            flexDirection: 'column',
            marginTop: '25px',
            marginRight: '25px',
            textAlign: 'left',
            ...(sx || {}),
        }}
    >
        <Paragraph sx={{ fontSize: '12px', minHeight: 30, fontWeight: 600 }}>{title}</Paragraph>

        {type === 'kyc' && (
            <Box
                sx={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '100px',
                    marginTop: '10px',
                    backgroundColor: value ? 'green' : 'red',
                }}
            />
        )}
        <RowWrapper href={linkToPage ? pageUrl : null}>
            {type !== 'kyc' && (
                <Paragraph
                    sx={{
                        fontSize: '14px',
                        color: required && value === '' ? 'red' : linkToPage ? 'linkColor' : '#1C1C1C',
                        marginTop: '10px',
                        fontWeight: '500',
                        cursor: linkToPage ? 'pointer' : 'default',
                        // textDecoration: linkToPage ? 'underline' : 'unset',
                        ...(valueSx || {}),
                    }}
                >
                    {!required ? `${value}` : `${value === '' ? 'required' : value}`}
                </Paragraph>
            )}
        </RowWrapper>
    </Flex>
);

export const TableHeaderItem = ({ text, topLeft = false, topRight = false }: any) => (
    <Box
        sx={{
            border: '1px solid #09042B',
            flex: 1,
            textAlign: 'center',
            fontSize: '13px',
            fontWeight: '600',
            lineHeight: '35px',
            padding: '0 10px',
            borderTopLeftRadius: topLeft ? '12px' : '',
            borderTopRightRadius: topRight ? '12px' : '',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor: '#09042B',
        }}
    >
        {text}
    </Box>
);

export const TableHeader = () => (
    <Flex
        sx={{
            justifyContent: 'space-between',
            width: '100%',
            height: '40px',
            color: '#fff',
        }}
    >
        <TableHeaderItem text="ID" topLeft />
        <TableHeaderItem text="First Name" />
        <TableHeaderItem text="Last Name" />
        <TableHeaderItem text="Email" />
        <TableHeaderItem text="KYC Status" />
        <TableHeaderItem text=" " topRight />
    </Flex>
);
export const TableHeaderCorporate = () => (
    <Flex
        sx={{
            justifyContent: 'space-between',
            width: '100%',
            height: '40px',
            color: '#fff',
        }}
    >
        <TableHeaderItem text="ID" topLeft />
        <TableHeaderItem text="Name" />
        <TableHeaderItem text=" " topRight />
    </Flex>
);

export const TableRowItem = ({
    text,
    action = () => {},
    type = '',
    expandRow = false,
    expandTypeText = false,
    large = false,
    openInNewTab = false,
    href,
    copyText = false,
}: any) => {
    const [showMore, updateShowMore] = useState(false);
    const [copied, updateCopyState] = useState(false);

    const copyValue = () => {
        updateCopyState(true);
        const theText = text.replace(/(\r\n|\n|\r)/gm, '');
        navigator.clipboard.writeText(theText);
        setTimeout(() => {
            updateCopyState(false);
        }, 1000);
    };

    let fontSize = showMore && expandTypeText ? '13px' : '13px';

    if (large) {
        fontSize = '50px';
    }

    return (
        <Flex
            sx={{
                borderBottom: '1px solid #F1F1F1',
                flex: 1,
                textAlign: 'center',
                lineHeight: '20px',
                padding: '20px',
                background: '#FFF',
                width: '90%',
                justifyContent: 'center',
                position: 'relative',
                minHeight: showMore ? '140px' : '90px',
                cursor: type === 'view' || expandTypeText ? 'pointer' : 'default',
            }}
            onClick={() => {
                if (!expandTypeText) {
                    action();
                }
            }}
        >
            <RowWrapper openInNewTab={openInNewTab} href={href}>
                <Paragraph
                    sx={{
                        color: copied ? 'green' : type === 'view' ? '#5100C9' : '#002033',
                        fontSize,
                        fontWeight: 400,
                        textAlign: expandTypeText ? 'left' : 'center',
                        width: type === 'view' ? 100 : '100%',
                        position: 'relative',
                    }}
                >
                    {expandRow && !copied
                        ? `${showMore && expandTypeText ? text : text.substring(0, 50)} ${showMore ? '' : '...'}`
                        : copied
                        ? 'copied to clipboard'
                        : text}

                    {expandTypeText && (
                        <i
                            style={{
                                fontSize: '13px',
                                fontWeight: 600,
                                position: 'absolute',
                                right: '-20px',
                                top: '5px',
                                cursor: 'pointer',
                                color: '#002033',
                            }}
                            className={`fal fa-chevron-${showMore ? 'up' : 'down'}`}
                            onClick={() => {
                                if (expandTypeText) {
                                    updateShowMore(!showMore);
                                }
                            }}
                        />
                    )}
                    {copyText && (
                        <i
                            style={{
                                fontSize: '13px',
                                fontWeight: 600,
                                position: 'absolute',
                                right: '0px',
                                top: '5px',
                                cursor: 'pointer',
                                color: '#002033',
                            }}
                            className="fal fa-copy"
                            onClick={() => {
                                if (copyText) {
                                    copyValue();
                                }
                            }}
                        />
                    )}
                </Paragraph>
            </RowWrapper>
        </Flex>
    );
};

const kycStatusMapping = {
    not_started: 'Not Started',
    pending: 'Pending',
    retry_all: 'Retry all',
    retry_onfido: 'Retry Onfido',
    retry_poa: 'Retry Proof of Address',
    verified: 'Verified',
    blocked: 'Blocked',
    rejected: 'Rejected',
    edd: 'EDD Legacy (Soon to be removed)',
    edd_onboarding: 'EDD Onboarding',
    edd_ongoing: 'EDD Ongoing',
    edd_on_hold: 'EDD On Hold',
};

export const TableRow = ({ user, i }: any) => (
    <Flex key={i} sx={{ justifyContent: 'space-between', width: '100%', height: '100px' }}>
        <TableRowItem text={user.user_id} />
        <TableRowItem text={user.first_name} />
        <TableRowItem text={user.last_name} />
        <TableRowItem text={user.email_address} />
        <TableRowItem
            text={
                // @ts-ignore
                kycStatusMapping[user.kyc_status] || ''
            }
        />
        <TableRowItem openInNewTab type="view" text="View" href={`/user/${user.user_id}`} />
    </Flex>
);

export const TableRowCorporate = ({ corporate, history }: any) => (
    <Flex
        key={`${corporate.id}-${corporate.name}-row`}
        sx={{ justifyContent: 'space-between', width: '100%', height: '100px' }}
    >
        <TableRowItem text={corporate.id} />
        <TableRowItem text={corporate.name} />
        <TableRowItem type="view" text="View" href={`/corporate/${corporate.id}`} />
    </Flex>
);

export const Pagination = ({
    state,
    updateState,
    paginationData,
    setPaginationData,
    dataFetchFunction = getUsers,
}: any) => (
    <Flex sx={{ width: '100px', margin: '20px auto', justifyContent: 'space-around' }}>
        {paginationData.pageNumber > 0 && (
            <Box
                sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                onClick={() => dataFetchFunction(state, updateState, 'prev', paginationData, setPaginationData)}
            >
                <Image
                    src="https://cdn.accru.finance/arrows/enabled-left.png"
                    width="26"
                    height="26"
                    alt="prev button"
                />
            </Box>
        )}

        {paginationData.pageNumber <= 0 && (
            <Box sx={{ width: '26px', height: '26px', cursor: 'not-allowed' }}>
                <Image
                    src="https://cdn.accru.finance/arrows/disabled-left.png"
                    width="26"
                    height="26"
                    alt="prev button"
                />
            </Box>
        )}

        <Paragraph sx={{ fontWeight: '600', fontSize: '13px' }}>{paginationData?.pageNumber + 1}</Paragraph>

        {paginationData.paginationArray[paginationData.pageNumber + 1] && (
            <Box
                sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                onClick={() => dataFetchFunction(state, updateState, 'next', paginationData, setPaginationData)}
            >
                <Image
                    src="https://cdn.accru.finance/arrows/enabled-right.png"
                    width="26"
                    height="26"
                    alt="next button"
                />
            </Box>
        )}

        {!paginationData.paginationArray[paginationData.pageNumber + 1] && (
            <Box sx={{ width: '26px', height: '26px', cursor: 'not-allowed' }}>
                <Image
                    src="https://cdn.accru.finance/arrows/disabled-right.png"
                    width="26"
                    height="26"
                    alt="next button"
                />
            </Box>
        )}
    </Flex>
);
