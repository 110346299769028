/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import { Flex, Box } from 'theme-ui';
import { Title } from './table';
import Item from './user-data-item';
import labelMapping from '../utils/label-mapping';

const formatAddress = (address) => {
    let addressText = '';
    addressText += address?.street ? `${address?.street}` : '';
    addressText += address?.sub_street
        ? `, ${address?.sub_street}
    `
        : '';
    addressText += address?.town
        ? `, 
        ${address?.town} `
        : '';
    addressText += address?.state
        ? ` ${address?.state}
    `
        : '';
    addressText += address?.post_code ? `,${address?.post_code}` : '';

    return addressText;
};

const ContactData = ({ state }) => (
    <Flex>
        <Flex sx={{ mt: '30px', flexDirection: 'column' }}>
            <Item title="Email" value={`${state.email_address}`} />
            <Item title="Tel. number" value={`${state.phone_number ? state.phone_number : ''}`} />
        </Flex>

        <Flex sx={{ mt: '30px', flexDirection: 'column', borderLeft: '1px grey solid', pl: '50px', pr: '50px' }}>
            <Item title="Address" value={`${state.address ? formatAddress(state.address) : ''}`} />
            <Flex sx={{ mt: 10 }} />
            <Item
                title="Country Of Residence"
                value={
                    state?.address?.country
                        ? state.countriesList.find((item) => item.code === state?.address?.country)?.name
                        : ''
                }
            />
            <Flex sx={{ mt: 10 }} />
            <Item
                title="Country Risk Level"
                value={
                    state?.address?.country
                        ? labelMapping(
                              state.countriesList.find((item) => item.code === state?.address?.country)?.rating
                          )
                        : ''
                }
            />
        </Flex>
    </Flex>
);

export default ({ state, updateState }) => (
    <Box sx={{ width: '100%', marginLeft: '15px', mb: 40 }}>
        <Title title="Contact Details" type="contact" state={state} updateState={updateState} />
        <ContactData state={state} updateState={updateState} />
        <Box sx={{ height: state?.corporate_id ? '20px' : '0' }} />
    </Box>
);
