/** @jsxImportSource theme-ui */
import { Button, Flex, Paragraph } from 'theme-ui';

const Confirm = ({ text, confirmHandler }) => (
    <Flex sx={{ mt: 100, alignItems: 'center', flexDirection: 'column' }}>
        <Paragraph sx={{ ml: 40, mb: 40, mr: 30, textAlign: 'center' }}>{text}</Paragraph>
        <Button sx={{ width: 100 }} onClick={() => confirmHandler()}>
            OK
        </Button>
    </Flex>
);

export default Confirm;
