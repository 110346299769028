import axios from 'axios';
import { useState } from 'react';
import { Flex, Paragraph, Button, Switch, Spinner } from 'theme-ui';

const save = async (currentState: any, updateState: any) => {
    try {
        updateState({ ...currentState, loading: true, error: null, showSuccessMessage: false });
        const { data } = await axios({
            url: process.env.REACT_APP_USERS_API,
            method: 'POST',
            data: {
                query: `mutation {
                    adminTogglePermissions(input: {
                        user_id: "${currentState.user.user_id}", 
                        enable: ${currentState.enabled},                
                        crypto_withdrawals: ${currentState.crypto_withdrawals},                
                        fiat_withdrawals: ${currentState.fiat_withdrawals},                
                        buying: ${currentState.buying},                
                        selling: ${currentState.selling}                
                    }) { 
                        success
                        message
                        code  
                    }
                }`,
            },
        });

        if (!data.data?.adminTogglePermissions.success) {
            throw new Error('failed');
        }

        return updateState({ ...currentState, loading: false, error: null, showSuccessMessage: true });
    } catch {
        return updateState({
            ...currentState,
            loading: false,
            showSuccessMessage: false,
            error: 'We are currently unable to set those permissions, please try again later',
        });
    }
};

const UserPermissions = ({ user, expanded }: any) => {
    const [currentState, updateState] = useState({
        enabled: user.enabled,
        loading: false,
        showSuccessMessage: false,
        user,
        expanded: expanded || false,
        error: null,
        crypto_withdrawals:
            typeof user?.permissions?.crypto_withdrawals === 'boolean' ? user?.permissions.crypto_withdrawals : true,
        fiat_withdrawals:
            typeof user?.permissions?.fiat_withdrawals === 'boolean' ? user?.permissions.fiat_withdrawals : true,
        buying: typeof user?.permissions?.buying === 'boolean' ? user?.permissions.buying : true,
        selling: typeof user?.permissions?.selling === 'boolean' ? user?.permissions.selling : true,
    });

    return (
        <Flex
            sx={{
                width: '100%',
                maxWidth: '900px',
                backgroundColor: 'white',
                borderRadius: '12px',
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                flexDirection: 'row',
            }}
        >
            <Flex
                sx={{
                    margin: currentState.expanded ? '50px 50px 30px' : '35px 30px 15px',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <Flex
                    onClick={() => updateState({ ...currentState, expanded: !currentState.expanded })}
                    sx={{
                        justifyContent: 'space-between',
                        height: 40,
                        borderBottom: currentState.expanded ? '1px solid #AAA' : 0,
                        mr: '2px',
                    }}
                >
                    <Flex>
                        <Paragraph sx={{ fontWeight: '800', fontSize: 18, cursor: 'pointer' }}>
                            User Permissions
                        </Paragraph>

                        {currentState.loading && <Spinner sx={{ ml: 20, mt: -15, cursor: 'pointer' }} />}
                    </Flex>
                    <i
                        style={{
                            fontSize: '13px',
                            fontWeight: 600,
                            cursor: 'pointer',
                            marginTop: 6,
                            marginLeft: 15,
                        }}
                        className={`fal fa-chevron-${currentState.expanded ? 'up' : 'down'}`}
                    />
                </Flex>
                {currentState.expanded && (
                    <Flex sx={{ flexDirection: 'column' }}>
                        <Flex sx={{ mt: 40 }}>
                            <Switch
                                label="Login"
                                onClick={() => updateState({ ...currentState, enabled: !currentState.enabled })}
                                defaultChecked={currentState.enabled}
                            />
                        </Flex>
                        <Flex sx={{ flexDirection: 'row', mt: 60, alignItems: 'center' }}>
                            <Flex>
                                <Switch
                                    label="Crypto Withdrawals"
                                    onClick={() =>
                                        updateState({
                                            ...currentState,
                                            crypto_withdrawals: !currentState.crypto_withdrawals,
                                        })
                                    }
                                    defaultChecked={currentState.crypto_withdrawals}
                                />
                            </Flex>

                            <Flex>
                                <Switch
                                    label="Fiat Withdrawals"
                                    sx={{ ml: 40 }}
                                    onClick={() =>
                                        updateState({
                                            ...currentState,
                                            fiat_withdrawals: !currentState.fiat_withdrawals,
                                        })
                                    }
                                    defaultChecked={currentState.fiat_withdrawals}
                                />
                            </Flex>
                        </Flex>
                        <Flex sx={{ flexDirection: 'row', mt: 60, alignItems: 'center' }}>
                            <Flex>
                                <Switch
                                    label="Buying"
                                    onClick={() => updateState({ ...currentState, buying: !currentState.buying })}
                                    defaultChecked={currentState.buying}
                                />
                            </Flex>

                            <Flex>
                                <Switch
                                    sx={{ ml: 40 }}
                                    label="Selling"
                                    onClick={() => updateState({ ...currentState, selling: !currentState.selling })}
                                    defaultChecked={currentState.selling}
                                />
                            </Flex>
                        </Flex>

                        {currentState.error && (
                            <Paragraph sx={{ mt: 40, color: 'red' }}>{currentState.error}</Paragraph>
                        )}

                        {currentState.showSuccessMessage && (
                            <Paragraph sx={{ mt: 40, color: 'green' }}>Saved</Paragraph>
                        )}

                        <Button
                            onClick={() => save(currentState, updateState)}
                            sx={{
                                width: 200,
                                mt: currentState.error || currentState.showSuccessMessage ? 40 : 70,
                                alignSelf: 'center',
                            }}
                        >
                            Save
                        </Button>
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};

export default UserPermissions;
