/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { Flex, Box, Paragraph, Select } from 'theme-ui';
import Input from '../form-input';

const sourceOfFunds = [
    { id: 'salary', label: 'Salary' },
    { id: 'dividends', label: 'Dividends' },
    { id: 'loan', label: 'Loan' },
    { id: 'inheritance', label: 'Inheritance' },
    { id: 'property_sale', label: 'Property Sale' },
    { id: 'savings', label: 'Savings' },
    { id: 'gift_or_donation', label: 'Gift/Donation' },
    { id: 'crypto', label: 'Existing Cryptocurrency' },
    { id: 'not_applicable', label: 'Not Applicable' },
];

const intendedAmountToInvest = [
    { id: 'between_0_50000', label: '0-50,000' },
    { id: 'between_50000_100000', label: '50,000-100,000' },
    { id: 'over_100000', label: 'Over 100,000' },
    { id: 'not_applicable', label: 'Not Applicable' },
];

const licenseOptions = [
    { id: 'true', label: 'True' },
    { id: 'false', label: 'False' },
];

const riskOptions = [
    { id: 'low', label: 'Low' },
    { id: 'medium', label: 'Medium' },
    { id: 'high', label: 'High' },
];

const AdditionalInfo = ({ formState, updateFormState, countries = [], errorState }: any) => {
    const {
        next_kyc_refresh_date,
        industry,
        expected_amount_to_invest,
        licence_details,
        licence_details_validity_date,
        source_of_funds,
        risk_score,
    } = formState;

    return (
        <Flex sx={{ justifyContent: 'center', width: '100%' }}>
            <Box sx={{ width: '100%' }}>
                <Paragraph sx={{ fontWeight: 600, textAlign: 'center', mb: '20px' }}>Additional Details</Paragraph>
                <Flex>
                    <Box sx={{ width: '100%', mr: '10px' }}>
                        <Input
                            sx={{
                                mb: '12px',
                                alignSelf: 'center',
                                width: '100%',
                                background: '#FFFFFF',
                                border: errorState.next_kyc_refresh_date ? '1px red solid' : '1px solid grey',
                            }}
                            type="date"
                            id="next_kyc_refresh_date"
                            data-testid="next_kyc_refresh_date"
                            name="next_kyc_refresh_date"
                            label="Next KYC refresh date"
                            placeholder="enter KYC refresh date"
                            onChange={(e: any) =>
                                updateFormState({ ...formState, next_kyc_refresh_date: e.target.value })
                            }
                            error={null}
                            value={
                                next_kyc_refresh_date === 'null' || !next_kyc_refresh_date ? '' : next_kyc_refresh_date
                            }
                        />
                        <Input
                            sx={{
                                mb: '12px',
                                alignSelf: 'center',
                                width: '100%',
                                background: '#FFFFFF',
                                border: errorState.industry ? '1px red solid' : '1px solid grey',
                            }}
                            type="text"
                            id="industry"
                            data-testid="industry"
                            name="industry"
                            label="Industry"
                            placeholder="enter industry"
                            onChange={(e: any) => updateFormState({ ...formState, industry: e.target.value })}
                            error={null}
                            value={industry}
                        />
                        <Input
                            sx={{
                                mb: '12px',
                                alignSelf: 'center',
                                width: '100%',
                                background: '#FFFFFF',
                                border: errorState.industry ? '1px red solid' : '1px solid grey',
                            }}
                            type="text"
                            id="source-of_funds"
                            data-testid="source_of_funds"
                            name="source_of_funds"
                            label="Source of Funds"
                            placeholder="Enter source of funds"
                            onChange={(e: any) => updateFormState({ ...formState, source_of_funds: e.target.value })}
                            error={null}
                            value={source_of_funds}
                        />

                        <Box sx={{ height: '10px' }} />

                        <Paragraph
                            sx={{
                                mt: '0px',
                                textAlign: 'left',
                                maxWidth: '450px',
                                color: '#09042B',
                                fontSize: '16px',
                            }}
                        >
                            Risk Score
                        </Paragraph>
                        <Select
                            sx={{
                                mt: '7px',
                                width: '100%',
                                height: '45px',
                                border: errorState.risk_score ? '1px red solid' : '1px solid grey',
                            }}
                            value={risk_score}
                            onChange={(e: any) => updateFormState({ ...formState, risk_score: e.target.value })}
                            arrow={
                                <Box
                                    as="svg" // @ts-ignore
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="currentcolor"
                                    sx={{
                                        ml: -28,
                                        mt: 15,
                                        alignSelf: 'center',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                                </Box>
                            }
                        >
                            <option key="select" value="select">
                                Select the risk score
                            </option>

                            {riskOptions.map((item: any) => (
                                <option key={item.id} value={item.id}>
                                    {item.label}
                                </option>
                            ))}
                        </Select>
                        <Box sx={{ height: '10px' }} />
                    </Box>
                    <Box sx={{ width: '100%', ml: '10px' }}>
                        <Paragraph
                            sx={{
                                mt: '0px',
                                textAlign: 'left',
                                maxWidth: '450px',
                                color: '#09042B',
                                fontSize: '16px',
                            }}
                        >
                            License Details
                        </Paragraph>
                        <Select
                            sx={{
                                mt: '7px',
                                width: '100%',
                                height: '45px',
                                border: errorState.licence_details ? '1px red solid' : '1px solid grey',
                            }}
                            value={licence_details}
                            onChange={(e: any) => updateFormState({ ...formState, licence_details: e.target.value })}
                            arrow={
                                <Box
                                    as="svg" // @ts-ignore
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="currentcolor"
                                    sx={{
                                        ml: -28,
                                        mt: 15,
                                        alignSelf: 'center',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                                </Box>
                            }
                        >
                            <option key="select" value="select">
                                Select an option
                            </option>

                            {licenseOptions.map((item: any) => (
                                <option key={item.id} value={item.id}>
                                    {item.label}
                                </option>
                            ))}
                        </Select>

                        <Box sx={{ height: '10px' }} />

                        <Input
                            sx={{
                                mb: '12px',
                                alignSelf: 'center',
                                width: '100%',
                                background: '#FFFFFF',
                                border: errorState.licence_details_validity_date ? '1px red solid' : '1px solid grey',
                            }}
                            type="date"
                            id="licence_details_validity_date"
                            data-testid="licence_details_validity_date"
                            name="licence_details_validity_date"
                            label="License details validity date"
                            placeholder="enter licence details validity date"
                            onChange={(e: any) =>
                                updateFormState({ ...formState, licence_details_validity_date: e.target.value })
                            }
                            error={null}
                            value={
                                licence_details_validity_date === 'null' || !licence_details_validity_date
                                    ? ''
                                    : licence_details_validity_date
                            }
                        />

                        <Paragraph
                            sx={{
                                mt: '0px',
                                textAlign: 'left',
                                maxWidth: '450px',
                                color: '#09042B',
                                fontSize: '16px',
                            }}
                        >
                            Expected Amount To Invest
                        </Paragraph>

                        <Select
                            sx={{
                                mt: '7px',
                                width: '100%',
                                height: '37px',
                                border: errorState.expected_amount_to_invest ? '1px red solid' : '1px solid grey',
                            }}
                            value={expected_amount_to_invest}
                            onChange={(e: any) =>
                                updateFormState({ ...formState, expected_amount_to_invest: e.target.value })
                            }
                            arrow={
                                <Box
                                    as="svg" // @ts-ignore
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="currentcolor"
                                    sx={{
                                        ml: -28,
                                        mt: 15,
                                        alignSelf: 'center',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                                </Box>
                            }
                        >
                            <option key="select" value="select">
                                Select your source of funds
                            </option>

                            {intendedAmountToInvest.map((item: any) => (
                                <option key={item.id} value={item.id}>
                                    {item.label}
                                </option>
                            ))}
                        </Select>
                    </Box>
                </Flex>
            </Box>
        </Flex>
    );
};

export default AdditionalInfo;
