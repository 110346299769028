import { Paragraph, Select, Box } from 'theme-ui';
import { countryCodeEmoji } from 'country-code-emoji';

export const CountryListSelect = ({ label, value, countries = [], updateField, error, size = 'default' }: any) => (
    <>
        <Paragraph
            sx={{
                mt: '0px',
                textAlign: 'left',
                maxWidth: '450px',
                color: '#09042B',
                fontSize: '16px',
            }}
        >
            {label}
        </Paragraph>
        <Select
            sx={{
                mt: '7px',
                width: '100%',
                border: error ? '1px red solid' : '1px solid lightgrey',
                height: size === 'default' ? '45px' : 35,
            }}
            value={value}
            arrow={
                <Box
                    as="svg" // @ts-ignore
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentcolor"
                    sx={{
                        ml: -28,
                        mt: 15,
                        alignSelf: 'center',
                        pointerEvents: 'none',
                    }}
                >
                    <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                </Box>
            }
            onChange={(e) => {
                updateField(e.target.value);
            }}
        >
            {countries.map((item: any) => (
                <option key={item.name} value={item.code}>
                    {item.code === 'SELECT' ? '' : countryCodeEmoji(item.code2Digit)} {item.name}
                </option>
            ))}
        </Select>
    </>
);
