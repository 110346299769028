/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-curly-brace-presence */
import { Flex, Paragraph, Button, Input } from 'theme-ui';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';

const setupCode = async (currentState, updateState) => {
    try {
        updateState({ ...currentState, loading: true });

        const user = await Auth.currentAuthenticatedUser();

        if (!currentState.startAgain && user.preferredMFA === 'SOFTWARE_TOKEN_MFA') {
            return updateState({ ...currentState, view: 'done' });
        }

        const code = await Auth.setupTOTP(user);
        return updateState({ ...currentState, loading: false, code, user });
    } catch (e) {
        return updateState({
            ...currentState,
            loading: false,
            error: 'The code entered does not match',
        });
    }
};

const verifyCode = async (currentState, updateState) => {
    try {
        updateState({
            ...currentState,
            loading: true,
            error: null,
        });
        await Auth.verifyTotpToken(currentState.user, currentState.otp);
        await Auth.setPreferredMFA(currentState.user, 'TOTP');
        updateState({ ...currentState, view: 'done' });
    } catch (e) {
        try {
            // ensure one is set
            await Auth.setPreferredMFA(currentState.user, 'SMS');
        } catch (ex) {
            // swallow
        }
        updateState({
            ...currentState,
            loading: false,
            error: 'The code entered does not match',
        });
    }
};

const getFullTotpUrl = (currentState) =>
    `otpauth://totp/AWSCognito:${currentState.user.username}?secret=${currentState.code}&issuer=${process.env.REACT_APP_TOTP_NAME}`;

const Settings = ({ logOut }) => {
    const [currentState, updateState] = useState({
        code: '',
        user: null,
        copied: false,
        view: 'setup',
        startAgain: false,
    });

    useEffect(() => {
        if (currentState.view === 'setup') {
            setupCode(currentState, updateState);
        }
    }, [currentState.view]);

    const [showMenu, toggleMenu] = useState(true);

    const history = useHistory();

    useEffect(() => {
        (async () => {
            try {
                await Auth.currentAuthenticatedUser();
            } catch (e) {
                history.push('/');
            }
        })();
    }, []);

    return (
        <Flex
            sx={{
                overflow: 'hidden',
                top: '0',
                left: '0',
                bottom: 0,
                minHeight: '100vh',
                width: '100vw',
                flexDirection: 'row',
                backgroundColor: 'greyBackground',
                margin: 0,
                padding: 0,
            }}
        >
            <Sidebar
                logout={logOut}
                currentPage="Settings"
                showMenu={showMenu}
                toggleMenu={toggleMenu}
                history={history}
            />

            <Flex
                sx={{
                    border: '0px red solid',
                    width: '90%',
                    maxWidth: '900px',
                    minHeight: '400px',
                    flexDirection: 'column',
                    mt: '50px',
                    mb: '100px',
                    ml: showMenu ? 240 : 45,
                }}
            >
                {currentState.loading && <Spinner />}

                <Flex
                    sx={{
                        flexDirection: 'column',
                        padding: 50,
                        backgroundColor: 'white',
                        borderRadius: 12,
                        maxWidth: 800,
                    }}
                >
                    <Paragraph sx={{ mb: '20px', fontWeight: '600', fontSize: '21px' }}>Setup MFA</Paragraph>

                    {currentState.code === '' && currentState.view === 'setup' && <Spinner />}

                    {currentState.view === 'done' && (
                        <>
                            <Paragraph sx={{ mb: 20 }}>You're all setup</Paragraph>
                        </>
                    )}

                    {currentState.code !== '' && currentState.view === 'setup' && (
                        <>
                            <Paragraph sx={{ mb: 20 }}>1. Scan the QR code into your Authenticator App</Paragraph>

                            <QRCode size={200} value={getFullTotpUrl(currentState)} />

                            <Paragraph sx={{ mt: 20, mb: 20 }}>
                                2. Enter the code from your app to check it's all setup
                            </Paragraph>

                            <Input
                                sx={{ width: 300, mb: 40 }}
                                type="text"
                                placeholder="Please enter your one time code"
                                value={currentState.otp}
                                onChange={(e) => updateState({ ...currentState, otp: e.target.value })}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        verifyCode(currentState, updateState);
                                    }
                                }}
                            />

                            <Button
                                sx={{ width: 300 }}
                                variant="primary"
                                onClick={() => verifyCode(currentState, updateState)}
                            >
                                Verify Code
                            </Button>

                            {currentState.error && (
                                <Paragraph sx={{ mt: 20, mb: 20, color: 'red' }}>{currentState.error}</Paragraph>
                            )}
                        </>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};

const mapDispatchToProps = (dispatch) => ({
    logOut: () => dispatch({ type: 'LOG_USER_OUT' }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return { loggedIn: account.loggedIn };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
