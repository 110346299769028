import { Flex, Button } from 'theme-ui';
import { Auth } from 'aws-amplify';
import buttonStyle from '../../utils/button-style';

export default ({ state, updateState, search }) => (
    <Flex sx={{ mt: -30 }}>
        <Button
            onClick={async () => {
                const user = await Auth.currentAuthenticatedUser();

                const newState = {
                    ...state,
                    filters: {
                        ...state.filters,
                        assignee: user.attributes.email,
                    },
                };

                search(newState, updateState, (users, total) => {
                    updateState({
                        ...newState,
                        loading: false,
                        items: users,
                        total,
                        showAdvancedSearch: false,
                        pageNumber: state.pageNumber,
                    });
                    document.body.style.overflowY = 'auto';
                });
            }}
            sx={{
                ...buttonStyle,
                width: 90,
                height: 30,
                fontSize: '11px',
                mr: 10,
                borderWidth: state.filters.assignee && state.filters.assignee !== 'select' ? '2px' : '1px',
            }}
        >
            My Cases
        </Button>

        <Button
            onClick={() => {
                const newState = {
                    ...state,
                    filters: {
                        ...state.filters,
                        riskScore: 'low',
                        matchStatus: 'no_match',
                    },
                };

                search(newState, updateState, (users, total) => {
                    updateState({
                        ...newState,
                        loading: false,
                        items: users,
                        total,
                        showAdvancedSearch: false,
                        pageNumber: state.pageNumber,
                    });
                    document.body.style.overflowY = 'auto';
                });
            }}
            sx={{
                ...buttonStyle,
                width: 150,
                height: 30,
                fontSize: '11px',
                borderWidth: state.filters.matchStatus && state.filters.matchStatus !== 'select' ? '2px' : '1px',
                mr: 10,
            }}
        >
            Low Risk/No Match
        </Button>

        <Button
            onClick={() => {
                const newState = {
                    ...state,
                    filters: {
                        kycStatuses: [
                            {
                                value: 'edd',
                                label: 'EDD Legacy',
                            },
                            {
                                value: 'edd_onboarding',
                                label: 'EDD Onboarding',
                            },
                        ],
                    },
                };

                search(newState, updateState, (users, total) => {
                    updateState({
                        ...newState,
                        loading: false,
                        items: users,
                        total,
                        showAdvancedSearch: false,
                        pageNumber: state.pageNumber,
                    });
                    document.body.style.overflowY = 'auto';
                });
            }}
            sx={{
                ...buttonStyle,
                width: 100,
                height: 30,
                fontSize: '11px',
                borderWidth: state.filters.kycStatuses?.find((item) => item.value === 'edd') ? '2px' : '1px',
            }}
        >
            EDD only
        </Button>
    </Flex>
);
