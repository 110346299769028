import { Flex, Label, Radio } from 'theme-ui';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import * as moment from 'moment';
import { formatting } from '@accru-finance/shared-ui-libs';
import { formatXAxis } from '../../utils/dashboard-helper';
import { Title } from '../table';

const Uninvested = ({ state, updateState }) => (
    <Flex sx={{ mt: '60px', flexDirection: 'column' }}>
        <Title title="Uninvested balances 📈" state={state} updateState={{}} showEditIcon={false} />

        <Flex
            sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                mb: 40,
                mt: '50px',
                ml: '20px',
                width: '95%',
                fontSize: '15px',
                lineHeight: '24px',
            }}
        >
            <Label>
                <Radio
                    onClick={() => updateState({ ...state, uninvestedCurrency: 'bitcoin' })}
                    name="dark-mode"
                    value="bitcoin"
                />
                Bitcoin
            </Label>
            <Label>
                <Radio
                    onClick={() => updateState({ ...state, uninvestedCurrency: 'ethereum' })}
                    name="dark-mode"
                    value="ethereum"
                />
                Ethereum
            </Label>
            <Label>
                <Radio
                    onClick={() => updateState({ ...state, uninvestedCurrency: 'usd-coin' })}
                    name="dark-mode"
                    value="usd-coin"
                />
                USDC
            </Label>
            <Label>
                <Radio
                    onClick={() => updateState({ ...state, uninvestedCurrency: 'tether' })}
                    name="dark-mode"
                    value="tether"
                />
                USDT
            </Label>
            <Label>
                <Radio
                    onClick={() => updateState({ ...state, uninvestedCurrency: 'dai' })}
                    name="dark-mode"
                    value="dai"
                />
                DAI
            </Label>
            <Label>
                <Radio
                    onClick={() => updateState({ ...state, uninvestedCurrency: 'eur' })}
                    name="dark-mode"
                    value="eur"
                    defaultChecked
                />
                Euros
            </Label>
            <Label>
                <Radio
                    onClick={() => updateState({ ...state, uninvestedCurrency: 'gbp' })}
                    name="dark-mode"
                    value="gbp"
                    defaultChecked
                />
                Pounds
            </Label>
        </Flex>

        <BarChart width={730} height={300} data={state.uninvestedBalances} margin={{ top: 10, left: 85 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
                tickFormatter={formatXAxis}
                dataKey="timestamp"
                minTickGap={45}
                tickMargin={10}
                tickSize={6}
                height={40}
            />
            <YAxis tickFormatter={(value) => formatting.formatForDisplay(value)} />
            <Tooltip
                formatter={(value) => formatting.formatForDisplay(value)}
                labelFormatter={(label) => moment.utc(label).format('Do MMM YYYY HH:mm')}
            />
            <Legend />
            <Bar dataKey={state.uninvestedCurrency} fill="#5100C9" />
        </BarChart>
    </Flex>
);

const Invested = ({ state, updateState }) => (
    <Flex sx={{ mt: '60px', flexDirection: 'column' }}>
        <Title title="Invested balances 📊" state={state} updateState={{}} showEditIcon={false} />

        <Flex
            sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                mb: 40,
                mt: '50px',
                ml: '20px',
                width: '95%',
                fontSize: '15px',
                lineHeight: '24px',
            }}
        >
            <Label>
                <Radio
                    onClick={() => updateState({ ...state, investedProduct: 'auto_btc' })}
                    name="dark-mode"
                    value="auto_btc"
                />
                Bitcoin
            </Label>
            <Label>
                <Radio
                    onClick={() => updateState({ ...state, investedProduct: 'auto_eth' })}
                    name="dark-mode"
                    value="auto_eth"
                />
                Ethereum
            </Label>
            <Label>
                <Radio
                    onClick={() => updateState({ ...state, investedProduct: 'stablecoin' })}
                    name="dark-mode"
                    value="stablecoin"
                    defaultChecked
                />
                Stablecoin
            </Label>
            <Label>
                <Radio
                    onClick={() => updateState({ ...state, investedProduct: 'maple' })}
                    name="dark-mode"
                    value="maple"
                    defaultChecked
                />
                Maple USDC
            </Label>
            <Label>
                <Radio
                    onClick={() => updateState({ ...state, investedProduct: 'maple_eth' })}
                    name="dark-mode"
                    value="maple"
                    defaultChecked
                />
                Maple ETH
            </Label>
        </Flex>

        <BarChart width={730} height={300} data={state.investedBalances} margin={{ top: 10, left: 85 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
                tickFormatter={formatXAxis}
                dataKey="timestamp"
                minTickGap={45}
                tickMargin={10}
                tickSize={6}
                height={40}
            />
            <YAxis tickFormatter={(value) => formatting.formatForDisplay(value)} />
            <Tooltip
                formatter={(value) => formatting.formatForDisplay(value)}
                labelFormatter={(label) => moment.utc(label).format('Do MMM YYYY HH:mm')}
            />
            <Legend />
            <Bar dataKey={state.investedProduct} fill="#27AE60" />
        </BarChart>
    </Flex>
);

const Deposits = ({ state, updateState }) => (
    <Flex sx={{ mt: '60px', flexDirection: 'column' }}>
        <Title title="Deposits 💵" state={state} updateState={{}} showEditIcon={false} />

        <Flex
            sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                mb: 40,
                mt: '50px',
                ml: '20px',
                width: '95%',
                fontSize: '15px',
                lineHeight: '24px',
            }}
        >
            <Label>
                <Radio
                    onClick={() => updateState({ ...state, depositCurrency: 'bitcoin' })}
                    name="dark-mode"
                    value="bitcoin"
                />
                Bitcoin
            </Label>
            <Label>
                <Radio
                    onClick={() => updateState({ ...state, depositCurrency: 'ethereum' })}
                    name="dark-mode"
                    value="ethereum"
                />
                Ethereum
            </Label>
            <Label>
                <Radio
                    onClick={() => updateState({ ...state, depositCurrency: 'usd-coin' })}
                    name="dark-mode"
                    value="usd-coin"
                />
                USDC
            </Label>
            <Label>
                <Radio
                    onClick={() => updateState({ ...state, depositCurrency: 'tether' })}
                    name="dark-mode"
                    value="tether"
                />
                USDT
            </Label>
            <Label>
                <Radio onClick={() => updateState({ ...state, depositCurrency: 'dai' })} name="dark-mode" value="dai" />
                DAI
            </Label>
            <Label>
                <Radio
                    onClick={() => updateState({ ...state, depositCurrency: 'eur' })}
                    name="dark-mode"
                    value="eur"
                    defaultChecked
                />
                Euros
            </Label>
            <Label>
                <Radio
                    onClick={() => updateState({ ...state, depositCurrency: 'gbp' })}
                    name="dark-mode"
                    value="gbp"
                    defaultChecked
                />
                Pounds
            </Label>
        </Flex>

        <BarChart width={730} height={300} data={state.depositedAmounts} margin={{ top: 10, left: 85 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
                tickFormatter={formatXAxis}
                dataKey="timestamp"
                minTickGap={45}
                tickMargin={10}
                tickSize={6}
                height={40}
            />
            <YAxis padding={70} tickFormatter={(value) => formatting.formatForDisplay(value)} />
            <Tooltip
                formatter={(value) => formatting.formatForDisplay(value)}
                labelFormatter={(label) => moment.utc(label).format('Do MMM YYYY HH:mm')}
            />
            <Legend />
            <Bar dataKey={state.depositCurrency} fill="#5100C9" />
        </BarChart>
    </Flex>
);

const Balances = ({ state, updateState }) => (
    <>
        <Uninvested state={state} updateState={updateState} />

        <Invested state={state} updateState={updateState} />

        <Deposits state={state} updateState={updateState} />
    </>
);

export default Balances;
