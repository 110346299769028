/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react';

import axios from 'axios';
import { Flex, Paragraph, Button, Select, Box, Heading } from 'theme-ui';
import Spinner from './spinner';

const emailOptions = [
    { value: 'id_ok_video_missing', text: 'ID - OK, Video - Missing' },
    { value: 'id_ok_video_not_acceptable', text: 'ID - OK, Video - Not acceptable' },
    { value: 'id_ok_third_party_intervention', text: 'ID - OK, Video - 3rd party intervention' },
    { value: 'id_nok_video_nok_poa_nok', text: 'ID - NOK, Video NOK, PoA NOK' },
    // 5
    { value: 'id_nok_blurry_etc_poa_missing', text: 'ID - NOK (Blurry/Expired/Unsupported), PoA - Missing' },
    { value: 'id_ok_video_ok_poa_nok_outdated', text: 'ID - OK, Video - OK, PoA - NOK (Outdated)' },
    // 7
    { value: 'id_ok_video_nok_poa_missing', text: 'ID OK, Video - NOK, POA - Missing' },
    { value: 'id_or_passport_nok_poa_ok', text: 'ID or Passport NOK, POA OK' },
    { value: 'passport_required_poa_ok', text: 'Passport required, PоA OK' },
    // 10
    { value: 'id_passport_required_dl_sent_poa_ok', text: 'ID/Passport required (DL sent), PоA OK' },
    { value: 'id_poa_video_ok_occupation_required', text: 'ID, PoA, Video OK - Occupation required' },
    { value: 'id_poa_video_ok_nationality_required', text: 'ID, PoA, Video OK - Nationality required' },
    // 13
    { value: 'id_poa_video_ok_address_mismatch', text: 'ID, PoA, Video OK - Address Mismatch' },
    { value: 'terminated_rejected', text: 'Terminated - Rejected' },
    { value: 'unsupported_country_rejected', text: 'Unsupported Country - Rejected' },
    { value: 'edd_ongoing', text: 'EDD check - Ongoing' },
    { value: 'edd_sow_required', text: 'EDD check - Onboarding' },
];
const sendEmail = async (emailTemplate: string, userId: string) => {
    try {
        const { data } = await axios({
            url: process.env.REACT_APP_USERS_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
            data: {
                query: `mutation {
                    adminTools(input: {
                        user_id: "${userId}"
                        tool: "email_user"
                        email_template_name: "${emailTemplate}"
                    }) { 
                        success
                        message
                    }
                }`,
                variables: null,
            },
        });

        return data.data.adminTools;
    } catch (error) {
        return error;
    }
};
const submit = async (currentState: any, updateState: any, close: any) => {
    if (currentState.emailTemplate === 'select') {
        updateState({ ...currentState, error: 'Please select an option' });
        return;
    }
    updateState({ ...currentState, loading: true });
    const emailSent = await sendEmail(currentState.emailTemplate, currentState.user_id);
    updateState({ ...currentState, loading: false });

    if (emailSent.success) {
        updateState({ ...currentState, success: true, error: '' });
        return;
    }
    updateState({ ...currentState, success: false, error: 'Error sending email' });
};

const EmailTemplateSelect = (props: any) => {
    const [currentState, updateState] = useState({
        error: '',
        loading: false,
        emailTemplate: 'select',
        success: false,
        user_id: props.userId,
    });
    const fullHeight = false;

    return (
        <Flex sx={{ justifyContent: 'center', height: '90%', alignItems: 'center' }}>
            <Flex
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    flexDirection: 'column',
                }}
            >
                {currentState.loading && <Spinner />}
                {currentState.success && (
                    <Flex
                        sx={{ justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column' }}
                    >
                        <Heading sx={{ color: 'green', mt: 20, mb: 50, fontSize: '2em' }}>Email sent!</Heading>
                        <Paragraph
                            onClick={() => props.closeOverlay()}
                            sx={{
                                mt: 40,
                                mb: 30,
                                fontWeight: '800',
                                fontSize: '1.3em',
                                ':hover': { color: 'red' },
                                cursor: 'pointer',
                            }}
                        >
                            Close X
                        </Paragraph>
                    </Flex>
                )}
                {!currentState.success && (
                    <Flex
                        sx={{ justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column' }}
                    >
                        <Paragraph sx={{ mt: 40, mb: 30, fontWeight: '800', fontSize: '1.3em' }}>
                            Send Email to user
                        </Paragraph>
                        <Select
                            defaultValue="select"
                            value={currentState.emailTemplate}
                            onChange={(e: any) => updateState({ ...currentState, emailTemplate: e.target.value })}
                            arrow={
                                <Box
                                    as="svg"
                                    // @ts-ignore
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="currentcolor"
                                    sx={{
                                        ml: -28,
                                        mt: -20,
                                        alignSelf: 'center',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                                </Box>
                            }
                            sx={{
                                mb: 20,
                                width: 400,
                                height: fullHeight ? 60 : 40,
                                border: currentState.error ? '1px solid red' : 'auto',
                            }}
                        >
                            <option value="select">Select email template...</option>
                            {emailOptions.map((option) => (
                                <option value={option.value}>{option.text}</option>
                            ))}
                        </Select>

                        <Button
                            onClick={() => submit(currentState, updateState, props.close)}
                            sx={{ width: 200, mt: 20 }}
                        >
                            Send
                        </Button>

                        {currentState.error && (
                            <Paragraph sx={{ color: 'red', mt: 20 }}>{currentState.error}</Paragraph>
                        )}
                        {currentState.success && <Paragraph sx={{ color: 'green', mt: 20 }}>Email sent!</Paragraph>}
                        <Paragraph sx={{ mt: 30 }}>
                            {' '}
                            <a
                                target="_blank"
                                href="https://docs.google.com/document/d/1-7yrNWevs6-QrOQyhF4i4DE0iAhdu1hiAYvWvH2LZ-M/edit#heading=h.ppjwztzhp99b"
                                rel="noreferrer"
                            >
                                Detailed info on email contents
                            </a>
                        </Paragraph>
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};

export default EmailTemplateSelect;
