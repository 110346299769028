/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
import { Flex, Paragraph, Input, Button } from 'theme-ui';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { debounce } from 'debounce';
import { connect } from 'react-redux';
import { useEffect, useState, useMemo } from 'react';
import { getMemberships } from '../utils/cognito';
import { TableHeaderCorporate, TableRowCorporate } from '../components/table';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';
import Overlay from '../components/overlay';
import AddCorporate from '../components/add-corporate';
import TransactionPagination from '../components/pagination/transactions';

const getCorporatesFromES = async (currentState, updateState) => {
    updateState({ ...currentState, loading: true });

    const filterOptions = [{ match_all: {} }];

    if (currentState.name !== '') {
        filterOptions.push({ wildcard: { CompanyName: `*${currentState.name}*` } });
    }

    try {
        const { data } = await axios({
            url: `${process.env.REACT_APP_ADMIN_SEARCH_API}?indexName=corporates`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
            data: {
                from: currentState.offset,
                size: currentState.limit,
                query: {
                    bool: {
                        filter: filterOptions,
                    },
                },
                sort: [
                    {
                        CreatedAt: {
                            order: 'desc',
                        },
                    },
                ],
            },
        });

        const formattedForMainTable = data.users.map((item) => ({
            id: item._source.UserId,
            name: item._source.CompanyName || '',
        }));
        updateState({
            ...currentState,
            items: formattedForMainTable,
            loading: false,
            total: data?.total?.value || 0,
        });
    } catch (e) {
        updateState({ ...currentState, error: 'Uh Oh, there has been a problem', loading: false });
    }
};

const Corporates = ({ logOut }) => {
    const [state, updateState] = useState({
        items: [],
        loading: true,
        showAddCorporate: false,
        name: '',
        pageNumber: 1,
        total: 0,
        limit: 10,
        offset: 0,
    });

    const [showMenu, toggleMenu] = useState(true);

    const history = useHistory();

    const getCorporatesDebounced = useMemo(
        () =>
            debounce((e) => {
                const newState = { ...state, name: e.target.value, showAddCorporate: false };
                updateState(newState);
                getCorporatesFromES(newState, updateState);
            }, 500),
        []
    );

    useEffect(() => {
        (async () => {
            try {
                await Auth.currentAuthenticatedUser();

                const memberships = await getMemberships();
                if (!memberships?.includes('tier_1')) {
                    history.push('/kyc-complete');
                }

                await getCorporatesFromES(state, updateState);
            } catch (e) {
                history.push('/');
            }
        })();
    }, []);

    useEffect(() => {
        getCorporatesFromES(state, updateState);
    }, [state.offset]);

    return (
        <Flex
            sx={{
                overflow: 'hidden',
                top: '0',
                left: '0',
                bottom: 0,
                minHeight: '100vh',
                width: '100vw',
                flexDirection: 'row',
                backgroundColor: 'greyBackground',
                margin: 0,
                padding: 0,
            }}
        >
            <Sidebar
                logout={logOut}
                currentPage="Corporates"
                showMenu={showMenu}
                toggleMenu={toggleMenu}
                history={history}
            />

            <Flex
                sx={{
                    border: '0px red solid',
                    width: '90%',
                    maxWidth: '900px',
                    minHeight: '400px',
                    flexDirection: 'column',
                    mt: '50px',
                    mb: '100px',
                    ml: showMenu ? 240 : 45,
                }}
            >
                {state.loading && <Spinner />}

                {state.showAddCorporate && (
                    <Overlay
                        closeOverlay={() => updateState({ ...state, showAddCorporate: false })}
                        overlayContent={<AddCorporate />}
                    />
                )}

                <>
                    <Paragraph sx={{ mb: '20px', fontWeight: '600', fontSize: '21px' }}>Corporate Records</Paragraph>

                    <Flex sx={{ width: '100%', justifyContent: 'space-between' }}>
                        <Input
                            onChange={getCorporatesDebounced}
                            sx={{ width: 400, mb: 20, background: '#FFFFFF' }}
                            placeholder="Search by name"
                        />
                        <Button onClick={() => updateState({ ...state, showAddCorporate: true })} sx={{ height: 40 }}>
                            Add New Corporate
                        </Button>
                    </Flex>

                    <TableHeaderCorporate />

                    {state?.items?.map((corporate, i) => (
                        <TableRowCorporate corporate={corporate} history={history} key={`${i}-row`} />
                    ))}

                    <TransactionPagination
                        showTotalPages
                        totalPages={Math.ceil(state.total ? state.total / state.limit : 1)}
                        currentState={state}
                        updateState={updateState}
                    />
                </>
            </Flex>
        </Flex>
    );
};

const mapDispatchToProps = (dispatch) => ({
    logOut: () => dispatch({ type: 'LOG_USER_OUT' }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return { loggedIn: account.loggedIn, paginationData: account.paginationData };
};

export default connect(mapStateToProps, mapDispatchToProps)(Corporates);
