import axios from 'axios';
import { transactionsCsv } from '@accru-finance/shared-ui-libs';

export default async (currentState, updateState) => {
    try {
        updateState({
            ...currentState,
            loading: true,
            error: false,
        });

        const { data } = await axios({
            url: process.env.REACT_APP_TRANSACTIONS_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
            data: {
                query: `query {
            getTransactionsHistoryAsAdmin(limit: 1000, offset: 0, user_id: "${currentState.user_id}") { 
                success
                transactions {
                    status
                    amount
                    amount_from
                    amount_to
                    currency_from
                    currency_to
                    currency_pair
                    product
                    product_label
                    fee
                    exchange_rate
                    type
                    created_at
                    trade_type
                    type_label
                    currency
                    currency_label
                    img
                    label
                }
            }
        }`,
                variables: null,
            },
        });

        if (!data.data.getTransactionsHistoryAsAdmin.success) {
            throw new Error('failed');
        }

        updateState({
            ...currentState,
            loading: false,
            error: false,
        });

        return transactionsCsv.buildCsv(data.data.getTransactionsHistoryAsAdmin.transactions);
    } catch (e) {
        updateState({
            ...currentState,
            loading: false,
            transactions: [],
            error: 'There seems to be an issue downloading the transactions, please contact support',
        });
    }
};

export const downloadKoinlyTransactions = async (currentState, updateState) => {
    try {
        updateState({
            ...currentState,
            loading: true,
            error: false,
        });

        const { data } = await axios({
            url: process.env.REACT_APP_TRANSACTIONS_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
            data: {
                query: `query {
            getTransactionsHistoryAsAdmin(limit: 4000, offset: 0, user_id: "${currentState.user_id}") { 
                success
                transactions {
                    status
                    amount
                    amount_from
                    amount_to
                    currency_from
                    currency_to
                    currency_pair
                    product
                    product_label
                    fee
                    exchange_rate
                    type
                    created_at
                    trade_type
                    type_label
                    currency
                    currency_label
                    img
                    label
                }
            }
        }`,
                variables: null,
            },
        });

        if (!data.data.getTransactionsHistoryAsAdmin.success) {
            throw new Error('failed');
        }

        updateState({
            ...currentState,
            loading: false,
            error: false,
        });

        return transactionsCsv.buildKoinlyCsv(data.data.getTransactionsHistoryAsAdmin.transactions);
    } catch (e) {
        updateState({
            ...currentState,
            loading: false,
            transactions: [],
            error: 'There seems to be an issue downloading the transactions, please contact support',
        });
    }
};
