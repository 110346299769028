/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Flex, Paragraph, Button, Heading, Select, Box } from 'theme-ui';
import { formatting, withdrawalLimitFinder } from '@accru-finance/shared-ui-libs';
import Spinner from './spinner';
import { updateEdd } from '../utils/tools';
import labelMapping from '../utils/label-mapping';
import { Title } from './table';
import Item from './user-data-item';

const equal = require('deep-equal');

export const eddStatuses = [
    { id: 'unknown', label: 'Unknown' },
    { id: 'na', label: 'Not Applicable' },
    { id: 'passed', label: 'Passed' },
    { id: 'failed', label: 'Failed' },
    { id: 'escalated', label: 'Escalated' },
];

export const eddSubReasons = [
    { id: 'unknown', label: 'Unknown' },
    { id: 'threshold_reached', label: 'Threshold Reached' },
    { id: 'very_high_risk_country', label: 'Very High Risk Country' },
    { id: 'suspicious_activity', label: 'Suspicious activity' },
    { id: 'pep_true_match', label: 'PEP true match' },
];

const WithdrawalLimits = (props: any) => {
    const { expected_amount_to_invest, expected_amount_to_invest_crypto, risk_score, edd } = props.state;

    const [currentState, updateState] = useState({
        userId: props.userId,
        loading: false,
        newTiers: edd.tier || { crypto: '0', fiat: '0' },
        oldTiers: edd.tier || { crypto: '0', fiat: '0' },
        edd_status: edd.status || '',
        edd_sub_reason: edd.sub_reason || '',
        error: '',
        rules: [],
        currentLimits: { fiat: '', crypto: '' },
        newLimits: { fiat: '', crypto: '' },
    });

    useEffect(() => {
        if (!risk_score) {
            updateState({ ...currentState, loading: false });
            return;
        }
        const fetchRules = async () => {
            const {
                data: { rules },
            } = await axios({
                url: `https://cdn.accru.finance/withdraw/withdrawal-rules.json`,
            });

            const limits = {
                fiat: withdrawalLimitFinder.default(
                    risk_score?.rating,
                    currentState.oldTiers.fiat,
                    expected_amount_to_invest,
                    'fiat',
                    rules
                ),
                crypto: withdrawalLimitFinder.default(
                    risk_score?.rating,
                    currentState.oldTiers.crypto,
                    expected_amount_to_invest_crypto,
                    'crypto',
                    rules
                ),
            };

            updateState({ ...currentState, rules, currentLimits: limits, newLimits: limits, loading: false });
        };
        updateState({ ...currentState, loading: true });
        fetchRules();
    }, []);

    return (
        <Flex
            sx={{
                mt: '30px',
                width: '100%',
                flexDirection: 'column',
                justifyContent: 'center',
                alignContent: 'center',
            }}
        >
            {currentState.loading && <Spinner />}
            <Heading sx={{ mb: '20px', mt: '-10px', alignSelf: 'center' }}>Withdrawal Limits</Heading>

            {!risk_score && (
                <Paragraph sx={{ textAlign: 'center', color: 'red' }}>User has not yet completed KYC</Paragraph>
            )}

            {risk_score && (
                <>
                    <Flex
                        sx={{
                            justifyContent: 'space-around',
                            flexDirection: 'column',
                            mb: '10px',
                            width: '100%',
                            mx: '30px',
                        }}
                    >
                        <Box sx={{ width: '600px', mt: '10px' }}>
                            <Item
                                title="Risk score"
                                value={labelMapping(risk_score.rating)}
                                customSx={{ minHeight: '20px', mb: '5px' }}
                            />
                            <Flex
                                sx={{
                                    justifyContent: 'space-between',
                                    width: 'auto',
                                    marginRight: '50px',
                                    marginLeft: '0px',
                                    minHeight: '40px',
                                    color: '#444',
                                    cursor: 'pointer',
                                }}
                            >
                                <Paragraph
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 600,
                                        textAlign: 'left',
                                        width: '220px',
                                        lineHeight: '30px',
                                    }}
                                >
                                    EDD status :
                                </Paragraph>
                                <Select
                                    value={currentState.edd_status}
                                    onChange={(e: any) => updateState({ ...currentState, edd_status: e.target.value })}
                                    name="select"
                                    id="select"
                                    sx={{ width: '160px', height: '30px', fontSize: '12px' }}
                                    arrow={
                                        <Box
                                            as="svg" // @ts-ignore
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="currentcolor"
                                            sx={{
                                                ml: -28,
                                                mt: '5px',
                                                alignSelf: 'flex-start',
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                                        </Box>
                                    }
                                >
                                    {eddStatuses.map((item: any) => (
                                        <option key={item.id} value={item.id}>
                                            {item.label}
                                        </option>
                                    ))}
                                </Select>
                            </Flex>
                            <Flex
                                sx={{
                                    justifyContent: 'space-between',
                                    width: 'auto',
                                    marginRight: '50px',
                                    marginLeft: '0px',
                                    minHeight: '40px',
                                    color: '#444',
                                    cursor: 'pointer',
                                }}
                            >
                                <Paragraph
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 600,
                                        textAlign: 'left',
                                        width: '220px',
                                    }}
                                >
                                    EDD sub reason :
                                </Paragraph>
                                <Select
                                    value={currentState.edd_sub_reason}
                                    onChange={(e: any) =>
                                        updateState({ ...currentState, edd_sub_reason: e.target.value })
                                    }
                                    name="select"
                                    id="select"
                                    sx={{ width: '160px', height: '30px', fontSize: '12px' }}
                                    arrow={
                                        <Box
                                            as="svg" // @ts-ignore
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="currentcolor"
                                            sx={{
                                                ml: -28,
                                                mt: '5px',
                                                alignSelf: 'flex-start',
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                                        </Box>
                                    }
                                >
                                    {eddSubReasons.map((item: any) => (
                                        <option key={item.id} value={item.id}>
                                            {item.label}
                                        </option>
                                    ))}
                                </Select>
                            </Flex>
                        </Box>
                    </Flex>

                    <Box sx={{ mb: '10px', mx: '30px', width: '100%' }}>
                        <Title title="🤑 Crypto" state={{}} updateState={{}} showEditIcon={false} />
                        <Box sx={{ width: '600px', mt: '10px' }}>
                            <Item
                                title="Withdrawal tier"
                                value={currentState.oldTiers.crypto}
                                customSx={{ minHeight: '30px' }}
                            />
                            <Item
                                title="Expected crypto to invest"
                                value={labelMapping(expected_amount_to_invest_crypto)}
                                customSx={{ minHeight: '30px' }}
                            />
                            <Item
                                title="Current withdrawal limit"
                                value={`€ ${formatting.formatForDisplay(currentState.currentLimits.crypto)}`}
                                customSx={{ minHeight: '30px' }}
                            />
                            <Item
                                title="New withdrawal limit"
                                value={
                                    Number(currentState.newLimits.crypto) > 100000000
                                        ? ' unlimited'
                                        : `€ ${formatting.formatForDisplay(currentState.newLimits.crypto)}`
                                }
                                customSx={{ minHeight: '30px' }}
                            />
                            <Flex
                                sx={{
                                    justifyContent: 'space-between',
                                    width: 'auto',
                                    marginRight: '50px',
                                    marginLeft: '0px',
                                    minHeight: '40px',
                                    color: '#444',
                                    cursor: 'pointer',
                                }}
                            >
                                <Paragraph
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 600,
                                        textAlign: 'left',
                                        width: '220px',
                                    }}
                                >
                                    Change withdrawal Limit
                                </Paragraph>
                                <Select
                                    value={currentState.newTiers.crypto}
                                    name="select"
                                    id="select"
                                    sx={{ width: '160px', height: '30px', fontSize: '12px' }}
                                    onChange={(e) => {
                                        updateState({
                                            ...currentState, // @ts-ignore
                                            tier: e.target.value,
                                            newTiers: {
                                                ...currentState.newTiers,
                                                crypto: e.target.value,
                                            },
                                            newLimits: {
                                                ...currentState.newLimits,
                                                crypto: withdrawalLimitFinder.default(
                                                    risk_score.rating || 'not-applicable',
                                                    e.target.value,
                                                    expected_amount_to_invest_crypto,
                                                    'crypto',
                                                    currentState.rules
                                                ),
                                            },
                                        });
                                    }}
                                    arrow={
                                        <Box
                                            as="svg" // @ts-ignore
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="currentcolor"
                                            sx={{
                                                ml: -28,
                                                mt: '5px',
                                                alignSelf: 'flex-start',
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                                        </Box>
                                    }
                                >
                                    <option value="0">EDD Tier: 0 disabled</option>
                                    <option value="1">EDD Tier: 1 verified</option>
                                    <option value="2">EDD Tier: 2 verified</option>
                                    <option value="3">EDD Tier: 3 verified</option>
                                    <option value="4">EDD Tier: 4 verified</option>
                                </Select>
                            </Flex>
                        </Box>
                    </Box>
                    <Box sx={{ mb: '30px', mx: '30px', width: '100%' }}>
                        <Title title="💰 Fiat" state={{}} updateState={{}} showEditIcon={false} />
                        <Box sx={{ width: '600px', mt: '10px' }}>
                            <Item
                                title="Withdrawal tier"
                                value={currentState.oldTiers.fiat}
                                customSx={{ minHeight: '30px' }}
                            />
                            <Item
                                title="Expected fiat to invest"
                                value={labelMapping(expected_amount_to_invest)}
                                customSx={{ minHeight: '30px' }}
                            />
                            <Item
                                title="Current withdrawal limit"
                                value={`€ ${formatting.formatForDisplay(currentState.currentLimits.fiat)}`}
                                customSx={{ minHeight: '30px' }}
                            />
                            <Item
                                title="New withdrawal limit"
                                value={
                                    Number(currentState.newLimits.fiat) > 100000000
                                        ? ' unlimited'
                                        : `€ ${formatting.formatForDisplay(currentState.newLimits.fiat)}`
                                }
                                customSx={{ minHeight: '30px' }}
                            />
                            <Flex
                                sx={{
                                    justifyContent: 'space-between',
                                    width: 'auto',
                                    marginRight: '50px',
                                    marginLeft: '0px',
                                    minHeight: '40px',
                                    color: '#444',
                                    cursor: 'pointer',
                                }}
                            >
                                <Paragraph
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 600,
                                        textAlign: 'left',
                                        width: '220px',
                                    }}
                                >
                                    Change withdrawal Limit
                                </Paragraph>
                                <Select
                                    value={currentState.newTiers.fiat}
                                    name="select"
                                    id="select"
                                    sx={{ width: '160px', height: '30px', fontSize: '12px' }}
                                    onChange={(e) => {
                                        updateState({
                                            ...currentState, // @ts-ignore
                                            tier: e.target.value,
                                            newTiers: {
                                                ...currentState.newTiers,
                                                fiat: e.target.value,
                                            },
                                            newLimits: {
                                                ...currentState.newLimits,
                                                fiat: withdrawalLimitFinder.default(
                                                    risk_score.rating || 'not-applicable',
                                                    e.target.value,
                                                    expected_amount_to_invest,
                                                    'fiat',
                                                    currentState.rules
                                                ),
                                            },
                                        });
                                    }}
                                    arrow={
                                        <Box
                                            as="svg" // @ts-ignore
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="currentcolor"
                                            sx={{
                                                ml: -28,
                                                mt: '5px',
                                                alignSelf: 'flex-start',
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                                        </Box>
                                    }
                                >
                                    <option value="0">EDD Tier: 0 disabled</option>
                                    <option value="1">EDD Tier: 1 verified</option>
                                    <option value="2">EDD Tier: 2 verified</option>
                                    <option value="3">EDD Tier: 3 verified</option>
                                    <option value="4">EDD Tier: 4 verified</option>
                                </Select>
                            </Flex>
                        </Box>
                    </Box>

                    {currentState.error && (
                        <Paragraph sx={{ color: 'red', mb: '10px', mt: '-10px', alignSelf: 'center' }}>
                            {currentState.error}
                        </Paragraph>
                    )}

                    <Button
                        sx={{ width: '200px', mt: '0px', alignSelf: 'center' }}
                        onClick={() => {
                            if (
                                equal(
                                    { ...edd, tier: currentState.oldTiers },
                                    {
                                        tier: currentState.newTiers,
                                        status: currentState.edd_status,
                                        sub_reason: currentState.edd_sub_reason,
                                    }
                                )
                            ) {
                                return updateState({ ...currentState, error: 'Please make at least one change' });
                            }

                            updateEdd(updateState, currentState);
                        }}
                    >
                        Save
                    </Button>
                </>
            )}
        </Flex>
    );
};

export default WithdrawalLimits;
