/* eslint-disable arrow-body-style */
import axios from 'axios';
import { useState } from 'react';
import { Button, Flex, Input, Paragraph } from 'theme-ui';
import AccruSpinner from '../spinner';

const transfer = async (localState, updateLocalState) => {
    try {
        updateLocalState({ ...localState, loading: true });

        const { data } = await axios({
            url: process.env.REACT_APP_TRANSACTIONS_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
            data: {
                query: `mutation { 
                    adminTools(input: {
                        tool: "move_intero_money"
                        user_id: "${localState.userId}"
                        amount: ${localState.amount}
                    }) { 
                        success
                        message
                        user_id
                    }
                }`,
                variables: null,
            },
        });

        if (!data.data.adminTools.success) {
            return updateLocalState({ ...localState, error: data.data.adminTools.message, loading: false });
        }

        return window.location.reload();
    } catch (e) {
        return updateLocalState({ ...localState, error: 'Failed to update', loading: false });
    }
};

const InteroTransfer = ({ userId }) => {
    const [localState, updateLocalState] = useState({ userId, loading: false, amount: 0 });
    return (
        <Flex sx={{ flexDirection: 'column', ml: 20, mt: 25 }}>
            {localState.loading && <AccruSpinner />}
            <Paragraph sx={{ fontSize: 18, fontWeight: 700 }}>Transfer funds from Intero to USDC wallet</Paragraph>
            <Input
                onChange={(e) => updateLocalState({ ...localState, amount: e.target.value })}
                value={localState.amount}
                sx={{ mt: 20, width: 300 }}
                type="number"
            />
            <Button
                sx={{ mt: 20, width: 200 }}
                onClick={async () => {
                    await transfer(localState, updateLocalState);
                }}
            >
                Transfer
            </Button>

            {localState.error && <Paragraph sx={{ mt: 20, fontSize: 15, color: 'red' }}>{localState.error}</Paragraph>}
        </Flex>
    );
};

export default InteroTransfer;
