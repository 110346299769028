/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import axios from 'axios';
import { Flex, Paragraph, Button, Box, Checkbox } from 'theme-ui';
import FormInput from './form-input';
import Spinner from './spinner';

const updateConfig = async (currentState, updateState) => {
    try {
        const { data } = await axios({
            url: process.env.REACT_APP_ADMIN_REFERRAL_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
            data: {
                query: `mutation {
                    updateReferralConfig(input: {
                        user_id: "${currentState.user_id}"
                        new_referral_code: "${currentState.referralConfigData.new_referral_code}"
                        referrer_usdt_amount: ${currentState.referralConfigData.referrer_usdt_amount}
                        referee_usdt_amount: ${currentState.referralConfigData.referee_usdt_amount}
                        refer_on_verification: ${currentState.referralConfigData.refer_on_verification}
                    }) { 
                        status
                        message
                    }
                }`,
                variables: null,
            },
        });

        if (data.data.updateReferralConfig.status !== 'SUCCESS') {
            return updateState({ ...currentState, error: data.data.updateReferralConfig.message, loading: false });
        }

        return window.location.reload();
    } catch (e) {
        return updateState({ ...currentState, error: 'Failed to update', loading: false });
    }
};

const submit = async (currentState, updateState) => {
    if (currentState.referralConfigData?.referral_code === currentState.referralConfigData?.new_referral_code) {
        return updateState({ ...currentState, error: 'Please enter a new 6 character referral code' });
    }

    if (
        !currentState.referralConfigData.new_referral_code ||
        currentState.referralConfigData.new_referral_code.length < 6
    ) {
        return updateState({ ...currentState, error: 'Please enter a 6 character referral code' });
    }

    if (
        currentState.referralConfigData.referrer_usdt_amount === '' ||
        isNaN(currentState.referralConfigData.referrer_usdt_amount) ||
        parseInt(currentState.referralConfigData.referrer_usdt_amount, 10) < 0
    ) {
        return updateState({
            ...currentState,
            error: 'Please set the referrer bonus to a number greater than or equal to 0',
        });
    }

    if (
        currentState.referralConfigData.referee_usdt_amount === '' ||
        isNaN(currentState.referralConfigData.referee_usdt_amount) ||
        parseInt(currentState.referralConfigData.referee_usdt_amount, 10) < 0
    ) {
        return updateState({
            ...currentState,
            error: 'Please set the referee bonus to a number greater than or equal to 0',
        });
    }

    const newState = { ...currentState, error: '', loading: true };

    updateState(newState);

    updateConfig(newState, updateState);
};

const ReferralConfig = (props) => {
    const [currentState, updateState] = useState({
        error: '',
        loading: false,
        description: '',
        success: false,
        user_id: props.userId,
        referralConfigData: {
            ...props?.state?.referralSummary,
        },
    });

    if (currentState?.referralConfigData?.number_of_referrals > 0) {
        return (
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    width: '100%',
                    height: '65px',
                    flexDirection: 'column',
                    ml: 40,
                    mt: 50,
                }}
            >
                <Paragraph sx={{ mb: 40, fontWeight: '800' }}>Referral Config</Paragraph>
                <Paragraph sx={{ mb: 40 }}>
                    Current referral code: {currentState.referralConfigData?.referral_code}
                </Paragraph>

                <Paragraph sx={{ mb: 40 }}>
                    Referrer USDC bonus: {currentState.referralConfigData?.referrer_usdt_amount}
                </Paragraph>

                <Paragraph sx={{ mb: 40 }}>
                    Referee USDC bonus: {currentState.referralConfigData?.referee_usdt_amount}
                </Paragraph>

                <Paragraph sx={{ mb: 40 }}>
                    Credit on verification: {currentState.referralConfigData?.refer_on_verification ? 'Yes' : 'No'}
                </Paragraph>
            </Flex>
        );
    }

    return (
        <Flex
            sx={{
                justifyContent: 'space-between',
                width: '100%',
                height: '65px',
                flexDirection: 'column',
                ml: 40,
                mt: 30,
            }}
        >
            {currentState.loading && <Spinner />}

            <Paragraph sx={{ mt: 40, mb: 30, fontWeight: '800' }}>Update Referral Config</Paragraph>

            <Paragraph sx={{ mb: 40 }}>
                Current referral code: {currentState.referralConfigData?.referral_code}
            </Paragraph>

            <FormInput
                onChange={(e) =>
                    updateState({
                        ...currentState,
                        referralConfigData: {
                            ...currentState.referralConfigData,
                            new_referral_code: e.target.value.toUpperCase(),
                        },
                    })
                }
                value={currentState.referralConfigData.new_referral_code}
                sx={{ width: 150 }}
                maxLength={6}
                label="New Referral Code"
            />

            <Box sx={{ mt: 20 }} />

            <Flex sx={{ width: 400 }}>
                <FormInput
                    onChange={(e) =>
                        updateState({
                            ...currentState,
                            referralConfigData: {
                                ...currentState.referralConfigData,
                                referrer_usdt_amount: e.target.value,
                            },
                        })
                    }
                    value={currentState.referralConfigData?.referrer_usdt_amount}
                    sx={{ width: 150 }}
                    label="Referrer USDT bonus"
                />

                <FormInput
                    onChange={(e) =>
                        updateState({
                            ...currentState,
                            referralConfigData: {
                                ...currentState.referralConfigData,
                                referee_usdt_amount: e.target.value,
                            },
                        })
                    }
                    value={currentState.referralConfigData?.referee_usdt_amount}
                    sx={{ width: 150 }}
                    label="Referee USDT bonus"
                />
            </Flex>

            <Flex
                sx={{ alignItems: 'center', mt: 20 }}
                onClick={(e) =>
                    updateState({
                        ...currentState,
                        referralConfigData: {
                            ...currentState.referralConfigData,
                            refer_on_verification: !currentState.referralConfigData.refer_on_verification,
                        },
                    })
                }
            >
                <Paragraph sx={{ mb: 10, mt: 10, mr: 10 }}>Credit account when user is verified*:</Paragraph>
                <Checkbox checked={currentState.referralConfigData?.refer_on_verification} />
            </Flex>

            <Button onClick={() => submit(currentState, updateState, props.close)} sx={{ width: 170, mt: 20 }}>
                Submit
            </Button>

            <Paragraph sx={{ fontSize: 12, mt: 30 }}>
                * The default is for a user to be credited after 30 days after investing 500 EUR
            </Paragraph>

            {currentState.error && <Paragraph sx={{ color: 'red', mt: 20 }}>{currentState.error}</Paragraph>}

            {currentState.success && <Paragraph sx={{ color: 'green', mt: 20 }}>Document uploaded</Paragraph>}
        </Flex>
    );
};

export default ReferralConfig;
