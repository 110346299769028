/** @jsxImportSource theme-ui */
import { Spinner, Flex } from 'theme-ui';

const AccruSpinner = ({ customSx = {} }) => (
    <Flex
        sx={{
            position: 'fixed',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 10000,
            ...customSx,
        }}
        onClick={(e) => e.stopPropagation()}
    >
        <Spinner sx={{ width: 100, height: 100 }} />
    </Flex>
);

export default AccruSpinner;
