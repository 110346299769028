import { Flex } from 'theme-ui';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import * as moment from 'moment';
import { formatXAxis } from '../../utils/dashboard-helper';
import { Title } from '../table';

const Registrations = ({ state }) => (
    <Flex sx={{ flexDirection: 'column', mt: '20px' }}>
        <Title title="Registrations 🙋‍♂️" state={state} updateState={{}} showEditIcon={false} />
        <Flex sx={{ mt: '35px', flexDirection: 'row' }}>
            <BarChart
                width={400}
                height={300}
                data={state.registrations}
                margin={{
                    top: 5,
                    right: 35,
                    left: 0,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    tickFormatter={formatXAxis}
                    dataKey="timestamp"
                    minTickGap={45}
                    tickMargin={10}
                    tickSize={6}
                    height={40}
                />
                <YAxis />
                <Tooltip labelFormatter={(label) => moment.utc(label).format('Do MMM YYYY HH:mm')} />
                <Legend />
                <Bar dataKey="amount" name="Total Registrations" fill="#5100C9" />
            </BarChart>
            <BarChart
                width={400}
                height={300}
                data={state.dailyRegistrations}
                margin={{
                    top: 5,
                    right: 0,
                    left: 0,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    tickFormatter={formatXAxis}
                    dataKey="timestamp"
                    minTickGap={45}
                    tickMargin={10}
                    tickSize={6}
                    height={40}
                />
                <YAxis />
                <Tooltip labelFormatter={(label) => moment.utc(label).format('Do MMM YYYY HH:mm')} />
                <Legend />
                <Bar dataKey="amount" name="Daily Registrations" fill="#F0B929" />
            </BarChart>
        </Flex>
    </Flex>
);

export default Registrations;
