/* eslint-disable no-unused-vars */
import { Flex } from 'theme-ui';
import { Pagination } from '../table';
import { getComments } from '../../utils/pagination';

const CommentsPagination = ({ currentState, updateState, paginationData, setPaginationData }) => (
    <Flex sx={{ width: '200px', margin: '20px auto', justifyContent: 'space-around' }}>
        <Pagination
            paginationData={paginationData}
            setPaginationData={setPaginationData}
            state={currentState}
            updateState={updateState}
            dataFetchFunction={getComments}
        />
    </Flex>
);

export default CommentsPagination;
