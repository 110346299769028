/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import { Flex, Paragraph } from 'theme-ui';

import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { countryCodeEmoji } from 'country-code-emoji';

import { useEffect, useState } from 'react';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';

const defaultState = {
    loading: true,
};

const fetchCountryBreakdown = async (state, updateState, history) => {
    try {
        updateState({ ...state, loading: true });

        const [countryBreakdown, { data: countriesList }] = await Promise.all([
            axios({
                url: process.env.REACT_APP_ADMIN_SEARCH_API,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                },
                method: 'POST',
                data: {
                    size: 100,
                    query: {
                        bool: {
                            filter: [{ match_phrase: { KycStatus: { query: 'verified' } } }],
                        },
                    },
                    aggs: {
                        countries: {
                            terms: { field: 'Address.country_as_keyword' },
                        },
                    },
                },
            }),
            axios({
                url: `https://cdn.accru.finance/countries/codes.json?cache=${new Date().getTime()}`,
            }),
        ]);

        updateState({
            ...state,
            countriesList,
            loading: false,
            breakdown: countryBreakdown.data.aggregations.countries.buckets.map((bucket) => {
                const country = formatCountry(countriesList, bucket.key);
                return {
                    ...bucket,
                    label: country?.name || '',
                    code2Digit: country?.code2Digit || '',
                };
            }),
        });
    } catch (e) {
        history.push('/');
    }
};

const formatCountry = (countriesList, value) => {
    if (!value) {
        return '';
    }
    return countriesList.find((item) => item.code === value);
};

const Users = ({ logOut, memberships }) => {
    const [state, updateState] = useState(defaultState);
    const [showMenu, toggleMenu] = useState(true);
    const history = useHistory();

    useEffect(() => {
        fetchCountryBreakdown(state, updateState, history);
    }, []);

    return (
        <Flex
            sx={{
                overflow: 'hidden',
                top: '0',
                left: '0',
                bottom: 0,
                minHeight: '100vh',
                width: '100vw',
                flexDirection: 'row',
                backgroundColor: 'greyBackground',
                margin: 0,
                padding: 0,
            }}
        >
            <Sidebar
                logout={logOut}
                currentPage="Countries"
                showMenu={showMenu}
                toggleMenu={toggleMenu}
                history={history}
                memberships={memberships}
            />

            <Flex
                sx={{
                    border: '0px red solid',
                    width: '90%',
                    maxWidth: '900px',
                    minHeight: '400px',
                    flexDirection: 'column',
                    mt: '50px',
                    mb: '100px',
                    ml: showMenu ? 240 : 45,
                }}
            >
                <Flex
                    onClick={() => history.push('/users')}
                    sx={{
                        alignSelf: 'flex-start',
                        marginTop: '-30px',
                        marginLeft: 0,
                        fontSize: '15px',
                        color: '#5100C9',
                        position: 'absolute',
                        cursor: 'pointer',
                    }}
                >
                    <i sx={{ marginRight: '10px', paddingTop: '5px' }} className="fal fa-long-arrow-left" />
                    <Paragraph sx={{ pl: '5px', mt: '-2px' }}>Back</Paragraph>
                </Flex>

                {state.loading && <Spinner />}

                <Flex
                    sx={{
                        width: '100%',
                        maxWidth: '900px',
                        minHeight: '450px',
                        backgroundColor: 'white',
                        borderRadius: '12px',
                        flexDirection: 'column',
                    }}
                >
                    <Paragraph sx={{ fontSize: 20, ml: 60, mt: 60, fontWeight: '600' }}>
                        Verified users by country
                    </Paragraph>

                    <Flex sx={{ flexDirection: 'column', padding: 60, width: 400 }}>
                        {state?.breakdown?.map((breakdown) => (
                            <Flex sx={{ justifyContent: 'space-between', mb: 10 }}>
                                <Flex sx={{ justifyContent: 'space-between', mb: 10 }}>
                                    <Paragraph sx={{ mr: 10, mt: '-3px' }}>
                                        {breakdown.code2Digit && countryCodeEmoji(breakdown.code2Digit)}
                                    </Paragraph>
                                    <Paragraph>{breakdown.label}</Paragraph>
                                </Flex>
                                <Paragraph>{breakdown.doc_count}</Paragraph>
                            </Flex>
                        ))}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

const mapDispatchToProps = (dispatch) => ({
    logOut: () => dispatch({ type: 'LOG_USER_OUT' }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return { loggedIn: account.loggedIn, memberships: account.memberships };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
