/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import { useHistory } from 'react-router-dom';
import { Flex, Paragraph } from 'theme-ui';
import { connect } from 'react-redux';
import { useState } from 'react';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';
import TransactionsList from '../components/transactions-list';

const Trades = ({ logOut }) => {
    const [state] = useState({ loading: false });
    const [showMenu, toggleMenu] = useState(true);
    const history = useHistory();

    return (
        <Flex
            sx={{
                overflow: 'hidden',
                top: '0',
                left: '0',
                bottom: 0,
                minHeight: '100vh',
                width: '100vw',
                flexDirection: 'row',
                backgroundColor: 'greyBackground',
                margin: 0,
                padding: 0,
            }}
        >
            <Sidebar
                logout={logOut}
                currentPage="Trades"
                showMenu={showMenu}
                toggleMenu={toggleMenu}
                history={history}
            />

            <Flex
                sx={{
                    border: '0px red solid',
                    width: '90%',
                    maxWidth: '900px',
                    minHeight: '400px',
                    flexDirection: 'column',
                    mt: '50px',
                    mb: '100px',
                    ml: showMenu ? 240 : 45,
                }}
            >
                <Flex
                    onClick={() => history.push('/users')}
                    sx={{
                        alignSelf: 'flex-start',
                        marginTop: '-30px',
                        marginLeft: 0,
                        fontSize: '15px',
                        color: '#5100C9',
                        position: 'absolute',
                        cursor: 'pointer',
                    }}
                >
                    <i sx={{ marginRight: '10px', paddingTop: '5px' }} className="fal fa-long-arrow-left" />
                    <Paragraph sx={{ pl: '5px', mt: '-2px' }}>Back</Paragraph>
                </Flex>

                {state.loading && <Spinner />}

                <TransactionsList
                    openUserOnViewMore
                    enableDrilldownIntoUser
                    showCurrencyDropDown
                    showProductDropDown
                    showDatePickers
                    expanded
                    title="Trades"
                    type="trades"
                    userId="ALL"
                />
            </Flex>
        </Flex>
    );
};

const mapDispatchToProps = (dispatch) => ({
    logOut: () => dispatch({ type: 'LOG_USER_OUT' }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return { loggedIn: account.loggedIn };
};

export default connect(mapStateToProps, mapDispatchToProps)(Trades);
