import { useState } from 'react';
import axios from 'axios';
import { Flex, Paragraph, Select, Box } from 'theme-ui';
import TransactionsList from '../transactions-list';
import Spinner from '../spinner';

const submitStatusChangeComment = async (userId, comment) => {
    try {
        await axios({
            url: process.env.REACT_APP_USERS_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            data: {
                query: `mutation {
                adminAddComment(input: {
                    user_id: "${userId}", 
                    reason: "${comment}",
                    change: true
                }) {
                    success
                    message
                }}`,
                variables: null,
            },
        });
        // eslint-disable-next-line no-empty
    } catch (e) {}
};

const updateStatus = async (currentState, updateState, status) => {
    const changeComment = `Status changed from ${currentState.monitoringAlert.status} to ${status}`;
    const newState = {
        ...currentState,
        error: false,
        monitoringAlert: { ...currentState.monitoringAlert, status },
        loading: true,
    };

    updateState(newState);

    try {
        const { data } = await axios({
            url: process.env.REACT_APP_TX_MONITORING_API,
            method: 'POST',
            data: {
                query: `mutation { 
                adminUpdateMonitoringCheck(input: { 
                    user_id: "${currentState.userId}",
                    status: "${status}",
                    timestamp: "${currentState.monitoringAlert.timestamp}"
                }) { 
                    success
                }
            }`,
                variables: null,
            },
        });

        await submitStatusChangeComment(
            `USER#${currentState.userId}#CHECK#${currentState.monitoringAlert.timestamp}`,
            changeComment
        );

        if (!data?.data?.adminUpdateMonitoringCheck?.success) throw new Error('failed');

        updateState({ ...newState, loading: false });
    } catch (e) {
        updateState({ ...newState, loading: false, error: true });
    }
};

const Content = ({ monitoringAlert, userId }) => {
    const [currentState, updateState] = useState({ monitoringAlert, userId });

    return (
        <Flex sx={{ flexDirection: 'column', ml: 30, mt: 55 }}>
            {currentState.loading && <Spinner />}

            <Paragraph sx={{ fontSize: 20, fontWeight: '700', mb: 20, ml: 30 }}>Alerts</Paragraph>
            <Flex sx={{ justifyContent: 'space-between', width: 1000, ml: 30 }}>
                <Flex sx={{ flexDirection: 'column' }}>
                    <Paragraph sx={{ mb: 10 }}>The following alerts have been triggered: </Paragraph>

                    <ul style={{ marginLeft: 20, lineHeight: '30px' }}>
                        {monitoringAlert.alerts.map((alert) => (
                            <li key={alert.type}>{alert.description}</li>
                        ))}
                    </ul>
                </Flex>
                <Flex sx={{ flexDirection: 'column' }}>
                    <Paragraph sx={{ mb: '5px' }}>Status</Paragraph>
                    <Select
                        sx={{ width: 350 }}
                        value={currentState.monitoringAlert.status}
                        onChange={(e) => {
                            updateStatus(currentState, updateState, e.target.value);
                        }}
                        arrow={
                            <Box
                                as="svg" // @ts-ignore
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="currentcolor"
                                sx={{
                                    ml: -28,
                                    mt: '3px',
                                    alignSelf: 'center',
                                    pointerEvents: 'none',
                                }}
                            >
                                <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                            </Box>
                        }
                    >
                        <option value="new">New</option>
                        <option value="in_progress">In Progress</option>
                        <option value="resolved">Resolved</option>
                        <option value="under_investigation">Under Investigation</option>
                        <option value="sar">SAR</option>
                    </Select>
                    {currentState.error && (
                        <Paragraph sx={{ color: 'red', mt: 10 }}>Uh oh, something went wrong</Paragraph>
                    )}
                </Flex>
            </Flex>

            {!currentState.loading && (
                <TransactionsList
                    expanded
                    title="Changes"
                    type="changes"
                    commentsLimit={3}
                    checkStatus={currentState.status}
                    userId={`USER#${userId}#CHECK#${monitoringAlert.timestamp}`}
                />
            )}
        </Flex>
    );
};

export default Content;
