/* eslint-disable no-empty */
/* eslint-disable no-use-before-define */
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'theme-ui';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import Amplify from 'aws-amplify';
import AppWrapper from './components/app-wrapper';
import theme from './theme';
import reducers from './store/reducers';
import { rootSaga } from './store/sagas/index';
import LogIn from './pages/log-in';
import Dashboard from './pages/dashboard';
import DashboardOrig from './pages/dashboard-orig';
import Trades from './pages/trades';
import Users from './pages/users';
import UserDetails from './pages/user-detail';
import Corporates from './pages/corporates';
import CorporateDetails from './pages/corporate-detail';
import KycComplete from './pages/kyc-complete';
import Settings from './pages/settings';
import Countries from './pages/countries';
import DepositsToApprove from './pages/deposits-to-approve';
import Withdrawals from './pages/withdrawals';
import Invest from './pages/invest';
import ReferralLeaderboard from './pages/referral-leaderboard';
import TransactionMonitoring from './pages/transaction-monitoring';
import { interceptor } from './utils/axios';
import { getMemberships } from './utils/cognito';

Amplify.configure({
    aws_project_region: 'eu-west-1',
    aws_cognito_region: 'eu-west-1',
    aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_USERS_API,
    oauth: {},
});

const sagaMiddleware = createSagaMiddleware();

const composer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancers = composer(applyMiddleware(sagaMiddleware));
const store = createStore(reducers, enhancers);
sagaMiddleware.run(rootSaga);
interceptor(store);

(async () => {
    try {
        const memberships = await getMemberships();
        store.dispatch({ type: 'SET_MEMBERSHIPS', memberships });
    } catch (e) {}
})();

const BackOffice = () => (
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <AppWrapper>
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <LogIn />
                        </Route>
                        <Route exact path="/dashboard">
                            <Dashboard />
                        </Route>
                        <Route exact path="/dashboard-legacy">
                            <DashboardOrig />
                        </Route>
                        <Route exact path="/trades">
                            <Trades />
                        </Route>
                        <Route exact path="/kyc-complete">
                            <KycComplete />
                        </Route>
                        <Route exact path="/settings">
                            <Settings />
                        </Route>
                        <Route exact path="/tx-monitoring">
                            <TransactionMonitoring />
                        </Route>
                        <Route exact path="/users">
                            <Users />
                        </Route>
                        <Route exact path="/deposits-to-approve">
                            <DepositsToApprove />
                        </Route>
                        <Route exact path="/countries">
                            <Countries />
                        </Route>
                        <Route exact path="/withdrawals">
                            <Withdrawals />
                        </Route>
                        <Route exact path="/user/:id/:email">
                            <UserDetails />
                        </Route>
                        <Route exact path="/user/:id">
                            <UserDetails />
                        </Route>
                        <Route exact path="/invest">
                            <Invest />
                        </Route>
                        <Route exact path="/corporates">
                            <Corporates />
                        </Route>
                        <Route exact path="/corporate/:id">
                            <CorporateDetails />
                        </Route>
                        <Route exact path="/referral-performance">
                            <ReferralLeaderboard />
                        </Route>
                        <Route path="*">{() => <p>Insert A 404 Page ... </p>}</Route>
                    </Switch>
                </Router>
            </AppWrapper>
        </ThemeProvider>
    </Provider>
);

export default BackOffice;
