/* eslint-disable no-param-reassign */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-underscore-dangle */
/** @jsxImportSource theme-ui */
import axios from 'axios';
import { Auth } from 'aws-amplify';
import ReactSelect from 'react-select';
import { useState, useEffect } from 'react';
import { Flex, Paragraph, Button, Box, Select } from 'theme-ui';
import Spinner from './spinner';
import FormInput from './form-input';
import KycDropDown from './kyc-drop-down';
import { eddStatuses, eddSubReasons } from './withdrawal-limits';
import AssigneeDropdown from './assign-cases/assignee-dropdown';
import { CountryListSelect } from './user-update/country-select';
import { getAdminUsers } from '../utils/get-user-details';

export const search = async (currentState, updateState, updateMainTable, limit = 10) => {
    updateState({ ...currentState, loading: true });

    let userIdFoundByCryptoAddress;
    if (currentState?.filters?.cryptoAddress) {
        try {
            const { data } = await axios({
                url: process.env.REACT_APP_TRANSACTIONS_API,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                },
                method: 'POST',
                data: {
                    query: `mutation { 
                    adminTools(input: {
                        tool: "find_by_address"
                        address: "${currentState?.filters?.cryptoAddress}"
                    }) { 
                        success
                        message
                        user_id
                    }
                }`,
                    variables: null,
                },
            });

            if (data?.data.adminTools.user_id) {
                userIdFoundByCryptoAddress = data?.data.adminTools.user_id;
            } else {
                userIdFoundByCryptoAddress = '-1';
            }
        } catch (e) {
            userIdFoundByCryptoAddress = '-1';
        }
    }

    const filterOptions = [{ match_all: {} }];

    if (currentState?.filters?.email) {
        filterOptions.push({ match_phrase_prefix: { Email: currentState?.filters?.email } });
    }

    if (currentState?.filters?.firstName) {
        filterOptions.push({ wildcard: { FirstName: `*${currentState?.filters?.firstName}*` } });
    }

    if (currentState?.filters?.lastName) {
        filterOptions.push({ wildcard: { LastName: `*${currentState?.filters?.lastName}*` } });
    }

    if (currentState?.filters?.telephoneNumber) {
        filterOptions.push({ wildcard: { PhoneNumberParts: `*${currentState?.filters?.telephoneNumber}*` } });
    }

    if (currentState?.filters?.kycStatuses) {
        filterOptions.push({
            bool: {
                should: currentState?.filters?.kycStatuses.map((item) => ({
                    match_phrase: {
                        KycStatus: {
                            query: item.value,
                        },
                    },
                })),
            },
        });
    }

    if (currentState?.filters?.edd_statuses && currentState?.filters?.edd_statuses !== 'unknown') {
        filterOptions.push({
            bool: {
                should: currentState?.filters?.edd_statuses.map((item) => ({
                    match_phrase: {
                        EddStatus: {
                            query: item.value,
                        },
                    },
                })),
            },
        });
    }

    if (currentState?.filters?.edd_sub_reasons && currentState?.filters?.edd_sub_reasons !== 'unknown') {
        filterOptions.push({
            bool: {
                should: currentState?.filters?.edd_sub_reasons.map((item) => ({
                    match_phrase: {
                        EddStatusSubReason: {
                            query: item.value,
                        },
                    },
                })),
            },
        });
    }

    if (currentState?.filters?.nationality) {
        filterOptions.push({ match_phrase: { Nationality: { query: currentState?.filters?.nationality } } });
    }

    if (currentState?.filters?.otherNationality) {
        filterOptions.push({ match_phrase: { OtherNationality: { query: currentState?.filters?.otherNationality } } });
    }

    if (currentState?.filters?.countryOfResidence) {
        filterOptions.push({
            match_phrase: { 'Address.country': { query: currentState?.filters?.countryOfResidence } },
        });
    }

    if (currentState?.filters?.userId || userIdFoundByCryptoAddress) {
        filterOptions.push({
            match_phrase: {
                UserId: {
                    query:
                        !userIdFoundByCryptoAddress || userIdFoundByCryptoAddress === '-1'
                            ? currentState?.filters?.userId
                            : userIdFoundByCryptoAddress,
                },
            },
        });
    }

    if (currentState?.filters?.referredByUserId) {
        filterOptions.push({ match_phrase: { ReferredByUserId: { query: currentState?.filters?.referredByUserId } } });
    }

    if (currentState?.filters?.referralCode) {
        filterOptions.push({ match_phrase: { ReferralCode: { query: currentState?.filters?.referralCode } } });
    }

    if (currentState?.filters?.matchStatus && currentState?.filters?.matchStatus !== 'select') {
        filterOptions.push({
            match_phrase: { ComplyAdvantageMatchStatus: { query: currentState?.filters?.matchStatus } },
        });
    }

    if (currentState?.filters?.hasTransactionAlerts && currentState?.filters?.hasTransactionAlerts !== 'select') {
        filterOptions.push({
            match_phrase: {
                HasTransactionAlert: { query: currentState?.filters?.hasTransactionAlerts ? true : false },
            },
        });
    }

    if (currentState?.filters?.assignee && currentState?.filters?.assignee !== 'select') {
        filterOptions.push({
            match_phrase_prefix: { AssignedTo: { query: currentState?.filters?.assignee } },
        });
    }

    if (currentState?.filters?.riskScore && currentState?.filters?.riskScore !== 'select') {
        filterOptions.push({ match_phrase: { 'RiskScore.rating': { query: currentState?.filters?.riskScore } } });
    }

    try {
        const { data } = await axios({
            url: process.env.REACT_APP_ADMIN_SEARCH_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
            data: {
                size: limit,
                from: currentState.offset,
                query: {
                    bool: {
                        filter: filterOptions,
                    },
                },
                sort: [
                    {
                        CreatedAt: {
                            order: 'desc',
                        },
                    },
                ],
            },
        });

        updateState({ ...currentState, error: '', loading: false });

        const formattedForMainTable = data.users.map((item) => ({
            email_address: item._source.Email,
            first_name: item._source.FirstName || '',
            kyc_status: item._source.KycStatus,
            last_name: item._source.LastName,
            user_id: item._source.UserId,
        }));

        updateMainTable(formattedForMainTable, data?.total?.value || 0);
    } catch (e) {
        if (e?.response?.data?.message === 'Disallowed') {
            try {
                await Auth.signOut();
            } catch (ex) {
                console.log('swallow');
            }
            return window.location.reload();
        }
        updateState({ ...currentState, error: 'Uh Oh, there has been a problem', loading: false });
    }
};

const loadData = async (currentState, updateState) => {
    updateState({
        ...currentState,
        loading: true,
    });

    const { data } = await axios({
        url: `https://cdn.accru.finance/countries/codes.json?cache=${new Date().getTime()}`,
    });

    updateState({
        ...currentState,
        countries: [{ code: 'SELECT', name: 'Please Select' }, ...data],
        loading: false,
        adminUserEmails: await getAdminUsers(),
    });
};

const AdvancedSearch = ({ updateMainTable, parentState, updateParentState }) => {
    const [currentState, updateState] = useState({
        countries: [],
    });

    useEffect(() => {
        loadData(currentState, updateState);
    }, []);

    return (
        <Flex sx={{ flexDirection: 'column', ml: 20 }}>
            {currentState.loading && <Spinner />}
            <Paragraph sx={{ mt: 20, mb: 20, fontWeight: '800' }}>Search</Paragraph>
            <Flex>
                <Flex sx={{ flexDirection: 'column' }}>
                    <FormInput
                        onChange={(e) =>
                            updateParentState({
                                ...parentState,
                                filters: { ...parentState.filters, firstName: e.target.value },
                            })
                        }
                        value={parentState?.filters?.firstName}
                        sx={{ width: 400, height: 35 }}
                        label="First Name"
                    />
                    <FormInput
                        onChange={(e) =>
                            updateParentState({
                                ...parentState,
                                filters: { ...parentState.filters, email: e.target.value },
                            })
                        }
                        value={parentState?.filters?.email}
                        sx={{ width: 400, height: 35 }}
                        label="Email"
                    />
                    <FormInput
                        onChange={(e) =>
                            updateParentState({
                                ...parentState,
                                filters: { ...parentState.filters, lastName: e.target.value },
                            })
                        }
                        value={parentState?.filters?.lastName}
                        sx={{ width: 400, height: 35 }}
                        label="Last Name"
                    />
                    <FormInput
                        onChange={(e) =>
                            updateParentState({
                                ...parentState,
                                filters: { ...parentState.filters, telephoneNumber: e.target.value },
                            })
                        }
                        value={parentState?.filters?.telephoneNumber}
                        sx={{ width: 400, height: 35 }}
                        label="Telephone Number"
                    />
                    <Paragraph sx={{ mb: 10, mt: 10 }}>KYC Status</Paragraph>
                    <KycDropDown
                        includePleaseSelectOption
                        currentState={parentState.filters}
                        updateState={(filters) => updateParentState({ ...parentState, filters })}
                    />

                    <Box sx={{ mt: 10 }} />
                    <FormInput
                        onChange={(e) =>
                            updateParentState({
                                ...parentState,
                                filters: { ...parentState.filters, referredByUserId: e.target.value },
                            })
                        }
                        value={parentState?.filters?.referredByUserId}
                        sx={{ width: 400, height: 35 }}
                        label="Referred By User Id"
                    />

                    <Box sx={{ mt: 10 }} />
                    <FormInput
                        onChange={(e) =>
                            updateParentState({
                                ...parentState,
                                filters: { ...parentState.filters, referralCode: e.target.value },
                            })
                        }
                        value={parentState?.filters?.referralCode}
                        sx={{ width: 400, height: 35 }}
                        label="Referral Code"
                    />

                    <Paragraph sx={{ mt: 10, mb: 15 }}>Assigned To:</Paragraph>
                    <AssigneeDropdown
                        currentState={parentState.filters}
                        adminUserEmails={currentState.adminUserEmails}
                        updateState={(filters) => updateParentState({ ...parentState, filters })}
                        fullHeight={false}
                    />

                    <Flex>
                        <Flex sx={{ flexDirection: 'column' }}>
                            <Paragraph
                                sx={{
                                    mt: '0px',
                                    textAlign: 'left',
                                    maxWidth: '450px',
                                    color: '#09042B',
                                    fontSize: '16px',
                                }}
                            >
                                EDD Status
                            </Paragraph>
                            <ReactSelect
                                value={parentState?.filters?.edd_statuses}
                                onChange={(value) =>
                                    updateParentState({
                                        ...parentState,
                                        filters: { ...parentState.filters, edd_statuses: value },
                                    })
                                }
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        width: 200,
                                    }),
                                }}
                                isMulti
                                options={eddStatuses.map((item) => ({
                                    value: item.id,
                                    label: item.label,
                                }))}
                            />
                        </Flex>
                        <Flex sx={{ flexDirection: 'column', ml: 20 }}>
                            <Paragraph
                                sx={{
                                    mt: '0px',
                                    textAlign: 'left',
                                    maxWidth: '450px',
                                    color: '#09042B',
                                    fontSize: '16px',
                                }}
                            >
                                EDD Sub Reasons
                            </Paragraph>
                            <ReactSelect
                                value={parentState?.filters?.edd_sub_reasons}
                                onChange={(value) =>
                                    updateParentState({
                                        ...parentState,
                                        filters: { ...parentState.filters, edd_sub_reasons: value },
                                    })
                                }
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        width: 200,
                                    }),
                                }}
                                isMulti
                                options={eddSubReasons.map((item) => ({
                                    value: item.id,
                                    label: item.label,
                                }))}
                            />
                        </Flex>
                    </Flex>

                    <FormInput
                        onChange={(e) =>
                            updateParentState({
                                ...parentState,
                                filters: { ...parentState.filters, cryptoAddress: e.target.value },
                            })
                        }
                        value={parentState?.filters?.cryptoAddress}
                        sx={{ width: 400, height: 35 }}
                        label="Crypto Address"
                        labelStyle={{ mt: 10 }}
                    />
                </Flex>
                <Flex sx={{ ml: 250, flexDirection: 'column', minHeight: 800 }}>
                    {currentState.countries?.length ? (
                        <CountryListSelect
                            error={null}
                            size="small"
                            label="Nationality"
                            value={parentState?.filters?.nationality}
                            countries={currentState.countries}
                            updateField={(e) =>
                                updateParentState({
                                    ...parentState,
                                    filters: { ...parentState.filters, nationality: e === 'SELECT' ? '' : e },
                                })
                            }
                        />
                    ) : null}
                    <Box sx={{ mt: 20 }} />
                    {currentState.countries?.length ? (
                        <CountryListSelect
                            error={null}
                            size="small"
                            label="Other Nationality"
                            value={parentState?.filters?.otherNationality}
                            countries={currentState.countries}
                            updateField={(e) =>
                                updateParentState({
                                    ...parentState,
                                    filters: { ...parentState.filters, otherNationality: e === 'SELECT' ? '' : e },
                                })
                            }
                        />
                    ) : null}
                    <Box sx={{ mt: 20 }} />
                    {currentState.countries?.length ? (
                        <CountryListSelect
                            error={null}
                            size="small"
                            label="Country Of Residence"
                            value={parentState?.filters?.countryOfResidence}
                            countries={currentState.countries}
                            updateField={(e) =>
                                updateParentState({
                                    ...parentState,
                                    filters: { ...parentState.filters, countryOfResidence: e === 'SELECT' ? '' : e },
                                })
                            }
                        />
                    ) : null}
                    <Box sx={{ mt: 20 }} />
                    <FormInput
                        onChange={(e) =>
                            updateParentState({
                                ...parentState,
                                filters: { ...parentState.filters, userId: e.target.value },
                            })
                        }
                        value={parentState?.filters?.userId}
                        sx={{ width: 400, height: 35 }}
                        label="User Id"
                    />

                    <Paragraph sx={{ mb: 10, mt: 10 }}>Match Status</Paragraph>

                    <Select
                        defaultValue={currentState.matchStatus}
                        value={parentState?.filters?.matchStatus}
                        onChange={(e) =>
                            updateParentState({
                                ...parentState,
                                filters: { ...parentState.filters, matchStatus: e.target.value },
                            })
                        }
                        sx={{ mb: 20, width: 300, height: 35 }}
                        arrow={
                            <Box
                                as="svg"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="currentcolor"
                                sx={{
                                    ml: -28,
                                    mt: -15,
                                    alignSelf: 'center',
                                    pointerEvents: 'none',
                                }}
                            >
                                <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                            </Box>
                        }
                    >
                        <option value="select">Please Select</option>
                        <option value="no_match">No Match</option>
                        <option value="false_positive">False Positive</option>
                        <option value="potential_match">Potential Match</option>
                        <option value="true_positive">True Positive</option>
                        <option value="unknown">Unknown</option>
                        <option value="true_positive_approve">True Positive Approve</option>
                        <option value="true_positive_reject">True Positive Reject</option>
                    </Select>

                    <Paragraph sx={{ mb: 10, mt: 10 }}>Risk Score</Paragraph>

                    <Select
                        defaultValue={currentState.riskScore}
                        value={parentState?.filters?.riskScore}
                        onChange={(e) =>
                            updateParentState({
                                ...parentState,
                                filters: { ...parentState.filters, riskScore: e.target.value },
                            })
                        }
                        sx={{ mb: 20, width: 300, height: 35 }}
                        arrow={
                            <Box
                                as="svg"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="currentcolor"
                                sx={{
                                    ml: -28,
                                    mt: -15,
                                    alignSelf: 'center',
                                    pointerEvents: 'none',
                                }}
                            >
                                <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                            </Box>
                        }
                    >
                        <option value="select">Please Select</option>
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                    </Select>

                    <Paragraph sx={{ mb: 10, mt: 10 }}>Has Transaction Alerts </Paragraph>

                    <Select
                        defaultValue={parentState?.filters?.hasTransactionAlerts}
                        value={parentState?.filters?.hasTransactionAlerts}
                        onChange={(e) =>
                            updateParentState({
                                ...parentState,
                                filters: { ...parentState.filters, hasTransactionAlerts: e.target.value },
                            })
                        }
                        sx={{ mb: 20, width: 300, height: 35 }}
                        arrow={
                            <Box
                                as="svg"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="currentcolor"
                                sx={{
                                    ml: -28,
                                    mt: -15,
                                    alignSelf: 'center',
                                    pointerEvents: 'none',
                                }}
                            >
                                <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                            </Box>
                        }
                    >
                        <option value="select">Please Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </Select>

                    <Button
                        onClick={() => search(parentState, updateParentState, updateMainTable)}
                        sx={{ alignSelf: 'flex-end', width: 180, mt: 20 }}
                    >
                        Search
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default AdvancedSearch;
