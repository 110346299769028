/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import axios from 'axios';

export const getUsers = async (
    state: any,
    updateState: any,
    direction = 'next',
    paginationData: any,
    setPaginationData: any
) => {
    updateState({
        ...state,
        loading: true,
    });

    const pageArray = [...paginationData.paginationArray];

    let pageNumber;
    let token;

    if (direction === 'start') {
        pageNumber = paginationData.pageNumber;
        token = pageArray[pageNumber];
    } else if (direction === 'next') {
        pageNumber = paginationData.pageNumber + 1;
        token = pageArray[pageNumber];
    } else {
        pageNumber = paginationData.pageNumber - 1;
        token = pageArray[pageNumber];
    }

    const { data } = await axios({
        url: process.env.REACT_APP_USERS_API,
        method: 'POST',
        data: {
            query: `query { 
                searchForUsers(limit: 13, ${token ? `pagination_token: "${token}"` : ''}, filter: "${
                state.email || ''
            }") { 
                    users{
                        email_address
                        first_name
                        last_name
                        user_id
                        kyc_status
                    }
                    pagination_token
                }
            }`,
            variables: null,
        },
    });

    const result = data.data?.searchForUsers;

    if (['next', 'start'].includes(direction)) {
        pageArray[pageNumber + 1] = result?.pagination_token;
    }

    const newPaginationData = {
        paginationArray: pageArray,
        pageNumber,
    };

    updateState({
        users: result?.users,
        loading: false,
    });

    setPaginationData(newPaginationData);

    return result;
};

export const getComments = async (
    state: any,
    updateState: any,
    direction = 'next',
    paginationData: any,
    setPaginationData: any
) => {
    updateState({
        ...state,
        loading: true,
    });

    const pageArray = [...paginationData.paginationArray];

    let pageNumber;
    let token;

    if (direction === 'start') {
        pageNumber = paginationData.pageNumber;
        token = pageArray[pageNumber];
    } else if (direction === 'next') {
        pageNumber = paginationData.pageNumber + 1;
        token = pageArray[pageNumber];
    } else {
        pageNumber = paginationData.pageNumber - 1;
        token = pageArray[pageNumber];
    }

    const { data } = await axios({
        url: process.env.REACT_APP_USERS_API,
        method: 'POST',
        data: {
            query: `query { 
                adminUserGetComments(limit: ${state.commentsLimit || 10}, user_id: "${state.userId}", 
                ${token !== '' ? `pagination_token: "${token}"` : ''}) { 
                    items {
                        reason
                        user_id
                        created_at
                    }
                    pagination_token
            } 
        }`,
            variables: null,
        },
    });

    const result = data.data?.adminUserGetComments;

    if (['next', 'start'].includes(direction)) {
        pageArray[pageNumber + 1] = result?.pagination_token;
    }

    const newPaginationData = {
        paginationArray: pageArray,
        pageNumber,
    };

    updateState({
        ...state,
        items: result?.items,
        loading: false,
    });

    setPaginationData(newPaginationData);

    return result;
};

export const getActionedWithdrawals = async (currentState: any, updateState: any) => {
    try {
        const { data } = await axios({
            url: process.env.REACT_APP_TRANSACTIONS_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
            data: {
                query: `mutation { 
                    adminTools(input: {
                        offset: ${currentState.offset}
                        limit: ${currentState.limit}
                        tool: "list_actioned_withdrawals"
                    }) { 
                        success
                        message
                        count
                        withdrawals {
                            user_id
                            amount
                            currency
                            uuid
                            created_at
                            withdrawal_date
                        }
                    }
            }`,
                variables: null,
            },
        });

        if (!data.data.adminTools.success) {
            return updateState({ ...currentState, error: 'Uh Oh, there has been a problem', loading: false });
        }

        return updateState({
            ...currentState,
            items: data.data.adminTools.withdrawals,
            total: data.data.adminTools.count,
            loading: false,
        });
    } catch (e) {
        return updateState({ ...currentState, error: 'Uh Oh, there has been a problem', loading: false });
    }
};

export const getDepositsToApprove = async (currentState: any, updateState: any) => {
    try {
        const { data } = await axios({
            url: process.env.REACT_APP_TRANSACTIONS_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
            data: {
                query: `mutation { 
                    adminTools(input: {
                        offset: ${currentState.offset}
                        limit: ${currentState.limit}
                        status: "${currentState.depositsToApproveStatuses}"
                        user_id: "${currentState.userId}"
                        tool: "list_deposits_to_inspect"
                    }) { 
                        success
                        message
                        count
                        deposits_to_inspect {
                            user_id
                            amount
                            currency
                            uuid
                            status
                            created_at
                            first_name_on_record
                            last_name_on_record
                            name_on_transaction
                            iban
                            account_number
                            sort_code
                        }
                    }
            }`,
                variables: null,
            },
        });

        if (!data.data.adminTools.success) {
            return updateState({ ...currentState, error: 'Uh Oh, there has been a problem', loading: false });
        }

        return updateState({
            ...currentState,
            items: data.data.adminTools.deposits_to_inspect,
            total: data.data.adminTools.count,
            loading: false,
        });
    } catch (e) {
        return updateState({ ...currentState, error: 'Uh Oh, there has been a problem', loading: false });
    }
};

export const getCorporateUsers = async (currentState: any, updateState: any) => {
    const filterOptions = [{ match_all: {} }, { match_phrase: { CorporateId: { query: currentState.userId } } }];

    try {
        const { data } = await axios({
            url: process.env.REACT_APP_ADMIN_SEARCH_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
            data: {
                from: currentState.offset,
                size: 10,
                query: {
                    bool: {
                        filter: filterOptions,
                    },
                },
                sort: [
                    {
                        CreatedAt: {
                            order: 'desc',
                        },
                    },
                ],
            },
        });

        const formattedForMainTable = data.users.map((item: any) => ({
            email_address: item._source.Email,
            corporate_id: item._source.UserId,
            created_at: item._source.CreatedAt,
        }));

        updateState({
            ...currentState,
            items: formattedForMainTable,
            loading: false,
            total: data?.total?.value || 0,
        });
    } catch (e) {
        updateState({ ...currentState, error: 'Uh Oh, there has been a problem', loading: false });
    }
};
