import axios from 'axios';
import { useState } from 'react';
import { Flex, Textarea, Paragraph, Button, Heading } from 'theme-ui';
import ReactSelect from 'react-select';
import Spinner from './spinner';
import { SingleSelectDropDown } from './kyc-drop-down';

const subStatusOptions = [
    { value: 'identity_ood', label: 'Identity Doc - Expired' },
    { value: 'identity_wrong_doc', label: 'Identity Doc - Not Acceptable Type' },
    { value: 'identity_missing_issue_date', label: 'Identity Doc - Missing Issue Date' },
    { value: 'identity_edges_cut_off', label: 'Identity Doc - Obscured Info' },
    { value: 'identity_too_blurry', label: 'Identity Doc - Blurred / Not Readable' },
    { value: 'identity_suspicious', label: 'Identity Doc - Suspicious Document' },
    { value: 'identity_third_party', label: 'Identity Doc - Third Party Document' },
    { value: 'identity_missing_backside', label: 'Identity Doc - Missing back side' },
    { value: 'poa_ood', label: 'Proof of address - Expired' },
    { value: 'poa_diff_inc_address', label: 'Proof of address - Different/Incomplete Address' },
    { value: 'poa_missing_address', label: 'Proof of address - Missing Address' },
    { value: 'poa_wrong_doc', label: 'Proof of address -Not Acceptable Type' },
    { value: 'poa_missing_issue_date', label: 'Proof of address - Missing Issue Date' },
    { value: 'poa_missing', label: 'Proof of address - Document Not Uploaded' },
    { value: 'poa_wrong_format', label: 'Proof of address - Wrong Format' },
    { value: 'poa_different_name', label: 'Proof of address - Different Name' },
    { value: 'poa_edges_cut_off', label: 'Proof of address - Obscured Info' },
    { value: 'video_failed', label: 'Video - Failed' },
    { value: 'video_3rd_party_involvement', label: 'Video - Third party involvement' },
];

const toggleVerifiedStatus = async (currentState: any, updateState: any, closeOverlay: any) => {
    if (!currentState.reason) {
        return updateState({ ...currentState, error: 'Please state a reason for this change' });
    }

    if (currentState.status?.includes('retry') && !currentState.subStatuses?.length) {
        return updateState({ ...currentState, error: 'Please specify why the customer needs to retry something' });
    }

    updateState({ ...currentState, error: null, loading: true });

    try {
        const { data } = await axios({
            url: process.env.REACT_APP_USERS_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            data: {
                query: `mutation {
                updateKycStatus(input: {
                    user_id: "${currentState.userId}", 
                    status: "${currentState.status}", 
                    reason: "${currentState.reason.replace(/\n/g, ' ')}"
                    sub_statuses: [${currentState.subStatuses.map((item: any) => `"${item.value}"`).join(',')}]    
                }) {
                    success
                    message
                }}`,
                variables: null,
            },
        });

        if (!data.data.updateKycStatus.success) {
            return updateState({
                ...currentState,
                error:
                    data.data.updateKycStatus.message ||
                    'We are currently unable to save this change, please try again later',
                loading: false,
            });
        }

        updateState({
            ...currentState,
            error: null,
            loading: false,
        });

        closeOverlay();

        document.body.style.overflowY = 'auto';
    } catch (e) {
        updateState({
            ...currentState,
            error: 'We are currently unable to save this change, please try again later',
            loading: false,
        });
    }
};

const VerifyKyc = (props: any) => {
    const [currentState, updateState] = useState({
        loading: false,
        error: null,
        reason: '',
        status: props.status,
        userId: props.userId,
        subStatuses: [],
    });
    return (
        <Flex sx={{ mt: 70, ml: 20, mr: 20, flexDirection: 'column' }}>
            {currentState.loading && <Spinner />}
            <Heading sx={{ mb: 30 }}>Change KYC Status</Heading>

            <Paragraph sx={{ mb: 10, fontWeight: 800 }}>New Status</Paragraph>
            <SingleSelectDropDown currentState={currentState} updateState={updateState} />

            {currentState.status?.includes('retry') && (
                <>
                    <Paragraph sx={{ mb: 10, fontWeight: 800 }}>Reason customer has been asked to retry</Paragraph>

                    <ReactSelect
                        value={currentState.subStatuses}
                        onChange={(subStatuses: any) => updateState({ ...currentState, subStatuses })}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                width: 300,
                            }),
                        }}
                        isMulti
                        // @ts-ignore
                        options={subStatusOptions}
                    />
                </>
            )}

            <Paragraph sx={{ mb: 10, mt: 20, fontWeight: '800' }}>Please add a comment</Paragraph>

            <Textarea
                value={currentState.reason}
                onChange={(e) => updateState({ ...currentState, reason: e.target.value })}
                sx={{ mb: 30, fontSize: 16, fontFamily: '"Open Sans", sans-serif' }}
                rows={8}
            />

            {currentState.error && <Paragraph sx={{ color: 'red', mb: 30 }}>{currentState.error}</Paragraph>}

            <Button onClick={() => toggleVerifiedStatus(currentState, updateState, props.closeOverlay)} sx={{ mb: 30 }}>
                Save
            </Button>
        </Flex>
    );
};

export default VerifyKyc;
