/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import axios from 'axios';
import { Flex, Paragraph, Button, Link } from 'theme-ui';
import FileBase64 from 'react-file-base64';
import FormInput from './form-input';
import Spinner from './spinner';

const uploadFile = async (currentState, updateState, close) => {
    try {
        const { data } = await axios({
            url: process.env.REACT_APP_USERS_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
            data: {
                query: `mutation {
                    adminUploadDocument(input: {
                        type: "admin_upload"
                        file_type: "${currentState.type}"
                        description: "${currentState.description}"
                        user_id: "${currentState.user_id}"
                        return_signed_url: true
                    }) { 
                        success
                        message
                        signed_url
                    }
                }`,
                variables: null,
            },
        });

        if (!data.data.adminUploadDocument.success) {
            throw new Error('failed');
        }

        await axios({
            url: data.data.adminUploadDocument.signed_url,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'PUT',
            data: {
                type: 'admin_upload',
                file_type: currentState.type,
                base_64: currentState.base64,
            },
        });

        close();
    } catch (e) {
        updateState({ ...currentState, error: 'Failed to upload', loading: false });
    }
};

const getBase64 = (currentState, updateState) => async (file) => {
    updateState({ ...currentState, error: '' });

    const { base64, size, type } = file;

    updateState({
        ...currentState,
        error: '',
        base64,
        size,
        type,
    });
};

const submit = async (currentState, updateState, close) => {
    if (!currentState.description) {
        return updateState({ ...currentState, error: 'Please enter a description' });
    }

    if (!currentState.base64) {
        return updateState({ ...currentState, error: 'Please select a file to upload' });
    }

    updateState({ ...currentState, error: '', loading: true });

    uploadFile(currentState, updateState, close);
};

const UploadUserDocument = (props) => {
    const [currentState, updateState] = useState({
        error: '',
        loading: false,
        description: '',
        success: false,
        user_id: props.userId,
    });
    return (
        <Flex
            sx={{
                justifyContent: 'space-between',
                width: '100%',
                height: '65px',
                flexDirection: 'column',
                ml: 20,
            }}
        >
            {currentState.loading && <Spinner />}

            <Flex sx={{ mt: 40, mb: 30 }}>
                <Paragraph sx={{ fontWeight: '800' }}>Upload User Document</Paragraph>
            </Flex>

            <FormInput
                onChange={(e) => updateState({ ...currentState, description: e.target.value })}
                value={currentState.description}
                sx={{ width: 400 }}
                label="Description"
            />

            <Flex sx={{ mt: 25, mb: 15 }}>
                <FileBase64 onDone={getBase64(currentState, updateState)} />
            </Flex>

            <Button onClick={() => submit(currentState, updateState, props.close)} sx={{ width: 200, mt: 20 }}>
                Submit
            </Button>

            <Link
                onClick={() => props.viewUploads()}
                sx={{ mt: 10, fontSize: 12, textDecoration: 'underline', color: 'blue', ml: 60 }}
            >
                View Uploads
            </Link>

            {currentState.error && <Paragraph sx={{ color: 'red', mt: 20 }}>{currentState.error}</Paragraph>}

            {currentState.success && <Paragraph sx={{ color: 'green', mt: 20 }}>Document uploaded</Paragraph>}
        </Flex>
    );
};

export default UploadUserDocument;
