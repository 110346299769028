import * as axios from 'axios';
import * as moment from 'moment';

export const UTC_TIMESTAMP_FORMAT = 'YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]';

export const getCountForElasticSearch = async (query) => {
    const { data } = await axios({
        url: process.env.REACT_APP_ADMIN_SEARCH_API,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
        },
        params: {
            api: '_count',
        },
        method: 'POST',
        data: {
            query,
        },
    });

    return data?.count;
};

const notStarted = {
    bool: {
        filter: [
            {
                bool: {
                    should: [
                        {
                            match_phrase: {
                                KycStatus: {
                                    query: 'not_started',
                                },
                            },
                        },
                    ],
                },
            },
        ],
    },
};

const pending = {
    bool: {
        filter: [
            {
                bool: {
                    should: [
                        {
                            match_phrase: {
                                KycStatus: {
                                    query: 'pending',
                                },
                            },
                        },
                    ],
                },
            },
        ],
    },
};

const pendingToday = () => ({
    bool: {
        filter: {
            range: {
                KycPendingDate: {
                    gte: moment.utc().startOf('day').format(UTC_TIMESTAMP_FORMAT),
                    lte: moment.utc().endOf('day').format(UTC_TIMESTAMP_FORMAT),
                },
            },
        },
    },
});

const verifiedToday = () => ({
    bool: {
        filter: {
            range: {
                KycVerifiedDate: {
                    gte: moment.utc().startOf('day').format(UTC_TIMESTAMP_FORMAT),
                    lte: moment.utc().endOf('day').format(UTC_TIMESTAMP_FORMAT),
                },
            },
        },
    },
});

const kycPendingForOver24Hours = () => ({
    bool: {
        filter: [
            {
                bool: {
                    must: [
                        {
                            range: {
                                KycPendingDate: {
                                    lte: moment.utc().subtract(24, 'hours').format(UTC_TIMESTAMP_FORMAT),
                                    // ensure we dont get 1970s
                                    gte: moment.utc('2021-01-01T00:00:00Z').format(UTC_TIMESTAMP_FORMAT),
                                },
                            },
                        },
                        {
                            bool: {
                                should: ['pending'].map((x) => ({
                                    match_phrase: {
                                        KycStatus: {
                                            query: x,
                                        },
                                    },
                                })),
                            },
                        },
                    ],
                },
            },
        ],
    },
});

const processedTodayIntoUnsuccessfulState = () => ({
    bool: {
        filter: [
            {
                bool: {
                    must: [
                        {
                            range: {
                                KycUpdatedDate: {
                                    gte: moment.utc().startOf('day').format(UTC_TIMESTAMP_FORMAT),
                                    lte: moment.utc().endOf('day').format(UTC_TIMESTAMP_FORMAT),
                                },
                            },
                        },
                        {
                            bool: {
                                should: [
                                    'blocked',
                                    'rejected',
                                    'edd',
                                    'edd_onboarding',
                                    'edd_ongoing',
                                    'edd_on_hold',
                                    'retry_all',
                                    'retry_poa',
                                    'retry_onfido',
                                ].map((x) => ({
                                    match_phrase: {
                                        KycStatus: {
                                            query: x,
                                        },
                                    },
                                })),
                            },
                        },
                    ],
                },
            },
        ],
    },
});

const retryState = () => ({
    bool: {
        filter: [
            {
                bool: {
                    must: [
                        {
                            bool: {
                                should: ['retry_all', 'retry_poa', 'retry_onfido'].map((x) => ({
                                    match_phrase: {
                                        KycStatus: {
                                            query: x,
                                        },
                                    },
                                })),
                            },
                        },
                    ],
                },
            },
        ],
    },
});

const eddInProgress = () => ({
    bool: {
        filter: [
            {
                bool: {
                    must: [
                        {
                            bool: {
                                should: ['edd', 'edd_onboarding', 'edd_ongoing', 'edd_on_hold'].map((x) => ({
                                    match_phrase: {
                                        KycStatus: {
                                            query: x,
                                        },
                                    },
                                })),
                            },
                        },
                    ],
                },
            },
        ],
    },
});

export const loadCountsForKyc = async () => {
    const [
        notStartedCount,
        pendingCount,
        pendingTodayCount,
        verifiedTodayCount,
        processedTodayIntoUnsuccessfulStateCount,
        kycPendingForOver24HoursCount,
        eddInProgressCount,
        retryStateCount,
    ] = await Promise.all([
        getCountForElasticSearch(notStarted),
        getCountForElasticSearch(pending),
        getCountForElasticSearch(pendingToday()),
        getCountForElasticSearch(verifiedToday()),
        getCountForElasticSearch(processedTodayIntoUnsuccessfulState()),
        getCountForElasticSearch(kycPendingForOver24Hours()),
        getCountForElasticSearch(eddInProgress()),
        getCountForElasticSearch(retryState()),
    ]);

    return {
        notStartedCount,
        pendingCount,
        pendingTodayCount,
        verifiedTodayCount,
        processedTodayIntoUnsuccessfulStateCount,
        kycPendingForOver24HoursCount,
        eddInProgressCount,
        retryStateCount,
    };
};
