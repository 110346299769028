import { useEffect } from 'react';
import { Flex, Box } from 'theme-ui';

const Overlay = (props: any) => {
    useEffect(() => {
        document.body.style.overflowY = 'hidden';
    }, []);

    let maxWidth = 700;
    let maxHeight = 720;

    if (props?.size === 'large') {
        maxWidth = 1200;
        maxHeight = 830;
    } else if (props?.size === 'small') {
        maxWidth = 400;
        maxHeight = 300;
    } else if (props?.size === 'tall') {
        maxWidth = 650;
        maxHeight = 800;
    }

    return (
        <Flex
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                backgroundColor: 'rgba(9,4,43,0.7)',
                width: '100%',
                height: '100%',
                zIndex: 1005,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    width: '98%',
                    maxWidth,
                    height: '98%',
                    maxHeight,
                    backgroundColor: 'white',
                    borderRadius: '12px',
                    position: 'relative',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'scroll',
                }}
            >
                <Flex
                    sx={{ position: 'absolute', top: '23px', right: '25px', cursor: 'pointer', fontWeight: '600' }}
                    onClick={() => {
                        props.closeOverlay();
                        document.body.style.overflowY = 'auto';
                    }}
                    data-testid="close-overlay"
                >
                    <i
                        // @ts-ignore
                        style={{
                            fontSize: 24,
                        }}
                        className="fas fa-times"
                    />
                </Flex>

                {props.overlayContent}
            </Box>
        </Flex>
    );
};

export default Overlay;
