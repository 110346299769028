/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { Flex, Box, Paragraph } from 'theme-ui';
import Input from '../form-input';
import { CountryListSelect } from '../user-update/country-select';

const AddressCompany = ({ formState, updateFormState, countries = [], errorState }: any) => {
    const {
        correspondence_address,
        correspondence_address: { street, sub_street, town, post_code, state, country },
    } = formState;

    return (
        <Flex sx={{ justifyContent: 'center', width: '100%' }}>
            <Box sx={{ width: '100%' }}>
                <Paragraph sx={{ fontWeight: 600, textAlign: 'center', mb: '80px' }}>Correspondence Address</Paragraph>
                <Flex>
                    <Box sx={{ width: '100%', mr: '10px' }}>
                        <Input
                            sx={{
                                mb: '12px',
                                alignSelf: 'center',
                                width: '100%',
                                background: '#FFFFFF',
                                border: errorState.correspondence_address ? '1px red solid' : '1px solid grey',
                            }}
                            type="text"
                            id="street"
                            data-testid="street"
                            name="street"
                            label="Address Line 1"
                            placeholder="enter address line 1"
                            onChange={(e: any) =>
                                updateFormState({
                                    ...formState,
                                    correspondence_address: { ...correspondence_address, street: e.target.value },
                                })
                            }
                            error={null}
                            value={street}
                        />
                        <Input
                            sx={{
                                mb: '12px',
                                alignSelf: 'center',
                                width: '100%',
                                background: '#FFFFFF',
                                border: '1px solid grey',
                            }}
                            type="text"
                            id="sub_street"
                            data-testid="sub_street"
                            name="sub_street"
                            label="Address Line 2"
                            placeholder="enter address line 2"
                            onChange={(e: any) =>
                                updateFormState({
                                    ...formState,
                                    correspondence_address: { ...correspondence_address, sub_street: e.target.value },
                                })
                            }
                            error={null}
                            value={sub_street}
                        />
                        <Input
                            sx={{
                                mb: '12px',
                                alignSelf: 'center',
                                width: '100%',
                                background: '#FFFFFF',
                                border: errorState.correspondence_address ? '1px red solid' : '1px solid grey',
                            }}
                            type="text"
                            id="town"
                            data-testid="town"
                            name="town"
                            label="Town"
                            placeholder="enter address town"
                            onChange={(e: any) =>
                                updateFormState({
                                    ...formState,
                                    correspondence_address: { ...correspondence_address, town: e.target.value },
                                })
                            }
                            error={null}
                            value={town}
                        />
                    </Box>
                    <Box sx={{ width: '100%', mr: '10px' }}>
                        <Input
                            sx={{
                                mb: '12px',
                                alignSelf: 'center',
                                width: '100%',
                                background: '#FFFFFF',
                                border: errorState.correspondence_address ? '1px red solid' : '1px solid grey',
                            }}
                            type="text"
                            id="post_code"
                            data-testid="post_code"
                            name="post_code"
                            label="Post Code"
                            placeholder="enter address post code"
                            onChange={(e: any) =>
                                updateFormState({
                                    ...formState,
                                    correspondence_address: { ...correspondence_address, post_code: e.target.value },
                                })
                            }
                            error={null}
                            value={post_code}
                        />
                        <Input
                            sx={{
                                mb: '12px',
                                alignSelf: 'center',
                                width: '100%',
                                background: '#FFFFFF',
                                border: '1px solid grey',
                            }}
                            type="text"
                            id="state"
                            data-testid="state"
                            name="state"
                            label="State"
                            placeholder="enter address state"
                            onChange={(e: any) =>
                                updateFormState({
                                    ...formState,
                                    correspondence_address: { ...correspondence_address, state: e.target.value },
                                })
                            }
                            error={null}
                            value={state}
                        />
                        <CountryListSelect
                            error={errorState.correspondence_address}
                            label="Country"
                            value={country}
                            countries={countries}
                            updateField={(e: any) =>
                                updateFormState({
                                    ...formState,
                                    correspondence_address: { ...correspondence_address, country: e },
                                })
                            }
                        />
                    </Box>
                </Flex>
            </Box>
        </Flex>
    );
};

export default AddressCompany;
