/* eslint-disable camelcase */

import axios from 'axios';
import { formatDate } from './validation-helpers';

export const UpdateUserDetails = async ({
    user_id,
    first_name,
    middle_names,
    last_name,
    occupation,
    source_of_funds,
    date_of_birth,
    nationality,
    address,
    place_of_birth,
    country_of_birth,
    personal_id_number,
    other_nationality,
    expected_amount_to_invest,
    expected_amount_to_invest_crypto,
    reason,
}: any) => {
    try {
        const { data } = await axios({
            url: process.env.REACT_APP_USERS_API,
            method: 'POST',
            data: {
                query: `mutation {
                    adminUserUpdate(input: {
                        user_id: "${user_id}",                  
                        first_name: "${first_name || ''}",
                        middle_names: "${middle_names || ''}",
                        last_name: "${last_name || ''}",
                        occupation: "${occupation || ''}",
                        source_of_funds: "${source_of_funds || ''}",
                        dob: "${date_of_birth ? formatDate(date_of_birth, true) : ''}",
                        nationality: "${nationality || ''}", 
                        address: {
                                    street: "${address?.street || ''}",
                                    sub_street: "${address?.sub_street || ''}",
                                    town: "${address?.town || ''}",
                                    post_code: "${address?.post_code || ''}",
                                    state: "${address?.state || ''}",
                                    country: "${address?.country || ''}"
                                },
                        place_of_birth: "${place_of_birth || ''}",
                        country_of_birth: "${country_of_birth || ''}",
                        personal_id_number: "${personal_id_number || ''}",
                        other_nationality: "${other_nationality || ''}",
                        expected_amount_to_invest: "${expected_amount_to_invest || ''}",
                        expected_amount_to_invest_crypto: "${expected_amount_to_invest_crypto || ''}",
                        reason: "${reason}"
                    }) { 
                        success
                        message
                        code  
                    }
                }`,
            },
        });
        const result = data.data?.adminUserUpdate;
        return result;
    } catch {
        return { success: false };
    }
};
