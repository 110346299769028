/* eslint-disable no-nested-ternary */
import { Flex, Paragraph } from 'theme-ui';
import { useState } from 'react';

const ItemWrapper = (props) => {
    if (props.href) {
        return (
            <a style={{ textDecoration: 'none' }} href={props.href}>
                {props.children}
            </a>
        );
    }
    return props.children;
};

export default ({
    title,
    value,
    linkToPage = false,
    pageUrl = '',
    smallWidth = false,
    empty = false,
    customSx = {},
    customKeySx = {},
    valueColor = 'black',
}) => {
    const [copied, updateState] = useState(false);

    const copyValue = () => {
        updateState(true);
        navigator.clipboard.writeText(value);
        setTimeout(() => {
            updateState(false);
        }, 1000);
    };

    return (
        <ItemWrapper href={linkToPage ? pageUrl : null}>
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    width: 'auto',
                    marginRight: '50px',
                    marginLeft: '0px',
                    minHeight: '40px',
                    color: '#444',
                    cursor: 'pointer',
                    ...customSx,
                }}
                onClick={() => {
                    if (!linkToPage) {
                        copyValue();
                    }
                }}
            >
                <Paragraph
                    sx={{
                        fontSize: '14px',
                        fontWeight: 600,
                        textAlign: 'left',
                        width: smallWidth ? '100px' : '220px',
                        ...customKeySx,
                    }}
                >
                    {title} {empty ? '' : ':'}
                </Paragraph>
                <Paragraph
                    sx={{
                        fontSize: '13px',
                        ml: '30px',
                        width: smallWidth ? '350px' : '150px',
                        overflow: 'hidden',
                        color: linkToPage ? 'purple' : copied ? 'green' : valueColor,
                    }}
                >
                    {copied ? 'copied to clipboard' : value}
                </Paragraph>
            </Flex>
        </ItemWrapper>
    );
};
