import { Flex } from 'theme-ui';
import SubMenuItems from './sub-menu-items';
import SubMenuOptions from './sub-menu-options';

const CorporateDetailsOptions = ({ state, updateState, subMenuType, toggleSubMenu, memberships }) => (
    <>
        <Flex sx={{ justifyContent: 'space-between' }}>
            <SubMenuOptions
                entity="corporate"
                uid={state.corporate_id}
                subMenuType={subMenuType}
                toggleSubMenu={toggleSubMenu}
                items={[
                    { title: 'Company', subMenuGroup: 'corporate' },
                    { title: 'Currency', subMenuGroup: 'currency' },
                    { title: 'Admin', subMenuGroup: 'admin' },
                ]}
            />
        </Flex>

        {subMenuType === 'corporate' && (
            <SubMenuItems
                entity="corporate"
                uid={state.corporate_id}
                state={state}
                updateState={updateState}
                toggleSubMenu={toggleSubMenu}
                items={[
                    ...(memberships?.includes('tier_1')
                        ? [
                              {
                                  activeTab: 'corporateDetail',
                                  activeTabGroup: 'corporate',
                                  title: 'Details',
                              },
                              {
                                  activeTab: 'users',
                                  activeTabGroup: 'corporate',
                                  title: 'Users',
                              },
                          ]
                        : []),
                ]}
            />
        )}
        {subMenuType === 'currency' && (
            <SubMenuItems
                entity="corporate"
                uid={state.corporate_id}
                state={state}
                updateState={updateState}
                toggleSubMenu={toggleSubMenu}
                items={[
                    ...(memberships?.includes('tier_1')
                        ? [
                              {
                                  activeTab: 'graph',
                                  activeTabGroup: 'currency',
                                  title: 'Portfolio',
                              },
                              {
                                  activeTab: 'trades',
                                  activeTabGroup: 'currency',
                                  title: 'Trades',
                              },
                              {
                                  activeTab: 'deposits',
                                  activeTabGroup: 'currency',
                                  title: 'Deposits',
                              },
                              {
                                  activeTab: 'withdrawals',
                                  activeTabGroup: 'currency',
                                  title: 'Withdrawals',
                              },
                              {
                                  activeTab: 'exchange',
                                  activeTabGroup: 'currency',
                                  title: 'Exchange',
                              },
                          ]
                        : []),
                ]}
            />
        )}

        {subMenuType === 'admin' && (
            <SubMenuItems
                entity="corporate"
                uid={state.corporate_id}
                state={state}
                updateState={updateState}
                toggleSubMenu={toggleSubMenu}
                items={[
                    ...(memberships?.includes('compliance')
                        ? [{ activeTab: 'monitoringAlerts', activeTabGroup: 'admin', title: 'Alerts' }]
                        : []),
                    ...(memberships?.includes('tier_1')
                        ? [
                              {
                                  activeTab: 'changes',
                                  activeTabGroup: 'admin',
                                  title: 'Comments',
                              },
                          ]
                        : []),
                    ...(memberships?.includes('api_configurator')
                        ? [{ activeTab: 'apiConfig', activeTabGroup: 'admin', title: 'API' }]
                        : []),
                ]}
            />
        )}
    </>
);

export default CorporateDetailsOptions;
